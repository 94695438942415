import type { HttpErrorResponse } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { catchError, throwError } from 'rxjs';
import type { ComunicacaoErro } from '../models/comunicacao-erro.interface';
import { Globais } from './../../area-restrita/shared/globais/globais';

@Injectable({
  providedIn: 'root'
})
export class ComunicacaoErroService {
  private readonly http = inject(HttpClient);

  incluir(dadosComunicacao: ComunicacaoErro): Observable<ComunicacaoErro> {
    return this.http.post<ComunicacaoErro>(`${Globais.urlToken}site-pge/api/comunicar-erro`, dadosComunicacao).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }
}
