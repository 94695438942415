import { Location } from '@angular/common';
import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, signal, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatChipRow, MatChipSet } from '@angular/material/chips';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { ActivatedRoute } from '@angular/router';
import { finalize, Subject } from 'rxjs';
import { ImagemTratamento } from 'src/app/area-aberta/features/home/shared/services/imagem-tratamento';
import { WaitLoadingService } from 'src/app/area-restrita/shared/components/wait-loading/wait-loading.service';
import { ConversorValidadorService } from 'src/app/area-restrita/shared/services/conversor-validador.service';
import type { Noticias, pdfAnexo } from 'src/app/shared/models/noticia.interface';
import { NoticiaService } from 'src/app/shared/services/noticia.service';
import { ToastService } from 'src/app/shared/toast/toast.service';

@Component({
  selector: 'app-visualizar-noticia',
  templateUrl: './visualizar-noticia.component.html',
  styleUrl: './visualizar-noticia.component.scss',
  providers: [ImagemTratamento],
  standalone: true,
  imports: [MatFormField, MatInput, MatRadioGroup, FormsModule, MatRadioButton, MatIcon, MatLabel, MatChipSet, MatChipRow],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VisualizarNoticiaComponent implements OnInit, OnDestroy {
  private readonly noticiaService = inject(NoticiaService);
  private readonly route = inject(ActivatedRoute);
  private readonly imagemTratamento = inject(ImagemTratamento);
  private readonly location = inject(Location);
  private readonly base64ToPdfService = inject(ConversorValidadorService);
  private readonly toastr = inject(ToastService);
  private readonly loading = inject(WaitLoadingService);

  private readonly destroy$ = new Subject<void>();
  maxCaracteres: number = 50;
  idNoticia: string;
  campoCapa = signal(false);
  campoAcesso = signal(false);
  arquivos = signal<pdfAnexo[]>([]);
  campoDestaque = signal(false);
  listaImagens = signal<pdfAnexo[]>([]);

  readonly palavrasChaves = signal([]);

  noticias = signal<Noticias>(null);

  ngOnInit() {
    this.idNoticia = this.route.snapshot.params['id'];
    this.buscarDados(this.idNoticia);
  }

  buscarDados(id: string) {
    this.loading.open('Buscando notícia...');
    this.noticiaService
      .getOneNoticiaAtivoTrueOrFalse(Number(id))
      .pipe(
        finalize(() => {
          this.loading.close();
        })
      )
      .subscribe({
        next: (response) => {
          this.noticias.set(response);
          this.obterArquivos(Number(id));
          this.alternarAcessoRegistro();
          if (response.palavraChave) {
            const arrayDeString = response.palavraChave.split(' ');
            arrayDeString.forEach((string) => {
              this.adicionar(string);
            });
          }
        },
        error: (error) => {
          console.log(error);
          this.toastr.error('Erro ao obter notícia.');
        }
      });
  }

  obterArquivos(idNoticia: number): void {
    this.noticiaService.obterArquivosNoticias(idNoticia).subscribe(
      (response) => {
        response.forEach((arquivoResponse) => {
          arquivoResponse.imagem ? this.listaImagens.set([...this.listaImagens(), arquivoResponse]) : this.arquivos.set([...this.arquivos(), arquivoResponse]);
        });
      },
      (error) => {
        console.log(error);
        this.toastr.error('Erro ao obter arquivo notícia.');
      }
    );
  }

  adicionar(evento: string): void {
    const valor = (evento || '').trim();
    if (valor) {
      this.palavrasChaves.update((palavraChave) => [...palavraChave, valor]);
    }
  }

  alternarAcessoRegistro(): void {
    if (this.noticias().categoria.nome === 'Notícia Área Restrita') {
      this.campoAcesso.set(true);
      if (this.noticias().imagem !== '' || this.noticias().imagem != null) {
        this.campoCapa.set(true);
      }
    } else if (this.noticias().categoria.nome === 'Notícia Área Aberta') {
      this.campoCapa.set(true);
      this.campoDestaque.set(true);
    }
  }

  obterImagem(): string | undefined {
    return this.imagemTratamento.obterImagemBlob(this.noticias().imagem);
  }

  processarTexto(texto: string): string {
    if (texto) {
      return texto.replace(/<img\b[^>]*>/, (match) => match.replace('>', ' width="40%">'));
    } else {
      return '';
    }
  }

  voltar() {
    this.location.back();
  }

  abrirArquivo(evento: MouseEvent, arquivoPdfOuImagem: pdfAnexo): void {
    evento.preventDefault();
    this.base64ToPdfService.base64ToBlob(arquivoPdfOuImagem.arquivo);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.imagemTratamento.revogarUrlBlob();
  }
}
