import { Component } from '@angular/core';
import { ServicoList } from 'src/app/area-aberta/features/home/servicos/servicos';
import { MenuList } from '../../menu/menu-file';
import { RouterLink } from '@angular/router';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-mapa-site',
  templateUrl: './mapa-site.component.html',
  styleUrl: './mapa-site.component.scss',
  standalone: true,
  imports: [MatIcon, RouterLink]
})
export class MapaSiteComponent {
  menuList = MenuList;
  servicoList = ServicoList;

  espandida: boolean = false;

  alteranarConteudo(): void {
    this.espandida = !this.espandida;
  }
}
