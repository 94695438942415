<div class="container">
  <br />
  <h1>Solicitação de Reembolso</h1>

  <br />
  <form class="row g-3" [formGroup]="dadosFormulario" (ngSubmit)="retificacao() ? EnviarFormularioRetificado() : Enviarformulario()">
    <div class="d-flex row justify-content-center">
      @if (mensagemErro() !== "" && (!dadosFormulario.valid || !requerimentoDigital())) {
        <span class="w-50 text-center bg-danger text-white px-4 rounded-pill">{{ mensagemErro() }}</span>
      }
    </div>
    <div class="formulario-campos">
      <div class="col-md-6">
        <div class="form-group">
          <label for="tipoAuxilio"><span class="text-danger requerido">*</span> Tipo de Auxílio:</label>
          <mat-form-field class="mat-input-250">
            <mat-select class="mat-form-control" formControlName="tipoAuxilio" tabindex="0">
              @for (auxilio of tipoAuxilioResponse(); track auxilio.id) {
                <mat-option [value]="auxilio.id">
                  {{ auxilio.tipo }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-group">
          <label for="tipoAuxilioSaude"><span class="text-danger requerido">*</span>Tipo de Auxílio Saúde:</label>
          <mat-form-field class="mat-input-250">
            <mat-select class="mat-form-control" placeholder="Selecione um Auxílio" formControlName="tipoAuxilioSaude" tabindex="0">
              @for (auxilioSaude of tipoAuxilioSaudeResponse(); track auxilioSaude.id) {
                <mat-option [value]="auxilioSaude.id">
                  {{ auxilioSaude.tipo }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-group">
          <label for="anoReferenteReembolso"><span class="text-danger requerido">*</span> Ano referente ao reembolso:</label>
          <mat-form-field class="mat-input-250">
            <mat-select class="mat-form-control" placeholder="Selecione um Ano" formControlName="anoReembolso" tabindex="0" (selectionChange)="onAnoSelecionado()">
              @for (ano of anoReembolsoResponse(); track ano) {
                <mat-option [value]="ano">
                  {{ ano }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-group">
          <label for="mesSolicitadoReembolso"> <span class="text-danger requerido">*</span> Mês de solicitação reembolso:</label>
          <mat-form-field class="mat-input-250">
            <mat-select class="mat-form-control" placeholder="Selecione um Mês" formControlName="mesParcela" tabindex="0" (selectionChange)="verficacaoReembolsoAnteriorDataNascimentoDependente()">
              @for (mes of mesesSelect(); track mes; let i = $index) {
                <mat-option [value]="i + 1">
                  {{ mes }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-group">
          <label for="dataInscricao"> <span class="text-danger requerido">*</span>Data da inscrição</label>
          <mat-form-field class="mat-input-250">
            <input matInput type="text" disabled="true" id="dataInscricao" name="dataInscricao" tabindex="0" [value]="this.dataInscricaoResponse" />
          </mat-form-field>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label for="dataVencimentoParcela"> <span class="text-danger requerido">*</span> Data do vencimento da parcela:</label>
          <mat-form-field class="mat-input-250">
            <input #DataVencimento matInput type="text" formControlName="dataVencimentoParcela" placeholder="Defina uma Data" [matDatepicker]="picker" />
            <mat-datepicker #picker />
            <mat-datepicker-toggle matIconSuffix [for]="picker" />
          </mat-form-field>
        </div>
        <div class="form-group">
          <label for="mesSolicitadoReembolso"> <span class="text-danger requerido">*</span> Tipo de Solicitação:</label>
          <mat-form-field class="mat-input-250">
            <mat-select
              class="mat-form-control"
              placeholder="Selecione o tipo"
              [disabled]="!habilitarCampoTipoSolicitacao()"
              [(value)]="valorSelecionadoTipo"
              (selectionChange)="alternarCamposHabilitados($event.value)"
            >
              <mat-option class="opcao" [value]="1">Titular</mat-option>
              <mat-option class="opcao" [value]="2">Dependente</mat-option>
              <mat-option class="opcao" [value]="3">Titular e Dependente</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        @if (habilitarTitular()) {
          <div class="form-group">
            <label for="valorSolicitadoReembolsoTitular">
              @if (valorTitularRequerido()) {
                <span class="text-danger requerido">*</span>
              }
              Valor solicitado pelo reembolso titular:</label
            >

            <mat-form-field class="mat-input-250">
              <input
                matInput
                formControlName="valorReembolsoTitular"
                currencyMask
                name="valorSolicitadoReembolsoTitular"
                autocomplete="off"
                tabindex="0"
                maxlength="8"
                [max]="limiteReembolsoResponse()"
                [options]="{
                  align: 'left',
                  allowNegative: false,
                  allowZero: false,
                  decimal: ',',
                  precision: 2,
                  prefix: 'R$ ',
                  suffix: '',
                  thousands: '.',
                  nullable: true,
                  min: 0
                }"
              />
            </mat-form-field>
          </div>
        }
        @if (temDependentes() && habilitarDependente()) {
          <div class="form-group">
            <label for="valorSolicitadoReembolsoDependente"><span class="text-danger requerido">*</span> Valor solicitado pelo reembolso do(s) dependente(s):</label>
            <mat-form-field class="mat-input-250">
              <input
                matInput
                currencyMask
                id="valorSolicitadoReembolsoDependente"
                name="valorSolicitadoReembolsoDependente"
                formControlName="valorReembolsoDependente"
                autocomplete="off"
                tabindex="0"
                maxlength="8"
                [min]="0"
                [options]="{
                  align: 'left',
                  allowNegative: false,
                  allowZero: false,
                  decimal: ',',
                  precision: 2,
                  prefix: 'R$ ',
                  suffix: '',
                  thousands: '.',
                  nullable: true,
                  min: 0
                }"
              />
            </mat-form-field>
          </div>
        }

        <div class="form-group">
          <label for="totalValorSolicitadoReembolso"> <span class="text-danger requerido">*</span>Total valor solicitado pelo Reembolso:</label>
          <div class="d-flex linha-coluna-limite">
            <div>
              <mat-form-field class="mat-input-250">
                <input
                  matInput
                  formControlName="valorReembolsoTotal"
                  currencyMask
                  name="totalValorSolicitadoReembolso"
                  autocomplete="off"
                  tabindex="0"
                  [max]="limiteReembolsoResponse()"
                  [options]="{
                    align: 'left',
                    allowNegative: false,
                    allowZero: false,
                    decimal: ',',
                    precision: 2,
                    prefix: 'R$ ',
                    suffix: '',
                    thousands: '.',
                    nullable: true,
                    min: 0
                  }"
                />
              </mat-form-field>
            </div>
            @if (dadosFormulario.get("anoReembolso").value > 0 && dadosFormulario.get("mesParcela").value > 0 && limiteReembolsoResponse() > somaValorDeferido()) {
              <div class="margin-frase">Limite:{{ limiteReembolsoResponse() - somaValorDeferido() | currency: "BRL" }}</div>
            }
          </div>
          @if (dadosFormulario.get("anoReembolso").value > 0 && dadosFormulario.get("mesParcela").value > 0 && limiteReembolsoResponse() <= somaValorDeferido() && somaValorDeferido() !== null) {
            <span class="text-danger">Valor indisponível para o período selecionado.</span>
          }
        </div>
      </div>
    </div>

    <div class="linhas-arquivos">
      @if (habilitarTitular()) {
        <div class="col-md-6 margin-arquivo">
          <label for="anexoNotaFiscalTitular"> <span class="text-danger requerido">*</span>Anexar nota fiscal do titular:</label>
          @if (alterarCamposHabilitadosOuDesabilitados()) {
            <div>
              <ngx-file-drop dropZoneLabel="Adicione um ou mais arquivos ou solte os arquivos aqui" clickable="true" (onFileDrop)="arquivosSoltos($event, true)" (click)="arquivoSoltoClicado(true)" />
              <div class="clear-float">
                <table>
                  <tbody class="upload-name-style">
                    @for (item of documentosTitular(); track item.id; let i = $index) {
                      <tr>
                        <td>{{ item.nomeArquivo }}</td>
                        <td>
                          <img src="images/fechar.svg" tabindex="0" class="botao-fechar" alt="Fechar" (click)="removerArquivo(i, true)" (keypress)="removerArquivo(i, true)" />
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          }
          @if (!alterarCamposHabilitadosOuDesabilitados()) {
            <div>
              @for (documentoTitular of documentosTitular(); track documentoTitular.id; let i = $index) {
                <div class="row">
                  <div class="col">
                    <a href="#" (click)="abrirPdf($event, documentoTitular)">
                      <img class="icon-pdf" src="images/pdf-file-svgrepo-com.svg" alt="PDF" />
                      {{ documentoTitular.nomeArquivo }}
                    </a>
                  </div>
                </div>
              }
            </div>
          }
        </div>
      }
      @if (temDependentes() && habilitarDependente()) {
        <div class="col-md-6 margin-arquivo">
          <label for="anexoNotaFiscalDependente"><span class="text-danger requerido">*</span>Anexar nota fiscal para os dependentes:</label>
          @if (alterarCamposHabilitadosOuDesabilitados()) {
            <div>
              <ngx-file-drop
                dropZoneLabel="Adicione um ou mais arquivos ou solte os arquivos aqui"
                clickable="true"
                (onFileDrop)="arquivosSoltos($event, false)"
                (click)="arquivoSoltoClicado(false)"
              />
              <div class="clear-float">
                <table>
                  <tbody>
                    @for (item of documentosDependente(); track item.id; let i = $index) {
                      <tr>
                        <td>{{ item.nomeArquivo }}</td>
                        <td>
                          <img src="images/fechar.svg" tabindex="0" class="botao-fechar" alt="Fechar" (click)="removerArquivo(i, false)" (keypress)="removerArquivo(i, false)" />
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          }
          @if (!alterarCamposHabilitadosOuDesabilitados()) {
            <div>
              @for (documentoDependente of documentosDependente(); track documentoDependente; let i = $index) {
                <div class="row">
                  <div class="col">
                    <a href="#" (click)="abrirPdf($event, documentoDependente)">
                      <img class="icon-pdf" src="images/pdf-file-svgrepo-com.svg" alt="PDF" />
                      {{ documentoDependente.nomeArquivo }}
                    </a>
                  </div>
                </div>
              }
            </div>
          }
        </div>
      }
    </div>

    @if (alterarCamposHabilitadosOuDesabilitados()) {
      <div class="margin-frase">Após adicionar o(s) arquivos é necessário clicar no botão Enviar Solicitação.</div>
    }
    @if (alterarCamposHabilitadosOuDesabilitados()) {
      <div>
        <div class="margin-frase">
          <section>
            <mat-checkbox color="primary" id="declaracaoIR" [checked]="requerimentoDigital()" (change)="atualizarRequerimentoDigital()"
              ><span class="text-danger requerido">*</span> <span class="frase-importante">Li e me responsabilizo pelas informações declaradas no requerimento digital.</span></mat-checkbox
            >
          </section>
        </div>
        <div class="margin-frase">
          <section>
            <mat-checkbox color="primary" id="procedimentosEsteticos" [checked]="procedimentosEsteticos()" (change)="atualizarProcedimentosEsteticos()"
              ><span class="text-danger requerido">*</span>
              <span class="frase-importante">Declaro, para os devidos fins, que esta solicitação não tem a finalidade de atender a procedimentos estéticos.</span></mat-checkbox
            >
          </section>
        </div>
        @if (temDependentes() && habilitarDependente()) {
          <div class="margin-frase">
            <section>
              <mat-checkbox color="primary" id="autorizacaoPlano" [checked]="possuoDependente()" (change)="atualizarPossuoDependente()"
                ><span class="text-danger requerido">*</span
                ><span class="frase-importante">Declaro que possuo dependentes conforme resolução PGE nº 38/2021, na redação dada pelo artigo 2º da resolução PGE nº 61/2023.</span></mat-checkbox
              >
            </section>
          </div>
        }
      </div>
    }
    @if (retificacao()) {
      <div class="d-flex row">
        <div class="form-group">
          <label for="observacao">Observação:</label>
          <textarea id="observacao" class="campo-observacao" disabled>{{ this.observacao() }}</textarea>
        </div>
      </div>
    }
    @if (!historico() && retificacao()) {
      <div>
        <div class="d-flex justify-content-end">
          <button mat-flat-button type="submit" class="btn-dark">Enviar Retificação</button>
        </div>
      </div>
    }
    @if (historico()) {
      <div>
        <div class="d-flex justify-content-end">
          <button mat-flat-button type="submit" class="btn-dark">Enviar Solicitação</button>
        </div>
        <div class="ajuste-titulo-botao">
          <div>
            <h4 class="titulo">Histórico de Solicitações</h4>
          </div>
        </div>
        <div class="table-responsive">
          <table class="tabelaPadrao">
            <thead>
              <tr>
                <th class="titulo text-center">Mês Solicitado para Reembolso</th>
                <th class="titulo text-center">Programa</th>
                <th class="titulo text-center">Tipo Auxilio Saúde</th>
                <th class="titulo text-center">Total Solicitado</th>
                <th class="titulo text-center">Total Deferido</th>
                <th class="titulo text-center">Status</th>
              </tr>
            </thead>
            <tbody>
              @for (historico of historicoSolicitacoesResponse(); track historico.id; let i = $index) {
                <tr [ngClass]="historico.status.id === statusAuxilioEnum.CANCELADO ? 'auxilio-cancelado' : ''">
                  <td class="text-center">
                    {{ meses[historico.mesParcela - 1] + "/" + historico.anoReembolso }}
                  </td>
                  <td class="text-center">{{ historico.tipoAuxilio.tipo }}</td>
                  <td class="text-center">{{ historico.tipoAuxilioSaude.tipo }}</td>
                  <td class="text-center">
                    {{ historico.valorReembolsoTotal | currency }}
                  </td>
                  <td class="text-center">
                    {{ historico.valorDeferidoPge || 0 | currency }}
                  </td>
                  <td class="text-center">
                    {{ historico.status.nome }}
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-center">
          @if (historicoSolicitacoesResponse().length > 0) {
            <mat-paginator
              #paginator
              class="custom-paginator"
              aria-label="Select page"
              showFirstLastButtons
              [length]="comprimento()"
              [pageSize]="tamanhoDaPagina()"
              [disabled]="false"
              [pageSizeOptions]="opcoesDoTamanhoDaPagina"
              [hidePageSize]="false"
              [pageIndex]="numeroPagina()"
              (page)="lidarEventoPagina($event)"
            />
          }
        </div>
      </div>
    }
  </form>
</div>
