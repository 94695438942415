import { Injectable, inject } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import type { SafeHtml } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, takeUntil } from 'rxjs';
import { DynamicTableService } from 'src/app/area-restrita/shared/services/dynamic-table.service';
import { ReportService } from 'src/app/area-restrita/shared/services/report.service';
import { RelatorioAuxilioSaudeService } from '../services/relatorio-auxilio-saude.service';
import type { AnalisePedidoAuxilioSaude } from './auxilio-saude';
import type { RelatorioAnalisePedidoAuxilioSaude } from './relatorio';

@Injectable({
  providedIn: 'root'
})
export class FabricaRelatorioAnalisePedidoAuxilio {
  private readonly relatorioAuxilioSaudeService = inject(RelatorioAuxilioSaudeService);
  private readonly reportService = inject(ReportService);
  private readonly sanitizer = inject(DomSanitizer);
  private readonly dynamicTableService = inject(DynamicTableService);

  private readonly destroy$ = new Subject<void>();
  private titulo: string;
  private rows: string[][];
  private tipo: string;
  private tituloColunas: string[];
  private totalElements: number;
  private pageSize: number;
  private pageNumber: number;

  async criarRelatorio(dadosFormulario: FormGroup, pageNumber: number, pageSize: number): Promise<void> {
    const dadosRelatorio = await this.obterDadosRelatorio(dadosFormulario, pageNumber, pageSize);

    this.titulo = 'Relatório Análise de Pedidos Realizados';
    this.totalElements = dadosRelatorio.totalElements;
    this.pageSize = dadosRelatorio.number;
    this.pageNumber = dadosRelatorio.size;
    this.rows = this.carregarLinhas(dadosRelatorio.content);
    this.tipo = 'pdf/csv';
    this.tituloColunas = ['Nome', 'CPF', 'Data de Inscrição', 'Tipo Auxílio Saúde', 'Beneficiário', 'Valor Total do Reembolso', 'Data da Aprovação', 'status'];
  }

  private async obterDadosRelatorio(dadosFormulario: FormGroup, pageNumber: number, pageSize: number): Promise<RelatorioAnalisePedidoAuxilioSaude> {
    return new Promise<RelatorioAnalisePedidoAuxilioSaude>((resolve, reject) => {
      this.relatorioAuxilioSaudeService
        .relatorioPesquisaAnalisePedidosAuxilio(dadosFormulario, pageNumber, pageSize)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (dados) => {
            resolve(dados);
          },
          error: (error) => {
            reject(new Error('Erro ao obter dados do relatorio: ', error));
          }
        });
    });
  }

  private carregarLinhas(pedidos: AnalisePedidoAuxilioSaude[]): string[][] {
    const rows: string[][] = [];
    for (const pedido of pedidos) {
      const row: string[] = [];
      row.push(pedido.usuario?.nome ?? '');
      row.push(this.reportService.formatCPF(pedido.usuario?.cpf));
      row.push(pedido.inscricaoAuxilioSaude.dataInscricao);
      row.push(pedido.tipoAuxilioSaude?.tipo.toString() ?? '');
      row.push(
        (pedido.valorReembolsoTitular ?? 0) > 0 && (pedido.valorReembolsoDependente ?? 0) > 0 ? 'Titular e Dependente'
        : (pedido.valorReembolsoTitular ?? 0) > 0 ? 'Titular'
        : 'Dependente'
      );
      row.push(this.formatarValorMonetario(pedido.valorReembolsoTotal));
      row.push(pedido.dataAprovacao ?? '-');
      row.push(pedido.status?.nome ?? '');
      rows.push(row);
    }

    return rows;
  }

  private formatarValorMonetario(value?: number | null): string {
    if (value === null || value === undefined) {
      return '';
    }

    // Converte o valor para número, se for uma string válida
    const valorNumerico = typeof value === 'string' ? parseFloat(value) : value;

    if (isNaN(valorNumerico)) {
      return '';
    }

    // Formata o número para o formato de moeda R$ 0,00
    return (
      'R$ ' +
      valorNumerico
        .toFixed(2)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    );
  }

  public getTabela(): SafeHtml {
    const resultH = this.dynamicTableService.createSearchHTML(this.tituloColunas, this.rows);
    return this.sanitizer.bypassSecurityTrustHtml(resultH);
  }

  public getRows(): string[][] {
    return this.rows;
  }

  public getTipo(): string {
    return this.tipo;
  }

  public getTitulo(): string {
    return this.titulo;
  }

  public getColunas(): string[] {
    return this.tituloColunas;
  }

  public getTotalElementos(): number {
    return this.totalElements;
  }

  public getPageSize(): number {
    return this.pageNumber;
  }

  public getPageNumber(): number {
    return this.pageSize;
  }
}
