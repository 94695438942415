import type { HttpErrorResponse } from '@angular/common/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import type { Observable } from 'rxjs';
import { catchError, throwError } from 'rxjs';
import { Globais } from 'src/app/area-restrita/shared/globais/globais';
import type { RelatorioAuxilioSaude } from 'src/app/area-restrita/shared/models/report-auxilio-saude';

@Injectable({
  providedIn: 'root'
})
export class RelatorioAuxilioSaudeService {
  private readonly http = inject(HttpClient);

  RelatorioPesquisaPedidosAuxilio(dadosFormulario: FormGroup, pageNumber: number, pageSize: number): Observable<RelatorioAuxilioSaude> {
    const dataRecebida = dadosFormulario.controls['mesAnoSolicitacao'].value || '';

    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({ Authorization: token });

    let params = new HttpParams()

      .set('nomeTitular', dadosFormulario.controls['nomeTitular'].value.trim().toUpperCase())
      .set('cpfTitular', dadosFormulario.controls['cpfTitular'].value.trim().toUpperCase())
      .set('vencimentoParcela', dadosFormulario.controls['vencimentoParcela'].value)
      .set('valorReembolso', dadosFormulario.controls['valorReembolso'].value)
      .set('dataAvaliacao', dadosFormulario.controls['dataAvaliacao'].value)
      .set('idTipoAuxilioSaude', dadosFormulario.controls['tipoAuxilioSaude'].value)
      .set('page', pageNumber.toString())
      .set('size', pageSize == 0 ? '10' : pageSize.toString());

    if (dadosFormulario.controls['status'].value != 0) {
      params = params.set('status', dadosFormulario.controls['status'].value);
    }
    if (dataRecebida && dataRecebida.trim() !== '') {
      const partesData = dataRecebida.split('-');
      params = params.set('anoReembolso', partesData[0] != null ? partesData[0] : '');
      params = params.set('mesParcela', partesData[1] != null ? partesData[1] : '');
    }

    return this.http
      .get<RelatorioAuxilioSaude>(`${Globais.urlToken}site-pge/api/auxilios/saude/consultar/relatorio/pedidos`, { headers: headers, params: params })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Erro durante a obtenção de dependentes:', error);
          return throwError(() => 'Falha na obtenção de dependentes.');
        })
      );
  }

  relatorioPesquisaPedidosAuxilioTipo(
    idStatusAuxilio: number,
    nomeTitular: string,
    cpfTitular: string,
    nomeDependente: string,
    cpfDependente: string,
    parentesco: string,
    tipoRelatorio: string
  ): Observable<Blob> {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({ Authorization: token });

    const params = new HttpParams()
      .set('status', idStatusAuxilio.toString())
      .set('nomeTitular', nomeTitular.trim().toUpperCase())
      .set('cpfTitular', cpfTitular.trim().toUpperCase())
      .set('nomeDependente', nomeDependente.trim().toUpperCase())
      .set('cpfDependente', cpfDependente.trim().toUpperCase())
      .set('parentesco', parentesco.trim().toUpperCase());

    return this.http
      .get(`${Globais.urlToken}site-pge/api/auxilios/saude/exportar/relatorio/pedidos/${tipoRelatorio}`, {
        headers: headers,
        params: params,
        responseType: 'blob'
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Erro durante a obtenção do relatório:', error.error);
          return throwError(() => 'Falha na obtenção de dependentes.');
        })
      );
  }
}
