export const dados = [
  {
    id: 1,
    municipio: "adamantina",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 2,
    municipio: "adolfo",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 3,
    municipio: "aguaí",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 4,
    municipio: "águas da prata",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 5,
    municipio: "águas de lindóia",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 6,
    municipio: "águas de sta bárbara",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 7,
    municipio: "águas de SÃO pedro",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 8,
    municipio: "agudos",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 9,
    municipio: "alambari",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 10,
    municipio: "alfredo marcondes",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 11,
    municipio: "altair",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 12,
    municipio: "altinópolis",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 13,
    municipio: "alto alegre",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 14,
    municipio: "alumínio",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 15,
    municipio: "álvares florence",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 16,
    municipio: "alvares machado",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 17,
    municipio: "alvaro de carvalho",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 18,
    municipio: "alvinlândia",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 19,
    municipio: "americana",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 20,
    municipio: "américo brasiliense",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 21,
    municipio: "américo de campos",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 22,
    municipio: "amparo",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 23,
    municipio: "analândia",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 24,
    municipio: "andradina",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 25,
    municipio: "angatuba",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 26,
    municipio: "anhenbi",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 27,
    municipio: "anhumas",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 28,
    municipio: "aparecida",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 29,
    municipio: "aparecida d´oeste",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 30,
    municipio: "apiaí",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 31,
    municipio: "araçariguama",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 32,
    municipio: "ARAÇATUBA",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 33,
    municipio: "araçoiaba da serra",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 34,
    municipio: "aramina",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 35,
    municipio: "arandu",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 36,
    municipio: "arapei",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 37,
    municipio: "araraquara",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 38,
    municipio: "araras",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 39,
    municipio: "arco iris",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 40,
    municipio: "arealva",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 41,
    municipio: "areias",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 42,
    municipio: "areiópolis",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 43,
    municipio: "ariranha",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 44,
    municipio: "artur nogueira",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 45,
    municipio: "arujá",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 46,
    municipio: "aspásia",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 47,
    municipio: "assis",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 48,
    municipio: "atibaia",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 49,
    municipio: "auriflama",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 50,
    municipio: "avaí",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 51,
    municipio: "avanhandava",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 52,
    municipio: "avaré",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 53,
    municipio: "bady bassit",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 54,
    municipio: "balbinos",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 55,
    municipio: "bálsamo",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 56,
    municipio: "bananal",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 57,
    municipio: "barão de antonina",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 59,
    municipio: "bariri",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 60,
    municipio: "barra bonita",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 61,
    municipio: "barra do chapéu",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 62,
    municipio: "barra do turvo",
    codigo: "PR02",
    regional: "SANTOS",
    chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 63,
    municipio: "barretos",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 64,
    municipio: "barrinha",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 65,
    municipio: "barueri",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 66,
    municipio: "bastos",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 67,
    municipio: "batatais",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 68,
    municipio: "bauru",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 69,
    municipio: "bebedouro",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 70,
    municipio: "bento de abreu",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 71,
    municipio: "bernardino de campos",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 72,
    municipio: "bertioga",
    codigo: "PR02",
    regional: "SANTOS",
    chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 73,
    municipio: "bilac",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 74,
    municipio: "birigui",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 75,
    municipio: "birtiba-mirim",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 76,
    municipio: "boa esperança do sul",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 77,
    municipio: "bocaína",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 78,
    municipio: "bofete",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 79,
    municipio: "boituva",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 80,
    municipio: "bom jesus dos perdões",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 81,
    municipio: "bom sucesso de itararé",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 82,
    municipio: "borá",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 83,
    municipio: "boracéia",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 84,
    municipio: "borborema",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 85,
    municipio: "borebi",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 86,
    municipio: "botucatu",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 87,
    municipio: "bragança paulista",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 88,
    municipio: "bras cubas",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 89,
    municipio: "braúna",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 90,
    municipio: "brejo alegre",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 91,
    municipio: "brodósqui",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 92,
    municipio: "brotas",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 93,
    municipio: "buri",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 94,
    municipio: "buritama",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 95,
    municipio: "buritizal",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 96,
    municipio: "cabrália paulista",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 97,
    municipio: "cabreuva",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 98,
    municipio: "caçapava",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 99,
    municipio: "cachoeira das emas",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 100,
    municipio: "cachoeira paulista",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 101,
    municipio: "caconde",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 102,
    municipio: "cafelândia",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 103,
    municipio: "caiabu",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 104,
    municipio: "caieiras",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 105,
    municipio: "caíua",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 106,
    municipio: "cajamar",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 107,
    municipio: "cajati",
    codigo: "PR02",
    regional: "SANTOS",
    chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 108,
    municipio: "cajobi",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 109,
    municipio: "cajuru",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 110,
    municipio: "campina do monte alegre",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 111,
    municipio: "campinas",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 112,
    municipio: "campo limpo paulista",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 113,
    municipio: "campos do jordão",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 114,
    municipio: "campos novos paulista",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 115,
    municipio: "cananéia",
    codigo: "PR02",
    regional: "SANTOS",
    chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 116,
    municipio: "canas",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 117,
    municipio: "cândido mota",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 118,
    municipio: "candido rodrigues",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 119,
    municipio: "canitar",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 120,
    municipio: "capão bonito",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 121,
    municipio: "capela do alto",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 122,
    municipio: "capivari",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 123,
    municipio: "caraguatatuba",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 124,
    municipio: "carapicuiba",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 125,
    municipio: "cardoso",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 126,
    municipio: "casa branca",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 127,
    municipio: "cassia dos coqueiros",
    codigo: "PR06",
    regional: "RIBIERÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 128,
    municipio: "castilho",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 129,
    municipio: "catanduva",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 130,
    municipio: "catiguá",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 131,
    municipio: "cedral",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 132,
    municipio: "cerqueira cesar",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 133,
    municipio: "cerquilho",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 134,
    municipio: "cesário lange",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 135,
    municipio: "charqueada",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 136,
    municipio: "chavantes",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 137,
    municipio: "clementina",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 138,
    municipio: "colina",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 139,
    municipio: "colômbia",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 140,
    municipio: "conchal",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 141,
    municipio: "conchas",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 142,
    municipio: "cordeirópolis",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 143,
    municipio: "coroados",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 144,
    municipio: "coronel macedo",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 145,
    municipio: "corumbataí",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 146,
    municipio: "cosmópolis",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 147,
    municipio: "cosmorama",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 148,
    municipio: "cotia",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 149,
    municipio: "cravinhos",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 150,
    municipio: "cristais paulista",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 151,
    municipio: "cruzália",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 153,
    municipio: "cubatão",
    codigo: "PR02",
    regional: "SANTOS",
    chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 154,
    municipio: "cunha",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 155,
    municipio: "descalvado",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 156,
    municipio: "diadema",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 157,
    municipio: "dirce reis",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 158,
    municipio: "divinolândia",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 159,
    municipio: "dobrada",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 160,
    municipio: "dois córregos",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 161,
    municipio: "dolcinópolis",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 162,
    municipio: "dourado",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 163,
    municipio: "dracena",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 164,
    municipio: "duartina",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 165,
    municipio: "dumont",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 166,
    municipio: "echaporã",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 167,
    municipio: "eldorado",
    codigo: "PR02",
    regional: "SANTOS",
    chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 168,
    municipio: "elias fausto",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 169,
    municipio: "elisiário",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 170,
    municipio: "embaúba",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 171,
    municipio: "embu",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 172,
    municipio: "embu-guaçu",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 173,
    municipio: "emilianóplis",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 174,
    municipio: "engenheiro coelho",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 175,
    municipio: "espirito santo do pinhal",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 176,
    municipio: "espirito santo do turvo",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 177,
    municipio: "estiva gerbi",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 178,
    municipio: "estrela do norte",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 179,
    municipio: "estrela d´oeste",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 180,
    municipio: "euclides da cunha paulista",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 181,
    municipio: "fartura",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 182,
    municipio: "fernando prestes",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 183,
    municipio: "fernandópolis",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 184,
    municipio: "fernão",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 185,
    municipio: "ferraz de vasconcelos",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 186,
    municipio: "flora rica",
    codigo: "PR10",
    regional: "PRESIDENTE  PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 187,
    municipio: "floreal",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 188,
    municipio: "florida paulista",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 189,
    municipio: "florinéa",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 190,
    municipio: "franca",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 191,
    municipio: "francisco morato",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 192,
    municipio: "franco da rocha",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 193,
    municipio: "gabriel monteiro",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 194,
    municipio: "galia",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 195,
    municipio: "garça",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 196,
    municipio: "gastão vidigal",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 197,
    municipio: "gavião peixoto",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 198,
    municipio: "general salgado",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 199,
    municipio: "getulina",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 200,
    municipio: "glicério",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 201,
    municipio: "guaiçará",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 202,
    municipio: "guaimbé",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 203,
    municipio: "guaíra",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 204,
    municipio: "guapiaçu",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 205,
    municipio: "guapiara",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 206,
    municipio: "guara",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 207,
    municipio: "guaraçaí",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 208,
    municipio: "guaraci",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 209,
    municipio: "guarani d´oeste",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 210,
    municipio: "guarantã",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 211,
    municipio: "guararapes",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 212,
    municipio: "guararema",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 213,
    municipio: "guaratinguetá",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 214,
    municipio: "guarei",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 215,
    municipio: "guariba",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 216,
    municipio: "guarujá",
    codigo: "PR02",
    regional: "SANTOS",
    chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 217,
    municipio: "guarulhos",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 218,
    municipio: "guatapará",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 219,
    municipio: "guzolândia",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 220,
    municipio: "herculândia",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 221,
    municipio: "holambra",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 222,
    municipio: "hortolândia",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 223,
    municipio: "iacanga",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 224,
    municipio: "iacri",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 225,
    municipio: "iaras",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 226,
    municipio: "ibaté",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 227,
    municipio: "ibirá",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 228,
    municipio: "ibirarema",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 229,
    municipio: "ibitinga",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 230,
    municipio: "ibitiúva",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 231,
    municipio: "ibiúna",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 232,
    municipio: "icem",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 233,
    municipio: "iepê",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 234,
    municipio: "igaraçu do tietê",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 235,
    municipio: "igarapava",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 236,
    municipio: "igarata",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 237,
    municipio: "iguape",
    codigo: "PR02",
    regional: "SANTOS",
    chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 238,
    municipio: "ilha comprida",
    codigo: "PR02",
    regional: "SANTOS",
    chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 239,
    municipio: "ilha solteira",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 240,
    municipio: "ilhabela",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 241,
    municipio: "indaiatuba",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 242,
    municipio: "indiana",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 243,
    municipio: "indiaporã",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 244,
    municipio: "inubia paulista",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 245,
    municipio: "ipauçu",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 246,
    municipio: "iperó",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 247,
    municipio: "ipeúna",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 248,
    municipio: "ipiguá",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 249,
    municipio: "iporanga",
    codigo: "PR02",
    regional: "SANTOS",
    chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 250,
    municipio: "ipuã",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 251,
    municipio: "iracemápolis",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 252,
    municipio: "irapuã",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 253,
    municipio: "irapuru",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 254,
    municipio: "itabera",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 255,
    municipio: "itaí",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 256,
    municipio: "itajobi",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 257,
    municipio: "itaju",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 258,
    municipio: "itanhaém",
    codigo: "PR02",
    regional: "SANTOS",
    chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 259,
    municipio: "itaoca",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 260,
    municipio: "itapecerica da serra",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 261,
    municipio: "itapetininga",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 262,
    municipio: "itapeva",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 263,
    municipio: "itapevi",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 264,
    municipio: "itapira",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 265,
    municipio: "itapirapuã paulista",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 266,
    municipio: "itapolis",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 267,
    municipio: "itaporanga",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 268,
    municipio: "itapuí",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 269,
    municipio: "itapura",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 270,
    municipio: "itaquaquecetuba",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 271,
    municipio: "itararé",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 272,
    municipio: "itariri",
    codigo: "PR02",
    regional: "SANTOS",
    chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 273,
    municipio: "itatiba",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 274,
    municipio: "itatinga",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 275,
    municipio: "itirapina",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 276,
    municipio: "itirapuã",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 277,
    municipio: "itobi",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 278,
    municipio: "itu",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 279,
    municipio: "itupeva",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 280,
    municipio: "ituverava",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 281,
    municipio: "jaborandi",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 282,
    municipio: "jaboticabal",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 283,
    municipio: "jacarei",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 284,
    municipio: "jaci",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 285,
    municipio: "jacupiranga",
    codigo: "PR02",
    regional: "SANTOS",
    chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 286,
    municipio: "jaguariúna",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 287,
    municipio: "jales",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 288,
    municipio: "jambeiro",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 289,
    municipio: "jandira",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 290,
    municipio: "jardinópolis",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 291,
    municipio: "jarinu",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 292,
    municipio: "jaú",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 293,
    municipio: "jeriquara",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 294,
    municipio: "joanópolis",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 295,
    municipio: "joão ramalho",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 296,
    municipio: "JOSÉ bonifácio",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 297,
    municipio: "júlio mesquita",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 298,
    municipio: "jumirim",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 299,
    municipio: "jundiaí",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 300,
    municipio: "junqueirópolis",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 301,
    municipio: "juquiá",
    codigo: "PR02",
    regional: "SANTOS",
    chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 302,
    municipio: "juquitiba",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 303,
    municipio: "lagoinha",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 304,
    municipio: "laranjal paulista",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 305,
    municipio: "lavínia",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 306,
    municipio: "lavrinhas",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 307,
    municipio: "leme",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 308,
    municipio: "lençóis paulista",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 309,
    municipio: "limeira",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 310,
    municipio: "lindóia",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 311,
    municipio: "lins",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 312,
    municipio: "lorena",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 313,
    municipio: "lourdes",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 314,
    municipio: "louveira",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 315,
    municipio: "lucélia",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 316,
    municipio: "lucianópolis",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 317,
    municipio: "luiz antônio",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 318,
    municipio: "luiziânia",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 319,
    municipio: "lupércio",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 320,
    municipio: "lutécia",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 321,
    municipio: "macatuba",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 322,
    municipio: "macaubal",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 323,
    municipio: "macedônia",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 324,
    municipio: "magda",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 325,
    municipio: "mairinque",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 326,
    municipio: "mairiporã",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 327,
    municipio: "manduri",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 328,
    municipio: "marabá paulista",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 329,
    municipio: "maracaí",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 330,
    municipio: "marapoama",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 331,
    municipio: "mariápolis",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 332,
    municipio: "MARÍLIA",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 333,
    municipio: "mainópolis",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 334,
    municipio: "martinópolis",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 335,
    municipio: "matão",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 336,
    municipio: "maua",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 337,
    municipio: "mendonça",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 338,
    municipio: "meridiano",
    codigo: "PR08",
    regional: "S. SJOÁ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 339,
    municipio: "mesópolis",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 340,
    municipio: "miguelópolis",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 341,
    municipio: "mineiros do tietê",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 342,
    municipio: "mira estrela",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 343,
    municipio: "miracatu",
    codigo: "PR02",
    regional: "SANTOS",
    chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 344,
    municipio: "mirandópolis",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 345,
    municipio: "mirante do paranapanema",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 346,
    municipio: "mirassol",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 347,
    municipio: "mirassolândia",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO  PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 348,
    municipio: "mococa",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 349,
    municipio: "mogi das cruzes",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 350,
    municipio: "mogi-guaçú",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 351,
    municipio: "mogi-mirim",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 352,
    municipio: "mombuca",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 353,
    municipio: "monções",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 354,
    municipio: "mongaguá",
    codigo: "PR02",
    regional: "SANTOS",
    chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 355,
    municipio: "monte alegre do sul",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 356,
    municipio: "monte alto",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 357,
    municipio: "monte aprazível",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 358,
    municipio: "monte azul paulista",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 359,
    municipio: "monte castelo",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 360,
    municipio: "monte mor",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 361,
    municipio: "monteiro lobato",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 362,
    municipio: "morro agudo",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 363,
    municipio: "morungaba",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 364,
    municipio: "motuca",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 365,
    municipio: "murutinga do sul",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 366,
    municipio: "nantes",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 367,
    municipio: "narandiba",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 368,
    municipio: "natividade da serra",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 369,
    municipio: "nazaré paulista",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 370,
    municipio: "neves paulista",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 371,
    municipio: "nhandeara",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 372,
    municipio: "nipoã",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 373,
    municipio: "nova aliança",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 374,
    municipio: "nova campina",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 375,
    municipio: "nova canaã paulista",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 376,
    municipio: "nova castilho",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 377,
    municipio: "nova europa",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 378,
    municipio: "nova granada",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 379,
    municipio: "nova guataporanga",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 380,
    municipio: "nova independência",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 381,
    municipio: "nova lusitânia",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 382,
    municipio: "nova odessa",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 383,
    municipio: "novais",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 384,
    municipio: "novo horizonte",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 385,
    municipio: "nuporanga",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 386,
    municipio: "ocauçú",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 387,
    municipio: "óleo",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 388,
    municipio: "olimpia",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 389,
    municipio: "onda verde",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 390,
    municipio: "oriente",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 391,
    municipio: "orindiuva",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 392,
    municipio: "orlândia",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 393,
    municipio: "osasco",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 394,
    municipio: "oscar bressane",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 395,
    municipio: "osvaldo cruz",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 396,
    municipio: "ourinhos",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 397,
    municipio: "ouro verde",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 398,
    municipio: "ouroeste",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 399,
    municipio: "pacaembu",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 400,
    municipio: "palestina",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 401,
    municipio: "palmares paulista",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 402,
    municipio: "palmeira d´oeste",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 403,
    municipio: "palmital",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 404,
    municipio: "panorama",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 405,
    municipio: "paraguaçu paulista",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 406,
    municipio: "paraibuna",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 407,
    municipio: "paraíso",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 408,
    municipio: "paranapanema",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 409,
    municipio: "paranapuã",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 410,
    municipio: "parapuã",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 411,
    municipio: "pardinho",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 412,
    municipio: "pariquera-açu",
    codigo: "PR02",
    regional: "SANTOS",
    chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 413,
    municipio: "parisi",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 414,
    municipio: "patrocinio paulista",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 415,
    municipio: "paulicéia",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 416,
    municipio: "paulínia",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 418,
    municipio: "pederneiras",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 419,
    municipio: "pedra bela",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 420,
    municipio: "pedranópolis",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 421,
    municipio: "pedregulho",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 422,
    municipio: "pedreira",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 423,
    municipio: "pedrinhas paulista",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 424,
    municipio: "pedro de toledo",
    codigo: "PR02",
    regional: "SANTOS",
    chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 425,
    municipio: "penápolis",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 426,
    municipio: "pereira barreto",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 427,
    municipio: "pereiras",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 428,
    municipio: "peruíbe",
    codigo: "PR02",
    regional: "SANTOS",
    chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 429,
    municipio: "piacatu",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 430,
    municipio: "piedade",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 431,
    municipio: "pilar do sul",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 432,
    municipio: "pindamonhangaba",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 433,
    municipio: "pindorama",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 434,
    municipio: "pinhalzinho",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 435,
    municipio: "piquerobi",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 436,
    municipio: "piquete",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 437,
    municipio: "piracaia",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 438,
    municipio: "piracicaba",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 439,
    municipio: "pirassununga",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 440,
    municipio: "piraju",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 441,
    municipio: "pirajuí",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 442,
    municipio: "pirangi",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 443,
    municipio: "pirapora do bom jesus",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 444,
    municipio: "pirapozinho",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 445,
    municipio: "piratininga",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 446,
    municipio: "pitangueiras",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 447,
    municipio: "planalto",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 448,
    municipio: "platina",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 449,
    municipio: "poa",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 450,
    municipio: "poloni",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 451,
    municipio: "pompéia",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 452,
    municipio: "pongaí",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 453,
    municipio: "pontal",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 454,
    municipio: "ponta linda",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 455,
    municipio: "pontes gestal",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 456,
    municipio: "populina",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 457,
    municipio: "porangaba",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 458,
    municipio: "porto feliz",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 459,
    municipio: "porto ferreira",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 460,
    municipio: "potim",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 461,
    municipio: "potirendaba",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 462,
    municipio: "pracinha",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 463,
    municipio: "pradópolis",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 464,
    municipio: "praia grande",
    codigo: "PR02",
    regional: "SANTOS",
    chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 465,
    municipio: "pratania",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 466,
    municipio: "presidente alves",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 467,
    municipio: "presidente bernardes",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 468,
    municipio: "presidente epitácio",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 469,
    municipio: "presidente prudente",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 470,
    municipio: "presidente venceslau",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 471,
    municipio: "promisSÃO",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 472,
    municipio: "quadra",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 473,
    municipio: "quatá",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 474,
    municipio: "queiroz",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 475,
    municipio: "queluz",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 476,
    municipio: "quintana",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 477,
    municipio: "rafard",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 478,
    municipio: "rancharia",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 479,
    municipio: "redenção da serra",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 480,
    municipio: "regente feijó",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 481,
    municipio: "reginópolis",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 482,
    municipio: "registro",
    codigo: "PR02",
    regional: "SANTOS",
    chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 483,
    municipio: "restinga",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 484,
    municipio: "ribeira",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 485,
    municipio: "ribeirão bonito",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 486,
    municipio: "ribeirão branco",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 487,
    municipio: "ribeirão corrente",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 488,
    municipio: "ribeirão do sul",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 489,
    municipio: "ribeirão dos índios",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 490,
    municipio: "ribeirão grande",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 491,
    municipio: "ribeirão pires",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 492,
    municipio: "RIBEIRÃO PRETO",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 493,
    municipio: "rifaina",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 494,
    municipio: "rincão",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 495,
    municipio: "rinópolis",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 496,
    municipio: "rio claro",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 497,
    municipio: "rio das pedras",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 498,
    municipio: "rio grande da serra",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 499,
    municipio: "riolândia",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 500,
    municipio: "riversul",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 501,
    municipio: "rosana",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 502,
    municipio: "roseira",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 503,
    municipio: "rubiácea",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 504,
    municipio: "rubinéia",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 505,
    municipio: "sabino",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 506,
    municipio: "sagres",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 507,
    municipio: "sales",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 508,
    municipio: "sales de oliveira",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 509,
    municipio: "salesópolis",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 510,
    municipio: "salmourão",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 511,
    municipio: "saltinho",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 512,
    municipio: "salto",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 513,
    municipio: "salto de pirapora",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 514,
    municipio: "salto grande",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 515,
    municipio: "sandovalina",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 516,
    municipio: "santa adélia",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 517,
    municipio: "santa albertina",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 518,
    municipio: "santa barbara d´oeste",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 519,
    municipio: "santa branca",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 520,
    municipio: "santa clara d´oeste",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 521,
    municipio: "santa cruz da conceição",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 522,
    municipio: "santa cruz das palmeiras",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 523,
    municipio: "santa cruz do rio pardo",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 524,
    municipio: "santa ernestina",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 525,
    municipio: "santa fé do sul",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 526,
    municipio: "santa gertrudes",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 527,
    municipio: "santa isabel",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 528,
    municipio: "santa lúcia",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 529,
    municipio: "santa maria da serra",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 530,
    municipio: "santa mercedes",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 531,
    municipio: "santa rita do passa quatro",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 532,
    municipio: "santa rita d´oeste",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 533,
    municipio: "santa rosa do viterbo",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 534,
    municipio: "santa salete",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 535,
    municipio: "santana da ponte pensa",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 536,
    municipio: "santana do parnaíba",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 537,
    municipio: "santo anastacio",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 538,
    municipio: "santo andré",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 539,
    municipio: "santo antonio da alegria",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 540,
    municipio: "santo antonio da posse",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 541,
    municipio: "santo antonio do aracangua",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 542,
    municipio: "santo antonio do jardim",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 543,
    municipio: "santo antonio do pinhal",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 544,
    municipio: "santo expedito",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 545,
    municipio: "santópolis do aguapeí",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 546,
    municipio: "santos",
    codigo: "PR02",
    regional: "SANTOS",
    chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 547,
    municipio: "SÃO bento do sapucai",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 549,
    municipio: "SÃO caetano do sul",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 550,
    municipio: "SÃO carlos",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 551,
    municipio: "SÃO francisco",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 552,
    municipio: "SÃO francisco da praia",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 553,
    municipio: "SÃO joão da boa vista",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 554,
    municipio: "SÃO joão das duas pontes",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 555,
    municipio: "SÃO joão de iracema",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 556,
    municipio: "SÃO joão do pau d´alho",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 557,
    municipio: "SÃO joaquim da barra",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 558,
    municipio: "SÃO JOSÉ da bela vista",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 559,
    municipio: "SÃO JOSÉ do barreiro",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 560,
    municipio: "SÃO JOSÉ do rio pardo",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 561,
    municipio: "SÃO JOSÉ do rio preto",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 562,
    municipio: "SÃO JOSÉ dos campos",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 563,
    municipio: "SÃO lourenço da serra",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 564,
    municipio: "SÃO luiz do paraitinga",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 565,
    municipio: "SÃO manuel",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 566,
    municipio: "SÃO miguel arcanjo",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 567,
    municipio: "SÃO paulo",
    codigo: "PJ",
    regional: "PROCURADORIA JUDICIAL",
    chefe: "MARIA CAROLINA CARVALHO"
  },
  {
    id: 568,
    municipio: "SÃO pedro",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 569,
    municipio: "SÃO pedro do turvo",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 570,
    municipio: "SÃO roque",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 571,
    municipio: "SÃO sebastião",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 572,
    municipio: "SÃO sebatião da grama",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 573,
    municipio: "SÃO sebastião do patrimônio",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 574,
    municipio: "SÃO simão",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 575,
    municipio: "SÃO vicente",
    codigo: "PR02",
    regional: "SANTOS",
    chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 576,
    municipio: "sarapui",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 577,
    municipio: "sarutaiá",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 578,
    municipio: "sebastianópolis do sul",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 579,
    municipio: "serra azul",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 580,
    municipio: "serra negra",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 581,
    municipio: "serrana",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 582,
    municipio: "sertãozinho",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 583,
    municipio: "sete barras",
    codigo: "PR02",
    regional: "SANTOS",
    chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 584,
    municipio: "severínia",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 585,
    municipio: "silveiras",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 586,
    municipio: "socorro",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 587,
    municipio: "sorocaba",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 588,
    municipio: "sud menucci",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 589,
    municipio: "sumaré",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 590,
    municipio: "suzano",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 591,
    municipio: "suzanópolis",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 592,
    municipio: "tabapuá",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 593,
    municipio: "tabatinga",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 594,
    municipio: "taboão da serra",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 595,
    municipio: "taciba",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 596,
    municipio: "taguaí",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 597,
    municipio: "taiaçu",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 598,
    municipio: "taiuva",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 599,
    municipio: "tambau",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 600,
    municipio: "tanabi",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 601,
    municipio: "tapiraí",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 602,
    municipio: "tapiratiba",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 603,
    municipio: "taquaral",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 604,
    municipio: "taquaritinga",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 605,
    municipio: "taquarituba",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 606,
    municipio: "taquarivaí",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 607,
    municipio: "tarabaí",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 608,
    municipio: "taruma",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 609,
    municipio: "tatuí",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 610,
    municipio: "TAUBATÉ",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 611,
    municipio: "tejupá",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 612,
    municipio: "teodoro sampaio",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 613,
    municipio: "terra roxa",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 614,
    municipio: "tietê",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 615,
    municipio: "timburi",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 616,
    municipio: "torre de pedra",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 617,
    municipio: "torrinha",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 618,
    municipio: "trabijú",
    codigo: "PR12",
    regional: "SÃO CARLOS",
    chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 619,
    municipio: "tremembé",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 620,
    municipio: "três fronteiras",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 621,
    municipio: "tuiuti",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 622,
    municipio: "tupã",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 623,
    municipio: "tupi paulista",
    codigo: "PR10",
    regional: "PRESIDENTE PRUDENTE",
    chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 624,
    municipio: "turiuba",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 625,
    municipio: "turmalina",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 626,
    municipio: "ubarama",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 627,
    municipio: "ubatuba",
    codigo: "PR03",
    regional: "TAUBATÉ",
    chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 628,
    municipio: "ubirajara",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 629,
    municipio: "uchoa",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 630,
    municipio: "união paulista",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 631,
    municipio: "urânia",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 632,
    municipio: "uru",
    codigo: "PR07",
    regional: "BAURU",
    chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 633,
    municipio: "urupês",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 634,
    municipio: "valentim gentil",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 635,
    municipio: "valinhos",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 636,
    municipio: "valparaiso",
    codigo: "PR09",
    regional: "ARAÇATUBA",
    chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 637,
    municipio: "vargem",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 638,
    municipio: "vargem grande do sul",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 639,
    municipio: "vargem grande paulista",
    codigo: "PR01",
    regional: "GRANDE SÃO PAULO",
    chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 640,
    municipio: "varzea paulista",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 641,
    municipio: "vera cruz",
    codigo: "PR11",
    regional: "MARÍLIA",
    chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 642,
    municipio: "vicente de carvalho",
    codigo: "PR02",
    regional: "SANTOS",
    chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 643,
    municipio: "vinhedo",
    codigo: "PR05",
    regional: "CAMPINAS",
    chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 644,
    municipio: "viradouro",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 645,
    municipio: "vista alegre do alto",
    codigo: "PR06",
    regional: "RIBEIRÃO PRETO",
    chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 646,
    municipio: "vitoria brasil",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 647,
    municipio: "votorantim",
    codigo: "PR04",
    regional: "SOROCABA",
    chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 648,
    municipio: "votuporanga",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 649,
    municipio: "zacarias",
    codigo: "PR08",
    regional: "SÃO JOSÉ DO RIO PRETO",
    chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  }
];
