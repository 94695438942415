import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private readonly storage?: Storage;

  public constructor() {
    this.storage = window.localStorage;
  }

  public get(key: string): string {
    if (this.storage) {
      return this.storage.getItem(key) ?? '';
    }
    return '';
  }

  public getJson(key: string): unknown {
    if (this.storage) {
      const item = this.storage.getItem(key);
      if (item !== null) {
        return JSON.parse(item);
      } else {
        return null;
      }
    }
    return null;
  }

  public setJson(key: string, value: unknown): void {
    if (this.storage) this.storage.setItem(key, JSON.stringify(value));
  }

  public remove(key: string): boolean {
    if (this.storage) {
      this.storage.removeItem(key);
      return true;
    }
    return false;
  }

  public clear(): boolean {
    if (this.storage) {
      this.storage.clear();
      return true;
    }
    return false;
  }
}
