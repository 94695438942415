@if (menuAtual === "INSTITUCIONAL") {
  <div>
    <app-separador titulo="INSTITUCIONAL" />
    <!-- Item 1 - Sobre a PGE -->
    <div class="accordion-menu">
      <div class="accordion-title" tabindex="0" (click)="alternar(0)" (keypress)="alternar(0)">
        <mat-icon class="red-icon">{{ Institucional[0].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Institucional[0].titulo }}
      </div>
      @if (Institucional[0].aberto) {
        <div class="accordion-content">
          <app-quem-somos-pge />
        </div>
      }
    </div>
    <!-- Item 2 - Gabinete -->
    <div class="accordion-menu">
      <div class="accordion-title" tabindex="0" (click)="alternar(1)" (keypress)="alternar(1)">
        <mat-icon class="red-icon">{{ Institucional[1].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Institucional[1].titulo }}
      </div>
      @if (Institucional[1].aberto) {
        <div class="accordion-content">
          <div class="accordion-menu">
            <div class="accordion-title" tabindex="0" (click)="alternar(1, 0)" (keypress)="alternar(1, 0)">
              <mat-icon class="red-icon">{{ Institucional[1].children[0].aberto ? "expand_less" : "expand_more" }}</mat-icon>
              {{ Institucional[1].children[0].titulo }}
            </div>
            @if (Institucional[1].children[0].aberto) {
              <div class="accordion-content">
                <app-composicao-gab />
              </div>
            }
          </div>
          <div class="accordion-menu">
            <div class="accordion-title" tabindex="0" (click)="alternar(1, 1)" (keypress)="alternar(1, 1)">
              <mat-icon class="red-icon">{{ Institucional[1].children[1].aberto ? "expand_less" : "expand_more" }}</mat-icon>
              {{ Institucional[1].children[1].titulo }}
            </div>
            @if (Institucional[1].children[1].aberto) {
              <div class="accordion-content">
                <app-assessorias-gab />
              </div>
            }
          </div>
        </div>
      }
    </div>
    <!-- Item 3 - Histórico -->
    <div class="accordion-menu">
      <div class="accordion-title" tabindex="0" (click)="alternar(2)" (keypress)="alternar(2)">
        <mat-icon class="red-icon">{{ Institucional[2].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Institucional[2].titulo }}
      </div>
      @if (Institucional[2].aberto) {
        <div class="accordion-content">
          <app-historico />
        </div>
      }
    </div>
    <!-- Item 4 - Ex-Procuradores Gerais -->
    <div class="accordion-menu">
      <div class="accordion-title" tabindex="0" (click)="alternar(3)" (keypress)="alternar(3)">
        <mat-icon class="red-icon">{{ Institucional[3].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Institucional[3].titulo }}
      </div>
      @if (Institucional[3].aberto) {
        <div class="accordion-content">
          <app-ex-procuradores-gerais />
        </div>
      }
    </div>
    <!-- Item 5 - Organograma -->
    <div class="accordion-menu">
      <p class="accordion-title" tabindex="0" (click)="alternar(4)" (keypress)="alternar(4)">
        <mat-icon class="red-icon">{{ Institucional[4].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Institucional[4].titulo }}
      </p>
      @if (Institucional[4].aberto) {
        <div class="accordion-content">
          <app-separador titulo="ORGANOGRAMA" />
          <br />
          <a href="pdf/organograma_pge_2024.pdf" target="_blank">Organograma</a>
        </div>
      }
    </div>
    <!-- Item 6 - Lei Orgânica -->
    <div class="accordion-menu">
      <div class="accordion-title" tabindex="0" (click)="alternar(5)" (keypress)="alternar(5)">
        <mat-icon class="red-icon">{{ Institucional[5].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Institucional[5].titulo }}
      </div>
      @if (Institucional[5].aberto) {
        <div class="accordion-content">
          <app-separador titulo="LEI ORGÂNICA" />
          <br />
          <a href="pdf/lei_organica_pge_revista_e_atualizao_lc_1082_08_1.pdf" target="_blank">Lei Orgânica</a>
        </div>
      }
    </div>
    <!-- Item 7 - Legislação -->
    <div class="accordion-menu">
      <div class="accordion-title" tabindex="0" (click)="alternar(6)" (keypress)="alternar(6)">
        <mat-icon class="red-icon">{{ Institucional[6].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Institucional[6].titulo }}
      </div>
      @if (Institucional[6].aberto) {
        <div class="accordion-content">
          <app-separador titulo="LEGISLAÇÃO" />
          <br />
          <a href="https://www.pge.sp.gov.br/editais/atosnormativos/index.aspx" target="_blank">Legislação</a>
          @if (ambiente === "loc" || ambiente === "dev" || ambiente === "hml") {
            <div>
              <br />
              <a [routerLink]="['/institucional/legislacao-pesquisa']">Legislação Novo</a>
            </div>
          }
        </div>
      }
    </div>
  </div>
}
@if (menuAtual === "AREASATUACAO") {
  <div>
    <app-separador titulo="ÁREAS DE ATUAÇÃO" />
    <!-- Item 1 - Consultoria Geral -->
    <div class="accordion-menu">
      <div class="accordion-title" tabindex="0" (click)="alternar(0)" (keypress)="alternar(0)">
        <mat-icon class="red-icon">{{ AreaAtuacao[0].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ AreaAtuacao[0].titulo }}
      </div>
      @if (AreaAtuacao[0].aberto) {
        <div class="accordion-content">
          <!-- SubItem 1 - Subprocuradoria -->
          <div class="accordion-menu">
            <div class="accordion-title" tabindex="0" (click)="alternar(0, 0)" (keypress)="alternar(0, 0)">
              <mat-icon class="red-icon">{{ AreaAtuacao[0].children[0].aberto ? "expand_less" : "expand_more" }}</mat-icon>
              {{ AreaAtuacao[0].children[0].titulo }}
            </div>
            @if (AreaAtuacao[0].children[0].aberto) {
              <div class="accordion-content">
                <app-subprocuradoria-consultoria-geral />
              </div>
            }
          </div>
          <!-- SubItem 2 - Especializadas -->
          <div class="accordion-menu">
            <div class="accordion-title" tabindex="0" (click)="alternar(0, 1)" (keypress)="alternar(0, 1)">
              <mat-icon class="red-icon">{{ AreaAtuacao[0].children[1].aberto ? "expand_less" : "expand_more" }}</mat-icon>
              {{ AreaAtuacao[0].children[1].titulo }}
            </div>
            @if (AreaAtuacao[0].children[1].aberto) {
              <div class="accordion-content">
                <app-especializadas-consultoria-geral />
              </div>
            }
          </div>
          <!-- SubItem 3 - Scretarias -->
          <div class="accordion-menu">
            <div class="accordion-title" tabindex="0" (click)="alternar(0, 2)" (keypress)="alternar(0, 2)">
              <mat-icon class="red-icon">{{ AreaAtuacao[0].children[2].aberto ? "expand_less" : "expand_more" }}</mat-icon>
              {{ AreaAtuacao[0].children[2].titulo }}
            </div>
            @if (AreaAtuacao[0].children[2].aberto) {
              <div class="accordion-content">
                <app-consultorias />
              </div>
            }
          </div>
          <!-- SubItem 4 - Autarquias -->
          <div class="accordion-menu">
            <div class="accordion-title" tabindex="0" (click)="alternar(0, 3)" (keypress)="alternar(0, 3)">
              <mat-icon class="red-icon">{{ AreaAtuacao[0].children[3].aberto ? "expand_less" : "expand_more" }}</mat-icon>
              {{ AreaAtuacao[0].children[3].titulo }}
            </div>
            @if (AreaAtuacao[0].children[3].aberto) {
              <div class="accordion-content">
                <app-autarquias />
              </div>
            }
          </div>
        </div>
      }
    </div>
    <!-- Item 2 - Contencioso Geral -->
    <div class="accordion-menu">
      <div class="accordion-title" tabindex="0" (click)="alternar(1)" (keypress)="alternar(1)">
        <mat-icon class="red-icon">{{ AreaAtuacao[1].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ AreaAtuacao[1].titulo }}
      </div>
      @if (AreaAtuacao[1].aberto) {
        <div class="accordion-content">
          <!-- SubItem 1 - Subprocuradoria -->
          <div class="accordion-menu">
            <div class="accordion-title" tabindex="0" (click)="alternar(1, 0)" (keypress)="alternar(1, 0)">
              <mat-icon class="red-icon">{{ AreaAtuacao[1].children[0].aberto ? "expand_less" : "expand_more" }}</mat-icon>
              {{ AreaAtuacao[1].children[0].titulo }}
            </div>
            @if (AreaAtuacao[1].children[0].aberto) {
              <div class="accordion-content">
                <app-subprocuradoria-contencioso-geral />
              </div>
            }
          </div>
          <!-- SubItem 2 - Unidades Especializadas -->
          <div class="accordion-menu">
            <div class="accordion-title" tabindex="0" (click)="alternar(1, 1)" (keypress)="alternar(1, 1)">
              <mat-icon class="red-icon">{{ AreaAtuacao[1].children[1].aberto ? "expand_less" : "expand_more" }}</mat-icon>
              {{ AreaAtuacao[1].children[1].titulo }}
            </div>
            @if (AreaAtuacao[1].children[1].aberto) {
              <div class="accordion-content">
                <app-especializadas-contencioso-geral />
              </div>
            }
          </div>
          <!-- SubItem 3 - Regionais -->
          <div class="accordion-menu">
            <div class="accordion-title" tabindex="0" (click)="alternar(1, 2)" (keypress)="alternar(1, 2)">
              <mat-icon class="red-icon">{{ AreaAtuacao[1].children[2].aberto ? "expand_less" : "expand_more" }}</mat-icon>
              {{ AreaAtuacao[1].children[2].titulo }}
            </div>
            @if (AreaAtuacao[1].children[2].aberto) {
              <div class="accordion-content">
                <app-regionais-contencioso-geral />
              </div>
            }
          </div>
        </div>
      }
    </div>
    <!-- Item 3 - Tributário Fiscal -->
    <div class="accordion-menu">
      <div class="accordion-title" tabindex="0" (click)="alternar(2)" (keypress)="alternar(2)">
        <mat-icon class="red-icon">{{ AreaAtuacao[2].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ AreaAtuacao[2].titulo }}
      </div>
      @if (AreaAtuacao[2].aberto) {
        <div class="accordion-content">
          <!-- SubItem 1 - Subprocuradoria -->
          <div class="accordion-menu">
            <div class="accordion-title" tabindex="0" (click)="alternar(2, 0)" (keypress)="alternar(2, 0)">
              <mat-icon class="red-icon">{{ AreaAtuacao[2].children[0].aberto ? "expand_less" : "expand_more" }}</mat-icon>
              {{ AreaAtuacao[2].children[0].titulo }}
            </div>
            @if (AreaAtuacao[2].children[0].aberto) {
              <div class="accordion-content">
                <app-subprocuradoria-tributario-fiscal />
              </div>
            }
          </div>
          <!-- SubItem 2 - Especializadas -->
          <div class="accordion-menu">
            <div class="accordion-title" tabindex="0" (click)="alternar(2, 1)" (keypress)="alternar(2, 1)">
              <mat-icon class="red-icon">{{ AreaAtuacao[2].children[1].aberto ? "expand_less" : "expand_more" }}</mat-icon>
              {{ AreaAtuacao[2].children[1].titulo }}
            </div>
            @if (AreaAtuacao[2].children[1].aberto) {
              <div class="accordion-content">
                <app-especializadas-tributario-fiscal />
              </div>
            }
          </div>
          <!-- SubItem 3 - Regionais -->
          <div class="accordion-menu">
            <div class="accordion-title" tabindex="0" (click)="alternar(2, 2)" (keypress)="alternar(2, 2)">
              <mat-icon class="red-icon">{{ AreaAtuacao[2].children[2].aberto ? "expand_less" : "expand_more" }}</mat-icon>
              {{ AreaAtuacao[2].children[2].titulo }}
            </div>
            @if (AreaAtuacao[2].children[2].aberto) {
              <div class="accordion-content">
                <app-regionais-tributario-fiscal />
              </div>
            }
          </div>
        </div>
      }
    </div>
  </div>
}

@if (menuAtual === "CORREGEDORIA") {
  <div>
    <app-separador titulo="CORREGEDORIA" />
    <!-- Item 1 - Apresentação de Composição-->
    <div class="accordion-menu">
      <div class="accordion-title" tabindex="0" (click)="alternar(0)" (keypress)="alternar(0)">
        <mat-icon class="red-icon">{{ Corregedoria[0].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Corregedoria[0].titulo }}
      </div>
      @if (Corregedoria[0].aberto) {
        <div class="accordion-content">
          <app-apresentacao-composicao />
        </div>
      }
    </div>
    <!-- Item 2 - Ex-corregedores Gerais -->
    <div class="accordion-menu">
      <div class="accordion-title" tabindex="0" (click)="alternar(1)" (keypress)="alternar(1)">
        <mat-icon class="red-icon">{{ Corregedoria[1].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Corregedoria[1].titulo }}
      </div>
      @if (Corregedoria[1].aberto) {
        <div class="accordion-content">
          <app-ex-corregedores-gerais-pge />
        </div>
      }
    </div>
    <!-- Item 3 - Legislação -->
    <div class="accordion-menu">
      <div class="accordion-title" tabindex="0" (click)="alternar(2)" (keypress)="alternar(2)">
        <mat-icon class="red-icon">{{ Corregedoria[2].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Corregedoria[2].titulo }}
      </div>
      @if (Corregedoria[2].aberto) {
        <div class="accordion-content">
          <app-legislacao-corregedoria />
        </div>
      }
    </div>
  </div>
}

@if (menuAtual === "CONSELHO") {
  <div>
    <app-separador titulo="CONSELHO" />
    <!-- Item 1 - Apresentação -->
    <div class="accordion-menu">
      <div class="accordion-title" tabindex="0" (click)="alternar(0)" (keypress)="alternar(0)">
        <mat-icon class="red-icon">{{ Conselho[0].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Conselho[0].titulo }}
      </div>
      @if (Conselho[0].aberto) {
        <div class="accordion-content">
          <app-apresentacao />
        </div>
      }
    </div>
    <!-- Item 2 - Composição -->
    <div class="accordion-menu">
      <div class="accordion-title" tabindex="0" (click)="alternar(1)" (keypress)="alternar(1)">
        <mat-icon class="red-icon">{{ Conselho[1].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Conselho[1].titulo }}
      </div>
      @if (Conselho[1].aberto) {
        <div class="accordion-content">
          <app-composicao-conselho />
        </div>
      }
    </div>
    <!-- Item 3 - Atribuições -->
    <div class="accordion-menu">
      <div class="accordion-title" tabindex="0" (click)="alternar(2)" (keypress)="alternar(2)">
        <mat-icon class="red-icon">{{ Conselho[2].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Conselho[2].titulo }}
      </div>
      @if (Conselho[2].aberto) {
        <div class="accordion-content">
          <app-atribuicoes />
        </div>
      }
    </div>
    <!-- Item 4 - Legislação -->
    <div class="accordion-menu">
      <div class="accordion-title" tabindex="0" (click)="alternar(3)" (keypress)="alternar(3)">
        <mat-icon class="red-icon">{{ Conselho[3].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Conselho[3].titulo }}
      </div>
      @if (Conselho[3].aberto) {
        <div class="accordion-content">
          <app-legislacao-conselho />
        </div>
      }
    </div>
  </div>
}

@if (menuAtual === "OUVIDORIA") {
  <div>
    <app-separador titulo="OUVIDORIA" />
    <!-- Item 1 - Sobre a Ouvidoria -->
    <div class="accordion-menu">
      <div class="accordion-title" tabindex="0" (click)="alternar(0)" (keypress)="alternar(0)">
        <mat-icon class="red-icon">{{ Ouvidoria[0].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Ouvidoria[0].titulo }}
      </div>
      @if (Ouvidoria[0].aberto) {
        <div class="accordion-content">
          <app-sobre-ouvidoria />
        </div>
      }
    </div>
    <!-- Item 2 - Atendimento -->
    <div class="accordion-menu">
      <div class="accordion-title" tabindex="0" (click)="alternar(1)" (keypress)="alternar(1)">
        <mat-icon class="red-icon">{{ Ouvidoria[1].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Ouvidoria[1].titulo }}
      </div>
      @if (Ouvidoria[1].aberto) {
        <div class="accordion-content">
          <app-atendimento-ouvidoria />
        </div>
      }
    </div>

    <!-- Item 3 - Ex-Ouvidorias -->
    <div class="accordion-menu">
      <div class="accordion-title" tabindex="0" (click)="alternar(2)" (keypress)="alternar(2)">
        <mat-icon class="red-icon">{{ Ouvidoria[2].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Ouvidoria[2].titulo }}
      </div>
      @if (Ouvidoria[2].aberto) {
        <div class="accordion-content">
          <app-ex-ouvidoria />
        </div>
      }
    </div>

    <!-- Item 4 - Relatórios -->
    <div class="accordion-menu">
      <div class="accordion-title" tabindex="0" (click)="alternar(3)" (keypress)="alternar(3)">
        <mat-icon class="red-icon">{{ Ouvidoria[3].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Ouvidoria[3].titulo }}
      </div>
      @if (Ouvidoria[3].aberto) {
        <div class="accordion-content">
          <app-relatorio-ouvidoria />
        </div>
      }
    </div>

    <!-- Item 5 - Informações classificadas e desclassificadas -->
    <div class="accordion-menu">
      <div class="accordion-title" tabindex="0" (click)="alternar(4)" (keypress)="alternar(4)">
        <mat-icon class="red-icon">{{ Ouvidoria[4].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Ouvidoria[4].titulo }}
      </div>
      @if (Ouvidoria[4].aberto) {
        <div class="accordion-content">
          <app-inform-ouvidoria />
        </div>
      }
    </div>

    <!-- Item 6 - Programa de Integridade/PGE -->
    <div class="accordion-menu">
      <div class="accordion-title" tabindex="0" (click)="alternar(5)" (keypress)="alternar(5)">
        <mat-icon class="red-icon">{{ Ouvidoria[5].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Ouvidoria[5].titulo }}
      </div>
      @if (Ouvidoria[5].aberto) {
        <div class="accordion-content">
          <app-programa-integridade-ouvidoria />
        </div>
      }
    </div>

    <!-- Item 7 - SIC -->
    <div class="accordion-menu">
      <div class="accordion-title" tabindex="0" (click)="alternar(6)" (keypress)="alternar(6)">
        <mat-icon class="red-icon">{{ Ouvidoria[6].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ Ouvidoria[6].titulo }}
      </div>
      @if (Ouvidoria[6].aberto) {
        <div class="accordion-content">
          <app-sic-ouvidoria />
        </div>
      }
    </div>
  </div>
}
