import type { HttpErrorResponse } from '@angular/common/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import type { Observable } from 'rxjs';
import { BehaviorSubject, catchError, map, throwError } from 'rxjs';
import type { Dependente } from 'src/app/area-restrita/features/auxilio/saude/shared/models/dependente';
import { Globais } from 'src/app/area-restrita/shared/globais/globais';
import type { Page } from 'src/app/shared/models/paginacao/page.model';
import type { FormAvaliacao, FormInscricaoAuxilioSaude, InscricaoAuxilioSaude } from '../models/auxilio-saude';
@Injectable({
  providedIn: 'root'
})
export class InscricaoAuxilioSaudeService {
  private readonly http = inject(HttpClient);

  userSelectedId = new BehaviorSubject<number>(null);

  obterInscricao(): Observable<InscricaoAuxilioSaude> {
    const idUsuario = localStorage.getItem('idUsuario');
    return this.http.get<InscricaoAuxilioSaude>(`${Globais.urlToken}site-pge/api/inscricoes-auxilio-saude?idUsuario=${idUsuario}`).pipe(
      map((req) => req),
      catchError((error: HttpErrorResponse) => {
        console.error('Erro durante o envio de dados:', error);
        return throwError(() => 'Falha no envio de dados.');
      })
    );
  }

  obterListaDependentes(): Observable<Dependente[]> {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      Authorization: token
    });
    const params = new HttpParams().set('idUsuario', Number(localStorage.getItem('idUsuario')));
    return this.http.get<Dependente[]>(`${Globais.urlToken}site-pge/api/inscricoes-auxilio-saude/dependentes`, { headers: headers, params: params }).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Erro durante a obtenção de dependentes:', error);
        return throwError(() => 'Falha na obtenção de dependentes.');
      })
    );
  }

  obterListaStatusInscricaoAuxilio() {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      Authorization: token
    });

    return this.http.get<Dependente[]>(`${Globais.urlToken}site-pge/api/status-inscricao-auxilio`, { headers: headers }).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Erro durante a obtenção da lista de status:', error);
        return throwError(() => 'Falha na obtenção da lista de status.');
      })
    );
  }

  buscarPedidos(
    dadosFormulario: FormGroup<FormInscricaoAuxilioSaude>,
    pageIndex: string | number,
    pageSize: string | number
  ): Observable<Page<InscricaoAuxilioSaude>> {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });
    const dataEnvio = dadosFormulario.controls.dataEnvio.value.split('-');

    const parametros = new HttpParams()
      .set('nomeUsuario', dadosFormulario.controls.procuradorServidor.value != null ? dadosFormulario.controls.procuradorServidor.value : '')
      .set('idStatus', dadosFormulario.controls.status.value != null ? dadosFormulario.controls.status.value : '')
      .set('mesEnvio', dataEnvio[1] || '')
      .set('anoEnvio', dataEnvio[0] || '')
      .set('page', pageIndex)
      .set('size', pageSize);

    return this.http
      .get<Page<InscricaoAuxilioSaude>>(`${Globais.urlToken}site-pge/api/inscricoes-auxilio-saude/listarPedidos`, { headers: headers, params: parametros })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  deferirPedidos(idsAuxilios: Set<number>): Observable<string> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token ? token : ''
    });

    const idUsuario = Number(localStorage.getItem('idUsuario'));
    const parametros = new HttpParams().set('idUsuario', idUsuario.toString());

    return this.http
      .put(`${Globais.urlToken}site-pge/api/inscricoes-auxilio-saude/deferir`, Array.from(idsAuxilios), {
        headers: headers,
        params: parametros,
        responseType: 'text'
      })
      .pipe(
        catchError(() => {
          return throwError('Ocorreu um erro ao deferir os pedidos. Por favor, tente novamente mais tarde.');
        })
      );
  }

  indeferirPedidos(idsAuxilios: Set<number>): Observable<string> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token ? token : ''
    });

    const idUsuario = Number(localStorage.getItem('idUsuario'));
    const parametros = new HttpParams().set('idUsuario', idUsuario.toString());

    return this.http
      .put(`${Globais.urlToken}site-pge/api/inscricoes-auxilio-saude/indeferir`, Array.from(idsAuxilios), {
        headers: headers,
        params: parametros,
        responseType: 'text'
      })
      .pipe(
        catchError(() => {
          return throwError('Ocorreu um erro ao deferir os pedidos. Por favor, tente novamente mais tarde.');
        })
      );
  }

  deferirIndeferirPedido(dadosAvaliacao: FormAvaliacao): Observable<string> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token ? token : ''
    });

    const idUsuario = Number(localStorage.getItem('idUsuario'));
    const parametros = new HttpParams()
      .set('idUsuarioAvaliador', idUsuario.toString())
      .set('idUsuario', dadosAvaliacao.idUsuario.toString())
      .set('idFicha', dadosAvaliacao.idFicha.toString())
      .set('justificativa', dadosAvaliacao.justificativa ? dadosAvaliacao.justificativa.toString() : '')
      .set('status', dadosAvaliacao.idStatus == 2 ? 'A' : 'I');

    return this.http
      .put(
        `${Globais.urlToken}site-pge/api/inscricoes-auxilio-saude/deferir-indeferir-individual`,
        {},
        { headers: headers, params: parametros, responseType: 'text' }
      )
      .pipe(
        catchError(() => {
          return throwError('Ocorreu um erro ao deferir os pedidos. Por favor, tente novamente mais tarde.');
        })
      );
  }

  dateFormat(data: Date | string) {
    return new Date(data).toLocaleDateString();
  }
}
