import { Component, input, inject, ChangeDetectionStrategy } from '@angular/core';
import type { MenuNode } from '../MenuNode';
import { MenuSguService } from '../MenuSgu.service';
import { MatExpansionPanel, MatExpansionPanelHeader } from '@angular/material/expansion';
import { MatIcon } from '@angular/material/icon';
import { Router, RouterLink } from '@angular/router';
import { MatListItem, MatListItemIcon, MatListItemLine, MatNavList } from '@angular/material/list';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrl: './menu-item.component.scss',
  standalone: true,
  imports: [MatListItem, RouterLink, MatIcon, MatListItemIcon, MatListItemLine, MatExpansionPanel, MatExpansionPanelHeader, MatNavList],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuItemComponent {
  private readonly menuSguService = inject(MenuSguService);
  private readonly route = inject(Router);

  item = input.required<MenuNode>();
  menuAberto = input.required<boolean>();
  estaMostrando = input.required<boolean>();

  itemsVisiveis = new Set<number>();
  keyUp = 0;

  alternarVisibilidade(item: MenuNode) {
    if (this.itemsVisiveis.has(item.id)) {
      this.itemsVisiveis.delete(item.id);
    } else {
      this.itemsVisiveis.add(item.id);
    }
  }

  eVisivel(item: MenuNode): boolean {
    return this.itemsVisiveis.has(item.id);
  }

  KeyUp() {
    this.keyUp += 1;
  }

  mudarRota(router: string = ''): string[] {
    return router ? [router] : [];
  }

  sobreItemClicado(): void {
    this.menuSguService.emitMenuItemclicado(this.item());
  }

  sair(route: string) {
    if (route === 'logout-site-pge') void this.route.navigate(['area-restrita/logout-site-pge']);
  }

  abrirNovaAba(url: string): void {
    if (url.includes('http')) {
      let urlAlternativa = url;
      const token = localStorage.getItem('token');
      const idUsuario = localStorage.getItem('idUsuario');
      if (url.includes('{TOKENID}')) urlAlternativa = urlAlternativa.replace('{TOKENID}', token + idUsuario);
      window.open(urlAlternativa, '_blank');
    }
  }

  tratarUrl(item: MenuNode): void {
    const url = item.router;

    const baseUrl = this.getBaseUrl();

    if (this.isPdf(url)) {
      const fullUrl = `${baseUrl}${url}`;
      this.abrirNovaAba(fullUrl);
    } else if (this.isDoc(url)) {
      const fullUrl = `${baseUrl}${url}`;
      this.baixarArquivo(fullUrl);
    } else {
      void this.route.navigate([this.mudarRota(url)]);
    }
  }

  getBaseUrl(): string {
    return `${window.location.origin}/`;
  }

  isPdf(url: string): boolean {
    return url.startsWith('site-pge/pdf/');
  }

  isDoc(url: string): boolean {
    return url.endsWith('.doc') || url.endsWith('.docx');
  }

  baixarArquivo(url: string): void {
    const a = document.createElement('a');
    a.href = url;
    a.download = '';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
