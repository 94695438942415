<div>
  <app-separador titulo="Minutas Padronizadas da LEI 14.133/2021" />
  <div class="row">
    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
      <p>
        Nesta página eletrônica (um dos sítios eletrônicos oficiais do Estado), são disponibilizados os modelos de minutas de editais, de termos de referência, de contratos, e de outros documentos
        instituídos nos termos do inc. IV do art. 19 da <a href="https://www.planalto.gov.br/ccivil_03/_Ato2019-2022/2021/Lei/L14133.htm"> Lei federal nº 14.133/2021</a>, e do art. 3º do
        <a href="http://www.legislacao.sp.gov.br/legislacao/dg280202.nsf/5fb5269ed17b47ab83256cfb00501469/ae4c99f07f9f4f7d03258980004dbc9d?OpenDocument&Highlight=0,67.608"> Decreto nº 67.608/2023</a>,
        após a análise técnica da Subsecretaria de Gestão da Secretaria de Gestão e Governo Digital, e o exame jurídico da Procuradoria Geral do Estado (PGE).
      </p>
      <p><strong>Modelos disponibilizados.</strong> Encontram-se disponibilizados nesta página modelos gerais para:</p>
      <table class="linhaNumerada">
        <thead>
          <tr>
            <td>(i)</td>
            <td>licitações por menor preço ou maior desconto nas modalidades pregão e concorrência, abrangendo:</td>
          </tr>
          <tr>
            <td></td>
            <td>(i.1) minutas de edital de pregão e de concorrência;</td>
          </tr>
          <tr>
            <td></td>
            <td>(i.2) quatro variações de minutas de contrato conforme o objeto;</td>
          </tr>
          <tr>
            <td></td>
            <td>(i.3) quatro variações de minutas de termo de referência conforme o objeto;</td>
          </tr>
          <tr>
            <td>(ii)</td>
            <td>contratação direta, abrangendo:</td>
          </tr>
          <tr>
            <td></td>
            <td>(ii.1) minuta de aviso de contratação direta;</td>
          </tr>
          <tr>
            <td></td>
            <td>(ii.2) quatro variações de minutas de contrato conforme o objeto;</td>
          </tr>
          <tr>
            <td></td>
            <td>(ii.3) quatro variações de minutas de termo de referência conforme o objeto;</td>
          </tr>
          <tr>
            <td>(iii)</td>
            <td>minuta de ata de registro de preços, que poderá ser utilizado nas hipóteses de registro de preços mediante licitação ou contratação direta;</td>
          </tr>
          <tr>
            <td>(iv)</td>
            <td>minuta de edital de leilão para alienação de imóveis.</td>
          </tr>
        </thead>
      </table>
      <p>
        <br />
        <strong>Complementaridade entre modelos.</strong> Alguns dos modelos disponibilizados apresentam relação de complementaridade entre si conforme o objeto, de modo que, nessas hipóteses, o uso
        de um modelo de minuta padronizada deve ocorrer em conjunto com os demais modelos pertinentes ao caso concreto. Ex.: na hipótese de licitação para aquisição de bem comum por menor preço, há
        relação de complementaridade entre os modelos de termo de referência e de contrato para aquisição de bens mediante licitação, e o modelo de edital de pregão.
      </p>
      <p><strong>Orientações para uso.</strong> Cada modelo contém orientações e notas para uso da respectiva minuta padronizada, que deverão ser observadas pela Administração.</p>
      <p>
        <strong>Declaração de utilização de minutas padronizadas.</strong> Disponibiliza-se nesta página modelo de declaração de utilização de minutas padronizadas, a ser elaborada pelas Unidades
        Contratantes para instrução de seus processos de licitação ou contratação direta a serem encaminhados para análise da respectiva Consultoria Jurídica, a fim de otimizar a padronização,
        segurança jurídica e eficiência na tramitação dos respectivos processos.
      </p>
      <p>
        <strong>Material de apoio.</strong> Como material de apoio, disponibiliza-se nesta página a versão mais recente das principais orientações jurídicas consolidadas da Subprocuradoria Geral da
        Consultoria Geral a respeito da aplicação da <a href="https://www.planalto.gov.br/ccivil_03/_Ato2019-2022/2021/Lei/L14133.htm">Lei federal nº 14.133/2021</a> (atualmente a 8ª versão, conforme
        a e-orientação SubG-Cons nº 12/2024).
      </p>
      <p><strong>Resumo das principais alterações das últimas atualizações.</strong> As principais alterações das últimas atualizações são:</p>
      <table class="linhaNumerada">
        <thead>
          <tr>
            <td>1.</td>
            <td>a inclusão de versão de 05/09/2024 das minutas padronizadas, para aperfeiçoamento dos campos editáveis dos documentos;</td>
          </tr>
          <tr>
            <td>2.</td>
            <td>a inclusão da 8ª Versão das Orientações Consolidadas SubG-Cons PGE/SP, de 20/09/2024, como material de apoio.</td>
          </tr>
        </thead>
      </table>
      <br />
      <p>
        <strong>Propostas de alterações e considerações.</strong> Propostas de alterações no texto e considerações sobre os modelos podem ser enviadas à Subsecretaria de Gestão, por intermédio do
        endereço eletrônico <a href="https://compras.sp.gov.br/fale-conosoco">https://compras.sp.gov.br/fale-conosoco</a> e à PGE, por intermédio do e-mail
        <a href="mailto:sgcgeral@sp.gov.br">sgcgeral&#64;sp.gov.br</a>.
      </p>
    </div>
  </div>
  <br />
  <div class="accordion-menu">
    <p class="accordion-title" tabindex="0" (click)="alternar(0)" (keypress)="alternar(0)">
      <mat-icon class="red-icon">{{ minutas[0].estaAberto ? "expand_less" : "expand_more" }}</mat-icon>
      {{ minutas[0].titulo }}
    </p>
    @if (minutas[0].estaAberto) {
      <div class="accordion-content">
        <br />
        <div class="table-responsive">
          <table class="tabelaPadrao">
            <thead>
              <tr class="mb-2">
                <th class="fw-bold text-center">Descrição</th>
                <th class="fw-bold text-center">Inserida em</th>
                <th class="fw-bold text-center">Baixar</th>
              </tr>
            </thead>
            <tbody>
              @for (contratacaoDireta of listaContratacaoDireta; track contratacaoDireta; let i = $index) {
                <tr class="mb-2">
                  <td>{{ contratacaoDireta.descricao }}</td>
                  <td class="text-center">{{ contratacaoDireta.inseridaEm }}</td>
                  <td class="text-center"><a [href]="contratacaoDireta.baixar">Download</a></td>
                </tr>
              }
            </tbody>
          </table>
          <br />
        </div>
      </div>
    }
  </div>
  <div class="accordion-menu">
    <p class="accordion-title" tabindex="0" (click)="alternar(1)" (keypress)="alternar(1)">
      <mat-icon class="red-icon">{{ minutas[1].estaAberto ? "expand_less" : "expand_more" }}</mat-icon>
      {{ minutas[1].titulo }}
    </p>
    @if (minutas[1].estaAberto) {
      <div class="accordion-content">
        <br />
        <div class="table-responsive">
          <table class="tabelaPadrao">
            <thead>
              <tr class="mb-2">
                <th class="fw-bold text-center">Descrição</th>
                <th class="fw-bold text-center">Inserida em</th>
                <th class="fw-bold text-center">Baixar</th>
              </tr>
            </thead>
            <tbody>
              @for (licitacao of listaLicitacao; track licitacao; let i = $index) {
                <tr class="mb-2">
                  <td>{{ licitacao.descricao }}</td>
                  <td class="text-center">{{ licitacao.inseridaEm }}</td>
                  <td class="text-center"><a [href]="licitacao.baixar">Download</a></td>
                </tr>
              }
            </tbody>
          </table>
          <br />
        </div>
      </div>
    }
  </div>
  <div class="accordion-menu">
    <p class="accordion-title" tabindex="0" (click)="alternar(2)" (keypress)="alternar(2)">
      <mat-icon class="red-icon">{{ minutas[2].estaAberto ? "expand_less" : "expand_more" }}</mat-icon>
      {{ minutas[2].titulo }}
    </p>
    @if (minutas[2].estaAberto) {
      <div class="accordion-content">
        <br />
        <div class="table-responsive">
          <table class="tabelaPadrao">
            <thead>
              <tr class="mb-2">
                <th class="fw-bold text-center">Descrição</th>
                <th class="fw-bold text-center">Inserida em</th>
                <th class="fw-bold text-center">Baixar</th>
              </tr>
            </thead>
            <tbody>
              @for (licitacaoContratacaoDireta of listaLicitacaoContratacaoDireta; track licitacaoContratacaoDireta; let i = $index) {
                <tr class="mb-2">
                  <td>{{ licitacaoContratacaoDireta.descricao }}</td>
                  <td class="text-center">{{ licitacaoContratacaoDireta.inseridaEm }}</td>
                  <td class="text-center"><a [href]="licitacaoContratacaoDireta.baixar">Download</a></td>
                </tr>
              }
            </tbody>
          </table>
          <br />
        </div>
      </div>
    }
  </div>
  <div class="accordion-menu">
    <p class="accordion-title" tabindex="0" (click)="alternar(3)" (keypress)="alternar(3)">
      <mat-icon class="red-icon">{{ minutas[3].estaAberto ? "expand_less" : "expand_more" }}</mat-icon>
      {{ minutas[3].titulo }}
    </p>
    @if (minutas[3].estaAberto) {
      <div class="accordion-content">
        <br />
        <div class="table-responsive">
          <table class="tabelaPadrao">
            <thead>
              <tr class="mb-2">
                <th class="fw-bold text-center">Descrição</th>
                <th class="fw-bold text-center">Inserida em</th>
                <th class="fw-bold text-center">Baixar</th>
              </tr>
            </thead>
            <tbody>
              @for (documentacaoInstrucaoProcesso of listaDocumentacaoInstrucaoProcesso; track documentacaoInstrucaoProcesso; let i = $index) {
                <tr class="mb-2">
                  <td>{{ documentacaoInstrucaoProcesso.descricao }}</td>
                  <td class="text-center">{{ documentacaoInstrucaoProcesso.inseridaEm }}</td>
                  <td class="text-center"><a [href]="documentacaoInstrucaoProcesso.baixar">Download</a></td>
                </tr>
              }
            </tbody>
          </table>
          <br />
        </div>
      </div>
    }
  </div>
  <div class="accordion-menu">
    <p class="accordion-title" tabindex="0" (click)="alternar(4)" (keypress)="alternar(4)">
      <mat-icon class="red-icon">{{ minutas[4].estaAberto ? "expand_less" : "expand_more" }}</mat-icon>
      {{ minutas[4].titulo }}
    </p>
    @if (minutas[4].estaAberto) {
      <div class="accordion-content">
        <br />
        <div class="table-responsive">
          <table class="tabelaPadrao">
            <thead>
              <tr class="mb-2">
                <th class="fw-bold text-center">Descrição</th>
                <th class="fw-bold text-center">Inserida em</th>
                <th class="fw-bold text-center">Baixar</th>
              </tr>
            </thead>
            <tbody>
              @for (materialApoio of listaMaterialApoio; track materialApoio; let i = $index) {
                <tr class="mb-2">
                  <td>{{ materialApoio.descricao }}</td>
                  <td class="text-center">{{ materialApoio.inseridaEm }}</td>
                  <td class="text-center"><a [href]="materialApoio.baixar">Download</a></td>
                </tr>
              }
            </tbody>
          </table>
        </div>
        <br />
      </div>
    }
  </div>
</div>
