export interface Filtro {
  tipo: FiltroTipoEnum;
  valores: string[];
}

export enum FiltroTipoEnum {
  BUSCA_LIVRE = 0,
  PERIODO_VIGENCIA = 1,
  PERIODO_PUBLICACAO = 2,
  NOME_SANCIONADO = 3,
  CPF_CNPJ_SANCIONADO = 4,
  UF_SANCIONADO = 5
}

export const filtroTipoTexto = {
  [FiltroTipoEnum.BUSCA_LIVRE]: 'Busca livre',
  [FiltroTipoEnum.PERIODO_VIGENCIA]: 'Período da vigência',
  [FiltroTipoEnum.PERIODO_PUBLICACAO]: 'Perído da publicação',
  [FiltroTipoEnum.NOME_SANCIONADO]: 'Nome do sancionado',
  [FiltroTipoEnum.CPF_CNPJ_SANCIONADO]: 'CPF/CNPJ do sancionado',
  [FiltroTipoEnum.UF_SANCIONADO]: 'UF do sancionado'
};
