<div class="header-dialog">
  <button mat-icon-button mat-dialog-close type="submit">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="header-dialog"><img class="img" src="images/brasao.svg" alt="Brasão PGE" tabindex="0" (click)="paginaInicial()" (keypress)="paginaInicial()" /></div>
<div class="social-icons">
  <a href="https://www.whatsapp.com/channel/0029VaO5LfK9mrGgrM7MFi3X" target="_blank"><img src="images/whatsapp.svg" alt="Whats App" /></a>
  <a href="https://www.facebook.com/pgespoficial/?locale=pt_BR" target="_blank"><img src="images/facebook.svg" alt="Facebook" /></a>
  <a href="https://www.instagram.com/pgespoficial/" target="_blank"> <img src="images/instagram.svg" alt="Instagram" /></a>
  <a href="https://br.linkedin.com/company/procuradoria-geral-do-estado-de-s%C3%A3o-paulo-pge-sp" target="_blank"><img src="images/linkedin.svg" alt="Linkedin" /></a>
</div>
<app-search class="busca-mobile" />
<div class="menu-container-flex">
  <div class="div-web">
    @for (menuItem of menuList; track menuItem) {
      <div class="menu">
        <ng-container>
          @if (menuItem.menuFilho) {
            <span class="menubtn">{{ menuItem.titulo }}</span>
          }
          @if (!menuItem.menuFilho) {
            <a class="nav-link menubtn" target="_blank" [href]="menuItem.redirecionar">{{ menuItem.titulo }} </a>
          }
        </ng-container>
        <div class="menu-content">
          @for (childL1 of menuItem.menuFilho; track childL1) {
            @if (!childL1.menuFilho) {
              <li mat-menu-item tabindex="0" (click)="redirecionar(childL1)" (keyup)="redirecionar(childL1)">
                {{ childL1.titulo }}
              </li>
            }
            @if (childL1.menuFilho && childL1.menuFilho.length > 0) {
              {{ childL1.menuFilho }}
            }
          }
        </div>
      </div>
    }
    <app-search class="busca-web" />
  </div>
  <div class="div-mobile">
    @for (menuItem of menuList; track menuItem) {
      <div class="menu">
        <ng-container>
          @if (menuItem.menuFilho) {
            <span class="menubtn centralizar-div" tabindex="0" (click)="alternarMenu(menuItem)" (keyup)="alternarMenu(menuItem)">{{ menuItem.titulo }}</span>
            @if (subMenuitem === menuItem) {
              @for (childL1 of subMenuitem?.menuFilho; track childL1) {
                @if (!childL1.menuFilho) {
                  <li mat-menu-item class="centralizar-div" tabindex="0" (click)="redirecionar(childL1)" (keyup)="redirecionar(childL1)">
                    {{ childL1.titulo }}
                  </li>
                }
                @if (childL1.menuFilho && childL1.menuFilho.length > 0) {
                  {{ childL1.menuFilho }}
                }
              }
            }
          }
          @if (!menuItem.menuFilho) {
            <a class="nav-link menubtn" target="_blank" [href]="menuItem.redirecionar">{{ menuItem.titulo }} </a>
          }
        </ng-container>
      </div>
    }
  </div>
</div>

<ng-template #menuItemContainer let-menuItem>
  @if (menuItem.externo) {
    <a class="menu-item nav-link" target="_blank" [href]="menuItem.redirecionar">{{ menuItem.titulo }} </a>
  }
  @if (!menuItem.externo) {
    <a class="menu-item nav-link" target="_self" [href]="menuItem.redirecionar">{{ menuItem.titulo }} </a>
  }
</ng-template>
