import { Injectable } from '@angular/core';
import type { DependenteParetesco } from 'src/app/area-restrita/features/auxilio/saude/shared/models/dependente-parentesco';

@Injectable({
  providedIn: 'root'
})
export class ReportAuxilioSaudeService {
  public getParentesco(): DependenteParetesco[] {
    this.opcoesRelacionamento();
    return this.parentesco;
  }

  private readonly parentesco: DependenteParetesco[] = [];

  private opcoesRelacionamento() {
    this.addParentesco(1, 'Conjuge ou companheiro(a)');
    this.addParentesco(2, 'Conjuge ou companheiro(a) na constancia do casamento ou uniao estavel');
    this.addParentesco(3, 'Enteado ou menor tutelado sob dependencia economica');
    this.addParentesco(4, 'Ex-conjuge');
    this.addParentesco(5, 'Filho(a) ou enteado(a) ate 21 anos de idade ou incapacitado(a) fisica ou mentalmente para o trabalho');
    this.addParentesco(6, 'Filho(a) ou enteado(a) ate 24 anos universitario ou cursando escola tecnica');
    this.addParentesco(7, 'Filho ou filha invalido(a) para o trabalho ou incapaz civilmente sob dependencia economica');
    this.addParentesco(8, 'Filho ou filha menor de 21 anos e nao emancipado(a)');
    this.addParentesco(
      9,
      'Irmao(a), neto(a) ou bisneto(a) ate 21 anos do(a) qual tenha guarda judicial ou incapacitado fisica ou mentalmente para o trabalho ou com idade de 21 ate 24 anos universitario ou cursando escola tecnica ate 21'
    );
    this.addParentesco(10, 'Menor pobre ate 21 anos do qual tenha guarda judicial');
    this.addParentesco(11, 'Pessoa absolutamente incapaz da qual seja tutor ou curador');
  }

  private addParentesco(id: number, nome: string): void {
    const paretescoNovo: DependenteParetesco = { id, nome };
    this.parentesco.push(paretescoNovo);
  }
}
