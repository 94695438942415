import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'obterMesData',
  standalone: true
})
export class ObterMesDataPipe implements PipeTransform {
  transform(value: string): string {
    const dateParts = value.split('/');
    const month = parseInt(dateParts[1]);
    const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    return months[month - 1];
  }
}
