<app-separador titulo="Consulta de Sanções" />
<div class="container-sancoes">
  <div class="barra-topo">
    <!-- Esconder o botão por solicitação do cliente.  -->
    @if (false) {
      <button #botaoPesquisa type="button" mat-mini-fab color="primary" (click)="alternarPainel()">
        <mat-icon class="rotacionar-icone" [class.rotacionado]="mostrarPainel()">
          {{ mostrarPainel() ? "close" : "search" }}
        </mat-icon>
      </button>

      <span class="flex-spacer-separador"></span>
      <div class="row">
        <button type="button" title="Imprimir" mat-mini-fab color="primary">
          <mat-icon>printer</mat-icon>
        </button>
        <button type="button" title="Baixar CSV" mat-mini-fab color="primary">
          <mat-icon>download</mat-icon>
        </button>
        <button type="button" title="Mostrar/esconder colunas" mat-mini-fab color="primary">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
    }
  </div>
  @if (filtrosAplicados().length > 0) {
    <app-filtro-adicionado [filtros]="filtrosAplicados()" />
  }
  <div class="container-pesquisa-tabela">
    <div class="search-container">
      <div class="painel-pesquisa" [@slideInOut]="mostrarPainel() ? 'in' : 'out'">
        <mat-accordion #accordionFiltro="matAccordion">
          <mat-expansion-panel #painelBuscaLivre hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title> Busca livre </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="form-flex">
              <p>Utilize esse campo para pesquisar o termo desejado em todos os demais campos textuais de busca relacionados a esta tela</p>
              <input #inputBuscaLivre class="col-12" type="text" pInputText placeholder="Informe o conteúdo para busca livre" />
              <button type="button" mat-raised-button color="primary" (click)="aplicarFiltro(filtroTipoEnum.BUSCA_LIVRE, inputBuscaLivre.value)">Aplicar</button>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel #painelPeriodoVigencia hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title> Período de vigência </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="form-flex">
              <div class="d-flex-col">
                <label for="inicioVigencia">Início</label>
                <input #inicioVigencia type="date" class="col-12" pInputText placeholder="Informe uma data" id="inicioVigencia" />
              </div>

              <div class="d-flex-col">
                <label for="fimVigencia">Fim</label>
                <input #fimVigencia type="date" class="col-12" pInputText placeholder="Informe uma data" id="fimVigencia" />
              </div>

              <button
                type="button"
                mat-raised-button
                color="primary"
                (click)="aplicarFiltro(filtroTipoEnum.PERIODO_VIGENCIA, inicioVigencia.valueAsDate?.toLocaleDateString() + ' até ' + fimVigencia.valueAsDate?.toLocaleDateString())"
              >
                Aplicar
              </button>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel #painelPeriodoPublicacao hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title> Período de publicação </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="form-flex">
              <div class="d-flex-col">
                <label for="inicioPublicacao">Início</label>
                <input #inicioPublicacao type="date" class="col-12" pInputText placeholder="Informe uma data" id="inicioPublicacao" />
              </div>

              <div class="d-flex-col">
                <label for="fimPublicacao">Fim</label>
                <input #fimPublicacao type="date" class="col-12" pInputText placeholder="Informe uma data" id="fimPublicacao" />
              </div>

              <button
                type="button"
                mat-raised-button
                color="primary"
                (click)="aplicarFiltro(filtroTipoEnum.PERIODO_PUBLICACAO, inicioPublicacao.valueAsDate?.toLocaleDateString() + ' até ' + fimPublicacao.valueAsDate?.toLocaleDateString())"
              >
                Aplicar
              </button>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel #painelNome hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title> Nome sancionado </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="form-flex">
              <input #inputNome class="col-12" type="text" pInputText placeholder="Informe o nome do sancionado" />
              <button type="button" mat-raised-button color="primary" (click)="aplicarFiltro(filtroTipoEnum.NOME_SANCIONADO, inputNome.value)">Aplicar</button>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel #painelCpfCnpj hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title> CPF/CNPJ sancionado </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="form-flex">
              <input #inputCpfCnpj class="col-12" type="text" pInputText placeholder="Informe o cpf ou cnpj do sancionado" />
              <button type="button" mat-raised-button color="primary" (click)="aplicarFiltro(filtroTipoEnum.CPF_CNPJ_SANCIONADO, inputCpfCnpj.value)">Aplicar</button>
            </div>
          </mat-expansion-panel>
          <mat-expansion-panel #painelNome hideToggle>
            <mat-expansion-panel-header>
              <mat-panel-title> UF do sancionado </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="form-flex">
              <p-multiSelect appendTo="body" optionLabel="nome" optionValue="sigla" placeholder="Seleciona a(s) UF(s)" [options]="ufs" [(ngModel)]="ufsSelecionadas" />
              <button type="button" mat-raised-button color="primary" (click)="aplicarFiltroUf()">Aplicar</button>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>

    <div class="container-table">
      <table class="mat-elevation-z8" mat-table [dataSource]="dataSource">
        <ng-container>
          <ng-container matColumnDef="detalhar">
            <th *matHeaderCellDef mat-header-cell>Detalhar</th>
            <td *matCellDef="let element" mat-cell>
              <a type="button" class="detalhar" href="pdf/despacho-PGE_23042024_secao-I.pdf" target="_blank"><mat-icon>visibility</mat-icon></a>
            </td>
          </ng-container>
          <ng-container matColumnDef="cnpjCpf">
            <th *matHeaderCellDef mat-header-cell>CNPJ/CPF Sancionado</th>
            <td *matCellDef="let element" mat-cell>{{ element.cnpjCpf }}</td>
          </ng-container>
          <ng-container matColumnDef="nome">
            <th *matHeaderCellDef mat-header-cell>Nome Sancionado</th>
            <td *matCellDef="let element" mat-cell>{{ element.nome }}</td>
          </ng-container>
          <ng-container matColumnDef="uf">
            <th *matHeaderCellDef mat-header-cell>UF Sancionado</th>
            <td *matCellDef="let element" mat-cell>{{ element.uf }}</td>
          </ng-container>
          <ng-container matColumnDef="orgao">
            <th *matHeaderCellDef mat-header-cell>Órgão/Entidade Sancionadora</th>
            <td *matCellDef="let element" mat-cell>{{ element.orgao }}</td>
          </ng-container>
          <ng-container matColumnDef="categoria">
            <th *matHeaderCellDef mat-header-cell>Categoria Sanção</th>
            <td *matCellDef="let element" mat-cell>{{ element.categoria }}</td>
          </ng-container>
          <ng-container matColumnDef="dataPublicacao">
            <th *matHeaderCellDef mat-header-cell>Data de publicação da Sanção</th>
            <td *matCellDef="let element" mat-cell>{{ element.dataPublicacao | date: "dd/MM/yyyy" }}</td>
          </ng-container>
          <ng-container matColumnDef="valorMulta">
            <th *matHeaderCellDef mat-header-cell>Valor da Multa</th>
            <td *matCellDef="let element" mat-cell>{{ element.valorMulta }}</td>
          </ng-container>
          <ng-container matColumnDef="quantidade">
            <th *matHeaderCellDef mat-header-cell>Quantidade</th>
            <td *matCellDef="let element" mat-cell>{{ element.quantidade }}</td>
          </ng-container>

          <tr *matHeaderRowDef="colunas" mat-header-row></tr>
          <tr *matRowDef="let row; columns: colunas" mat-row></tr>
        </ng-container>
      </table>
    </div>
  </div>
</div>
