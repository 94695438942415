import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { map } from 'rxjs';
import { Globais } from 'src/app/area-restrita/shared/globais/globais';

@Injectable({
  providedIn: 'root'
})
export class WebServiceInfosService {
  private readonly http = inject(HttpClient);

  getInfosPorCodigo(codigo): Observable<string> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post(`${Globais.urlToken}site-pge/api/subprocuradoria/consulta`, codigo, { headers: headers, responseType: 'text' }).pipe(
      map((response) => {
        return response;
      })
    );
  }

  getInfosPorLista(listaCodigos: string[]): Observable<string[]> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post<string[]>(`${Globais.urlToken}site-pge/api/subprocuradoria/consulta-lista`, listaCodigos, { headers: headers }).pipe(
      map((response) => {
        return response;
      })
    );
  }
}
