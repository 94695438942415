<main class="main" role="main">
  <section class="page">
    <div class="container">
      <app-separador titulo="Procuradoria Regional da Grande São Paulo (PR01)" />
      <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
          <br />
          <h3>PR01 CHEFIA GABINETE</h3>
          <div class="pge-unidade">
            <p class="procurador"><strong>Procurador(a) Chefe</strong>: REBECCA CORREA PORTO DE FREITAS</p>
            <p class="procurador"><strong>Procurador(a)</strong>: {{ encontrarNome("Login de Teste Procurador") }}</p>
            <p class="endereco"><strong>Endereço</strong>: Avenida Rangel Pestana, 300, 15º andar, Centro, São Paulo-SP, 01017-000.</p>
            <p class="tel"><strong>Telefone</strong>: (11) 3243-3541 | (11) 3243-3397</p>
            <p class="email"><strong>E-mail</strong>: pfatendimento&#64;sp.gov.br</p>
            <div class="descricao">
              <strong>Municípios abrangidos:</strong> Arujá, Barueri, Biritiba Mirim, Brás Cubas; Caieiras, Carapicuíba, Cotia, Diadema, Embu, Embu-Guaçu, Ferraz de Vasconcelos, Francisco Morato,
              Franco da Rocha, Guararema, Guarulhos, Igaratá, Itapecerica da Serra, Itapevi, Itaquaquecetuba, Jandira, Juquitiba, Mairiporã, Mauá, Mogi das Cruzes, Osasco, Pirapora do Bom Jesus, Poá,
              Ribeirão Pires, Rio Grande da Serra, Santa Isabel, Santana do Parnaíba, Santo André, São Bernardo do Campo, São Caetano do Sul, São Lourenço da Serra, Suzano, Taboão da Serra, Vargem
              Grande Paulista.
            </div>
          </div>
          <br />
          <hr />
          <br />
          <h3>SECCIONAL DE MOGI DAS CRUZES</h3>
          <div class="pge-unidade">
            <p class="endereco"><strong>Endereço</strong>: Avenida Capitão Manoel Rudge, 1536, , Parque Monte Líbano, Mogi das Cruzes-SP, 08780-290.</p>
            <p class="tel"><strong>Telefone</strong>: (11) 4726-2629 / 4721-5497 / 4726-1692 / 4726-5417</p>
            <p class="email"><strong>E-mail</strong>: pr1mogidascruzes&#64;sp.gov.br</p>
          </div>
          <br />
          <hr />
          <br />
          <app-voltar-component />
          <br />
        </div>
      </div>
    </div>
  </section>
</main>
