import type { OnInit } from '@angular/core';
import { Component, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatSuffix } from '@angular/material/form-field';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss',
  standalone: true,
  imports: [MatFormField, MatInput, FormsModule, ReactiveFormsModule, MatButton, MatSuffix, MatIcon]
})
export class SearchComponent implements OnInit {
  private readonly activeRoute = inject(ActivatedRoute);

  router: Router = inject(Router);
  buscarControl = new FormControl('');

  ngOnInit(): void {
    this.obterConsultaParametro();
  }

  buscar() {
    const value = this.buscarControl.value;
    const queryParam = this.activeRoute.snapshot.queryParams['buscar'];
    if (!value && queryParam) {
      void this.router.navigate(['/']).then(() => {
        window.location.reload();
      });
    }
    if (!value) return;
    void this.router.navigate(['/'], { queryParams: { buscar: value } }).then(() => {
      window.location.reload();
    });
  }

  obterConsultaParametro() {
    this.activeRoute.queryParams.subscribe((params) => {
      this.buscarControl.setValue(params['buscar']);
    });
  }
}
