export default class FichaAuxilioHelper {
  opcoesRelacionamento = [
    'Conjuge ou companheiro(a)',
    'Enteado ou menor tutelado sob dependencia economica',
    'Ex-conjuge',
    'Filho(a) ou enteado(a) ate 21 anos de idade ou incapacitado(a) fisica ou mentalmente para o trabalho',
    'Filho(a) ou enteado(a) ate 24 anos universitario ou cursando escola tecnica',
    'Filho ou filha invalido(a) para o trabalho ou incapaz civilmente sob dependencia economica',
    'Filho ou filha menor de 21 anos e nao emancipado(a)',
    'Irmao(a), neto(a) ou bisneto(a) ate 21 anos do(a) qual tenha guarda judicial ou incapacitado fisica ou mentalmente para o trabalho ou com idade de 21 ate 24 anos universitario ou cursando escola tecnica ate 21',
    'Menor pobre ate 21 anos do qual tenha guarda judicial',
    'Pessoa absolutamente incapaz da qual seja tutor ou curador'
  ];

  mensagensErro = [
    { id: 0, campo: 'nome', mensagem: 'Nome obrigatório', icone: 'cancel' },
    { id: 1, campo: 'cpfDependente', mensagem: 'CPF inválido', icone: 'cancel' },
    { id: 2, campo: 'dataNascimento', mensagem: 'Data de Nascimento invalida', icone: 'cancel' },
    { id: 3, campo: 'descricaoDependente', mensagem: 'Descrição obrigatória', icone: 'cancel' },
    { id: 4, campo: 'tipoPcd', mensagem: 'Campo PCD obrigatório', icone: 'cancel' }
  ];
}
