<main class="main" role="main">
  <section class="page">
    <app-separador titulo="COMPOSIÇÃO" />
    <div class="row">
      <div>
        <h4>PROCURADORA GERAL DO ESTADO</h4>
        <div class="pge-unidade">
          <p class="procurador"><strong>Procurador(a)</strong>: INES MARIA DOS SANTOS COIMBRA</p>
        </div>
        <p>&nbsp;</p>
        <h4>PROCURADOR GERAL DO ESTADO ADJUNTO</h4>
        <div class="pge-unidade">
          <p class="procurador"><strong>Procurador(a)</strong>: {{ encontrarNome("CAIO CESAR GUZZARDI DA SILVA") }}</p>
        </div>
        <p>&nbsp;</p>
        <h4>CHEFE DE GABINETE</h4>
        <div class="pge-unidade">
          <p class="procurador"><strong>Procurador(a)</strong>: {{ encontrarNome("ERIC RONALD JANUARIO") }}</p>
        </div>
        <hr />
      </div>
    </div>
  </section>
</main>
