import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'app-separador-email',
  templateUrl: './separador-email.component.html',
  styleUrl: './separador-email.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SeparadorEmailComponent {
  titulo = input.required<string>();
  msg1 = input.required<string>();
  msg2 = input.required<string>();
  msg3 = input.required<string>();
  msg4 = input<string>('');
}
