import type { HttpErrorResponse } from '@angular/common/http';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import type { Observable } from 'rxjs';
import { catchError, map, throwError } from 'rxjs';
import type { Legislacao, LegislacaoArquivo } from 'src/app/area-restrita/features/auxilio/saude/shared/models/legislacao';
import type { TipoLegislacao } from 'src/app/area-restrita/features/auxilio/saude/shared/models/tipo-publicacao';
import { Globais } from 'src/app/area-restrita/shared/globais/globais';
import type { Page } from 'src/app/shared/models/paginacao/page.model';

@Injectable({
  providedIn: 'root'
})
export class LegislacaoService {
  private readonly http = inject(HttpClient);

  public buscar(dadosFormulario: FormGroup, pageIndex: string | number, pageSize: string | number): Observable<Page<Legislacao>> {
    let parametros = new HttpParams().set('page', pageIndex.toString()).set('size', pageSize.toString());
    if (dadosFormulario.controls['publicacao'].value) parametros = parametros.set('publicacao', dadosFormulario.controls['publicacao'].value);

    if (dadosFormulario.controls['conteudo'].value) parametros = parametros.set('conteudo', dadosFormulario.controls['conteudo'].value);

    if (dadosFormulario.controls['tipoLegislacao'].value) parametros = parametros.set('tipoLegislacao', dadosFormulario.controls['tipoLegislacao'].value);

    console.log('parametros', parametros);

    return this.http.get<Page<Legislacao>>(`${Globais.urlToken}site-pge/api/legislacao`, { params: parametros }).pipe(
      map((response) => {
        return response;
      })
    );
  }

  public obterListaTipoPublicacao(): Observable<TipoLegislacao[]> {
    return this.http.get<TipoLegislacao[]>(`${Globais.urlToken}site-pge/api/tipo-legislacao`).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Erro durante a obtenção da lista de status:', error);
        return throwError(() => 'Falha na obtenção da lista de status.');
      })
    );
  }

  public buscarPdf(id: number): Observable<LegislacaoArquivo> {
    const parametros = new HttpParams().set('id', id);
    return this.http.get<LegislacaoArquivo>(`${Globais.urlToken}site-pge/api/legislacao/pdf`, { params: parametros }).pipe(
      map((response) => {
        return response;
      })
    );
  }
}
