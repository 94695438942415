<mat-drawer-container hasBackdrop="unset" class="customizacao-principal">
  <div [ngClass]="{ 'flex-center': naoFomularioLogin() }">
    <div class="content-wrapper" [ngClass]="{ 'all-content': naoFomularioLogin() }">
      <main>
        <div>
          <div class="header-menu-container">
            @if (naoFomularioLogin()) {
              <app-header />
            }
            @if (naoFomularioLogin()) {
              <button class="button-menu" mat-icon-button type="submit" (click)="abrirMenu()">
                <mat-icon>menu</mat-icon>
              </button>
              <app-menu class="menu-container" />
            }
          </div>
          @if (!buscar) {
            <div class="container-router">
              <router-outlet />
            </div>
          }
          @if (buscar) {
            <app-search-results />
          }
        </div>
      </main>
    </div>
  </div>
  @if (naoFomularioLogin()) {
    <div class="app-footer">
      <app-footer />
    </div>
  }
</mat-drawer-container>
