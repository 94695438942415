import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SeparadorComponent } from '../../core/layout/separador/separador.component';

@Component({
  selector: 'app-privacidade-protecao-dados',
  standalone: true,
  imports: [SeparadorComponent],
  templateUrl: './privacidade-protecao-dados.component.html',
  styleUrl: './privacidade-protecao-dados.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivacidadeProtecaoDadosComponent {}
