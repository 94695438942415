import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, signal, viewChild } from '@angular/core';
import { FormBuilder, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import type { MatAccordion } from '@angular/material/expansion';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { SeparadorComponent } from 'src/app/area-aberta/core/layout/separador/separador.component';
import { UNIDADES_FEDERATIVAS } from 'src/app/shared/data/unidades-federativas.data';
import { SANCOES_MOCK } from './consulta-sancoes.mock';
import type { Filtro } from './filtro';
import { FiltroTipoEnum } from './filtro';
import { FiltroAdicionadoComponent } from './filtro-adicionado/filtro-adicionado.component';

export interface Sancao {
  cadastro: string;
  cnpjCpf: string;
  nome: string;
  uf: string;
  orgao: string;
  categoria: string;
  dataPublicacao: Date;
  valorMulta: number;
  quantidade: number;
}

@Component({
  selector: 'app-consulta-sancoes',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    MatFormFieldModule,
    InputTextModule,
    TableModule,
    MatExpansionModule,
    MultiSelectModule,
    FiltroAdicionadoComponent,
    SeparadorComponent
  ],
  templateUrl: './consulta-sancoes.component.html',
  styleUrl: './consulta-sancoes.component.scss',
  animations: [
    trigger('slideInOut', [
      state('in', style({ transform: 'translateX(0)', opacity: 1 })),
      state('out', style({ width: 0, opacity: 0 })),
      transition('in <=> out', animate('300ms ease-in-out'))
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsultaSancoesComponent {
  private readonly accordionFiltro = viewChild<MatAccordion>('accordionFiltro');
  private readonly fb = inject(FormBuilder);
  public readonly filtroTipoEnum = FiltroTipoEnum;
  public readonly ufs = UNIDADES_FEDERATIVAS;
  public readonly dataSource = new MatTableDataSource<Sancao>(SANCOES_MOCK);
  public colunas = ['detalhar', 'cnpjCpf', 'nome', 'uf', 'orgao', 'categoria', 'dataPublicacao', 'valorMulta', 'quantidade'];
  public mostrarPainel = signal(false);

  public ufsSelecionadas = [];
  public filtrosAplicados = signal<Filtro[]>([]);

  formFiltro = this.fb.nonNullable.group({
    buscaLivre: ['']
  });

  public alternarPainel(): void {
    this.accordionFiltro()?.closeAll();
    this.mostrarPainel.set(!this.mostrarPainel());
  }

  public aplicarFiltro(tipoFiltro: FiltroTipoEnum, valor: string): void {
    this.filtrosAplicados.update((filtros) => {
      const array = filtros.find((f) => f.tipo === tipoFiltro);
      if (array) {
        array.valores.push(valor);
        return filtros;
      } else return [...filtros, { tipo: tipoFiltro, valores: [valor] }];
    });
    console.log('filtros', this.filtrosAplicados());
  }

  public aplicarFiltroUf(): void {
    if (this.ufsSelecionadas.length > 0) {
      this.ufsSelecionadas.forEach((uf) => {
        this.aplicarFiltro(this.filtroTipoEnum.UF_SANCIONADO, uf);
      });
    }
  }
}
