import { Component, output, inject, ChangeDetectionStrategy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import type { InformaProcesso } from './informa-processo';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
@Component({
  selector: 'app-informa-dialog',
  templateUrl: './informa-processo-dialog.component.html',
  styleUrl: './informa-processo-dialog.component.scss',
  standalone: true,
  imports: [MatIconButton, MatIcon],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InformaProcessoDialogComponent {
  dialogRef = inject<MatDialogRef<InformaProcessoDialogComponent>>(MatDialogRef);
  data = inject<InformaProcesso>(MAT_DIALOG_DATA);

  readonly fechar = output<string>();

  onClose() {
    this.fechar.emit('');
    this.dialogRef.close();
  }
}
