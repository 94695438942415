<main class="main" role="main">
  <app-separador titulo="Relatórios OUVIDORIA" />
  <section class="page">
    <div class="container">
      <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
          <p><a href="http://www.portal.pge.sp.gov.br/wp-content/uploads/2022/08/relatorio_1semestre_2022.pdf" target="_blank" rel="noopener noreferrer">Relatório 2022 – 1º Semestre</a></p>
          <p><a href="http://www.portal.pge.sp.gov.br/wp-content/uploads/2022/02/relatorio_022021.pdf" target="_blank" rel="noopener noreferrer">Relatório 2021 – 2º Semestre</a></p>
          <p><a href="http://www.portal.pge.sp.gov.br/wp-content/uploads/2021/08/relatorio_012021.pdf" target="_blank" rel="noopener noreferrer">Relatório 2021 – 1º Semestre</a></p>
          <p><a href="http://www.portal.pge.sp.gov.br/wp-content/uploads/2021/08/relatorio_022020.pdf" target="_blank" rel="noopener noreferrer">Relatório 2020 – 2º Semestre</a></p>
          <p><a href="http://www.portal.pge.sp.gov.br/wp-content/uploads/2021/02/relatorio_012020.pdf" target="_blank" rel="noopener noreferrer">Relatório 2020 – 1º Semestre</a></p>
          <p><a href="http://www.portal.pge.sp.gov.br/wp-content/uploads/2021/02/relatorio_022019.pdf" target="_blank" rel="noopener noreferrer">Relatório 2019 – 2º Semestre</a></p>
          <p><a href="http://www.portal.pge.sp.gov.br/wp-content/uploads/2021/02/relatorio_012019.pdf" target="_blank" rel="noopener noreferrer">Relatório 2019 – 1º Semestre</a></p>
          <p>&nbsp;</p>
          <hr />
        </div>
      </div>
    </div>
  </section>
</main>
