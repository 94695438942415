<main class="main" role="main">
  <app-separador titulo="Credenciamento de Contadores" />
  <section class="secao">
    <div id="is-content" class="row">
      @for (obj of objCredenciamentoContadores(); track obj.id) {
        <div class="news-card" tabindex="0" (click)="credenciamento(obj?.id!)" (keypress)="credenciamento(obj?.id!)">
          @if (obj?.imagem) {
            <div class="news-card-image">
              <img alt="Imagem da Notícia" tabindex="0" [src]="obterImagem(obj?.imagem)" (click)="credenciamento(obj?.id!)" (keypress)="credenciamento(obj?.id!)" />
            </div>
          }
          <div class="news-detail">
            <span class="titulo">{{ obj?.titulo }}</span>
            <span class="date">{{ obj?.dataPublicacao }}</span>
            <span class="description">{{ ajustetexto(obj?.texto!) }}</span>
          </div>
        </div>
      }
    </div>
  </section>
  <div>
    <mat-paginator
      #paginator
      class="custom-paginator"
      aria-label="Select page"
      showFirstLastButtons
      [length]="comprimento()"
      [pageSize]="tamanhoDaPagina()"
      [pageSizeOptions]="opcoesDoTamanhoDaPagina"
      [pageIndex]="numeroPagina()"
      (page)="lidarEventoPagina($event)"
    />
  </div>
</main>
