import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatNativeDateModule, MatOption } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInput, MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import type { PageEvent } from '@angular/material/paginator';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import type { SafeHtml } from '@angular/platform-browser';
import { NgxCurrencyDirective } from 'ngx-currency';
import { NgxMaskDirective } from 'ngx-mask';
import { Subject, finalize, takeUntil } from 'rxjs';
import type { DependenteParetesco } from 'src/app/area-restrita/features/auxilio/saude/shared/models/dependente-parentesco';
import { AuxilioSaudeService } from 'src/app/area-restrita/features/auxilio/saude/shared/services/auxilio-saude.service';
import { WaitLoadingService } from 'src/app/area-restrita/shared/components/wait-loading/wait-loading.service';
import { PaginaVisitadaService } from 'src/app/area-restrita/shared/services/pagina-visitada.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import type { Perfil, StatusAuxilio } from '../shared/models/auxilio-saude';
import { FabricaRelatorioAnalisePedidoAuxilio } from '../shared/models/fabrica-relatorio-analise-pedido-auxilio';
import { FabricaRelatorioInscricao } from '../shared/models/fabrica-relatorio-inscricao';
import type { TipoAuxilioSaude } from '../shared/models/tipo-auxilio-saude';
import { MesaAuxilioSaudeService } from '../shared/services/mesa-auxilio-saude.service';
import { RelatorioAuxilioSaudeService } from '../shared/services/relatorio-auxilio-saude.service';
import { ReportAuxilioSaudeService } from '../shared/services/report-auxilio-saude.service';
import { SolicitacaoAuxilioSaudeService } from '../shared/services/solicitacao-auxilio-saude.service';
import { FabricaRelatorioDependente } from './../shared/models/fabrica-relatorio-dependente';

@Component({
  selector: 'app-relatorio-auxilio-saude',
  templateUrl: './relatorio-auxilio-saude.component.html',
  styleUrl: './relatorio-auxilio-saude.component.scss',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatSelect,
    MatOption,
    MatInput,
    NgxCurrencyDirective,
    NgxMaskDirective,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatDatepicker,
    MatButton,
    MatPaginator,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RelatorioAuxilioSaudeComponent implements OnInit {
  private readonly solicitacaoAuxilioSaudeService = inject(SolicitacaoAuxilioSaudeService);
  private readonly reportAuxilioSaude = inject(ReportAuxilioSaudeService);
  private readonly reportAuxilioSaudeService = inject(AuxilioSaudeService);
  private readonly relatorioAnalisePedidoAuxilio = inject(FabricaRelatorioAnalisePedidoAuxilio);
  private readonly relatorioPedidoAuxilioService = inject(RelatorioAuxilioSaudeService);
  private readonly mesaAuxilioSaudeService = inject(MesaAuxilioSaudeService);
  private readonly relatorioDependente = inject(FabricaRelatorioDependente);
  private readonly relatorioInscricao = inject(FabricaRelatorioInscricao);
  private readonly waitLoadingService = inject(WaitLoadingService);
  private readonly toastr = inject(ToastService);
  private readonly paginaService = inject(PaginaVisitadaService);

  resultadoPesquisa = signal<SafeHtml>('');
  parentesco: DependenteParetesco[] = [];
  gerando = signal(false);

  comprimento = signal(0);
  tamanhoDaPagina = signal(10);
  numeroPagina = signal(0);
  opcoesDoTamanhoDaPagina = [5, 10, 25];
  perfil: Perfil[];

  statusAuxilio = signal<StatusAuxilio[]>([]);
  tiposAuxilioSaude = signal<TipoAuxilioSaude[]>([]);

  relatorioOpcoes = [
    { id: 1, nome: 'Dependentes' },
    { id: 2, nome: 'Inscrição' },
    { id: 3, nome: 'Análise Pedidos' }
    // { id: 4, nome: 'DOF' }
  ];

  tipoBeneficiariosOpcoes = [
    { id: 1, nome: 'Titular' },
    { id: 2, nome: 'Dependente' },
    { id: 3, nome: 'Titular e Dependente' }
  ];

  formulario = new FormGroup({
    status: new FormControl(),
    tipoRelatorio: new FormControl(this.relatorioOpcoes[0].id),
    nomeTitular: new FormControl(),
    cpfTitular: new FormControl(),
    nomeDependente: new FormControl(),
    cpfDependente: new FormControl(),
    parentesco: new FormControl(99),
    exportaPdfCsv: new FormControl(''),
    vencimentoParcela: new FormControl(''),
    valorReembolso: new FormControl(0),
    tipoAuxilioSaude: new FormControl(),
    tipoCargo: new FormControl(),
    dataInscricao: new FormControl(),
    tipoBeneficiario: new FormControl(),
    dataAprovacao: new FormControl()
  });

  private readonly destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.paginaService.salvaPagina('Relatório de Auxílio Saúde').subscribe();
    this.parentesco = this.reportAuxilioSaude.getParentesco();
    this.obterTiposStatus();
    this.obterListaTipoAuxilioSaude();
    this.obterPerfis();
  }

  obterTiposStatus(): void {
    this.mesaAuxilioSaudeService
      .obterListaStatusAuxilio()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dados) => {
          dados.sort((a, b) => a.id - b.id);
          this.statusAuxilio.set(dados);
        },
        error: (error) => {
          console.error('Erro ao obter dados do serviço', error);
        }
      });
  }

  obterPerfis(): void {
    this.waitLoadingService.open();
    this.mesaAuxilioSaudeService
      .obterListaPerfis()
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.waitLoadingService.close();
        })
      )
      .subscribe({
        next: (dados) => {
          this.perfil = dados;
        },
        error: (error) => {
          console.error('Erro ao obter dados do serviço', error);
        }
      });
  }

  limpar(): void {
    this.formulario.patchValue({
      nomeTitular: '',
      cpfTitular: '',
      nomeDependente: '',
      cpfDependente: '',
      exportaPdfCsv: '',
      vencimentoParcela: '',
      valorReembolso: 0,
      tipoAuxilioSaude: '',
      tipoCargo: '',
      dataInscricao: '',
      tipoBeneficiario: '',
      dataAprovacao: ''
    });
    this.comprimento.set(0);
    this.tamanhoDaPagina.set(10);
    this.numeroPagina.set(0);
    this.gerando.set(false);
  }

  executarPesquisa(): void {
    this.numeroPagina.set(0);
    this.comprimento.set(0);
    void this.obterPesquisa();
  }

  async obterPesquisa(): Promise<void> {
    this.gerando.set(false);

    const indice = Number(this.formulario.controls.parentesco.value);
    const parentesco = indice == 99 ? '' : this.parentesco[indice].nome;

    if (this.formulario.controls.tipoRelatorio.value == 1) {
      await this.relatorioDependente.criarRelatorio(
        this.formulario.controls.nomeTitular.value ?? '',
        this.formulario.controls.cpfTitular.value ?? '',
        this.formulario.controls.nomeDependente.value ?? '',
        this.formulario.controls.cpfDependente.value ?? '',

        parentesco,
        this.numeroPagina(),
        this.tamanhoDaPagina()
      );

      this.resultadoPesquisa.set(this.relatorioDependente.getTabela());
      this.comprimento.set(this.relatorioDependente.getTotalElementos());
      this.numeroPagina.set(this.relatorioDependente.getPageNumber());
      this.tamanhoDaPagina.set(this.relatorioDependente.getPageSize());
      this.gerando.set(true);
    } else if (this.formulario.controls.tipoRelatorio.value == 2) {
      await this.relatorioInscricao.criarRelatorio(
        this.formulario.controls.nomeTitular.value ?? '',
        this.formulario.controls.cpfTitular.value ?? '',
        this.formulario.controls.tipoCargo.value ?? '',
        this.formulario.controls.dataInscricao.value ?? '',
        this.numeroPagina(),
        this.tamanhoDaPagina()
      );

      this.resultadoPesquisa.set(this.relatorioInscricao.getTabela());
      this.comprimento.set(this.relatorioInscricao.getTotalElementos());
      this.numeroPagina.set(this.relatorioInscricao.getPageNumber());
      this.tamanhoDaPagina.set(this.relatorioInscricao.getPageSize());
      this.gerando.set(true);
    } else if (this.formulario.controls.tipoRelatorio.value == 3) {
      await this.relatorioAnalisePedidoAuxilio.criarRelatorio(this.formulario, this.numeroPagina(), this.tamanhoDaPagina());

      this.resultadoPesquisa.set(this.relatorioAnalisePedidoAuxilio.getTabela());
      this.comprimento.set(this.relatorioAnalisePedidoAuxilio.getTotalElementos());
      this.numeroPagina.set(this.relatorioAnalisePedidoAuxilio.getPageNumber());
      this.tamanhoDaPagina.set(this.relatorioAnalisePedidoAuxilio.getPageSize());
      this.gerando.set(true);
    }
  }

  obterPdfCsv(tipo: string): void {
    const indice = Number(this.formulario.controls.parentesco.value);
    const parentesco = indice == 99 ? '' : this.parentesco[indice].nome;
    if (this.formulario.controls.tipoRelatorio.value == 1) {
      this.reportAuxilioSaudeService
        .relatorioPesquisaDependentesTipo(
          this.formulario.controls.nomeTitular.value ?? '',
          this.formulario.controls.cpfTitular.value ?? '',
          this.formulario.controls.nomeDependente.value ?? '',
          this.formulario.controls.cpfDependente.value ?? '',
          parentesco,
          tipo.toUpperCase()
        )
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => {
            this.waitLoadingService.close();
          })
        )
        .subscribe({
          next: (response: Blob) => {
            const url = window.URL.createObjectURL(response);
            window.open(url);
          },
          error: (error: Error) => {
            this.toastr.error(error.message);
          }
        });
    } else if (this.formulario.controls.tipoRelatorio.value == 2) {
      this.relatorioPedidoAuxilioService
        .relatorioPesquisaInscricaoTipo(
          this.formulario.controls.nomeTitular.value ?? '',
          this.formulario.controls.cpfTitular.value ?? '',
          this.formulario.controls.tipoCargo.value ?? '',
          this.formulario.controls.dataInscricao.value ?? '',
          tipo.toUpperCase()
        )
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => {
            this.waitLoadingService.close();
          })
        )
        .subscribe({
          next: (response: Blob) => {
            const url = window.URL.createObjectURL(response);
            window.open(url);
          },
          error: (error: Error) => {
            this.toastr.error(error.message);
          }
        });
    } else if (this.formulario.controls.tipoRelatorio.value == 3) {
      this.relatorioPedidoAuxilioService
        .relatorioPesquisaAnalisePedidosAuxilioTipo(
          this.formulario.controls.nomeTitular.value ?? '',
          this.formulario.controls.cpfTitular.value ?? '',
          this.formulario.controls.dataInscricao.value ?? '',
          this.formulario.controls.tipoAuxilioSaude.value ?? '',
          this.formulario.controls.tipoBeneficiario.value ?? '',
          this.formulario.controls.valorReembolso.value ?? '',
          this.formulario.controls.dataAprovacao.value ?? '',
          this.formulario.controls.status.value ?? '',
          tipo.toUpperCase()
        )
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => {
            this.waitLoadingService.close();
          })
        )
        .subscribe({
          next: (response: Blob) => {
            const url = window.URL.createObjectURL(response);
            window.open(url);
          },
          error: (error: Error) => {
            this.toastr.error(error.message);
          }
        });
    }
  }

  lidarEventoPagina(e: PageEvent): void {
    this.comprimento.set(e.length);
    this.tamanhoDaPagina.set(e.pageSize);
    this.numeroPagina.set(e.pageIndex);
    void this.obterPesquisa();
  }

  obterListaTipoAuxilioSaude(): void {
    this.solicitacaoAuxilioSaudeService.obterListaTipoAuxilioSaude().subscribe((response) => {
      this.tiposAuxilioSaude.set(response);
    });
  }
}
