import { Component, inject } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  standalone: true,
  imports: [MatIcon, MatTooltip]
})
export class HeaderComponent {
  private readonly router = inject(Router);

  menuAberto = false;

  constructor() {
    this.tema = localStorage.getItem('tema');
    if (this.tema == 'escuro') document.body.classList.add('dark-theme');
    this.tamanhoFonte = Number(localStorage.getItem('fonte')) || 16;
    this.alterarSalvarFonte();
  }

  tamanhoFonte = 16;
  tema = 'claro';

  paginaInicial() {
    void this.router.navigate(['/']);
  }

  incrementarFonte() {
    if (this.tamanhoFonte >= 30) return;
    this.tamanhoFonte += 1;
    this.alterarSalvarFonte();
  }

  decrementarFonte() {
    if (this.tamanhoFonte <= 10) return;
    this.tamanhoFonte -= 1;
    this.alterarSalvarFonte();
  }

  alterarSalvarFonte() {
    document.getElementsByTagName('html')[0].style.fontSize = this.tamanhoFonte + 'px';
    localStorage.setItem('fonte', this.tamanhoFonte.toString());
  }

  alterarTema() {
    this.tema = this.tema == 'claro' ? 'escuro' : 'claro';
    document.body.classList.toggle('dark-theme');
    localStorage.setItem('tema', this.tema);
  }
}
