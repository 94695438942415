import { Component, inject } from '@angular/core';
import { FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import type { Regional } from 'src/app/shared/models/regional.interface';

import type { PageEvent } from '@angular/material/paginator';
import { MatPaginator } from '@angular/material/paginator';
import { dados } from 'src/app/area-aberta/features/informacao-cidadao/localizar-regionais/dados';
import { MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { MatLabel, MatFormField } from '@angular/material/form-field';
import { SeparadorComponent } from '../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-localizar-regionais',
  templateUrl: './localizar-regionais.component.html',
  styleUrl: './localizar-regionais.component.scss',
  standalone: true,

  imports: [SeparadorComponent, FormsModule, ReactiveFormsModule, MatLabel, MatFormField, MatInput, MatButton, MatPaginator]
})
export class LocalizarRegionaisComponent {
  private readonly formBuilder = inject(FormBuilder);

  regionais: Regional[];
  regional: Regional;
  gridUm: boolean = false;
  gridDois: boolean = false;

  eventoPagina: PageEvent;
  comprimento = -1;
  vazio = false;
  tamanhoDaPagina = 10;
  numeroPagina = 0;
  opcoesDoTamanhoDaPagina = [5, 10, 25];

  paginador = false;
  ocultarTamanhoDaPagina = false;
  mostrarOpcoesDeTamanhoPagina = true;
  mostrarPrimeiroUltimosBotoes = true;

  listaRegionais = [];

  formulario = this.formBuilder.group({
    busca: ['', Validators.required]
  });

  BuscarRegional(): void {
    if (this.formulario.valid) {
      this.gridUm = false;
      this.gridDois = false;
      this.regionais = dados.filter((e) => e.Municipio.toLowerCase().includes(this.formulario.value.busca.toLowerCase()));
      if (this.regionais.length > 0) {
        this.gridUm = true;
        this.comprimento = this.regionais.length;
        this.atualizarPaginacaoRegionais();
      }
    }
  }

  lidarEventoPagina(event: PageEvent): void {
    this.tamanhoDaPagina = event.pageSize;
    this.numeroPagina = event.pageIndex;
    this.atualizarPaginacaoRegionais();
  }

  atualizarPaginacaoRegionais(): void {
    const inicioIndex = this.numeroPagina * this.tamanhoDaPagina;
    const fimIndex = inicioIndex + this.tamanhoDaPagina;
    this.listaRegionais = this.regionais.slice(inicioIndex, fimIndex);
  }

  visualizarGridDois(evento: Regional): void {
    this.regional = evento;
    this.gridUm = false;
    this.gridDois = true;
  }

  limparTudo(): void {
    this.gridUm = false;
    this.gridDois = false;
    this.formulario.reset();
    this.listaRegionais = [];
    this.comprimento = 0;
    this.tamanhoDaPagina = 10;
    this.numeroPagina = 0;
  }
}
