import { CurrencyPipe, DatePipe } from '@angular/common';
import type { AfterViewInit, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, ViewChild, inject, signal } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import type { PageEvent } from '@angular/material/paginator';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { Router, RouterLink } from '@angular/router';
import type { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { NgxFileDropModule } from 'ngx-file-drop';
import { ConfirmaDialogComponent } from 'src/app/area-restrita/shared/components/confirma-dialog/confirma-dialog.component';
import { InformaDialogService } from 'src/app/area-restrita/shared/components/informa-dialog/informa-dialog.service';
import { InformaProcessoDialogComponent } from 'src/app/area-restrita/shared/components/informa-processo-dialog/informa-processo-dialog.component';
import { WaitLoadingService } from 'src/app/area-restrita/shared/components/wait-loading/wait-loading.service';
import { LocalStorageService } from 'src/app/area-restrita/shared/services/local-storage.service';
import { PaginaVisitadaService } from 'src/app/area-restrita/shared/services/pagina-visitada.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import * as XLSX from 'xlsx';
import type { Apesp } from '../shared/models/apesp';
import { AuxilioSaudeService } from '../shared/services/auxilio-saude.service';
import type { AuxilioApesp } from './../shared/models/auxilio-saude';

@Component({
  selector: 'app-apesp-importe',
  templateUrl: './apesp-importe.component.html',
  styleUrl: './apesp-importe.component.scss',
  standalone: true,
  imports: [NgxFileDropModule, MatButton, RouterLink, MatIcon, MatPaginator, CurrencyPipe, DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApespImporteComponent implements AfterViewInit, OnInit {
  localS = inject(LocalStorageService);
  auxilioS = inject(AuxilioSaudeService);
  private readonly matPaginatorIntl = inject(MatPaginatorIntl);
  router = inject(Router);
  private readonly toastr = inject(ToastService);
  private readonly dialog = inject(MatDialog);
  private readonly informaDialog = inject(InformaDialogService);
  private readonly waitLoadingService = inject(WaitLoadingService);
  private readonly paginaService = inject(PaginaVisitadaService);

  listaApesp: Apesp[] = [];
  itensPaginados = signal<Apesp[]>([]);
  mesAnoSolicitacao = this.localS.get('mesAnoSolicitacao');
  envioApesp = signal(false);
  voltarPagina = signal(false);
  public progress: number = 0;
  paginaAtual = signal(0);
  tamanhoPaginador = signal(0);
  itensPorPagina: number = 10;
  indiceInicioPagina: number = 0;
  indiceFimPagina: number = 10;
  public dropZoneLabel = signal('Adicione um arquivo .xlsx');
  listaPedidosApesp: AuxilioApesp[] = [];
  headers: string[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor() {
    this.customizarTextosPaginator();
  }

  ngOnInit(): void {
    this.paginaService.salvaPagina('APESP').subscribe();
  }

  ngAfterViewInit(): void {
    this.paginator.page.subscribe((event: PageEvent) => {
      this.mudarPagina(event.pageIndex, event.pageSize);
    });
  }

  customizarTextosPaginator(): void {
    this.matPaginatorIntl.itemsPerPageLabel = 'Itens por página:';
    this.matPaginatorIntl.nextPageLabel = 'Próxima página';
    this.matPaginatorIntl.previousPageLabel = 'Página anterior';
    this.matPaginatorIntl.firstPageLabel = 'Primeira página';
    this.matPaginatorIntl.lastPageLabel = 'Última página';
    this.matPaginatorIntl.getRangeLabel = (page, pageSize, length): string => {
      if (length === 0 || pageSize === 0) {
        return `0 de ${length}`;
      }
      length = Math.max(length, 0);
      this.indiceInicioPagina = page * pageSize;
      this.indiceFimPagina = this.indiceInicioPagina < length ? Math.min(this.indiceInicioPagina + pageSize, length) : this.indiceInicioPagina + pageSize;
      return `${this.indiceInicioPagina + 1} – ${this.indiceFimPagina} de ${length}`;
    };
    this.matPaginatorIntl.changes.next();
  }

  mudarPagina(pagina: number, tamanho: number): void {
    this.paginaAtual.set(pagina);
    this.itensPorPagina = tamanho;
    const inicio = pagina * tamanho;
    this.itensPaginados.set(this.listaApesp.slice(inicio, inicio + tamanho));
  }

  get totalPaginas(): number {
    return Math.ceil(this.listaApesp.length / this.itensPorPagina);
  }

  public onFileDrop(files: NgxFileDropEntry[]): void {
    this.envioApesp.set(false);
    const droppedFile = files[0];
    if (files.length > 1) {
      this.toastr.warning('Por favor coloque apenas um arquivo.');
      return;
    }
    if (droppedFile.fileEntry.isFile) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {
        if (file.name.endsWith('.xlsx') || file.name.endsWith('.xls')) {
          this.dropZoneLabel.set(file.name);
          void this.handleFile(file);
        } else {
          this.toastr.warning('Por favor selecione um arquivo ".xlsx" ou ".xls".');
        }
      });
    }
  }

  private async handleFile(file: File): Promise<void> {
    let totalRegistros = 0;
    let totalInvalidos = 0;
    const reader: FileReader = new FileReader();
    reader.onload = async (e: ProgressEvent<FileReader>): Promise<void> => {
      this.waitLoadingService.open();
      const bstr = e.target?.result as ArrayBuffer;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      const data: Record<string, string | number | null>[] = XLSX.utils.sheet_to_json(ws);
      this.listaApesp = [];
      this.listaPedidosApesp = [];
      const monthsKey: string[] = [];
      const ddMmYyyy: Date[] = [];
      let cpfKey: string = '';
      let activeKey: string = '';
      const month = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez', 'feb', 'apr', 'may', 'aug', 'sep', 'oct', 'dec'];
      const regexMonth = new RegExp('\\b(' + month.join('|') + ')\\b', 'i');
      const regexCPF = /^\d{2,3}\.\d{3}\.\d{3}-\d{2}$/;

      Object.keys(data[0]).forEach((key) => {
        const cleanedKey = key.trim().toLowerCase();
        this.headers.push(cleanedKey);

        if (regexMonth.test(cleanedKey)) {
          monthsKey.push(key.trim());
          const dataConvertida = this.convertDateAbbreviated(cleanedKey);
          if (dataConvertida) ddMmYyyy.push(dataConvertida);
        }
        if (cpfKey.length <= 0 && cleanedKey.includes('cpf')) {
          cpfKey = cleanedKey;
        }
        if (activeKey.length <= 0 && cleanedKey.includes('at') && cleanedKey.includes('ap')) {
          activeKey = cleanedKey;
        }
      });

      if (!(data.length > 0 && cpfKey && activeKey && monthsKey.length > 0)) {
        console.warn(
          'ATENÇÃO! Não vai haver leitura. ',
          this.headers.join('!'),
          '], [colunas dos meses = ',
          monthsKey.join('|'),
          ' meses = ',
          monthsKey.length,
          '], [cpfKey ',
          cpfKey,
          '], [activeKey = ',
          activeKey,
          ']'
        );
      }
      const linhas = data.length;
      for (let i = 0; i < data.length; i++) {
        // Convertendo CPF para string e validando
        const cpf = String(data[i]['CPF'] ?? '').trim();
        const cpfValido = regexCPF.test(cpf);

        // Convertendo o valor de activeKey para string e verificando se está ativo
        const status = String(data[i]['AT / AP'] ?? '').toLowerCase();
        const ativo = !status.includes('inativo');

        if (cpfValido && ativo && monthsKey.length > 0) {
          for (const monthKey of monthsKey) {
            const valorMes = data[i][monthKey] as number;

            if (!isNaN(valorMes) && valorMes > 0) {
              const dataReembolso = this.extractMonthYear(monthKey);

              this.listaApesp.push({
                id: i,
                nome: String(data[i]['NOME']),
                cpf: data[i]['CPF'] as number,
                atAp: status,
                mesAno: this.convertDateAbbreviated(monthKey) ?? new Date(),
                total: valorMes,
                status: 0,
                ix: totalRegistros
              });

              this.listaPedidosApesp.push({
                nomeTitular: data[i]['NOME'] as string,
                cpfTitular: data[i]['CPF'] as string,
                mes: dataReembolso[0],
                ano: dataReembolso[1],
                valorReembolsoTotal: data[i][monthKey] as number,
                valorDeferidoPge: data[i][monthKey] as number,
                idUsuario: this.localS.get('idUsuario'),
                ix: totalRegistros
              });
              totalRegistros++;
            } else {
              totalInvalidos++;
            }
          }
        } else {
          totalInvalidos++;
        }

        this.progress = Math.round((i / (data.length - 1)) * 100);
      }

      this.orderByListaApesp();
      this.progress = 0;
      this.envioApesp.set(true);
      this.voltarPagina.set(true);
      this.waitLoadingService.close();
      this.paginaAtual.set(0);
      this.tamanhoPaginador.set(this.listaApesp.length);
      this.mudarPagina(0, this.itensPorPagina);
      this.informaDialog.paramTitle = file.name;
      this.informaDialog.paramRegistros = totalRegistros;
      this.informaDialog.paramLinhas = linhas;
      this.informaDialog.paramFalhas = totalInvalidos;
      this.informaDialog.paramParaProcessar = totalRegistros - totalInvalidos;
      this.informaDialog.paramWidth = '25%';
      this.informaDialog.paramHeight = '25%';
      this.informaDialog.show();
    };
    reader.readAsArrayBuffer(file);
  }

  private convertDateAbbreviated(abbreviatedDate: string): Date | null {
    let parts: string[] = [];

    if (abbreviatedDate.includes('/')) {
      parts = abbreviatedDate.split('/');
    } else if (abbreviatedDate.includes('-')) {
      parts = abbreviatedDate.split('-');
    } else {
      return null;
    }

    let yy = parseInt(parts[1]);
    const abbreviatedMm: string = parts[0].toLowerCase();

    if (yy < 100) {
      yy += 2000;
    }
    const mm: string = '|jan|fev|mar|abr|mai|jun|jul|ago|set|out|nov|dez';
    const mmEnglish: string = '|jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec';

    if (mm.includes(abbreviatedMm)) {
      const mesNumber: number = mm.indexOf(abbreviatedMm) / 4;
      const dateCompleted = new Date(yy, mesNumber);
      return dateCompleted;
    } else if (mmEnglish.includes(abbreviatedMm)) {
      const mesNumber: number = mmEnglish.indexOf(abbreviatedMm) / 4;
      const dateCompleted = new Date(yy, mesNumber);
      return dateCompleted;
    } else {
      return null;
    }
  }

  private extractMonthYear(dateStr: string): number[] {
    const months = {
      jan: 1,
      feb: 2,
      fev: 2,
      mar: 3,
      apr: 4,
      abr: 4,
      may: 5,
      mai: 5,
      jun: 6,
      jul: 7,
      aug: 8,
      ago: 8,
      sep: 9,
      set: 9,
      oct: 10,
      out: 10,
      ouc: 10,
      nov: 11,
      dec: 12,
      dez: 12
    };

    const parts = dateStr.split('-');
    if (parts.length !== 2) {
      throw new Error("O formato esperado é 'Mes/Ano'.");
    }

    const monthAbbr = parts[0].toLowerCase();
    const yearStr = parts[1];

    const monthNumber = months[monthAbbr] as number;
    if (!monthNumber) {
      throw new Error(`Abreviação de mes invalida: ${monthAbbr}`);
    }

    const year = yearStr.length === 2 ? parseInt('20' + yearStr, 10) : parseInt(yearStr, 10);
    if (isNaN(year)) {
      throw new Error('Somente é aceito ano com 4 ou 2 digitos.');
    }

    return [monthNumber, year];
  }

  private async delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async envioBotaoApesp(): Promise<void> {
    this.waitLoadingService.open();
    let sucessos = 0;
    let falhas = 0;
    try {
      for (const dado of this.listaPedidosApesp) {
        const resultado = await this.auxilioS.inserirAuxilioApesp(dado);
        const i = this.listaApesp.findIndex((item) => item.ix === dado.ix);
        if (resultado) {
          sucessos++;
          this.listaApesp[i].status = 1;
        } else {
          falhas++;
          this.listaApesp[i].status = 0;
        }
      }
      await this.delay(500);
      this.progress = 0;
      this.envioApesp.set(false);
    } catch (error) {
      this.toastr.error('Erro ao realizar o processamento:' + error);
    } finally {
      this.waitLoadingService.close();

      if (falhas > 0) {
        this.openInforma('Processo de envio finalizado', falhas, sucessos);
      } else {
        this.toastr.success('Processo de leitura da planilha Excel foi finalizado com sucesso!');
        void this.router.navigate(['/area-restrita/administracao-auxilio-saude/analisar-pedido']);
      }
    }
  }

  orderByListaApesp(): void {
    this.listaApesp.sort((a, b) => {
      if (a.cpf < b.cpf) return -2;
      if (a.cpf > b.cpf) return 2;
      if (b.mesAno < a.mesAno) return -1;
      if (b.mesAno > a.mesAno) return 1;
      return 0;
    });
  }

  openDialog(title: string, messsage: string): void {
    const dialogRef = this.dialog.open(ConfirmaDialogComponent, {
      width: '500px',
      data: { title: title, message: messsage }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.toastr.info('Observe a coluna "Processados".');
        this.orderByListaApesp();
      } else {
        void this.router.navigate(['/area-restrita/analise-pedidos-auxilio-saude']);
      }
    });
  }

  openInforma(title: string, falhas: number, sucessos: number): void {
    const dialogRef = this.dialog.open(InformaProcessoDialogComponent, {
      width: '500px',
      data: { title: title, falhas: falhas, sucessos: sucessos }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.toastr.info('Observe a coluna "Processados".');
        this.orderByListaApesp();
      } else {
        void this.router.navigate(['/area-restrita/analise-pedidos-auxilio-saude']);
      }
    });
  }

  openHelp(): void {
    const dialogRef = this.dialog.open(ConfirmaDialogComponent, {
      width: '75%',
      data: {
        title: 'Help',
        message: `A coluna de Processados, serve para te mostrar que a linha foi processada normalmente ou não.
      Processos com sucesso: O Check fica assinalado, quer dizer que o o valor foi deferido.
      Falhas no processo: O Check NÃO fica assinalado, significa que o auxílio não foi encontrado ou já foi deferido. Lembre-se, apenas auxílios aguardando avaliação para deferimento estão disponíveis para deferir.

      Planilha Excel:
      Na leitura de sua planilha. Certique-se que o CPF é válido, pertence a pessoa que receberá deferimento e está no formato correto: 000.000.000-00.
      Que o cabeçalho de cada valor corresponde ao mês e ano corretos.
      E que o valor está no formato correto.

      Deseja fechar essa tela?
      `
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        this.openHelp();
      }
    });
  }
}
