import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { Router } from '@angular/router';
import { CategoriaNoticia } from 'src/app/area-aberta/shared/enums/categoria-noticia';
import type { Noticias } from 'src/app/shared/models/noticia.interface';
import type { Page } from 'src/app/shared/models/paginacao/page.model';
import { NoticiaService } from 'src/app/shared/services/noticia.service';
import { ImagemTratamento } from './../../shared/services/imagem-tratamento';

@Component({
  selector: 'app-noticias-destaque',
  templateUrl: './noticias-destaque.component.html',
  styleUrl: './noticias-destaque.component.scss',
  providers: [ImagemTratamento],
  standalone: true,
  imports: [MatButton],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoticiasDestaqueComponent implements OnInit, OnDestroy {
  private readonly noticiaService = inject(NoticiaService);
  private readonly router = inject(Router);
  private readonly imagemTratamento = inject(ImagemTratamento);

  noticiasDestaque = signal<Noticias[]>([]);

  ngOnInit(): void {
    this.carregarNoticia();
  }

  carregarNoticia(): void {
    this.noticiasDestaque.set([]);
    this.noticiaService.getNoticia(0, 6, CategoriaNoticia.NOTICIA_AREA_ABERTA).subscribe({
      next: (response: Page<Noticias>) => {
        const noticia = response.content;
        noticia.forEach((element) => {
          this.noticiasDestaque.update((noticias) => {
            return [...noticias, element];
          });
        });
      },
      error: console.error
    });
  }

  getImagem(noticiaImagem?: string): string | undefined {
    return this.imagemTratamento.obterImagemBlob(noticiaImagem);
  }

  abrirUmaNoticia(noticiaId: number): void {
    void this.router.navigate(['/uma-noticia', noticiaId]);
  }

  noticiasDiversas(): void {
    void this.router.navigate(['/noticias-diversas']);
  }

  ngOnDestroy(): void {
    this.imagemTratamento.revogarUrlBlob();
  }
}
