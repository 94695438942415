import type { HttpErrorResponse } from '@angular/common/http';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import type { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import type { Page } from 'src/app/shared/models/paginacao/page.model';
import { Globais } from '../../../../../shared/globais/globais';
import { LocalStorageService } from '../../../../../shared/services/local-storage.service';
import type { AuxilioPedidoDto } from '../dto/auxilio-pedido.dto';
import type { AnoReembolso } from '../models/ano-reembolso';
import type { AuxilioSaude, NotaFiscalAnexadaAuxilio } from '../models/auxilio-saude';
import type { HistoricoSolicitacoes } from '../models/historico-solicitacoes';
import type { TipoAuxilio } from '../models/tipo-auxilio';
import type { TipoAuxilioSaude } from '../models/tipo-auxilio-saude';

@Injectable({
  providedIn: 'root'
})
export class SolicitacaoAuxilioSaudeService {
  private readonly http = inject(HttpClient);
  private readonly localStorage = inject(LocalStorageService);

  public obterLista(dadosFormulario: FormGroup | null, pageIndex: string | number, pageSize: string | number): Observable<Page<HistoricoSolicitacoes>> {
    const dataRecebida = (dadosFormulario?.controls['mesAnoSolicitacao'].value as string) || '';

    let parametros = new HttpParams()
      .set('idUsuario', this.localStorage.get('idUsuario'))
      .set('idStatus', dadosFormulario?.controls['status'].value || '')
      .set('idTipoAuxilio', dadosFormulario?.controls['programa'].value || '')
      .set('idTipoAuxilioSaude', dadosFormulario?.controls['tipoAuxilioSaude'].value || '')
      .set('page', pageIndex)
      .set('size', pageSize);
    if (dataRecebida && dataRecebida.trim() !== '') {
      const partesData = dataRecebida.split('-');
      parametros = parametros.set('anoReembolso', partesData[0]);
      parametros = parametros.set('mesParcela', partesData[1]);
    }
    if (dadosFormulario?.controls['totalSolicitado'].value > 0) {
      parametros = parametros.set('totalSolicitado', dadosFormulario?.controls['totalSolicitado'].value);
    }
    if (dadosFormulario?.controls['totalDeferido'].value > 0) {
      parametros = parametros.set('totalDeferido', dadosFormulario?.controls['totalDeferido'].value);
    }

    return this.http.get<Page<HistoricoSolicitacoes>>(`${Globais.urlToken}site-pge/api/auxilios/historico`, { params: parametros }).pipe(
      map((response) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        console.error('Erro durante o envio de dados:', error);
        return throwError(() => 'Falha no envio de dados.');
      })
    );
  }

  public obterListaTipoAuxilio(): Observable<TipoAuxilio[]> {
    return this.http.get<TipoAuxilio[]>(`${Globais.urlToken}site-pge/api/tipos-auxilios`).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Erro durante o envio de dados:', error);
        return throwError(() => 'Falha no envio de dados.');
      })
    );
  }

  public obterListaTipoAuxilioSaude(): Observable<TipoAuxilioSaude[]> {
    return this.http.get<TipoAuxilioSaude[]>(`${Globais.urlToken}site-pge/api/tipos-auxilios-saude`).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Erro durante o envio de dados:', error);
        return throwError(() => 'Falha no envio de dados.');
      })
    );
  }

  public obterListaAnoReembolso(): Observable<AnoReembolso[]> {
    return this.http.get<AnoReembolso[]>(`${Globais.urlToken}site-pge/api/auxilios/ano-referente-reembolso`).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Erro durante o envio de dados:', error);
        return throwError(() => 'Falha no envio de dados.');
      })
    );
  }

  public enviarSolicitacaoAuxilio(dados: AuxilioPedidoDto): Observable<AuxilioSaude> {
    return this.http.post<AuxilioSaude>(`${Globais.urlToken}site-pge/api/auxilios`, dados).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Erro durante o envio de dados:', error);
        return throwError(() => error);
      })
    );
  }

  public enviarRetificacaoSolicitacaoAuxilio(auxilioPedido: AuxilioPedidoDto): Observable<string> {
    return this.http
      .put(`${Globais.urlToken}site-pge/api/auxilios/saude/${auxilioPedido.id}/incluir-retificacao`, auxilioPedido, { responseType: 'text' })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  public enviarArquivoRetificado(dados: NotaFiscalAnexadaAuxilio[]): Observable<string> {
    return this.http.put(`${Globais.urlToken}site-pge/api/notas-fiscais-anexadas/batch`, dados, { responseType: 'text' }).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Erro durante o envio de dados:', error);
        return throwError(() => 'Falha no envio de dados.');
      }),
      finalize(() => {
        console.log('Requisição concluída.');
      })
    );
  }

  public obterTipoAuxilio(): Observable<TipoAuxilio[]> {
    return this.http.get<TipoAuxilio[]>(`${Globais.urlToken}site-pge/api/tipos-auxilios`).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Erro exclusão do arquivo:', error);
        return throwError(() => 'Falha na exclusão do arquivo.');
      })
    );
  }

  public obterSomaValorDeferido(idUsuario: number, mes: number, ano: number): Observable<number> {
    const params = new HttpParams().set('idUsuario', idUsuario.toString()).set('ano', ano.toString()).set('mes', mes.toString());

    return this.http.get<number>(`${Globais.urlToken}site-pge/api/auxilios/soma-valor-deferido`, { params });
  }
}
