import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatButton } from '@angular/material/button';

@Component({
  selector: 'app-voltar-component',
  templateUrl: './voltar-component.component.html',
  styleUrl: './voltar-component.component.scss',
  standalone: true,
  imports: [MatButton],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VoltarComponentComponent {
  voltar(): void {
    window.history.back();
  }
}
