<div class="top-bar">
  <div class="div-menu-button">
    @if (menuAberto()) {
      @if (!botaoFechar()) {
        <button class="button-menu" mat-icon-button type="submit" (click)="alternarMenu(false, true)">
          <mat-icon aria-hidden="false">menu_open</mat-icon>
        </button>
      }
      @if (botaoFechar()) {
        <button class="button-menu" mat-icon-button type="submit" (click)="alternarMenu(true, true)">
          <mat-icon aria-hidden="false">close</mat-icon>
        </button>
      }
    }
    @if (!menuAberto()) {
      <button class="button-menu" mat-icon-button type="submit" (click)="alternarMenu(false, true)">
        <mat-icon aria-hidden="false">menu</mat-icon>
      </button>
    }
  </div>
  <div class="usuario-area">
    <mat-icon aria-hidden="false" fontIcon="notifications" />
    <mat-icon aria-hidden="false" fontIcon="account_circle" />
    <div class="usuario-info" tabindex="0" (click)="alternarMenuUsuario()" (keypress)="alternarMenuUsuario()">
      <span>Usuário: {{ obterPrimeiroESegundoNome() }}</span>
      <span
        >Matrícula: {{ this.idUsuario }}<span class="data">{{ dataAtual() }}</span></span
      >
    </div>
    <mat-icon #menuIcon aria-hidden="false" fontIcon="expand_more" (click)="alternarMenuUsuario()" />
  </div>
</div>
<mat-drawer-container class="background-container router-area-restrita" autosize>
  <mat-drawer #drawer class="menu-area-restrita" mode="side" [opened]="true" [ngClass]="menuAberto() || estaMostrando() ? 'mat-drawer-open' : 'mat-drawer-close'">
    <div class="side-drawer" [class.side-drawer-reduzido]="!menuAberto() && !estaMostrando()">
      <img width="150px" src="images/brasao.png" alt="Brasão PGE" [class.brasao-reduzido]="!menuAberto() && !estaMostrando()" />
      @if (menuAberto() || estaMostrando()) {
        <p class="title-sp">
          Procuradoria Geral do <br />
          Estado de São Paulo
        </p>
      }
      <div class="contaier-overflow div-menu">
        <mat-nav-list [class.mat-nav-list-reduzido]="!menuAberto() && !estaMostrando()">
          @for (item of dataSource(); track $index; let i = $index) {
            <div>
              <app-menu-item [item]="item" [menuAberto]="menuAberto()" [estaMostrando]="estaMostrando()" />
            </div>
          }
        </mat-nav-list>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content [class.collapsed-content]="!menuAberto() && !estaMostrando()">
    <div class="contaier-overflow">
      <router-outlet />
    </div>
  </mat-drawer-content>
</mat-drawer-container>
@if (menuAtivo()) {
  <div class="opcao-usuario" (mouseleave)="fecharMenu()" (mouseenter)="manterMenu()">
    <div>Meu Cadastro</div>
    <div tabindex="0" (click)="alterarSenha()" (keypress)="alterarSenha()">Alterar senha</div>
    <div tabindex="0" (click)="sairUsuario()" (keypress)="sairUsuario()">Sair</div>
  </div>
}
