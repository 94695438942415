import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import type { Toast } from './toast.model';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrl: './toast.component.scss',
  standalone: true,
  imports: [MatIcon],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastComponent {
  data = inject<Toast>(MAT_SNACK_BAR_DATA);

  snackBarRef = inject(MatSnackBarRef);

  statusIcon = {
    success: 'done',
    warning: 'warning',
    error: 'cancel',
    info: 'info'
  };
}
