import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { SeparadorComponent } from '../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-procuradorias-regionais',
  templateUrl: './procuradorias-regionais.component.html',
  standalone: true,

  imports: [SeparadorComponent, RouterLink],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProcuradoriasRegionaisComponent {}
