import { Component } from '@angular/core';
import { SeparadorComponent } from '../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-reparacao-danos',
  templateUrl: './reparacao-danos.component.html',
  styleUrl: './reparacao-danos.component.sass',
  standalone: true,

  imports: [SeparadorComponent]
})
export class ReparacaoDanosComponent {}
