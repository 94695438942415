import { Component } from '@angular/core';
import { MatCard } from '@angular/material/card';
import { AcessoRapido } from './acesso-rapido';
import type { ItemAcessoRapido } from './link-acesso-rapido.model';

@Component({
  selector: 'app-acesso-rapido',
  templateUrl: './acesso-rapido.component.html',
  styleUrl: './acesso-rapido.component.scss',
  standalone: true,
  imports: [MatCard]
})
export class AcessoRapidoComponent {
  readonly items: ItemAcessoRapido[] = AcessoRapido;
}
