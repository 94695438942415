<main class="main" role="main">
  <section class="page">
    <div class="container">
      <app-separador titulo="Procuradoria Regional de São Carlos (PR12)" />
      <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
          <br />
          <h3>PR12 CHEFIA GABINETE</h3>
          <div class="pge-unidade">
            <p class="procurador"><strong>Procurador(a)</strong>: {{ encontrarNome("REGINA MARTA CEREDA LIMA LOUZADA") }}</p>
            <p class="endereco"><strong>Endereço</strong>: Rua Conde do Pinhal, nº 2041, , Centro, São Carlos-SP, 13560-648.</p>
            <p class="tel"><strong>Telefone</strong>: (16) 3371-9268 | 3372-2206 | 3364-5291 | 3372-2879</p>
            <p class="email"><strong>E-mail</strong>: pr12atendimento&#64;sp.gov.br</p>
            <div class="descricao">
              <strong>Municípios abrangidos:</strong> Américo Brasiliense, Analândia, Araraquara, Boa Esperança do Sul, Borborema, Brotas, Cachoeira das Emas, Cândido Rodrigues, Descalvado, Dobrada,
              Dourado, Fernando Prestes, Gavião Peixoto, Iacanga, Ibaté, Ibitinga, Itápolis, Itirapina, Matão, Motuca, Nova Europa, Pirassununga, Porto Ferreira, Ribeirão Bonito, Rincão, Santa Cruz da
              Conceição, Santa Ernestina, Santa Lúcia, Santa Rita do Passa Quatro, São Carlos, São Sebastião do Patrimônio, Tabatinga, Taquaritinga, Torrinha e Trabijú.
            </div>
          </div>
          <br />
          <hr />
          <br />
          <h3>SECCIONAL DE ARARAQUARA</h3>
          <div class="pge-unidade">
            <p class="procurador"><strong>Procurador(a)</strong>: ALEXANDRE FERRARI VIDOTTI</p>
            <p class="endereco"><strong>Endereço</strong>: Av. Espanha, 118, 2º andar, Centro, Araraquara-SP, 14801-130.</p>
            <p class="tel"><strong>Telefone</strong>: (16) 3322-1040/8510/7080</p>
            <p class="email"><strong>E-mail</strong>: pr12atendimento&#64;sp.gov.br</p>
          </div>
          <br />
          <hr />
          <br />
          <app-voltar-component />
          <br />
        </div>
      </div>
    </div>
  </section>
</main>
