<div class="conteudo">
  <br />
  <h1 class="card-title titulo">Inclusão de baixa de Pedidos do SIAFEM - Financeiro</h1>
  <br />
  <mat-grid-list cols="3" rowHeight="150px">
    <mat-grid-tile colspan="2">
      <ngx-file-drop
        browseBtnLabel="Browse files"
        browseBtnClassName="send-files btn btn-secondary m-2 gray-file-drop"
        [dropZoneLabel]="dropZoneLabel()"
        [showBrowseBtn]="true"
        (onFileDrop)="onFileDrop($event)"
      />
    </mat-grid-tile>
    <mat-grid-tile>
      @if (envioSiafem()) {
        <button mat-button class="envio-button" type="submit" (click)="envioBotaoSiafem()">Enviar dados</button>
      }
    </mat-grid-tile>
  </mat-grid-list>

  <table>
    <thead>
      <tr>
        <th>Nome</th>
        <th>CPF</th>
        <th>Data</th>
        <th>Total</th>
      </tr>
    </thead>
    <tbody>
      @for (a of itensPaginados(); track a.id) {
        <tr>
          <td>{{ a.nome }}</td>
          <td class="coluna-cpf-mes-ano">{{ a.cpf }}</td>
          <td class="coluna-cpf-mes-ano">{{ a.mes_ano }}</td>
          <td class="coluna-total">{{ a.total | currency }}</td>
        </tr>
      }
    </tbody>
  </table>
  <mat-paginator [length]="listaSiafem.length" [pageSize]="itensPorPagina()" [pageSizeOptions]="[5, 10, 20]" (page)="mudarPagina($event.pageIndex, $event.pageSize)" />
</div>
