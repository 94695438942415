import type { Sancao } from './consulta-sancoes.component';

export const SANCOES_MOCK: Sancao[] = [
  {
    cnpjCpf: '27.675.373/0001-19',
    nome: 'L.A.S. SERVIÇOS DE LIMPEZA E MANUTENÇÃO EIRELLI',
    cadastro: '',
    uf: 'SP',
    orgao: 'Procuradoria Geral do Estado de SP',
    categoria: 'I - multa; II - publicação extraordinária da decisão condenatória;',
    dataPublicacao: new Date('2024-04-23'),
    valorMulta: 13723.78,
    quantidade: 1
  },
  {
    cnpjCpf: '10.394.719/0001-08',
    nome: 'AT & SANTOS CONSULTORIA E SERVIÇOS EIRELLI',
    cadastro: '',
    uf: 'SP',
    orgao: 'Procuradoria Geral do Estado de SP',
    categoria: 'I - multa; II - publicação extraordinária da decisão condenatória;',
    dataPublicacao: new Date('2024-04-23'),
    valorMulta: 398042.08,
    quantidade: 1
  }
];
