<main class="main" role="main">
  <section class="page">
    <div class="align-button">
      <a tabindex="0" mat-button class="btn p-2 m-2 btn-primary custom-width" (click)="retornaListaComunicados()" (keypress)="retornaListaComunicados()"> Voltar para a lista de Comunicados</a>
    </div>
    <div class="only-one">
      <div class="uma-noticia">
        <h1 class="title-noticia">{{ noticia()?.titulo }}</h1>
        <p class="data" [innerHTML]="editarData(noticia()?.dataPublicacao)"></p>
        <div class="corpo-noticia" [innerHTML]="processarTexto(noticia()?.texto)"></div>
        <div class="arquivo">
          <table>
            <tbody>
              @for (item of arquivos(); track item.id; let i = $index) {
                <tr>
                  <td>
                    <a href="#" (click)="abrirArquivo($event, item)"><mat-icon fontIcon="cloud_download" />{{ item.nome }}</a>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
      <div class="mais-noticias">
        <app-separador titulo="Outras Notícias" />
        <div class="noticias-cards">
          <div class="noticia-card">
            @for (item of objNoticias(); track item.id; let i = $index) {
              <mat-card class="card">
                <a tabindex="0" target="_blank" (click)="abrirUmaNoticia(item?.id!)" (keypress)="abrirUmaNoticia(item?.id!)">
                  <img [src]="obterImagem(item?.imagem)" [alt]="'Imagem ' + (i + 1)" />
                </a>
                <mat-card-content>
                  <p class="titulo" tabindex="0" (click)="abrirUmaNoticia(item?.id!)" (keypress)="abrirUmaNoticia(item?.id!)">
                    {{ item?.titulo }}
                  </p>
                </mat-card-content>
              </mat-card>
            }
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
