<main class="main" role="main">
  <section class="page">
    <app-separador titulo="Legislação" />
    <div class="row">
      <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
        <p><a href="pdf/lei_organica_pge_revista_e_atualizao_lc_1082_08_1.pdf" target="_blank">Lei Orgânica PGE revista e atualização – LC 1082-08</a></p>
        <p><a href="pdf/legislacao_relativa_ao_conselho_atualizada_em_junho_de_2021.pdf" target="_blank">Legislação do Conselho</a></p>
        <br />
      </div>
    </div>
  </section>
</main>
