<main class="main" role="main">
  <section class="page">
    <app-separador titulo="Procuradorias Especializadas" />
    <div class="row">
      <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
        <h3>Procuradoria da Dívida Ativa</h3>
        <p class="procurador"><strong>Procurador(a) Chefe</strong>:<br /></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("ELAINE VIEIRA DA MOTTA") }}</p>
        </div>
        <p class="endereco"><strong>Endereço</strong>: Rua Pamplona, 227, 6º andar, Jardim Paulista, São Paulo-SP, 01415-902.</p>
        <p class="tel"><strong>Telefone</strong>: (11) 3372-6438</p>
        <p><strong>Demais Procuradores</strong></p>
        <!-- Essa lista está mocada -->
        <p>ALVARO FEITOSA DA SILVA FILHO</p>
        <p>ANA CRISTINA VENOSA DE OLIVEIRA LIMA</p>
        <p>CAMILA ROCHA SCHWENCK</p>
        <p>FERNANDA BARDICHIA PILAT YAMAMOTO</p>
        <p>FILIPE GADELHA DIOGENES FORTES</p>
        <p>RENATO PEIXOTO PIEDADE BICUDO</p>
        <p>SIBELE FERRIGNO POLI IDE ALVES</p>
        <hr />
        <h3>Procuradoria Fiscal (PF)</h3>
        <p class="procurador"><strong>Procurador(a) Chefe</strong>:<br /></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("REBECCA CORREA PORTO DE FREITAS") }}</p>
        </div>
        <p class="endereco"><strong>Endereço</strong>: Avenida Rangel Pestana, 300, 16º andar, Centro, São Paulo-SP, 01017-911.</p>
        <p class="tel"><strong>Telefone</strong>: (11) 3241-4790 | (11) 3243-3541 | (11) 3105-9098</p>
        <p class="email"><strong>E-mail</strong>: pfatendimento&#64;sp.gov.br</p>
        <p><strong>Assistentes</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("FABIO AUGUSTO DAHER MONTES") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MICHELLE MANAIA SANJAR") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("SUMAYA RAPHAEL MUCKDOSSE") }}</p>
        </div>
        <p>&nbsp;</p>
        <p><strong>Chefes de subprocuradorias</strong></p>
        <p><strong>1ª Subprocuradoria</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("RICARDO RODRIGUES FERREIRA") }}</p>
        </div>
        <p><strong>1A.SECCIONAL(PF-11)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("CINTIA WATANABE") }}</p>
        </div>
        <p><strong>2A.SECCIONAL(PF-12)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>3A.SECCIONAL(PF-13)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>4A.SECCIONAL(PF-14)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>&nbsp;</strong><strong>5A.SECCIONAL(PF-15)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("VALERIA LUCHIARI MAGALHAES") }}</p>
        </div>
        <p><strong>6A.SECCIONAL(PF-16)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>2ª Subprocuradoria (PF-2)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>1A.SECCIONAL(PF-21)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>1A.SECCIONAL(PF-22)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("HELOISE WITTMANN MAIA") }}</p>
        </div>
        <p><strong>3ª Subprocuradoria (PF-3)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>1A.SECCIONAL(PF-31)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>1A.SECCIONAL(PF-32)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>4ª Subprocuradoria (PF-4)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("FERNANDA BITTENCOURT PORCHAT GODOY") }}</p>
        </div>
        <p><strong>5ª Subprocuradoria (PF-5)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>1A.SECCIONAL(PF-51)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("VALERIA LUCHIARI MAGALHAES") }}</p>
        </div>
        <p><strong>2A.SECCIONAL(PF-52)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("RAPHAEL FRANCO DEL DUCA") }}</p>
        </div>
        <p><strong>3A.SECCIONAL(PF-53)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("SERGIO MAIA") }}</p>
        </div>
        <p><strong>4A.SECCIONAL(PF-54)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("KELLY PAULINO VENANCIO") }}</p>
        </div>
        <p><strong>5A.SECCIONAL(PF-55)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("MARIA IZABEL ALVES DE ANDRE") }}</p>
        </div>
        <p><strong>6A.SECCIONAL(PF-56)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>7A.SECCIONAL(PF-57)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("DANIELE CRISTINA MORALES") }}</p>
        </div>
        <p><strong>8A.SECCIONAL(PF-58)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>9A.SECCIONAL(PF-59)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>6ª Subprocuradoria (PF-6)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>1A.SECCIONAL(PF-61)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>1A.SECCIONAL(PF-62)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>7ª Subprocuradoria (PF-7)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>1A.SECCIONAL(PF-71)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>2A.SECCIONAL(PF-72)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>3A.SECCIONAL(PF-73)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>4A.SECCIONAL(PF-74)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>5A.SECCIONAL(PF-75)</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>6A.SECCIONAL</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>7A.SECCIONAL</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>8A.SECCIONAL</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>9A.SECCIONAL</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p><strong>13A.SECCIONAL</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p>&nbsp;</p>
        <p><strong>Procuradores lotados na unidade e subprocuradorias</strong></p>
        <div class="pge-unidade"></div>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("ADRIANO VIDIGAL MARTINS") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("ALEXANDRE DOTOLI NETO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("ALEXANDRE FERNANDES MACHADO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("AMARILIS INOCENTE BOCAFOLI") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("ANNA PAULA SENA DE GOBBI") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("AUGUSTO RODRIGUES PORCIUNCULA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("BERNARDO SANTOS SILVA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("CARLOS OGAWA COLONTONIO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("CONRADO LUIZ RIBEIRO SILVA BARROS") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("DANIEL AREVALO NUNES DA CUNHA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("DANIELA SPIGOLON LOUREIRO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("DENISE FERREIRA DE OLIVEIRA CHEID") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("FABIOLA TEIXEIRA SALZANO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("GUSTAVO CAMPOS ABREU") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("HEYDE MEDEIROS COSTA LIMA ROCHA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("JULIANA DE OLIVEIRA COSTA GOMES SATO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("LAURO TERCIO BEZERRA CAMARA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("LUCAS CAPARELLI GUIMARAES PINTO CORREIA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MARA REGINA CASTILHO REINAUER ONG") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MARCELA NOLASCO FERREIRA JORGE") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MARCELO ROBERTO BOROWSKI") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MARCIO FERNANDO FONTANA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MARCIO YUKIO SANTANA KAZIURA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MARINA MENEZES LEITE PRACA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MARISA MIDORI ISHII") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("NATALIA MUSA DOMINGUEZ NUNES") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("RAFAEL BARROSO DE ANDRADE") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("RENATO PEIXOTO PIEDADE BICUDO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("TALITA LEIXAS RANGEL") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("TATIANA FREIRE PINTO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("VINICIUS JOSE ALVES AVANZA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("VITOR MAURICIO BRAZ DI MASI") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("BRENO AUGUSTO MACIEL RIBEIRO DE LIMA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("DANIEL CASTILLO REIGADA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("PEDRO HENRIQUE LACERDA BARBOSA LADEIA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("VANESSA MOTTA TARABAY") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("FREDERICO BENDZIUS") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("LIETE BADARO ACCIOLI PICCAZIO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("PAULO GONCALVES DA COSTA JUNIOR") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("ANA MARIA DE SANT ANA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("REGINA PAULA RIBEIRO DE CARVALHO CASERTA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("ALYNE BASILIO DE ASSIS") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("AYLTON MARCELO BARBOSA DA SILVA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("CARINE SOARES FERRAZ") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("CARLA HANDEL MISTRORIGO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("CARLOS ALBERTO BITTAR FILHO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("CARLOS EDUARDO FERNANDES DA SILVEIRA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("CLAUDIA CARDOSO CHAHOUD") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("FELIPE ABRAHAO VEIGA JABUR") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("GABRIEL TEIXEIRA DE OLIVEIRA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("IGOR DENISARD DANTAS MELO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("JORGE MIGUEL FILHO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("LUAN BRANCHER GUSSO MACHADO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("LUCIANA GIACOMINI OCCHIUTO NUNES") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("LUCIANA PACHECO BASTOS DOS SANTOS") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MARCELO DE CARVALHO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MARCIA REGINA BONAVINA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MILTON DEL TRONO GROSCHE") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MONICA MARIA PETRI FARSKY") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MONICA TONETTO FERNANDEZ") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("PAULO ALVES NETTO DE ARAUJO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("RAFAEL DE OLIVEIRA RODRIGUES") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("ROMANOVA ABUD CHINAGLIA PAULA LIMA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("ROSE ANNE TANAKA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("ANA MARIA DE SANT ANA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("REGINA PAULA RIBEIRO DE CARVALHO CASERTA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("ADRIANO VIDIGAL MARTINS") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("ALEXANDRE DOTOLI NETO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("ALEXANDRE FERNANDES MACHADO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("AMARILIS INOCENTE BOCAFOLI") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("ANNA PAULA SENA DE GOBBI") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("AUGUSTO RODRIGUES PORCIUNCULA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("BERNARDO SANTOS SILVA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("CARLOS OGAWA COLONTONIO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("CONRADO LUIZ RIBEIRO SILVA BARROS") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("DANIEL AREVALO NUNES DA CUNHA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("DANIELA SPIGOLON LOUREIRO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("DENISE FERREIRA DE OLIVEIRA CHEID") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("FABIOLA TEIXEIRA SALZANO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("GUSTAVO CAMPOS ABREU") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("HEYDE MEDEIROS COSTA LIMA ROCHA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("JULIANA DE OLIVEIRA COSTA GOMES SATO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("LAURO TERCIO BEZERRA CAMARA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("LUCAS CAPARELLI GUIMARAES PINTO CORREIA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MARA REGINA CASTILHO REINAUER ONG") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MARCELA NOLASCO FERREIRA JORGE") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MARCELO ROBERTO BOROWSKI") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MARCIO FERNANDO FONTANA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MARCIO YUKIO SANTANA KAZIURA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MARINA MENEZES LEITE PRACA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MARISA MIDORI ISHII") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("NATALIA MUSA DOMINGUEZ NUNES") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("RAFAEL BARROSO DE ANDRADE") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("RENATO PEIXOTO PIEDADE BICUDO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("TALITA LEIXAS RANGEL") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("TATIANA FREIRE PINTO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("VINICIUS JOSE ALVES AVANZA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("VITOR MAURICIO BRAZ DI MASI") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("BRENO AUGUSTO MACIEL RIBEIRO DE LIMA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("DANIEL CASTILLO REIGADA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("PEDRO HENRIQUE LACERDA BARBOSA LADEIA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("VANESSA MOTTA TARABAY") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("FREDERICO BENDZIUS") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("LIETE BADARO ACCIOLI PICCAZIO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("PAULO GONCALVES DA COSTA JUNIOR") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("ELISA VIEIRA LOPEZ") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("LEONARDO GONCALVES RUFFO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("LEYDSLAYNE ISRAEL LACERDA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MAIRA GABRIELA AVELAR VIEIRA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("ARTUR BARBOSA DA SILVEIRA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("LIGIA PEREIRA BRAGA VIEIRA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("CARMEN SABRINA COCHRANE SANTIAGO VIANA") }}</p>
        </div>
      </div>
    </div>
  </section>
</main>
