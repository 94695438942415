<main class="main" role="main">
  <section class="page">
    <div class="container">
      <app-separador titulo="Procuradoria Regional de Ribeirão Preto (PR06)" />
      <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
          <br />
          <h3>PR06 CHEFIA GABINETE</h3>
          <div class="pge-unidade">
            <p class="procurador"><strong>Procurador(a)</strong>: {{ encontrarNome("LUCIANO ALVES ROSSATO") }}</p>
            <p class="endereco"><strong>Endereço</strong>: Avenida Presidente Kennedy, 1.550, , Ribeirânia, Ribeirão Preto-SP, 14096-340.</p>
            <p class="tel"><strong>Telefone</strong>: (016) 3610-1889/ 1583 e (016) 3636-6074</p>
            <p class="email"><strong>E-mail</strong>: pr6atendimento&#64;sp.gov.br</p>
            <div class="descricao">
              <strong>Municípios abrangidos:</strong> Altinópolis, Aramina, Barretos, Barrinha, Batatais, Bebedouro, Brodósqui, Buritizal, Cajuru, Cássia dos Coqueiros, Colina, Colômbia, Cravinhos,
              Cristais Paulista, Dumont, Franca, Guaira, Guará, Guariba, Guatapará, Ibitiúva, Igarapava, Ipuã, Itirapuã, Ituverava, Jaborandi, Jaboticabal, Jardinópolis, Jeriquara, Luiz Antônio,
              Miguelópolis, Monte Alto, Monte Azul Paulista, Morro Agudo, Nuporanga, Orlândia, Patrocínio Paulista, Pedregulho, Pirangi, Pitangueira Pontal, Pradópolis, Restinga, Ribeirão Corrente,
              Ribeirão Preto, Rifaina, Sales de Oliveira, Santa Rosa do Viterbo, Santo Antônio da Alegria, São Joaquim da Barra, São José da Bela Vista, São Simão, Serra Azul, Serrana, Sertãozinho,
              Taiacu, Taiuva, Taquaral, Terra Roxa, Viradouro, Vista Alegre do Alto.
            </div>
          </div>
          <br />
          <hr />
          <br />
          <h3>SECCIONAL DE FRANCA</h3>
          <div class="pge-unidade">
            <p class="procurador"><strong>Procurador(a)</strong>: THIAGO PUCCI BEGO</p>
            <p class="endereco"><strong>Endereço</strong>: R. Prof. Carmelino Correa Jr., 303, , Bairro São José, Franca-SP, 14401-292.</p>
            <p class="tel"><strong>Telefone</strong>: (16) 3721-0552</p>
          </div>
          <br />
          <hr />
          <br />
          <app-voltar-component />
          <br />
        </div>
      </div>
    </div>
  </section>
</main>
