import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Location } from '@angular/common';
import type { HttpErrorResponse } from '@angular/common/http';
import type { ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, signal, ViewChild } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatChipGrid, MatChipInput, MatChipRemove, MatChipRow } from '@angular/material/chips';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import type { NgxFileDropEntry } from 'ngx-file-drop';
import { NgxFileDropModule } from 'ngx-file-drop';
import { EditorModule } from 'primeng/editor';
import { Subject, takeUntil } from 'rxjs';
import type { AcessoNoticia, Noticias, PdfAnexo } from 'src/app/shared/models/noticia.interface';
import { NoticiaService } from 'src/app/shared/services/noticia.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import type { NoticiaForm } from '../shared/models/noticia-form';

@Component({
  selector: 'app-editar-noticia',
  templateUrl: './editar-noticia.component.html',
  styleUrl: './editar-noticia.component.scss',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatSelect,
    MatOption,
    MatRadioGroup,
    MatRadioButton,
    EditorModule,
    NgxFileDropModule,
    MatIcon,
    MatLabel,
    MatChipInput,
    MatChipGrid,
    MatChipRow,
    MatChipRemove,
    MatButton
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditarNoticiaComponent implements OnInit, OnDestroy {
  private readonly formBuilder = inject(FormBuilder);
  private readonly toastr = inject(ToastService);
  private readonly noticiaService = inject(NoticiaService);
  private readonly router = inject(Router);
  private readonly route = inject(ActivatedRoute);
  private readonly location = inject(Location);
  private readonly cdr = inject(ChangeDetectorRef);

  private readonly destroy$ = new Subject<void>();
  maxCaracteres: number = 50;
  idNoticia: string;
  noticia = signal<Noticias | null>(null);
  campoCategoriaAbertaRestrita = signal(false);
  campoAcesso: boolean = false;
  campoDestaque: boolean = false;
  pdf = signal<PdfAnexo[]>([]);
  listaImagens: PdfAnexo[] = [];
  listaTotalArquivos: PdfAnexo[] = [];

  readonly palavrasChaves = signal<string[]>([]);
  readonly anunciador = inject(LiveAnnouncer);
  limiteMaximoPlavrasChaves: number = 10;
  limiteCaracteres: number = 30;
  @ViewChild('palavraChaveInput') palavraChaveInput: ElementRef<HTMLInputElement>;

  objetoDestaque = [
    { value: 0, nome: 'Nenhuma' },
    { value: 1, nome: '1' },
    { value: 2, nome: '2' },
    { value: 3, nome: '3' },
    { value: 4, nome: '4' },
    { value: 5, nome: '5' }
  ];

  ngOnInit(): void {
    this.idNoticia = this.route.snapshot.params['id'] as string;
    this.buscarDados(this.idNoticia);
  }

  formulario = this.formBuilder.group({
    titulo: ['', Validators.required],
    dataPublicacao: ['', Validators.required],
    texto: ['', Validators.required],
    imagem: [''],
    nomeArquivo: [''],
    categoria: [0, Validators.required],
    acesso: this.formBuilder.nonNullable.control<number | null | AcessoNoticia>(null),
    usuario: { id: Number(localStorage.getItem('idUsuario')) },
    destaque: [0],
    ativo: [true],
    palavraChave: ['']
  });

  buscarDados(id: string): void {
    this.noticiaService.getOneNoticiaAtivoTrueOrFalse(Number(id)).subscribe({
      next: (response) => {
        if (response) {
          this.noticia.set(response);
          this.obterArquivos(Number(id));
          this.formulario.patchValue({
            titulo: response.titulo,
            dataPublicacao: this.formatarDataParaCampo(response.dataPublicacao),
            texto: response.texto,
            imagem: response.imagem,
            nomeArquivo: response.nomeArquivo,
            categoria: response.categoria.id,
            acesso: response.acesso ? response.acesso.id : null,
            destaque: response.destaque,
            ativo: response.ativo
          });
          if (response.palavraChave) {
            const vetorDeString = response.palavraChave.split(' ');
            vetorDeString.forEach((string) => {
              this.adicionarPalavra(string);
            });
          }
          this.alternarAcessoRegistro();
        }
      },
      error: (error) => {
        console.log(error);
        this.toastr.error('Erro ao obter notícia.');
      }
    });
  }

  obterArquivos(idNoticia: number): void {
    this.noticiaService.obterArquivosNoticias(idNoticia).subscribe((response: PdfAnexo[] | null) => {
      if (response) {
        response.forEach((arquivo) => {
          if (arquivo.imagem) {
            this.listaImagens.push({
              id: 0,
              noticia: { id: idNoticia },
              nome: arquivo.nome,
              arquivo: arquivo.arquivo,
              imagem: arquivo.imagem
            } as PdfAnexo);
          } else {
            this.pdf.update((pdfs) => {
              pdfs.push({
                id: 0,
                noticia: { id: idNoticia },
                nome: arquivo.nome,
                arquivo: arquivo.arquivo,
                imagem: arquivo.imagem
              } as PdfAnexo);
              return pdfs;
            });
          }

          this.cdr.markForCheck();
        });
      }
    });
  }

  public formatarDataParaCampo(data: string): string {
    const ano = data.substring(6, 10);
    const mes = data.substring(3, 5);
    const dia = data.substring(0, 2);
    const horaMinutoSegundo = data.substring(11, 16);
    return `${ano}-${mes}-${dia}T${horaMinutoSegundo}`;
  }

  alternarAcessoRegistro(): void {
    const categoria = Number(this.formulario.controls.categoria.value);
    if (categoria === 2) {
      this.campoAcesso = true;
      this.campoCategoriaAbertaRestrita.set(true);
    } else if (categoria === 1) {
      this.campoCategoriaAbertaRestrita.set(true);
      this.campoDestaque = true;
    }
  }

  imagemSelecionada(evento: NgxFileDropEntry[]): void {
    const primeiroArquivo = evento[0];

    if (primeiroArquivo.fileEntry.isFile) {
      const entradaArquivo = primeiroArquivo.fileEntry as FileSystemFileEntry;

      entradaArquivo.file((arquivo: File) => {
        if (arquivo.name.length > this.maxCaracteres) {
          this.toastr.error(`O nome do arquivo excede o limite permitido de ${this.maxCaracteres} caracteres.`);
          return;
        }
        if (!this.tamanhoMaximoArquivo(arquivo.size)) {
          this.toastr.error('O arquivo excede o tamanho máximo permitido de 25MB.');
          return;
        }
        if (!arquivo.type.startsWith('image/')) {
          this.toastr.error('Apenas imagem é aceita.');
          return;
        }
        this.formulario.controls.nomeArquivo.setValue(arquivo.name);
        const leitorArquivo = new FileReader();
        leitorArquivo.onload = (event): void => {
          this.formulario.controls.imagem.setValue((event.target?.result as string).split(',')[1]);
        };
        this.cdr.detectChanges();
        leitorArquivo.readAsDataURL(arquivo);
      });
    }
  }

  imagemsoltoClicado(): void {
    const elementoEntrada = document.createElement('input');
    elementoEntrada.type = 'file';
    elementoEntrada.multiple = false;
    elementoEntrada.accept = 'image/*';

    elementoEntrada.onchange = (event: Event): void => {
      const alvo = event.target as HTMLInputElement;
      if (alvo.files) {
        const listaArquivo: FileList = alvo.files;
        const entradasDeArquivos: NgxFileDropEntry[] = [];
        Array.from(listaArquivo).forEach((arquivo) => {
          const fileEntry: FileSystemFileEntry = {
            isDirectory: false,
            isFile: true,
            file: (successCallback: (file: File) => void) => {
              successCallback(arquivo);
            },
            name: arquivo.name,
            fullPath: arquivo.name,
            filesystem: undefined as unknown as FileSystem,
            getParent: function (): void {
              throw new Error('Function not implemented.');
            }
          };
          entradasDeArquivos.push({ relativePath: arquivo.name, fileEntry });
        });
        this.imagemSelecionada(entradasDeArquivos);
      }
    };
    elementoEntrada.click();
  }

  public arquivosSelecionados(eventoFileDrop: NgxFileDropEntry[], imagem: boolean): void {
    const maximoArquivosPDF: number = 5;
    const maximoArquivosImagem: number = 10;

    if (
      eventoFileDrop.length > 0 &&
      ((eventoFileDrop.length + this.pdf().length <= maximoArquivosPDF && !imagem) ||
        (eventoFileDrop.length + this.listaImagens.length <= maximoArquivosImagem && imagem))
    ) {
      for (const arquivoSelecionado of eventoFileDrop) {
        if (arquivoSelecionado.fileEntry.isFile) {
          const entradaArquivo = arquivoSelecionado.fileEntry as FileSystemFileEntry;
          entradaArquivo.file((arquivo: File) => {
            if (arquivo.name.length > this.maxCaracteres) {
              this.toastr.error(`O nome do arquivo ${arquivo.name} excede o limite permitido de ${this.maxCaracteres} caracteres.`);
              return;
            }
            if (!this.tamanhoMaximoArquivo(arquivo.size)) {
              this.toastr.error(`O arquivo ${arquivo.name} excede o tamanho máximo permitido de 25MB.`);
              return;
            }
            if (!this.verificarExtensao(arquivo.name) && !imagem) {
              this.toastr.error('Apenas arquivo no formatos PDF é aceito.');
              return;
            }
            if (imagem && !arquivo.type.startsWith('image/')) {
              this.toastr.error('Apenas imagem(s) são aceitas.');
              return;
            }
            const leitorArquivo = new FileReader();
            leitorArquivo.onload = (evento): void => {
              const base64String = (evento.target?.result as string).split(',')[1];
              const anexo: PdfAnexo = {
                id: 0,
                noticia: { id: Number(this.idNoticia) },
                nome: arquivo.name,
                arquivo: base64String,
                imagem: imagem
              };
              if (imagem) {
                const existeItem = this.listaImagens.find((item) => item.nome === anexo.nome);
                if (existeItem) {
                  existeItem.arquivo = anexo.arquivo;
                } else {
                  this.listaImagens.push(anexo);
                }
              } else {
                const existeItem = this.pdf().find((item) => item.nome === anexo.nome);
                if (existeItem) {
                  existeItem.arquivo = anexo.arquivo;
                } else {
                  this.pdf().push(anexo);
                }
              }

              this.cdr.markForCheck();
            };
            leitorArquivo.readAsDataURL(arquivo);
          });
        }
      }
    } else {
      if (imagem) {
        this.toastr.error(`As imagens excede  a quantidade máxima, permitido até  ${maximoArquivosImagem} imagens.`);
      } else {
        this.toastr.error(`O arquivos excede  a quantidade máxima, permitido até  ${maximoArquivosPDF} arquivos.`);
      }
    }
  }

  arquivossoltoClicado(tipoEnvio: boolean): void {
    const elementoEntrada = document.createElement('input');
    elementoEntrada.type = 'file';
    elementoEntrada.multiple = true;
    if (tipoEnvio) elementoEntrada.accept = 'image/*';

    elementoEntrada.onchange = (event: Event): void => {
      const alvo = event.target as HTMLInputElement;
      if (alvo.files) {
        const listaArquivo: FileList = alvo.files;
        const entradasDeArquivos: NgxFileDropEntry[] = [];
        Array.from(listaArquivo).forEach((arquivo) => {
          const fileEntry: FileSystemFileEntry = {
            isDirectory: false,
            isFile: true,
            file: (successCallback: (file: File) => void) => {
              successCallback(arquivo);
            },
            name: arquivo.name,
            fullPath: arquivo.name,
            filesystem: undefined as unknown as FileSystem,
            getParent: function (): void {
              throw new Error('Function not implemented.');
            }
          };
          entradasDeArquivos.push({ relativePath: arquivo.name, fileEntry });
        });
        this.arquivosSelecionados(entradasDeArquivos, tipoEnvio);
      }
    };
    elementoEntrada.click();
  }

  verificarExtensao(nomeArquivo: string): boolean {
    let verificarExtensao = false;
    const extensaoPermitida = '.pdf';
    const regex = /(?:\.([^.]+))?$/;
    const extensao = regex.exec(nomeArquivo);
    if (null !== extensao) {
      if (extensaoPermitida === extensao[0]) {
        verificarExtensao = true;
      }
    }
    return verificarExtensao;
  }

  tamanhoMaximoArquivo(tamanho: number): boolean {
    let tamanhoArquivo = false;
    if (tamanho < 25000000) {
      tamanhoArquivo = true;
    }
    return tamanhoArquivo;
  }

  public removerArquivo(index: number, imagem: boolean): void {
    if (imagem) {
      this.listaImagens.splice(index, 1);
    } else {
      this.pdf().splice(index, 1);
      this.pdf.set(this.pdf());
    }
  }

  removerimagem(): void {
    this.formulario.controls.imagem.setValue('');
    this.formulario.controls.nomeArquivo.setValue('');
  }
  adicionarPalavra(evento: string): void {
    const valor = (evento || '').trim();
    if (valor && valor.length >= this.limiteCaracteres) {
      this.toastr.error(`O nome da palavra excede o limite permitido de ${this.limiteCaracteres} caracteres.`);
      return;
    }
    if (this.palavrasChaves().length >= this.limiteMaximoPlavrasChaves) {
      this.toastr.error(`A quantidade de palavras excede o limite permitido de ${this.limiteMaximoPlavrasChaves} caracteres.`);
      return;
    }
    if (!this.palavrasChaves().includes(valor)) this.palavrasChaves.update((palavraChave) => [...palavraChave, valor]);
    this.palavraChaveInput.nativeElement.value = '';
  }

  removerPalavra(palavraChave: string): void {
    this.palavrasChaves.update((palavrasChaves) => {
      const indice = palavrasChaves.indexOf(palavraChave);
      if (indice < 0) {
        return palavrasChaves;
      }

      palavrasChaves.splice(indice, 1);
      void this.anunciador.announce(`Removed ${palavraChave}`);
      return [...palavrasChaves];
    });
  }

  cancelarEnvio(): void {
    this.location.back();
  }

  enviarFormulario(): void {
    if (this.formulario.valid && this.verificacao()) {
      this.ajustarFormulario(this.formulario.getRawValue());
      this.noticiaService
        .atualizar(this.formulario, Number(this.idNoticia))
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => {
            if (this.pdf().length > 0 || this.listaImagens.length > 0) {
              this.enviarFomularioArquivos();
            }
            this.toastr.success('Enviado com sucesso.');
            setTimeout(() => {
              this.location.back();
            }, 500);
          },
          error: (error: Error) => {
            this.toastr.error(error.message);
          }
        });
    } else {
      this.toastr.error('Falta preencher campos obrigatórios.');
    }
  }

  enviarFomularioArquivos(): void {
    this.listaTotalArquivos = [...this.pdf(), ...this.listaImagens];
    this.noticiaService
      .atualizarArquivo(this.listaTotalArquivos)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response) => {
          console.log(response);
        },
        error: (error: HttpErrorResponse) => {
          this.toastr.error('Erro ao enviar os arquivos.' + error.message);
        }
      });
  }

  ajustarFormulario(formularioValue: NoticiaForm): void {
    const dataPublicacao = new Date(String(formularioValue.dataPublicacao));
    const dia = ('0' + dataPublicacao.getDate()).slice(-2);
    const mes = ('0' + (dataPublicacao.getMonth() + 1)).slice(-2);
    const ano = dataPublicacao.getFullYear();
    const hora = ('0' + dataPublicacao.getHours()).slice(-2);
    const minutos = ('0' + dataPublicacao.getMinutes()).slice(-2);
    const segundos = ('0' + dataPublicacao.getSeconds()).slice(-2);

    formularioValue.dataPublicacao = `${dia}/${mes}/${ano} ${hora}:${minutos}:${segundos}`;
    formularioValue.categoria = Number(formularioValue.categoria);
    formularioValue.acesso = formularioValue.acesso !== null ? { id: Number(formularioValue.acesso) } : null;
    formularioValue.destaque = formularioValue.destaque == null || formularioValue.destaque == 0 ? null : Number(formularioValue.destaque);
    formularioValue.palavraChave = this.palavrasChaves()
      .map((palavra) => palavra)
      .join(' ');

    this.formulario.patchValue({
      dataPublicacao: formularioValue.dataPublicacao,
      categoria: formularioValue.categoria,
      acesso: formularioValue.acesso,
      destaque: formularioValue.destaque,
      palavraChave: formularioValue.palavraChave
    });
  }

  verificacao(): boolean {
    const categoriaControl = Number(this.formulario.controls.categoria.value);
    const acessoControl = this.formulario.controls.acesso.value ? Number(this.formulario.controls.acesso.value) : null;
    const imagemControl = this.formulario.controls.imagem.value;

    if ((categoriaControl === 2 && imagemControl !== '' && acessoControl !== null) || (categoriaControl === 1 && imagemControl !== '')) {
      return true;
    } else if (categoriaControl === 3 && acessoControl === null && imagemControl === null) {
      return true;
    }
    return false;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
