import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import type { Regional } from 'src/app/shared/models/regional.interface';

import { MatButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import type { PageEvent } from '@angular/material/paginator';
import { MatPaginator } from '@angular/material/paginator';
import { dados } from 'src/app/area-aberta/features/informacao-cidadao/localizar-regionais/dados';
import { SeparadorComponent } from '../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-localizar-regionais',
  templateUrl: './localizar-regionais.component.html',
  styleUrl: './localizar-regionais.component.scss',
  standalone: true,

  imports: [SeparadorComponent, FormsModule, ReactiveFormsModule, MatLabel, MatFormField, MatInput, MatButton, MatPaginator],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocalizarRegionaisComponent {
  private readonly formBuilder = inject(FormBuilder);

  regionais: Regional[];
  regional: Regional;
  gridUm = signal(false);
  gridDois = signal(false);

  comprimento = signal(-1);
  tamanhoDaPagina = signal(10);
  numeroPagina = signal(0);
  readonly opcoesDoTamanhoDaPagina = [5, 10, 25];

  listaRegionais = signal<Regional[]>([]);

  formulario = this.formBuilder.group({
    busca: ['', Validators.required]
  });

  buscarRegional(): void {
    if (this.formulario.valid) {
      this.gridUm.set(false);
      this.gridDois.set(false);
      this.regionais = dados.filter((e) => e.municipio.toLowerCase().includes(this.formulario.value.busca?.toLowerCase() ?? ''));
      if (this.regionais.length > 0) {
        this.gridUm.set(true);
        this.comprimento.set(this.regionais.length);
        this.atualizarPaginacaoRegionais();
      }
    }
  }

  lidarEventoPagina(event: PageEvent): void {
    this.tamanhoDaPagina.set(event.pageSize);
    this.numeroPagina.set(event.pageIndex);
    this.atualizarPaginacaoRegionais();
  }

  atualizarPaginacaoRegionais(): void {
    const inicioIndex = this.numeroPagina() * this.tamanhoDaPagina();
    const fimIndex = inicioIndex + this.tamanhoDaPagina();
    this.listaRegionais.set(this.regionais.slice(inicioIndex, fimIndex));
  }

  visualizarGridDois(evento: Regional): void {
    this.regional = evento;
    this.gridUm.set(false);
    this.gridDois.set(true);
  }

  limparTudo(): void {
    this.gridUm.set(false);
    this.gridDois.set(false);
    this.formulario.reset();
    this.listaRegionais.set([]);
    this.comprimento.set(0);
    this.tamanhoDaPagina.set(10);
    this.numeroPagina.set(0);
  }
}
