import { CommonModule, CurrencyPipe, DatePipe, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import type { ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, signal, ViewChild } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule, MatOption } from '@angular/material/core';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import type { PageEvent } from '@angular/material/paginator';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxCurrencyDirective } from 'ngx-currency';
import type { NgxFileDropEntry } from 'ngx-file-drop';
import { NgxFileDropModule } from 'ngx-file-drop';
import { combineLatest, delay, finalize, Subject, takeUntil } from 'rxjs';
import type { AnoReembolso } from 'src/app/area-restrita/features/auxilio/saude/shared/models/ano-reembolso';
import type { HistoricoSolicitacoes } from 'src/app/area-restrita/features/auxilio/saude/shared/models/historico-solicitacoes';
import type { ConfirmaDialogData } from 'src/app/area-restrita/shared/components/confirma-dialog/confirma-dialog-data';
import { ConfirmaDialogComponent } from 'src/app/area-restrita/shared/components/confirma-dialog/confirma-dialog.component';
import { WaitLoadingService } from 'src/app/area-restrita/shared/components/wait-loading/wait-loading.service';
import { ConversorValidadorService } from 'src/app/area-restrita/shared/services/conversor-validador.service';
import { PaginaVisitadaService } from 'src/app/area-restrita/shared/services/pagina-visitada.service';
import { AppDateAdapter } from 'src/app/date-adapter.custom';
import type { Page } from 'src/app/shared/models/paginacao/page.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { UsuarioService } from '../../../home/shared/services/usuario.service';
import { LimiteMensalService } from '../../shared/services/limite-mensal.service';
import type { AuxilioPedidoDto } from '../shared/dto/auxilio-pedido.dto';
import type { DependenteAuxilioSaudeDto } from '../shared/dto/dependente-auxilio-saude.dto';
import { AuxilioStatusEnum } from '../shared/enums/auxilio-status.enum';
import { obterPerfilPorId, PerfilEnum } from '../shared/enums/perfil.enum';
import type { InscricaoAuxilioSaude, NotaFiscalAnexadaAuxilio, Usuario } from '../shared/models/auxilio-saude';
import type { Dependente } from '../shared/models/dependente';
import type { LimiteMensalAuxilio } from '../shared/models/limite-mensal.model';
import type { TipoAuxilioSaude } from '../shared/models/tipo-auxilio-saude';
import { AuxilioSaudeService } from '../shared/services/auxilio-saude.service';
import { InscricaoAuxilioSaudeService } from '../shared/services/inscricao-auxilio-saude.service';
import { SolicitacaoAuxilioSaudeService } from '../shared/services/solicitacao-auxilio-saude.service';
import type { TipoAuxilio } from './../shared/models/tipo-auxilio';
export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
registerLocaleData(localePt);
@Component({
  selector: 'app-solicitacao-auxilio-saude',
  templateUrl: './solicitacao-auxilio-saude.component.html',
  styleUrl: './solicitacao-auxilio-saude.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatSelect,
    MatOption,
    MatInputModule,
    MatDatepickerModule,
    MatSuffix,
    MatDatepicker,
    NgxCurrencyDirective,
    NgxFileDropModule,
    MatCheckbox,
    MatButton,
    MatPaginator,
    CurrencyPipe,
    MatNativeDateModule
  ],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_LOCALE, useValue: 'pt-BR' },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SolicitacaoAuxilioSaudeComponent implements OnInit, OnDestroy {
  private readonly solicitacaoAuxilioSaudeService = inject(SolicitacaoAuxilioSaudeService);
  private readonly inscricaoAuxilioSaudeService = inject(InscricaoAuxilioSaudeService);
  private readonly auxilioSaudeService = inject(AuxilioSaudeService);
  private readonly limiteMensalService = inject(LimiteMensalService);
  private readonly formBuilder = inject(FormBuilder);
  private readonly toastr = inject(ToastService);
  private readonly waitLoadingService = inject(WaitLoadingService);
  private readonly usuarioService = inject(UsuarioService);
  private readonly dialog = inject(MatDialog);
  private readonly router = inject(Router);
  private readonly route = inject(ActivatedRoute);
  private readonly base64ToPdfService = inject(ConversorValidadorService);
  private readonly paginaService = inject(PaginaVisitadaService);
  private readonly datePipe = inject(DatePipe);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly waitLoading = inject(WaitLoadingService);

  @ViewChild('DataVencimento', { static: true }) dataVencimentoInput: ElementRef<HTMLInputElement>;

  saldo: number = 0;
  limite: number = 0;
  temDependentes = signal<boolean>(false);
  deferido: number = 0;

  private readonly destroy$ = new Subject<void>();
  readonly statusAuxilioEnum = AuxilioStatusEnum;
  historicoSolicitacoesResponse = signal<HistoricoSolicitacoes[]>([]);
  tipoAuxilioResponse = signal<TipoAuxilio[]>([]);
  tipoAuxilioSaudeResponse = signal<TipoAuxilioSaude[]>([]);
  anoReembolsoResponse = signal<AnoReembolso[]>([]);
  limiteReembolsoResponse = signal(0);
  dataInscricaoResponse: string = '';
  mensagemErro = signal('');
  readonly meses: string[] = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
  mesesSelect = signal<string[]>(this.meses);
  requerimentoDigital = signal(false);
  possuoDependente = signal(false);
  dependente: Dependente[] = [];
  verificarExistenciaInscricaoUsuario: boolean;
  procedimentosEsteticos = signal(false);

  somaValorDeferido = signal<number | null>(null);
  documentosTitular = signal<NotaFiscalAnexadaAuxilio[]>([]);
  documentosDependente = signal<NotaFiscalAnexadaAuxilio[]>([]);

  dadosFormulario = this.formBuilder.group({
    tipoAuxilio: [0, Validators.required],
    tipoAuxilioSaude: [0, Validators.required],
    anoReembolso: [0, Validators.required],
    mesParcela: [0, Validators.required],
    dataVencimentoParcela: ['', Validators.required],
    valorReembolsoDependente: [0],
    valorReembolsoTitular: [0],
    valorReembolsoTotal: [0],
    usuario: { id: Number(localStorage.getItem('idUsuario')) },
    dependentesAuxilioSaude: this.formBuilder.nonNullable.control<null | undefined | Dependente[]>(null)
  });
  quantidadeDependentes = 0;

  comprimento = signal(0);
  tamanhoDaPagina = signal(10);
  numeroPagina = signal(0);
  opcoesDoTamanhoDaPagina = [5, 10, 25];

  usuario: Usuario;
  retificacao = signal(false);
  observacao = signal<string>('');
  idProduto: string;
  historico = signal(true);
  alterarCamposHabilitadosOuDesabilitados = signal(true);
  valorTitularRequerido = signal(true);

  habilitarCampoTipoSolicitacao = signal(false);
  habilitarTitular = signal(false);
  habilitarDependente = signal(false);
  valorSelecionadoTipo = signal<number | null>(null);
  verificarStatusSolicitacao = signal(false);

  ngOnInit(): void {
    this.dadosFormulario.controls.valorReembolsoTotal.disable();
    this.paginaService.salvaPagina('Solicitação de Reembolso').subscribe();
    this.idProduto = this.route.snapshot.params['id'] as string;
    if (this.idProduto) {
      this.carregarAuxilioSaude(this.idProduto);
    } else this.obterDadosUsuario();

    this.dadosFormulario.controls.dataVencimentoParcela.valueChanges.subscribe(() => {
      this.mudancaData();
    });
  }

  carregarAuxilioSaude(idPedido: string): void {
    this.waitLoading.open();
    this.historico.set(false);
    this.auxilioSaudeService
      .obterPedidoAuxilioSaude(idPedido)
      .pipe(
        delay(500),
        finalize(() => {
          this.waitLoading.close();
        })
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dados) => {
          this.verificarStatusSolicitacao.set(dados.status?.nome == 'Enviado para Retificação');
          this.dadosFormulario.patchValue({
            tipoAuxilio: dados.tipoAuxilio?.id,
            tipoAuxilioSaude: dados.tipoAuxilioSaude?.id,
            anoReembolso: dados.anoReembolso,
            mesParcela: dados.mesParcela,
            dataVencimentoParcela: this.transformarDataStringParaISO(dados.dataVencimentoParcela),
            valorReembolsoDependente: dados.valorReembolsoDependente,
            valorReembolsoTitular: dados.valorReembolsoTitular,
            valorReembolsoTotal: dados.valorReembolsoTotal,
            usuario: { id: Number(dados.usuario?.id) },
            dependentesAuxilioSaude: dados.dependentesAuxilioSaude
          });

          if (dados.usuario) this.usuario = dados.usuario;

          this.obterListaTipoAuxilio();
          this.obterListaTipoAuxilioSaude();
          this.obterLista();
          this.obterListaAnoReembolso();
          this.obterInscricao();
          this.saldo = 0;
          this.deferido = 0;

          if (dados.status?.nome != 'Enviado para Retificação') {
            this.alterarCamposHabilitadosOuDesabilitados.set(false);
            this.dadosFormulario.controls.tipoAuxilioSaude.disable();
            this.dadosFormulario.controls.anoReembolso.disable();
            this.dadosFormulario.controls.mesParcela.disable();
            this.dadosFormulario.controls.dataVencimentoParcela.disable();
            this.dadosFormulario.controls.valorReembolsoDependente.disable();
            this.dadosFormulario.controls.valorReembolsoTitular.disable();
          }
          this.dadosFormulario.controls.valorReembolsoTitular.valueChanges.subscribe((value: number) => {
            this.dadosFormulario.controls.valorReembolsoTotal.setValue(value + Number(this.dadosFormulario.controls.valorReembolsoDependente.value));
          });

          this.dadosFormulario.controls.valorReembolsoDependente.valueChanges.subscribe((value: number) => {
            if (value > 0) this.valorTitularRequerido.set(false);

            this.dadosFormulario.controls.valorReembolsoTotal.setValue(value + Number(this.dadosFormulario.controls.valorReembolsoTitular.value));
          });

          this.retificacao.set(dados.status?.nome == 'Enviado para Retificação' ? true : false);
          this.observacao.set(dados.observacao ?? '');
          this.obterListaDocumentos(idPedido);
        },
        error: (error) => {
          console.error('Erro ao obter dados do serviço', error);
        }
      });
  }

  transformarDataStringParaISO(dataString?: string): string {
    if (dataString == undefined) return '';

    const partes = dataString.split('/');
    const dia = partes[0];
    const mes = partes[1];
    const ano = partes[2];
    return `${ano}-${mes}-${dia}`;
  }

  abrirPdf(evento: MouseEvent, pedido: NotaFiscalAnexadaAuxilio): void {
    evento.preventDefault();
    this.base64ToPdfService.base64ToBlob(pedido.arquivo);
  }

  obterListaDocumentos(pedido: string): void {
    this.auxilioSaudeService
      .obterListaDocumentos(pedido)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dados: NotaFiscalAnexadaAuxilio[]) => {
          const titularDocs: NotaFiscalAnexadaAuxilio[] = [];
          const dependenteDocs: NotaFiscalAnexadaAuxilio[] = [];

          dados.forEach((element: NotaFiscalAnexadaAuxilio) => {
            if (element.titular) {
              titularDocs.push(element);
            } else {
              dependenteDocs.push(element);
            }
          });

          this.documentosTitular.set(titularDocs);
          this.documentosDependente.set(dependenteDocs);
        },
        error: (error) => {
          console.error('Erro ao obter dados do serviço', error);
        }
      });
  }

  obterDadosUsuario(): void {
    this.usuarioService.obterDadosUsuario(Number(localStorage.getItem('idUsuario'))).subscribe({
      next: (response: Usuario) => {
        this.usuario = response;
        if (obterPerfilPorId(this.usuario.perfil.id) === PerfilEnum.APOSENTADO) {
          const data: ConfirmaDialogData = {
            title: '',
            message: 'Usuário com perfil APOSENTADO. Não é possível realizar inscrição.',
            escondeBotaoNao: true
          };
          this.dialog
            .open(ConfirmaDialogComponent, {
              width: '20%',
              data: data
            })
            .afterClosed()
            .subscribe((): void => {
              void this.router.navigate(['area-restrita/home']);
            });
        } else {
          this.obterListaTipoAuxilio();
          this.obterListaTipoAuxilioSaude();
          this.obterListaAnoReembolso();
          this.obterInscricao();
          this.obterLista();
          this.saldo = 0;
          this.deferido = 0;

          this.dadosFormulario.controls.valorReembolsoTitular.valueChanges.subscribe((value: number) => {
            this.dadosFormulario.controls.valorReembolsoTotal.setValue(value + Number(this.dadosFormulario.controls.valorReembolsoDependente.value));
          });
          this.dadosFormulario.controls.valorReembolsoDependente.valueChanges.subscribe((value: number) => {
            if (value > 0) this.valorTitularRequerido.set(false);

            this.dadosFormulario.controls.valorReembolsoTotal.setValue(value + Number(this.dadosFormulario.controls.valorReembolsoTitular.value));
          });
        }
      },
      error: console.error
    });
  }

  obterListaTipoAuxilio(): void {
    this.solicitacaoAuxilioSaudeService.obterListaTipoAuxilio().subscribe((response) => {
      this.tipoAuxilioResponse.set(response);
      const resultado = this.tipoAuxilioResponse().find(
        (f) =>
          f.tipo
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '') === 'auxilio saude'
      );
      const idOpcaoUnica = resultado ? resultado.id : null;
      if (typeof idOpcaoUnica === 'number') {
        this.dadosFormulario.controls.tipoAuxilio.setValue(idOpcaoUnica);
        this.dadosFormulario.controls.tipoAuxilio.disable();
      }
    });
  }

  obterListaTipoAuxilioSaude(): void {
    this.solicitacaoAuxilioSaudeService.obterListaTipoAuxilioSaude().subscribe((response) => {
      this.tipoAuxilioSaudeResponse.set(response);
    });
  }

  obterLista(): void {
    this.solicitacaoAuxilioSaudeService
      .obterLista(null, this.numeroPagina(), this.tamanhoDaPagina())
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dados: Page<HistoricoSolicitacoes>) => {
          this.historicoSolicitacoesResponse.set(dados.content);
          this.calculaSaldo();
          this.comprimento.set(dados.totalElements);
        },
        error: (error) => {
          this.toastr.error('Erro ao obter dados do serviço', error);
        }
      });
  }

  obterListaAnoReembolso(): void {
    this.solicitacaoAuxilioSaudeService
      .obterListaAnoReembolso()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dados) => {
          this.anoReembolsoResponse.set(dados);
        },
        error: (error) => {
          console.error('Erro ao obter dados do serviço', error);
        }
      });
  }

  obterInscricao(): void {
    this.inscricaoAuxilioSaudeService
      .obterInscricao()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dados: InscricaoAuxilioSaude | null) => {
          if (dados) {
            this.dependente = dados.dependentes.filter((usuario) => usuario.ativo);
            this.verificarExistenciaInscricaoUsuario = true;
            this.dataInscricaoResponse = dados.dataInscricao;
            this.quantidadeDependentes = dados.dependentes.length != 0 ? dados.dependentes.filter((usuario) => usuario.ativo).length : 0;

            const dependentesAtivos = dados.dependentes.filter((usuario) => usuario.ativo);
            this.temDependentes.set(dependentesAtivos.length > 0 ? true : false);
          } else {
            this.verificarExistenciaInscricaoUsuario = false;
          }

          this.obterLimite();

          if (this.router.url.includes('solicitacao-reembolso')) {
            if (this.temDependentes()) {
              this.habilitarCampoTipoSolicitacao.set(true);
            } else {
              this.habilitarTitular.set(true);
              this.valorSelecionadoTipo.set(1);
            }
          } else {
            const valorReembolsoTitular = (this.dadosFormulario.controls.valorReembolsoTitular.value ?? 0) > 0;
            const valorReembolsoDependente = (this.dadosFormulario.controls.valorReembolsoDependente.value ?? 0) > 0;
            if (this.verificarStatusSolicitacao() && this.temDependentes()) {
              this.habilitarCampoTipoSolicitacao.set(true);
            }
            if (valorReembolsoTitular && valorReembolsoDependente) {
              this.valorSelecionadoTipo.set(3);
              this.habilitarTitular.set(true);
              this.habilitarDependente.set(true);
            } else if (valorReembolsoTitular) {
              this.habilitarTitular.set(true);
              this.valorSelecionadoTipo.set(1);
            } else if (valorReembolsoDependente) {
              this.habilitarDependente.set(true);
              this.valorSelecionadoTipo.set(2);
            }
          }

          this.dadosFormulario.patchValue({ dependentesAuxilioSaude: dados?.dependentes });
        },
        error: (error) => {
          console.error('Erro ao obter dados do serviço', error);
        }
      });
  }

  obterLimite(): void {
    let dataNascimento = this.usuario.dataNascimento;
    const dependentePcD = this.dependente.length > 0 ? this.dependente.some((dep) => dep.tipoPcd.id < 8) : false;
    if (typeof dataNascimento === 'string' && dataNascimento.includes('/')) {
      const [dia, mes, ano] = dataNascimento.split('/');
      dataNascimento = `${ano}-${mes}-${dia}`;
    }
    const dataNascimentoDate = new Date(dataNascimento);
    if (isNaN(dataNascimentoDate.getTime())) {
      console.error('Data de Nascimento inválida:', dataNascimento);
      return;
    }
    const anoAtual = new Date().getFullYear();
    const idadeUsuario = anoAtual - dataNascimentoDate.getFullYear();
    let categoriaIdade = 0;
    if (this.usuario.pcd || dependentePcD) {
      categoriaIdade = 4;
    } else if (idadeUsuario <= 49) {
      categoriaIdade = 1;
    } else if (idadeUsuario <= 58) {
      categoriaIdade = 2;
    } else if (idadeUsuario >= 59) {
      categoriaIdade = 3;
    }
    const dataAtual = new Date();
    this.limiteMensalService
      .consultaLimiteMensal(this.usuario.perfil.id, dataAtual.getFullYear(), dataAtual.getMonth() + 1, categoriaIdade)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dados: LimiteMensalAuxilio) => {
          this.limiteReembolsoResponse.set(dados.valor);
        },
        error: (error) => {
          console.error('Erro ao obter dados do serviço', error);
        }
      });
  }

  calculaSaldo(): void {
    console.warn('Atenção!!! Calcula saldo de acordo com o mês corrente, lembre-se disso.');
    this.saldo = this.limiteReembolsoResponse();
    this.deferido = 0;
    const dados = this.historicoSolicitacoesResponse();
    for (const dado of dados) {
      this.deferido += Number(dado.valorDeferidoPge);
    }
    this.saldo -= this.deferido;
  }

  calcularValorReembolsoMes(): boolean {
    let somatorioTotalMes = 0;
    const dados = this.historicoSolicitacoesResponse();
    dados
      .filter(
        (dado) =>
          ![AuxilioStatusEnum.CANCELADO, AuxilioStatusEnum.INDEFERIDO, AuxilioStatusEnum.ENVIADO_PARA_RETIFICACAO].includes(dado.status.id) &&
          dado.mesParcela == this.dadosFormulario.controls.mesParcela.value &&
          dado.anoReembolso == this.dadosFormulario.controls.anoReembolso.value
      )
      .forEach((dado) => {
        somatorioTotalMes += parseFloat(dado.valorDeferidoPge) > 0 ? Number(dado.valorDeferidoPge) : Number(dado.valorReembolsoTotal);
      });

    const valorTitular = this.dadosFormulario.controls.valorReembolsoTitular.value ?? 0;
    const valorDependente = this.dadosFormulario.controls.valorReembolsoDependente.value ?? 0;

    if (!(valorTitular + valorDependente > 0)) {
      this.toastr.warning('Valor total deve ser maior que zero.');
      return false;
    }

    this.dadosFormulario.controls.valorReembolsoTotal.setValue(parseFloat((valorTitular + valorDependente).toFixed(2)));

    if ((this.dadosFormulario.controls.valorReembolsoTotal.value ?? 0) + somatorioTotalMes <= this.limiteReembolsoResponse()) {
      return true;
    } else {
      this.toastr.error('Limite de reembolso mensal atingido.');
      return false;
    }
  }

  arquivoSoltoClicado(tipoEnvio: boolean): void {
    const elementoEntrada = document.createElement('input');
    elementoEntrada.type = 'file';
    elementoEntrada.multiple = true;
    elementoEntrada.onchange = (event: Event): void => {
      const alvo = event.target as HTMLInputElement;
      if (alvo.files) {
        const listaArquivo: FileList = alvo.files;
        const entradasDeArquivos: NgxFileDropEntry[] = [];
        Array.from(listaArquivo).forEach((arquivo) => {
          const fileEntry: FileSystemFileEntry = {
            isDirectory: false,
            isFile: true,
            file: (successCallback: (file: File) => void) => {
              successCallback(arquivo);
            },
            name: arquivo.name,
            fullPath: arquivo.name,
            filesystem: undefined as unknown as FileSystem,
            getParent: function (): void {
              throw new Error('Function not implemented.');
            }
          };
          entradasDeArquivos.push({ relativePath: arquivo.name, fileEntry });
        });
        this.arquivosSoltos(entradasDeArquivos, tipoEnvio);
      }
    };
    elementoEntrada.click();
  }

  atualizarRequerimentoDigital(): void {
    this.requerimentoDigital.set(!this.requerimentoDigital());
  }
  atualizarPossuoDependente(): void {
    this.possuoDependente.set(!this.possuoDependente());
  }

  public arquivosSoltos(listaArquivos: NgxFileDropEntry[], tipoEnvio: boolean): void {
    const maximoArquivos: number = 5;
    const maximoNomeArquivo: number = 160;
    for (const arquivoIndividual of listaArquivos) {
      if (arquivoIndividual.fileEntry.isFile && this.verificarExtensao(arquivoIndividual.fileEntry.name)) {
        const entradaArquivoIndividual = arquivoIndividual.fileEntry as FileSystemFileEntry;
        entradaArquivoIndividual.file((arquivo: File) => {
          if (this.tamanhoMaximoArquivo(arquivo.size)) {
            if (arquivo.name.length <= maximoNomeArquivo) {
              if (
                (tipoEnvio && listaArquivos.length + this.documentosTitular().length <= maximoArquivos) ||
                (!tipoEnvio && listaArquivos.length + this.documentosDependente().length <= maximoArquivos * this.quantidadeDependentes)
              ) {
                const arquivoLeitura = new FileReader();
                arquivoLeitura.onload = (evento): void => {
                  const base64String = (evento.target?.result as string).split(',')[1];
                  const anexo: NotaFiscalAnexadaAuxilio = {
                    id: null,
                    auxilio: { id: this.idProduto ? this.idProduto : null },
                    nomeArquivo: arquivo.name,
                    arquivo: base64String,
                    titular: tipoEnvio
                  };
                  if (tipoEnvio) {
                    this.documentosTitular.set(this.atualizarLista(this.documentosTitular(), anexo));
                  } else {
                    this.documentosDependente.set(this.atualizarLista(this.documentosDependente(), anexo));
                  }

                  this.cdr.markForCheck();
                };
                arquivoLeitura.readAsDataURL(arquivo);
              } else {
                this.toastr.error(
                  `Número máximo de ${tipoEnvio ? maximoArquivos : maximoArquivos * this.quantidadeDependentes} arquivos excedido para${tipoEnvio ? ' o titular.' : ' os dependentes.'}`
                );
              }
            } else {
              this.toastr.error(`O nome do arquivo excede o limite permitido de ${maximoNomeArquivo} caracteres.`);
            }
          } else {
            this.toastr.error('O arquivo excede o tamanho máximo permitido de 25MB.');
          }
        });
      } else {
        this.toastr.error('Apenas arquivos nos formatos PDF, JPG, JPEG e PNG são aceitos.');
      }
    }
  }

  verificarExtensao(nomeArquivo: string): boolean {
    let verificarExtensao = false;
    const extensaoPermitida = ['.pdf', '.jpg', '.jpeg', '.png', '.docx'];
    const regex = /(?:\.([^.]+))?$/;
    const extensao = regex.exec(nomeArquivo);
    if (null !== extensao) {
      for (const ext of extensaoPermitida) {
        if (ext === extensao[0]) {
          verificarExtensao = true;
        }
      }
    }
    return verificarExtensao;
  }

  tamanhoMaximoArquivo(tamanho: number): boolean {
    let tamanhoArquivo = false;
    if (tamanho < 25000000) {
      tamanhoArquivo = true;
    }
    return tamanhoArquivo;
  }

  removerArquivo(index: number, titular: boolean): void {
    if (titular) {
      this.documentosTitular.update((documentos) => {
        documentos.splice(index, 1);
        return documentos;
      });
    } else {
      this.documentosDependente.update((documentos) => {
        documentos.splice(index, 1);
        return documentos;
      });
    }
  }

  alternarCamposHabilitados(id: number): void {
    if (id == 1) {
      this.habilitarDependente.set(false);
      this.habilitarTitular.set(true);
      this.dadosFormulario.controls.valorReembolsoDependente.setValue(0);
      this.documentosDependente.set([]);
    }
    if (id == 2) {
      this.habilitarTitular.set(false);
      this.habilitarDependente.set(true);
      this.dadosFormulario.controls.valorReembolsoTitular.setValue(0);
      this.documentosTitular.set([]);
    }
    if (id == 3 && this.temDependentes()) {
      this.habilitarTitular.set(true);
      this.habilitarDependente.set(true);
    }
  }

  validadorFormulario(): boolean {
    const valorTitular = this.dadosFormulario.controls.valorReembolsoTitular.value ?? 0;
    const valorDependente = this.dadosFormulario.controls.valorReembolsoDependente.value ?? 0;

    const camposObrigatoriosPreenchidos = (): boolean => {
      return (
        !!this.dadosFormulario.controls.anoReembolso.value &&
        !!this.dadosFormulario.controls.mesParcela.value &&
        this.dadosFormulario.controls.dataVencimentoParcela.valid &&
        this.requerimentoDigital() &&
        this.procedimentosEsteticos()
      );
    };

    switch (this.valorSelecionadoTipo()) {
      case 1:
        return valorTitular > 0 && camposObrigatoriosPreenchidos() && this.documentosTitular().length > 0;

      case 2:
        return valorDependente > 0 && camposObrigatoriosPreenchidos() && this.documentosDependente().length > 0 && this.possuoDependente();

      case 3:
        return (
          valorTitular > 0 &&
          valorDependente > 0 &&
          camposObrigatoriosPreenchidos() &&
          this.documentosTitular().length > 0 &&
          this.documentosDependente().length > 0 &&
          this.possuoDependente()
        );

      case null:
        return false;

      default:
        return false;
    }
  }

  comparaData(dateString: string, mesSolicitacao: number, anoSolicitacao: number): boolean {
    const dataInscricao = dateString.split('/').map(Number);
    const anoMesInscricao = dataInscricao[2] * 100 + dataInscricao[1];
    const anoMesSolicitacao = anoSolicitacao * 100 + mesSolicitacao;
    return anoMesSolicitacao >= anoMesInscricao;
  }

  enviarformulario(): void {
    const mesParcela = this.dadosFormulario.controls.mesParcela.value;
    const anoReembolso = this.dadosFormulario.controls.anoReembolso.value;

    if (mesParcela == null || anoReembolso == null || mesParcela == 0 || anoReembolso == 0) {
      this.mensagemErro.set('Existem campos obrigatórios não informados');
      this.lidarComErros(new Error('Favor preencher todos os dados obrigatórios'));
      return;
    }

    const DATA_LIMITE = '01/01/2023';
    const isDataLimite = this.comparaData(this.dataInscricaoResponse, mesParcela, anoReembolso);
    const isDataLimiteDependente = this.comparaData(DATA_LIMITE, mesParcela, anoReembolso);

    if (!this.calcularValorReembolsoMes()) return;

    const isObrigatorios = this.dadosFormulario.valid && this.validadorFormulario();
    if ((this.dadosFormulario.controls.valorReembolsoTotal.value ?? 0) > this.limiteReembolsoResponse() - (this.somaValorDeferido() ?? 0)) {
      this.toastr.info('O total deve ser menor ou igual ao limite informado.');
      return;
    }
    try {
      if (this.verificarExistenciaInscricaoUsuario) {
        if ((isDataLimite || isDataLimiteDependente) && isObrigatorios) {
          const formularioCopy = this.ajustarFormulario(this.dadosFormulario);

          const solicitacaoDto = this.setAuxilioPedidoDto(formularioCopy);
          console.log(JSON.stringify(solicitacaoDto));
          this.waitLoadingService.open('Enviando solicitação');
          this.solicitacaoAuxilioSaudeService
            .enviarSolicitacaoAuxilio(solicitacaoDto)
            .pipe(
              takeUntil(this.destroy$),
              finalize(() => {
                this.waitLoadingService.close();
              })
            )
            .subscribe({
              next: () => {
                this.toastr.success('Enviado com sucesso');
                location.reload();
              },
              error: (error: Error) => {
                console.error('Erro ao enviar dados', error);
                this.toastr.error('Erro ao enviar dados: ' + error.message);
              }
            });
        } else {
          if (!isObrigatorios) {
            this.mensagemErro.set('Existem campos obrigatórios não informados');
            throw new Error('Favor preencher todos os dados obrigatórios');
          } else if (!isDataLimite && !this.temDependentes()) {
            throw new Error('O Mês/Ano não pode ser menor que a data de inscrição.');
          } else if (!isDataLimiteDependente && this.temDependentes()) {
            throw new Error('Você possui dependentes, portanto a data não pode ser menor que 2024.');
          }
        }
      } else {
        this.toastr.error('Inscrição não realizada.');
        console.error('Ao enviar o formulário! Inscrição não realizada.');
      }
    } catch (error) {
      this.lidarComErros(error);
    }
  }

  lidarComErros(error: unknown): void {
    if (error instanceof Error) {
      this.toastr.error(error.message);
    } else if (typeof error === 'string') {
      this.toastr.error(error);
    } else {
      this.toastr.error('Ocorreu um erro desconhecido.');
      console.log('Erro desconhecido:', error);
    }
  }

  atualizarProcedimentosEsteticos(): void {
    this.procedimentosEsteticos.set(!this.procedimentosEsteticos());
  }

  enviarFormularioRetificado(): void {
    const mesParcela = this.dadosFormulario.controls.mesParcela.value;
    const anoReembolso = this.dadosFormulario.controls.anoReembolso.value;

    if (mesParcela == null || anoReembolso == null || mesParcela == 0 || anoReembolso == 0) {
      this.mensagemErro.set('Existem campos obrigatórios não informados');
      this.lidarComErros(new Error('Favor preencher todos os dados obrigatórios'));
      return;
    }

    const DATA_LIMITE = '01/01/2024';
    const isDataLimite = this.comparaData(this.dataInscricaoResponse, mesParcela, anoReembolso);
    const isDataLimiteDependente = this.comparaData(DATA_LIMITE, mesParcela, anoReembolso);

    if (!this.calcularValorReembolsoMes()) return;

    const isObrigatorios = this.dadosFormulario.valid && this.validadorFormulario();
    try {
      if ((isDataLimite || isDataLimiteDependente) && isObrigatorios) {
        const formularioCopy = this.ajustarFormulario(this.dadosFormulario);

        const auxilioPedidoDto = this.setAuxilioPedidoDto(formularioCopy);
        auxilioPedidoDto.id = parseInt(this.idProduto);

        this.waitLoadingService.open('Enviando retificação...');
        this.solicitacaoAuxilioSaudeService
          .enviarRetificacaoSolicitacaoAuxilio(auxilioPedidoDto)
          .pipe(
            takeUntil(this.destroy$),
            finalize(() => {
              this.waitLoadingService.close();
            })
          )
          .subscribe({
            next: () => {
              this.toastr.success('Solicitação enviada com sucesso.');
              setTimeout(() => {
                void this.router.navigate(['area-restrita', 'auxilio-saude, solicitacao-reembolso']);
              }, 1000);
            },
            error: (error: Error) => {
              console.error('Erro ao enviar dados', error);
              this.toastr.error('Erro ao enviar dados: ' + error.message);
            }
          });
      } else {
        if (!isObrigatorios) {
          this.mensagemErro.set('Existem campos obrigatórios não informados');
          throw new Error('Favor preencher todos os dados obrigatórios');
        } else if (!isDataLimite && !this.temDependentes()) {
          throw new Error('O Mês/Ano não pode ser menor que a data de inscrição.');
        } else if (!isDataLimiteDependente && this.temDependentes()) {
          throw new Error('Você possui dependentes, portanto a data não pode ser menor que 2024.');
        }
      }
    } catch (error) {
      this.lidarComErros(error);
    }
  }

  private setAuxilioPedidoDto(formularioCopy: FormGroup): AuxilioPedidoDto {
    const solicitacaoDto: AuxilioPedidoDto = {
      anoReembolso: formularioCopy.controls['anoReembolso'].value as number,
      dataVencimentoParcela: formularioCopy.controls['dataVencimentoParcela'].value as number,
      mesParcela: formularioCopy.controls['mesParcela'].value as number,
      tipoAuxilio: { id: 2 },
      tipoAuxilioSaude: { id: formularioCopy.controls['tipoAuxilioSaude'].value as number },
      usuario: { id: this.usuario.id },
      valorReembolsoDependente: formularioCopy.controls['valorReembolsoDependente'].value as number,
      valorReembolsoTitular: formularioCopy.controls['valorReembolsoTitular'].value as number,
      valorReembolsoTotal: formularioCopy.controls['valorReembolsoTotal'].value as number,
      notasFiscaisAnexadas: [],
      dependentesAuxilioSaude: (formularioCopy.controls['dependentesAuxilioSaude'].value as DependenteAuxilioSaudeDto[]).map((dependente) => ({
        id: dependente.id
      }))
    };

    this.documentosTitular().forEach((doc) => {
      solicitacaoDto.notasFiscaisAnexadas?.push({ arquivo: doc.arquivo, nomeArquivo: doc.nomeArquivo, titular: true });
    });

    this.documentosDependente().forEach((doc) => {
      solicitacaoDto.notasFiscaisAnexadas?.push({ arquivo: doc.arquivo, nomeArquivo: doc.nomeArquivo, titular: false });
    });

    return solicitacaoDto;
  }

  ajustarFormulario(formularioValue: FormGroup): FormGroup {
    const data = new Date(formularioValue.controls['dataVencimentoParcela'].value);
    const dia = ('0' + data.getDate()).slice(-2);
    const mes = ('0' + (data.getMonth() + 1)).slice(-2);
    const ano = data.getFullYear();
    formularioValue.controls['dataVencimentoParcela'].setValue(`${dia}/${mes}/${ano}`);
    return formularioValue;
  }

  enviarArquivosAuxilioRetificado(): void {
    combineLatest([
      this.solicitacaoAuxilioSaudeService.enviarArquivoRetificado(this.documentosTitular()),
      this.solicitacaoAuxilioSaudeService.enviarArquivoRetificado(this.documentosDependente())
    ]).subscribe({
      next: () => {
        this.toastr.success('Enviado com sucesso');
        this.mensagemErro.set('');
        this.limparFormulario();
        this.requerimentoDigital.set(false);
        this.possuoDependente.set(false);
        this.procedimentosEsteticos.set(false);
        this.obterLista();
      },
      error: (error: Error) => {
        this.toastr.error('Erro ao enviar arquivos: ' + error.message);
      }
    });
  }

  lidarEventoPagina(e: PageEvent): void {
    this.comprimento.set(e.length);
    this.tamanhoDaPagina.set(e.pageSize);
    this.numeroPagina.set(e.pageIndex);
    this.obterLista();
  }

  limparFormulario(): void {
    this.dadosFormulario.controls.anoReembolso.reset();
    this.dadosFormulario.controls.mesParcela.reset();
    this.dadosFormulario.controls.dataVencimentoParcela.reset();
    this.dadosFormulario.controls.valorReembolsoDependente.reset();
    this.dadosFormulario.controls.valorReembolsoTitular.reset();
    this.dadosFormulario.controls.valorReembolsoTotal.reset();
    this.documentosTitular.set([]);
    this.documentosDependente.set([]);
  }

  verficacaoReembolsoAnteriorDataNascimentoDependente(): void {
    const dadosFormulario = this.dadosFormulario.getRawValue();
    const { mesParcela, anoReembolso } = dadosFormulario;
    this.obterLimite();
    if (mesParcela && anoReembolso && this.dependente.length > 0) {
      const dependenteAtivo = this.dependente.filter((usuario) => usuario.ativo);
      this.temDependentes.set(
        dependenteAtivo.every((dep) => {
          const anoResponse = Number(dep.dataNascimento.substring(6, 10));
          const mesResponse = Number(dep.dataNascimento.substring(3, 5));

          if (anoResponse < anoReembolso) {
            return true;
          } else if (anoResponse === anoReembolso) {
            return mesResponse < mesParcela;
          } else {
            return false;
          }
        })
      );
      if (!this.temDependentes()) {
        this.toastr.warning('Ano e mês de de solicitação de reembolso menor que a data de nascimento do dependente.');
        this.dadosFormulario.controls.valorReembolsoDependente.reset();
      }
    }
    if (mesParcela && anoReembolso) {
      this.solicitacaoAuxilioSaudeService.obterSomaValorDeferido(Number(localStorage.getItem('idUsuario')), mesParcela, anoReembolso).subscribe({
        next: (response) => {
          this.somaValorDeferido.set(response);
        },
        error: (error) => {
          console.error('Erro ao obter a soma do valor deferido:', error);
        }
      });
    }
  }

  private atualizarLista(lista: NotaFiscalAnexadaAuxilio[], anexo: NotaFiscalAnexadaAuxilio): NotaFiscalAnexadaAuxilio[] {
    const existeItem = lista.find((item) => item.nomeArquivo === anexo.nomeArquivo);
    if (existeItem) {
      existeItem.arquivo = anexo.arquivo;
    } else {
      lista.push(anexo);
    }

    return lista;
  }

  ngOnDestroy(): void {
    this.waitLoading.close();
    this.destroy$.next();
    this.destroy$.complete();
  }

  mudancaData(): void {
    const elementoEntrada = this.dataVencimentoInput.nativeElement;
    const data = elementoEntrada.value;
    if (data.length === 10) return;

    const formatadoValor = this.aplicarMascaraData(data);

    if (data !== formatadoValor) {
      elementoEntrada.value = formatadoValor;
    }
  }

  aplicarMascaraData(value: string): string {
    let dataSemEspaco = value.replace(/\D/g, '');

    if (dataSemEspaco.length > 8) {
      dataSemEspaco = dataSemEspaco.slice(0, 8);
    }

    if (dataSemEspaco.length > 2 && dataSemEspaco.length <= 4) {
      dataSemEspaco = dataSemEspaco.replace(/(\d{2})(\d{1,2})/, '$1/$2');
    } else if (dataSemEspaco.length > 4 && dataSemEspaco.length <= 8) {
      dataSemEspaco = dataSemEspaco.replace(/(\d{2})(\d{2})(\d{1,4})/, '$1/$2/$3');
    }

    return dataSemEspaco;
  }

  onAnoSelecionado(): void {
    // this.dadosFormulario.controls['mesParcela'].setValue(0);
    // if (ano == 2024) {
    //Mostrando apenas Setembro para frente nos meses
    // this.mesesSelect.set(this.meses.filter((_, index) => index > 7));
    // } else {
    // this.mesesSelect.set(this.meses);
    // }
    this.verficacaoReembolsoAnteriorDataNascimentoDependente();
  }
}
