import type { OnDestroy } from '@angular/core';
import { Component, inject } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { EditorModule } from 'primeng/editor';
import { Subject, takeUntil } from 'rxjs';
import { ComunicacaoErroService } from 'src/app/shared/services/comunicacao-erro.service';
import { ToastService } from 'src/app/shared/toast/toast.service';

@Component({
  selector: 'app-comunicar-erro',
  templateUrl: './comunicar-erro.component.html',
  styleUrls: ['./comunicar-erro.component.scss', './comunicar-erro.component.responsive.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MatLabel, MatFormField, MatInput, EditorModule, MatProgressSpinner, MatButton]
})
export class ComunicarErroComponent implements OnDestroy {
  private readonly formBuilder = inject(FormBuilder);
  private readonly comunicacaoErroService = inject(ComunicacaoErroService);
  private readonly toastService = inject(ToastService);

  private readonly destroy$ = new Subject<void>();
  formComunicado: FormGroup;
  enviando = false;

  constructor() {
    this.formComunicado = this.formBuilder.group({
      nome: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      assunto: ['', [Validators.required]],
      mensagem: ['TESTE', [Validators.required]],
      texto: ['', [Validators.required]]
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  enviarComunicacao() {
    if (this.formComunicado.valid) {
      this.enviando = true;
      this.comunicacaoErroService
        .incluir(this.formComunicado.getRawValue())
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this.toastService.success('Enviado com sucesso.');
            this.formComunicado.reset();
            Object.keys(this.formComunicado.controls).forEach((key) => {
              this.formComunicado.get(key).setErrors(null);
            });
          },
          (error) => {
            this.enviando = false;
            this.toastService.error(error.message);
          },
          () => (this.enviando = false)
        );
    } else {
      if (this.formComunicado.controls['texto'].getRawValue() == '') this.toastService.error('Falta preencher o campo Mensagem', 'Aviso do Sistema!');
      if (this.formComunicado.controls['assunto'].getRawValue() == '') this.toastService.error('Falta preencher o campo Assunto', 'Aviso do Sistema!');
      if (this.formComunicado.controls['email'].getRawValue() == '') this.toastService.error('Falta preencher o campo Email', 'Aviso do Sistema!');
      if (this.formComunicado.controls['nome'].getRawValue() == '') this.toastService.error('Falta preencher o campo Nome', 'Aviso do Sistema!');
    }
  }
}
