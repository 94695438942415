import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'formatarBRL',
  standalone: true
})
export class FormatBRLPipe implements PipeTransform {
  transform(value: string | number): string {
    if (value === null || value === undefined) {
      return '';
    }

    // Converte o valor para número, se for uma string válida
    const valorNumerico = typeof value === 'string' ? parseFloat(value) : value;

    if (isNaN(valorNumerico)) {
      return '';
    }

    // Formata o número para o formato de moeda R$ 0,00
    return (
      'R$ ' +
      valorNumerico
        .toFixed(2)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    );
  }
}
