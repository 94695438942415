import { Component } from '@angular/core';
import { SeparadorComponent } from '../../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-perguntas-frequentes',
  templateUrl: './perguntas-frequentes.component.html',
  styleUrl: './perguntas-frequentes.component.scss',
  standalone: true,

  imports: [SeparadorComponent]
})
export class PerguntasFrequentesComponent {}
