<div class="conteudo">
  <br />
  <h1 class="card-title titulo">Analisar Pedido de Auxílio Saúde</h1>
  <br />

  <div class="d-flex flex-row justify-content-center">
    <div class="col-md-12">
      <ngx-file-drop
        browseBtnLabel="Browse files"
        browseBtnClassName="send-files btn btn-secondary m-2 gray-file-drop"
        [dropZoneLabel]="dropZoneLabel()"
        [showBrowseBtn]="true"
        (onFileDrop)="onFileDrop($event)"
      />
    </div>
  </div>
  <div class="d-flex flex-row justify-content-end">
    <div class="col-md-2"></div>
    <div class="col-md-10">
      @if (envioApesp()) {
        <button mat-button class="envio-button btn btn-dark me-2" type="submit" (click)="envioBotaoApesp()">Enviar dados</button>
      }
      @if (!envioApesp() && voltarPagina()) {
        <button mat-button type="button" id="voltartelaanterior" name="voltartelaanterior" class="btn btn-dark me-2" routerLink="/area-restrita/analise-pedidos-auxilio-saude">
          Voltar a tela anterior
        </button>
      }
    </div>
  </div>
  <button mat-button type="submit" (click)="openHelp()"><mat-icon class="icon-ajuda">help</mat-icon></button>
  <table>
    <thead>
      <tr>
        <th class="titulo-checkBox">&nbsp;&nbsp;Processados&nbsp;&nbsp;</th>
        <th>Nome</th>
        <th>CPF</th>
        <th>Mês / Ano</th>
        <th>Valor</th>
      </tr>
    </thead>
    <tbody>
      @for (a of itensPaginados(); track $index) {
        <tr>
          <td class="tabela-juncao">
            <input type="checkbox" disabled [checked]="a.status === 1" />
          </td>
          <td class="tabela-nome">{{ a.nome }}</td>
          <td class="tabela-juncao">{{ a.cpf }}</td>
          <td class="table-juncao">{{ a.mesAno | date: "MMMM/y" }}</td>
          <td class="tabela-total">{{ a.total | currency }}</td>
        </tr>
      }
    </tbody>
  </table>
  <mat-paginator
    showFirstLastButtons
    [pageIndex]="paginaAtual()"
    [length]="tamanhoPaginador()"
    [pageSize]="itensPorPagina"
    [pageSizeOptions]="[5, 10, 20]"
    (page)="mudarPagina($event.pageIndex, $event.pageSize)"
  />
</div>
