import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { WaitLoadingService } from '../../shared/components/wait-loading/wait-loading.service';
import { AuthenticationService } from '../../shared/services/authentication.service';

@Component({
  selector: 'app-logout',
  standalone: true,
  imports: [],
  templateUrl: './logout.component.html',
  styleUrl: './logout.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoutComponent implements OnInit {
  waitingService = inject(WaitLoadingService);
  authenticationService = inject(AuthenticationService);

  ngOnInit(): void {
    this.waitingService.open('Realizando logout do sistema...');
    setTimeout(() => {
      this.authenticationService.logout();
      this.waitingService.close();
    }, 500);
  }
}
