import type { OnDestroy, OnInit } from '@angular/core';
import { Component, inject } from '@angular/core';
import type { PageEvent } from '@angular/material/paginator';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { ImagemTratamento } from 'src/app/area-aberta/features/home/shared/services/imagem-tratamento';
import { CategoriaNoticia } from 'src/app/area-aberta/shared/enums/categoria-noticia';
import type { Noticias } from 'src/app/shared/models/noticia.interface';
import type { Page } from 'src/app/shared/models/paginacao/page.model';
import { NoticiaService } from 'src/app/shared/services/noticia.service';
import { SeparadorComponent } from '../../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-noticias-diversas',
  templateUrl: './noticias-diversas.component.html',
  styleUrl: './noticias-diversas.component.scss',
  providers: [ImagemTratamento],
  standalone: true,
  imports: [SeparadorComponent, MatPaginator]
})
export class NoticiasDiversasComponent implements OnInit, OnDestroy {
  private readonly noticiaService = inject(NoticiaService);
  private readonly router = inject(Router);
  private readonly imagemTratamento = inject(ImagemTratamento);

  objNoticias?: Noticias[] = [];
  currentImageIndex: number = 0;
  imagemMaxWidth: number = 350; // Defina o valor máximo desejado para a largura da imagem

  eventoPagina: PageEvent;
  comprimento = 0;
  tamanhoDaPagina = 10;
  numeroPagina = 0;
  opcoesDoTamanhoDaPagina = [5, 10, 25];

  ocultarTamanhoDaPagina = false;
  mostrarOpcoesDeTamanhoPagina = true;
  mostrarPrimeiroUltimosBotoes = true;
  desabilitado = false;

  ngOnInit(): void {
    this.carregarNoticia();
  }

  carregarNoticia(): void {
    this.noticiaService.getNoticia(this.numeroPagina, this.tamanhoDaPagina, CategoriaNoticia.NOTICIA_AREA_ABERTA).subscribe(
      (response: Page<Noticias>) => {
        this.objNoticias = response.content;
        this.comprimento = response.totalElements;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  lidarEventoPagina(e: PageEvent) {
    this.eventoPagina = e;
    this.comprimento = e.length;
    this.tamanhoDaPagina = e.pageSize;
    this.numeroPagina = e.pageIndex;
    this.carregarNoticia();
  }

  obterImagem(noticiaImagem: string): string | undefined {
    return this.imagemTratamento.obterImagemBlob(noticiaImagem);
  }

  abrirUmaNoticia(noticiaId: number) {
    void this.router.navigate(['/uma-noticia', noticiaId]);
  }

  ngOnDestroy(): void {
    this.imagemTratamento.revogarUrlBlob();
  }

  ajusteTexto(fonte: string) {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = fonte;
    return tmp.textContent || tmp.innerText || '';
  }
}
