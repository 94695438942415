import { ChangeDetectionStrategy, Component, effect, model } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { filtroTipoTexto, type Filtro, type FiltroTipoEnum } from '../filtro';

@Component({
  selector: 'app-filtro-adicionado',
  standalone: true,
  imports: [MatChipsModule, MatIconModule, MatExpansionModule, MatButtonModule],
  templateUrl: './filtro-adicionado.component.html',
  styleUrl: './filtro-adicionado.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FiltroAdicionadoComponent {
  public filtros = model<Filtro[]>();

  constructor() {
    effect(() => {
      console.log('filtros child', this.filtros());
    });
  }

  public textoFiltroTipo(tipo: FiltroTipoEnum): string {
    return filtroTipoTexto[tipo];
  }

  public remover(filtro: Filtro, valor: string): void {
    this.filtros.update((filtros: Filtro[]) => {
      const filtroFind = filtros.find((f) => f.tipo == filtro.tipo);
      if (!filtroFind) {
        return filtros;
      }

      const index = filtroFind.valores.indexOf(valor);
      if (index < 0) {
        return filtros;
      }

      filtroFind.valores.splice(index, 1);
      if (filtroFind.valores.length == 0) {
        const indexFiltro = filtros.indexOf(filtroFind);
        filtros.splice(indexFiltro, 1);
      }
      return [...filtros];
    });
  }

  public limpar(): void {
    this.filtros.update(() => {
      return [];
    });
  }
}
