import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConversorValidadorService {
  public base64ToBlob(base64: string): void {
    if (base64) {
      const binaryData = atob(base64);
      const arrayBuffer = new ArrayBuffer(binaryData.length);
      const byteArray = new Uint8Array(arrayBuffer);

      for (let i = 0; i < binaryData.length; i++) {
        byteArray[i] = binaryData.charCodeAt(i);
      }

      const tipoArquivo = this.getFileType(byteArray);
      const blob = new Blob([byteArray], { type: tipoArquivo });
      const url = URL.createObjectURL(blob);

      window.open(url, '_blank');
    } else {
      console.log('Nenhum arquivo disponível.', 'Erro');
    }
  }

  private getFileType(byteArray: Uint8Array): string {
    const signature = byteArray.slice(0, 4);
    const signatureHex = Array.from(signature)
      .map((byte) => byte.toString(16).padStart(2, '0'))
      .join('')
      .toUpperCase();

    const fileTypeMap = new Map<string, string>([
      ['25504446', 'application/pdf'], // PDF
      ['89504E47', 'image/png'], // PNG
      ['FFD8FFE0', 'image/jpeg'], // JPEG
      ['FFD8FFE1', 'image/jpeg'], // JPEG
      ['FFD8FFE2', 'image/jpeg'], // JPEG
      ['424D', 'image/bmp'], // BMP
      ['504B0304', 'application/zip'] // ZIP (DOCX, XLSX, PPTX etc.)
    ]);

    let fileType = fileTypeMap.get(signatureHex);

    // Para arquivos ZIP, precisamos inspecionar mais para diferenciar entre DOCX, XLSX, PPTX etc.
    if (fileType === 'application/zip') {
      if (this.isDocx(byteArray)) {
        fileType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      } else if (this.isXlsx(byteArray)) {
        fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      } else if (this.isPptx(byteArray)) {
        fileType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
      } else {
        fileType = 'application/zip';
      }
    }

    return fileType ?? 'unknown';
  }

  private isDocx(byteArray: Uint8Array): boolean {
    const subArray = byteArray.slice(30, 50); // Parte onde o Word faz suas marcações
    const subString = new TextDecoder().decode(subArray);
    return subString.includes('word/');
  }

  private isXlsx(byteArray: Uint8Array): boolean {
    const subArray = byteArray.slice(30, 50); // Parte onde o Excel faz suas marcações
    const subString = new TextDecoder().decode(subArray);
    return subString.includes('xl/');
  }

  private isPptx(byteArray: Uint8Array): boolean {
    const subArray = byteArray.slice(30, 50); // Parte onde o PowerPoint faz suas marcações
    const subString = new TextDecoder().decode(subArray);
    return subString.includes('ppt/');
  }

  // Tipos a serem usados no futuro

  // JPEG/JPG: Assinatura: FFD8FF
  // PNG: Assinatura: 89504E47
  // GIF: Assinatura: 47494638
  // TIFF: Assinatura: 49492A00 ou 4D4D002A
  // BMP: Assinatura: 424D
  // ZIP: Assinatura: 504B0304
  // MP3: Assinatura: 494433
  // MP4: Assinatura: 0000002066747970 (início de um arquivo MP4)
  // DOCX: Assinatura: 504B0304 (um arquivo ZIP que contém um arquivo DOCX tem essa assinatura)
  // XLSX: Assinatura: 504B0304 (um arquivo ZIP que contém um arquivo XLSX tem essa assinatura)
  // PPTX: Assinatura: 504B0304 (um arquivo ZIP que contém um arquivo PPTX tem essa assinatura)
  // PDF: Assinatura: 25504446

  // JPEG/JPG: image/jpeg
  // PNG: image/png
  // GIF: image/gif
  // TIFF: image/tiff
  // BMP: image/bmp
  // ZIP: application/zip
  // MP3: audio/mpeg
  // MP4: video/mp4
  // DOCX: application/vnd.openxmlformats-officedocument.wordprocessingml.document
  // XLSX: application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
  // PPTX: application/vnd.openxmlformats-officedocument.presentationml.presentation
  // PDF: application/pdf

  public validarCPF(cpf: string | undefined): boolean {
    // Trata o caso em que cpf é undefined, atribuindo um array vazio
    const cpfArray: number[] = cpf ? cpf.split('').map(Number) : [];

    // Verifique se todos os dígitos são iguais
    if (cpfArray.every((digito) => digito === cpfArray[0])) {
      return true;
    }

    // Calcula os dígitos verificadores
    let soma = 0;

    for (let i = 1; i <= 9; i++) {
      soma += cpfArray[i - 1] * (11 - i);
    }

    let resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
      resto = 0;
    }

    if (resto !== cpfArray[9]) {
      return true;
    }

    soma = 0;

    for (let i = 1; i <= 10; i++) {
      soma += cpfArray[i - 1] * (12 - i);
    }

    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) {
      resto = 0;
    }

    if (resto !== cpfArray[10]) {
      return true;
    }

    return false;
  }
}
