import { Component } from '@angular/core';
import { SeparadorComponent } from '../../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-legislacao-conselho',
  templateUrl: './legislacao-conselho.component.html',
  standalone: true,

  imports: [SeparadorComponent]
})
export class LegislacaoConselhoComponent {}
