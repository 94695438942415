import { Injectable, inject } from '@angular/core';
import type { SafeHtml } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, takeUntil } from 'rxjs';
import { DynamicTableService } from 'src/app/area-restrita/shared/services/dynamic-table.service';
import { ReportService } from 'src/app/area-restrita/shared/services/report.service';
import { AuxilioSaudeService } from '../services/auxilio-saude.service';
import type { Dependente } from './dependente';
import type { RelatorioDependenteResponse } from './relatorio';

@Injectable({
  providedIn: 'root'
})
export class FabricaRelatorioDependente {
  private readonly auxilioSaudeService = inject(AuxilioSaudeService);
  private readonly reportService = inject(ReportService);
  private readonly sanitizer = inject(DomSanitizer);
  private readonly dynamicTableService = inject(DynamicTableService);

  private readonly destroy$ = new Subject<void>();
  private titulo: string;
  private rows: string[][];
  private tipo: string;
  private tituloColunas: string[];
  private totalElements: number;
  private pageSize: number;
  private pageNumber: number;

  async criarRelatorio(
    nomeTitular: string,
    cpfTitular: string,
    nomeDependente: string,
    cpfDependente: string,
    parentesco: string,
    pageNumber: number,
    pageSize: number
  ) {
    const dadosRelatorio = await this.obterDadosRelatorio(nomeTitular, cpfTitular, nomeDependente, cpfDependente, parentesco, pageNumber, pageSize);
    this.titulo = 'Relatório de Dependentes';
    this.totalElements = dadosRelatorio.totalElements;
    this.pageSize = dadosRelatorio.size;
    this.pageNumber = dadosRelatorio.number;
    this.rows = this.carregarLinhas(dadosRelatorio.content);
    this.tipo = 'pdf/csv';
    this.tituloColunas = ['Nome do titular', 'CPF do titular', 'Nome do dependente', 'CPF do dependente', 'Data de nascimento', 'Parentesco'];
  }

  private async obterDadosRelatorio(
    nomeTitular: string,
    cpfTitular: string,
    nomeDependente: string,
    cpfDependente: string,
    parentesco: string,
    pageNumber: number,
    pageSize: number
  ) {
    return new Promise<RelatorioDependenteResponse>((resolve, reject) => {
      this.auxilioSaudeService
        .RelatorioPesquisaDependentes(nomeTitular, cpfTitular, nomeDependente, cpfDependente, parentesco, pageNumber, pageSize)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (dados) => {
            resolve(dados);
          },
          error: (error) => {
            reject(new Error('Erro ao obter dados do RelatorioPesquisaDependentes', error));
          }
        });
    });
  }

  private carregarLinhas(dados: Dependente[]) {
    const rows: string[][] = [];

    for (let i = 0; i < dados.length; i++) {
      const row = [];
      if (i == 0) {
        row.push(dados[i].usuario.nome);
        row.push(this.reportService.formatCPF(dados[i].usuario.cpf));
      } else if (i != 0 && dados[i].usuario.nome != dados[i - 1].usuario.nome) {
        row.push(dados[i].usuario.nome);
        row.push(this.reportService.formatCPF(dados[i].usuario.cpf));
      } else {
        row.push('');
        row.push('');
      }
      row.push(dados[i].nome);
      row.push(this.reportService.formatCPF(dados[i].cpfDependente));
      row.push(dados[i].dataNascimento);
      row.push(dados[i].descricaoDependente);
      rows.push(row);
    }
    return rows;
  }

  public getTabela(): SafeHtml {
    const resultH = this.dynamicTableService.createSearchHTML(this.tituloColunas, this.rows);
    return this.sanitizer.bypassSecurityTrustHtml(resultH);
  }

  public getRows(): string[][] {
    return this.rows;
  }

  public getTipo(): string {
    return this.tipo;
  }

  public getTitulo(): string {
    return this.titulo;
  }

  public getColunas(): string[] {
    return this.tituloColunas;
  }

  public getTotalElementos(): number {
    return this.totalElements;
  }

  public getPageNumber(): number {
    return this.pageNumber;
  }

  public getPageSize(): number {
    return this.pageSize;
  }
}
