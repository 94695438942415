import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import type { PageEvent } from '@angular/material/paginator';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { ImagemTratamento } from 'src/app/area-aberta/features/home/shared/services/imagem-tratamento';
import { CategoriaNoticia } from 'src/app/area-aberta/shared/enums/categoria-noticia';
import type { Noticias } from 'src/app/shared/models/noticia.interface';
import type { Page } from 'src/app/shared/models/paginacao/page.model';
import { NoticiaService } from 'src/app/shared/services/noticia.service';
import { SeparadorComponent } from '../../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-noticias-diversas',
  templateUrl: './noticias-diversas.component.html',
  styleUrl: './noticias-diversas.component.scss',
  providers: [ImagemTratamento],
  standalone: true,
  imports: [SeparadorComponent, MatPaginator],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoticiasDiversasComponent implements OnInit, OnDestroy {
  private readonly noticiaService = inject(NoticiaService);
  private readonly router = inject(Router);
  private readonly imagemTratamento = inject(ImagemTratamento);

  objNoticias = signal<Noticias[]>([]);

  comprimento = signal(0);
  tamanhoDaPagina = signal(10);
  numeroPagina = signal(0);
  readonly opcoesDoTamanhoDaPagina = [5, 10, 25];

  ngOnInit(): void {
    this.carregarNoticia();
  }

  carregarNoticia(): void {
    this.noticiaService.getNoticia(this.numeroPagina(), this.tamanhoDaPagina(), CategoriaNoticia.NOTICIA_AREA_ABERTA).subscribe({
      next: (response: Page<Noticias>) => {
        this.objNoticias.set(response.content);
        this.comprimento.set(response.totalElements);
      },
      error: console.error
    });
  }

  lidarEventoPagina(e: PageEvent): void {
    this.comprimento.set(e.length);
    this.tamanhoDaPagina.set(e.pageSize);
    this.numeroPagina.set(e.pageIndex);
    this.carregarNoticia();
  }

  obterImagem(noticiaImagem?: string): string | undefined {
    return this.imagemTratamento.obterImagemBlob(noticiaImagem);
  }

  abrirUmaNoticia(noticiaId: number): void {
    void this.router.navigate(['/uma-noticia', noticiaId]);
  }

  ngOnDestroy(): void {
    this.imagemTratamento.revogarUrlBlob();
  }

  ajusteTexto(fonte: string): string {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = fonte;
    return tmp.textContent ?? tmp.innerText;
  }
}
