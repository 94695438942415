import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { environment } from 'src/environments/environment';
import { SeparadorComponent } from '../../core/layout/separador/separador.component';
import { AtendimentoOuvidoriaComponent } from '../institucional/atendimento-ouvidoria/atendimento-ouvidoria.component';
import { ApresentacaoComponent } from '../institucional/conselho/apresentacao/apresentacao.component';
import { AtribuicoesComponent } from '../institucional/conselho/atribuicoes/atribuicoes.component';
import { ComposicaoConselhoComponent } from '../institucional/conselho/composicao-conselho/composicao-conselho.component';
import { LegislacaoConselhoComponent } from '../institucional/conselho/legislacao-conselho/legislacao-conselho.component';
import { AutarquiasComponent } from '../institucional/consultoria-geral/autarquias/autarquias.component';
import { ConsultoriasComponent } from '../institucional/consultoria-geral/consultorias/consultorias.component';
import { EspecializadasConsultoriaGeralComponent } from '../institucional/consultoria-geral/especializadas-consultoria-geral/especializadas-consultoria-geral.component';
import { SubprocuradoriaConsultoriaGeralComponent } from '../institucional/consultoria-geral/subprocuradoria-consultoria-geral/subprocuradoria-consultoria-geral.component';
import { EspecializadasContenciosoGeralComponent } from '../institucional/contencioso-geral/especializadas-contencioso-geral/especializadas-contencioso-geral.component';
import { RegionaisContenciosoGeralComponent } from '../institucional/contencioso-geral/regionais-contencioso-geral/regionais-contencioso-geral.component';
import { SubprocuradoriaContenciosoGeralComponent } from '../institucional/contencioso-geral/subprocuradoria-contencioso-geral/subprocuradoria-contencioso-geral.component';
import { ApresentacaoComposicaoComponent } from '../institucional/corregedoria/apresentacao-composicao/apresentacao-composicao.component';
import { ExCorregedoresGeraisPgeComponent } from '../institucional/corregedoria/ex-corregedores-gerais-pge/ex-corregedores-gerais-pge.component';
import { LegislacaoCorregedoriaComponent } from '../institucional/corregedoria/legislacao-corregedoria/legislacao-corregedoria.component';
import { ExOuvidoriaComponent } from '../institucional/ex-ouvidoria/ex-ouvidoria.component';
import { ExProcuradoresGeraisComponent } from '../institucional/ex-procuradores-gerais/ex-procuradores-gerais.component';
import { AssessoriasComponent } from '../institucional/gabinete/assessorias/assessorias.component';
import { ComposicaoComponent } from '../institucional/gabinete/composicao/composicao.component';
import { HistoricoComponent } from '../institucional/historico/historico.component';
import { InformOuvidriaComponent } from '../institucional/inform-ouvidoria/inform-ouvidoria.component';
import { ProgramaIntegridadeOuvidoriaComponent } from '../institucional/programa-integridade-ouvidoria/programa-integridade-ouvidoria.component';
import { QuemSomosComponent } from '../institucional/quem-somos/quem-somos.component';
import { RelatorioOuvidoriaComponent } from '../institucional/relatorio-ouvidoria/relatorio-ouvidoria.component';
import { SicOuvidoriaComponent } from '../institucional/sic-ouvidoria/sic-ouvidoria.component';
import { SobreOuvidoriaComponent } from '../institucional/sobre-ouvidoria/sobre-ouvidoria.component';
import { EspecializadasTributarioFiscalComponent } from '../institucional/tributario-fiscal/especializadas-tributario-fiscal/especializadas-tributario-fiscal.component';
import { RegionaisTributarioFiscalComponent } from '../institucional/tributario-fiscal/regionais-tributario-fiscal/regionais-tributario-fiscal.component';
import { SubprocuradoriaTributarioFiscalComponent } from '../institucional/tributario-fiscal/subprocuradoria-tributario-fiscal/subprocuradoria-tributario-fiscal.component';
import { ConsultaSancoesComponent } from '../ouvidoria/consulta-sancoes/consulta-sancoes.component';

@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.component.html',
  styleUrl: './submenu.component.scss',
  standalone: true,

  imports: [
    LegislacaoConselhoComponent,
    ComposicaoConselhoComponent,
    RegionaisTributarioFiscalComponent,
    EspecializadasTributarioFiscalComponent,
    SubprocuradoriaConsultoriaGeralComponent,
    SubprocuradoriaContenciosoGeralComponent,
    SubprocuradoriaTributarioFiscalComponent,
    SeparadorComponent,
    MatIcon,
    QuemSomosComponent,
    ComposicaoComponent,
    AssessoriasComponent,
    HistoricoComponent,
    ExProcuradoresGeraisComponent,
    EspecializadasConsultoriaGeralComponent,
    ConsultoriasComponent,
    AutarquiasComponent,
    EspecializadasContenciosoGeralComponent,
    RegionaisContenciosoGeralComponent,
    ApresentacaoComposicaoComponent,
    ExCorregedoresGeraisPgeComponent,
    LegislacaoCorregedoriaComponent,
    ApresentacaoComponent,
    AtribuicoesComponent,
    SobreOuvidoriaComponent,
    AtendimentoOuvidoriaComponent,
    ExOuvidoriaComponent,
    RelatorioOuvidoriaComponent,
    InformOuvidriaComponent,
    ProgramaIntegridadeOuvidoriaComponent,
    SicOuvidoriaComponent,
    RouterLink,
    ConsultaSancoesComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubmenuComponent implements OnInit {
  private readonly route = inject(ActivatedRoute);

  opcao = signal('');
  ambiente: string = '';

  constructor() {
    this.ambiente = environment.env;
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      console.log('params route', params);

      this.opcao.set(params['opcao']);
    });
  }

  institucional = [
    {
      titulo: 'Sobre a PGE',
      aberto: false
    },
    {
      titulo: 'Gabinete',
      aberto: false,
      children: [
        {
          titulo: 'Composição',
          aberto: false
        },
        {
          titulo: 'Assessorias',
          aberto: false
        }
      ]
    },
    {
      titulo: 'Histórico',
      aberto: false
    },
    {
      titulo: 'Ex-Procuradores Gerais',
      aberto: false
    },
    {
      titulo: 'Organograma',
      aberto: false
    },
    {
      titulo: 'Lei Orgânica',
      aberto: false
    },
    {
      titulo: 'Legislação',
      aberto: false
    }
  ];

  areaAtuacao = [
    {
      titulo: 'Consultoria Geral',
      aberto: false,
      children: [
        {
          titulo: 'Subprocuradoria',
          aberto: false
        },
        {
          titulo: 'Especializadas',
          aberto: false
        },
        {
          titulo: 'Consultorias',
          aberto: false
        },
        {
          titulo: 'Autarquias',
          aberto: false
        }
      ]
    },
    {
      titulo: 'Contencioso Geral',
      aberto: false,
      children: [
        {
          titulo: 'Subprocuradoria',
          aberto: false
        },
        {
          titulo: 'Unidades Especializadas',
          aberto: false
        },
        {
          titulo: 'Regionais',
          aberto: false
        }
      ]
    },
    {
      titulo: 'Contencioso Tributário Fiscal',
      aberto: false,
      children: [
        {
          titulo: 'Subprocuradoria',
          aberto: false
        },
        {
          titulo: 'Especializadas',
          aberto: false
        },
        {
          titulo: 'Regionais',
          aberto: false
        }
      ]
    }
  ];

  corregedoria = [
    {
      titulo: 'Apresentação e Composição',
      aberto: false
    },
    {
      titulo: 'Ex-Corregedores Gerais',
      aberto: false
    },
    {
      titulo: 'Legislação',
      aberto: false
    }
  ];

  conselho = [
    {
      titulo: 'Apresentação',
      aberto: false
    },
    {
      titulo: 'Composição',
      aberto: false
    },
    {
      titulo: 'Atribuições',
      aberto: false
    },
    {
      titulo: 'Legislação',
      aberto: false
    }
  ];

  ouvidoria = [
    {
      titulo: 'Sobre a Ouvidoria',
      aberto: false
    },
    {
      titulo: 'Atendimento',
      aberto: false
    },
    {
      titulo: 'Ex-Ouvidores',
      aberto: false
    },
    {
      titulo: 'Relatórios',
      aberto: false
    },
    {
      titulo: 'Informações classificadas e desclassificadas',
      aberto: false
    },
    {
      titulo: 'Programa de Integridade/PGE',
      aberto: false
    },
    {
      titulo: 'SIC',
      aberto: false
    },
    {
      titulo: 'Consulta de Sanções',
      aberto: false
    }
  ];

  alternar(indice: number, subIndice: number | null = null): void {
    const opc = this.opcao();

    switch (opc) {
      case 'INSTITUCIONAL': {
        if (subIndice !== null) {
          const item = this.institucional[indice]?.children?.[subIndice];
          if (item != undefined) {
            item.aberto = !item.aberto;

            this.institucional[indice]?.children?.forEach((child, i) => {
              if (i !== subIndice) child.aberto = false;
            });
          }
        } else {
          this.institucional[indice].aberto = !this.institucional[indice].aberto;
          this.institucional.forEach((item, i) => {
            if (i !== indice) item.aberto = false;
          });
        }

        break;
      }
      case 'AREASATUACAO': {
        if (subIndice !== null) {
          const selectedItem = this.areaAtuacao[indice];
          selectedItem.children.forEach((child, i) => {
            child.aberto = i === subIndice ? !child.aberto : false;
          });
          selectedItem.aberto = true;
        } else {
          this.areaAtuacao.forEach((item, i) => {
            item.aberto = i === indice ? !item.aberto : false;

            if (item.aberto) {
              item.children.forEach((child) => (child.aberto = false));
            }
          });
        }
        break;
      }
      case 'CORREGEDORIA': {
        this.corregedoria[indice].aberto = !this.corregedoria[indice].aberto;
        this.corregedoria.forEach((item, i) => {
          if (i !== indice) item.aberto = false;
        });
        break;
      }
      case 'CONSELHO': {
        this.conselho[indice].aberto = !this.conselho[indice].aberto;
        this.conselho.forEach((item, i) => {
          if (i !== indice) item.aberto = false;
        });
        break;
      }
      case 'OUVIDORIA': {
        this.ouvidoria[indice].aberto = !this.ouvidoria[indice].aberto;
        this.ouvidoria.forEach((item, i) => {
          if (i !== indice) item.aberto = false;
        });
        break;
      }
    }
  }
}
