<main class="main" role="main">
  <section class="page">
    <div class="container-noticia-aberta">
      <div class="container-noticia">
        <h1 class="title-noticia">{{ noticia?.titulo }}</h1>
        <p class="data">
          {{ noticia?.dataPublicacao | date: "dd/MM/yyyy" : "pt-BR" }}
        </p>
        @if (noticia?.imagem) {
          <img alt="Imagem da Notícia" class="imagem-noticia" [src]="getImagem(noticia?.imagem)" />
        }
        <div class="corpo-noticia" [innerHTML]="processarTexto(noticia?.texto)"></div>
        <div class="arquivo">
          <table>
            <tbody>
              @for (item of arquivos; track item; let i = $index) {
                <tr>
                  <td>
                    <a href="#" (click)="openPDF($event, item)"><mat-icon fontIcon="cloud_download" />{{ item.nome }}</a>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
        <div class="align-button">
          <a href="/">
            <mat-icon class="home-icon">home</mat-icon>
          </a>
        </div>
        <br />
      </div>
      <div class="mais-noticias">
        <app-separador titulo="Outras Notícias" />
        <div class="container-cards">
          @for (item of objNoticias; track item; let i = $index) {
            <div class="card-noticia" tabindex="0" (click)="abrirUmaNoticia(item?.id)" (keypress)="abrirUmaNoticia(item?.id)">
              @if (item?.imagem) {
                <div class="news-card-image">
                  <img alt="Imagem da Notícia" tabindex="0" [src]="getImagem(item?.imagem)" (click)="abrirUmaNoticia(item?.id)" (keypress)="abrirUmaNoticia(item?.id)" />
                </div>
              }
              <div class="titulo">
                {{ item?.titulo }}
              </div>
            </div>
          }
        </div>
        <br />
        <div>
          <button mat-flat-button class="botao-voltar" type="submit" (click)="retornaListaNoticias()"><mat-icon class="add-icon">add</mat-icon> NOTÍCIAS</button>
        </div>
      </div>
    </div>
  </section>
</main>
