<div class="background-container">
  <div class="content">
    <div class="row-spacer">
      <div class="col">
        <table class="table-brasao">
          <tr>
            <div class="back-logo-brasao"></div>
          </tr>
          <tr>
            <p class="fonte-titulo-brasao">Procuradoria Geral do</p>
          </tr>
          <tr>
            <p class="fonte-titulo-brasao">Estado de São Paulo</p>
          </tr>
        </table>
      </div>
      <div class="col">
        <div class="card-right">
          <ng-content />
        </div>
      </div>
    </div>
  </div>
</div>
