import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DynamicTableService {
  public createSearchHTML(headers: string[], rows: (string | undefined)[][]): string {
    let html = `<div class="conteudo-dinamico"> <table><tr>`;
    headers.forEach((header) => {
      html += `<th style="text-align: center;">${header}</th>`;
    });
    html += '</tr>';
    rows.forEach((row, rowIndex) => {
      html += '<tr>';
      row.forEach((cell, columnIndex) => {
        const isEmpty = cell?.trim() === '';
        let rowSpanCount = 1;
        if (!isEmpty) {
          for (let nextRow = rowIndex + 1; nextRow < rows.length; nextRow++) {
            if (rows[nextRow][columnIndex]?.trim() === '' && columnIndex < 2) {
              rowSpanCount++;
              rows[nextRow][columnIndex] = '__ROWSPANED__';
            } else {
              break;
            }
          }
        }
        if (cell === '__ROWSPANED__') return;

        if (rowSpanCount > 1) {
          html += `<td style="text-align: center;" rowspan="${rowSpanCount}">${cell}</td>`;
        } else {
          html += `<td style="text-align: center;">${cell}</td>`;
        }
      });
      html += '</tr>';
    });
    html += '</table></div>';
    return html;
  }
}
