import type { OnInit } from '@angular/core';
import { Component, inject } from '@angular/core';
import { WebServiceInfosService } from 'src/app/shared/services/infos-webservice.service';
import { SeparadorComponent } from '../../../../core/layout/separador/separador.component';
import { VoltarComponentComponent } from '../../../../core/layout/voltar-component/voltar-component.component';

@Component({
  selector: 'app-pr11',
  templateUrl: './pr11.component.html',
  standalone: true,

  imports: [SeparadorComponent, VoltarComponentComponent]
})
export class PR11Component implements OnInit {
  private readonly service = inject(WebServiceInfosService);

  informacoes: string[] = [];
  codigoUnidades: string[] = ['40251100000090'];

  ngOnInit(): void {
    this.carregarConteudo();
  }

  carregarConteudo() {
    const elementoVetor: string[] = [];
    this.codigoUnidades.forEach((codigo) => {
      this.service.getInfosPorCodigo(codigo).subscribe(
        (response) => {
          const parser = new DOMParser();
          const xml = parser.parseFromString(response, 'text/xml');
          xml.querySelectorAll('NOME_PROCURADOR, NOME_SUBSTITUTO, NOME_SUBSTITUTO2').forEach((element) => {
            if (element.textContent !== '') {
              elementoVetor.push(element.textContent.trim());
            }
          });
        },
        (error) => {
          console.log(error);
        }
      );
    });

    this.informacoes = elementoVetor;
  }

  encontrarNome(nome: string): string {
    const result = this.informacoes.find((element) => element === nome);
    return result ? result : 'Nome não encontrado';
  }
}
