<br />
<h1 class="card-title titulo">Relatório de Auxílio Saúde</h1>
<br />
<br />
<form [formGroup]="formulario">
  <div class="d-flex row div-split">
    <div class="col-md-4">
      <label for="tipoRelatorio" class="form-label">Tipo de Relatório:</label>
      <mat-form-field class="mat-full-width">
        <mat-select class="mat-form-control" formControlName="tipoRelatorio">
          @for (s of relatorioOpcoes; track s) {
            <mat-option [value]="s.id">
              {{ s.nome }}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>
    @if (formulario.controls["tipoRelatorio"].value === 3) {
      <div class="col-md-4">
        <label for="tipoRelatorio" class="form-label">Tipo de Beneficiários:</label>
        <mat-form-field class="mat-full-width">
          <mat-select class="mat-form-control" placeholder="Selecione um Beneficiários" formControlName="tipoBeneficiario">
            @for (s of tipoBeneficiariosOpcoes; track s) {
              <mat-option [value]="s.nome">
                {{ s.nome }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    }
    @if (formulario.get("tipoRelatorio")?.value === 1 || formulario.get("tipoRelatorio")?.value === 3) {
      <div class="col-md-4">
        <label for="tipoAuxilioSaude" class="form-label" id="tipoAuxilioSaude">Tipo Auxílio Saúde:</label>
        <mat-form-field class="mat-full-width">
          <mat-select id="tipoAuxilioSaude" class="mat-form-control" placeholder="Selecione um Auxílio" formControlName="tipoAuxilioSaude">
            @for (s of tiposAuxilioSaude(); track s.id) {
              <mat-option [value]="s.id">
                {{ s.tipo }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    }
    @if (formulario.get("tipoRelatorio")?.value === 2) {
      <div class="col-md-4">
        <label for="tipoRelatorio" class="form-label">Tipo de Cargo:</label>
        <mat-form-field class="mat-full-width">
          <mat-select class="mat-form-control" placeholder="Selecione um Cargo" formControlName="tipoCargo">
            @for (s of perfil; track s) {
              <mat-option [value]="s.id">
                {{ s.nome }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    }
  </div>

  <div class="d-flex row div-split">
    <div class="col-md-4">
      <label for="nomeTitular" class="form-label">Nome do titular:</label>
      <mat-form-field class="mat-full-width">
        <input matInput type="text" id="nomeTitular" name="nomeTitular" placeholder="Digite o nome do Titular" formControlName="nomeTitular" />
      </mat-form-field>
    </div>
    <div class="col-md-4">
      <label for="cpfTitular" class="form-label">CPF do titular:</label>
      <mat-form-field class="mat-full-width">
        <input matInput type="text" mask="000.000.000-00" id="cpfTitular" name="cpfTitular" placeholder="Digite o CPF do Titular" formControlName="cpfTitular" />
      </mat-form-field>
    </div>
    @if (formulario.controls["tipoRelatorio"].value === 3) {
      <div class="col-md-4">
        <label for="status" class="form-label" id="status">Status:</label>
        <mat-form-field class="mat-full-width">
          <mat-select class="mat-form-control" placeholder="Selecione um Status" formControlName="status">
            <mat-option value="">Todos</mat-option>
            @for (s of this.statusAuxilio(); track s.id) {
              <mat-option [value]="s.id">
                {{ s.nome }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    }
  </div>

  @if (formulario.get("tipoRelatorio")?.value === 1) {
    <div class="d-flex row div-split">
      <div class="col-md-4">
        <label for="nomeDependente" class="form-label">Nome do Dependente:</label>
        <mat-form-field class="mat-full-width">
          <input matInput type="text" id="nomeDependente" name="nomeDependente" placeholder="Digite o nome do Dependente" formControlName="nomeDependente" />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <label for="cpfDependente" class="form-label">CPF do Dependente:</label>
        <mat-form-field class="mat-full-width">
          <input matInput type="text" mask="000.000.000-00" id="cpfDependente" name="cpfDependente" placeholder="Digite o CPF do Dependente" formControlName="cpfDependente" />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <label for="parentesco" class="form-label">Parentesco:</label>
        <mat-form-field class="mat-full-width">
          <mat-select class="mat-form-control" placeholder="Selecione um Parentesco" formControlName="parentesco">
            @for (s of parentesco; track s; let i = $index) {
              <mat-option [value]="i">
                {{ s.nome }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  }
  <div class="d-flex row div-split">
    @if (formulario.get("tipoRelatorio")?.value === 3) {
      <div class="col-md-2">
        <label for="valorReembolso" class="form-label">Valor Total do Reembolso:</label>
        <mat-form-field class="mat-full-width">
          <input
            matInput
            id="valorReembolso"
            name="valorReembolso"
            formControlName="valorReembolso"
            currencyMask
            autocomplete="off"
            tabindex="0"
            maxlength="8"
            [options]="{
              align: 'left',
              allowNegative: false,
              allowZero: false,
              decimal: ',',
              precision: 2,
              prefix: 'R$ ',
              suffix: '',
              thousands: '.',
              nullable: true,
              min: 0
            }"
          />
        </mat-form-field>
      </div>
    }

    @if (formulario.get("tipoRelatorio")?.value === 2 || formulario.get("tipoRelatorio")?.value === 3) {
      <div class="col-md-2">
        <label for="dataInscricao" class="form-label">Data de Inscrição:</label>
        <mat-form-field class="mat-full-width">
          <input matInput formControlName="dataInscricao" [matDatepicker]="dataInscricao" />
          <mat-datepicker-toggle matIconSuffix [for]="dataInscricao" />
          <mat-datepicker #dataInscricao />
        </mat-form-field>
      </div>
    }

    @if (formulario.get("tipoRelatorio")?.value === 3) {
      <div class="col-md-2">
        <label for="dataAprovacao" class="form-label">Data de Aprovação:</label>
        <mat-form-field class="mat-full-width">
          <input matInput formControlName="dataAprovacao" [matDatepicker]="dataAprovacao" />
          <mat-datepicker-toggle matIconSuffix [for]="dataAprovacao" />
          <mat-datepicker #dataAprovacao />
        </mat-form-field>
      </div>
    }
  </div>
  <br />
  <div class="div-split">
    <div class="d-flex row div-split">
      <div class="botao-enviar-formulario">
        <div>
          <button mat-button class="btn-dark report-button ajusteEspaco" type="submit" (click)="obterPdfCsv('pdf')">
            &nbsp; Exportar para PDF
            <span class="material-icons">picture_as_pdf</span>
          </button>
          <button mat-button class="btn-dark report-button ajusteEspaco" type="submit" (click)="obterPdfCsv('xlsx')">&nbsp; Exportar para Excel <span class="material-icons">grid_on</span></button>
        </div>
        <div>
          <button mat-button type="submit" id="buscar" name="buscar" class="btn-dark botaoBuscarLimpar" (click)="executarPesquisa()">Buscar</button>
          <button mat-button type="button" id="limpaForm" class="botaoBuscarLimpar" name="limpaForm" (click)="limpar()">Limpar filtros</button>
        </div>
      </div>
    </div>
  </div>
  <br />
</form>
<br />
@if (gerando()) {
  <div class="div-split">
    <div class="row grid-for resultsSearch">
      <div id="table-atual" [innerHtml]="resultadoPesquisa()"></div>
    </div>
    <div class="d-flex justify-content-center">
      <mat-paginator
        #paginator
        class="custom-paginator"
        aria-label="Select page"
        showFirstLastButtons
        [length]="comprimento()"
        [pageSize]="tamanhoDaPagina()"
        [pageSizeOptions]="opcoesDoTamanhoDaPagina"
        [pageIndex]="numeroPagina()"
        (page)="lidarEventoPagina($event)"
      />
    </div>
  </div>
}
