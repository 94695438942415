import { ChangeDetectionStrategy, Component, inject, output } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import type { InformaDialog } from './informa-dialog';
@Component({
  selector: 'app-informa-dialog',
  templateUrl: './informa-dialog.component.html',
  styleUrl: './informa-dialog.component.scss',
  standalone: true,
  imports: [MatIconButton, MatIcon],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InformaDialogComponent {
  dialogRef = inject<MatDialogRef<InformaDialogComponent>>(MatDialogRef);
  data = inject<InformaDialog>(MAT_DIALOG_DATA);

  readonly fechar = output<string>();

  onClose(): void {
    this.fechar.emit('');
    this.dialogRef.close();
  }
}
