import type { HttpErrorResponse } from '@angular/common/http';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import type { Observable } from 'rxjs';
import { catchError, map, throwError } from 'rxjs';
import type { Dependente } from 'src/app/area-restrita/features/auxilio/saude/shared/models/dependente';
import { Globais } from 'src/app/area-restrita/shared/globais/globais';
import type { Page } from 'src/app/shared/models/paginacao/page.model';
import type { FormAvaliacao, FormInscricaoAuxilioSaude, InscricaoAuxilioSaude } from '../models/auxilio-saude';
@Injectable({
  providedIn: 'root'
})
export class InscricaoAuxilioSaudeService {
  private readonly http = inject(HttpClient);

  public obterInscricao(): Observable<InscricaoAuxilioSaude> {
    const idUsuario = localStorage.getItem('idUsuario');
    return this.http.get<InscricaoAuxilioSaude>(`${Globais.urlToken}site-pge/api/inscricoes-auxilio-saude?idUsuario=${idUsuario}`).pipe(
      map((req) => req),
      catchError((error: HttpErrorResponse) => {
        console.error('Erro durante o envio de dados:', error);
        return throwError(() => 'Falha no envio de dados.');
      })
    );
  }

  public obterListaStatusInscricaoAuxilio(): Observable<Dependente[]> {
    return this.http.get<Dependente[]>(`${Globais.urlToken}site-pge/api/status-inscricao-auxilio`).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Erro durante a obtenção da lista de status:', error);
        return throwError(() => 'Falha na obtenção da lista de status.');
      })
    );
  }

  public buscarPedidos(
    dadosFormulario: FormGroup<FormInscricaoAuxilioSaude>,
    pageIndex: string | number,
    pageSize: string | number
  ): Observable<Page<InscricaoAuxilioSaude>> {
    const dataEnvio = dadosFormulario.controls.dataEnvio.value?.split('-');

    const parametros = new HttpParams()
      .set('nomeUsuario', dadosFormulario.controls.procuradorServidor.value ?? '')
      .set('idStatus', dadosFormulario.controls.status.value ?? '')
      .set('idPerfil', dadosFormulario.controls.perfil.value ?? '')
      .set('dataDeferimento', dadosFormulario.controls.dataDeferimento.value ?? '')
      .set('mesEnvio', dataEnvio?.[1] ?? '')
      .set('anoEnvio', dataEnvio?.[0] ?? '')
      .set('page', pageIndex)
      .set('size', pageSize);

    return this.http.get<Page<InscricaoAuxilioSaude>>(`${Globais.urlToken}site-pge/api/inscricoes-auxilio-saude/listarPedidos`, { params: parametros }).pipe(
      map((response) => {
        return response;
      })
    );
  }

  public deferirPedidos(idsAuxilios: Set<number>): Observable<string> {
    const idUsuario = Number(localStorage.getItem('idUsuario'));
    const parametros = new HttpParams().set('idUsuario', idUsuario.toString());

    return this.http
      .put(`${Globais.urlToken}site-pge/api/inscricoes-auxilio-saude/deferir`, Array.from(idsAuxilios), {
        params: parametros,
        responseType: 'text'
      })
      .pipe(
        catchError(() => {
          return throwError(() => new Error('Ocorreu um erro ao deferir os pedidos. Por favor, tente novamente mais tarde.'));
        })
      );
  }

  public indeferirPedidos(idsAuxilios: Set<number>): Observable<string> {
    const idUsuario = Number(localStorage.getItem('idUsuario'));
    const parametros = new HttpParams().set('idUsuario', idUsuario.toString());

    return this.http
      .put(`${Globais.urlToken}site-pge/api/inscricoes-auxilio-saude/indeferir`, Array.from(idsAuxilios), {
        params: parametros,
        responseType: 'text'
      })
      .pipe(
        catchError(() => {
          return throwError(() => new Error('Ocorreu um erro ao deferir os pedidos. Por favor, tente novamente mais tarde.'));
        })
      );
  }

  public deferirIndeferirPedido(dadosAvaliacao: FormAvaliacao): Observable<string> {
    const idUsuario = Number(localStorage.getItem('idUsuario'));
    const parametros = new HttpParams()
      .set('idUsuarioAvaliador', idUsuario.toString())
      .set('idUsuario', dadosAvaliacao.idUsuario?.toString() ?? '')
      .set('idFicha', dadosAvaliacao.idFicha.toString())
      .set('justificativa', dadosAvaliacao.justificativa ? dadosAvaliacao.justificativa.toString() : '')
      .set('status', dadosAvaliacao.idStatus == 2 ? 'A' : 'I');

    return this.http
      .put(`${Globais.urlToken}site-pge/api/inscricoes-auxilio-saude/deferir-indeferir-individual`, {}, { params: parametros, responseType: 'text' })
      .pipe(
        catchError(() => {
          return throwError(() => new Error('Ocorreu um erro ao deferir os pedidos. Por favor, tente novamente mais tarde.'));
        })
      );
  }
}
