import { DatePipe } from '@angular/common';
import type { OnDestroy, OnInit } from '@angular/core';
import { Component, inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import type { ParamMap } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';
import { ImagemTratamento } from 'src/app/area-aberta/features/home/shared/services/imagem-tratamento';
import { CategoriaNoticia } from 'src/app/area-aberta/shared/enums/categoria-noticia';
import { ConversorValidadorService } from 'src/app/area-restrita/shared/services/conversor-validador.service';
import type { Noticias, pdfAnexo } from 'src/app/shared/models/noticia.interface';
import type { Page } from 'src/app/shared/models/paginacao/page.model';
import { NoticiaService } from 'src/app/shared/services/noticia.service';
import { SeparadorComponent } from '../../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-noticia-contadores',
  templateUrl: './noticia-contadores.component.html',
  styleUrl: './noticia-contadores.component.scss',
  providers: [ImagemTratamento],
  standalone: true,

  imports: [MatIcon, SeparadorComponent, MatButton, DatePipe]
})
export class NoticiaContadoresComponent implements OnInit, OnDestroy {
  private readonly noticiaService = inject(NoticiaService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly imagemTratamento = inject(ImagemTratamento);
  private readonly base64ToPdfService = inject(ConversorValidadorService);

  noticia: Noticias | undefined;
  objNoticias?: Noticias[] = [];
  noticiaId: number;
  arquivos: pdfAnexo[] = [];

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.noticiaId = Number.parseInt(params.get('id'));

      if (this.noticiaId !== null) {
        this.noticiaService.getOneNoticia(this.noticiaId).subscribe((noticia) => {
          this.noticia = noticia;
          this.obterArquivos(this.noticiaId);
          this.obterNoticiasDestaque();
        });
        //TODO: Redirect para o login caso não o ID seja invalido
      }
    });
  }
  retornaListaNoticias(): void {
    void this.router.navigate(['/informacao-cidadao/credenciamento-contadores']);
  }

  obterArquivos(idNoticia: number): void {
    this.noticiaService.obterArquivosNoticias(idNoticia).subscribe(
      (response) => {
        this.arquivos = response;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  obterNoticiasDestaque(): void {
    this.noticiaService.getNoticia(0, 5, CategoriaNoticia.CREDENCIAMENTO_DE_CONTADORES).subscribe((response: Page<Noticias>) => {
      this.objNoticias = response.content.filter((noticia) => noticia.ativo && noticia.id !== this.noticiaId).slice(0, 4);
    });
  }

  abrirUmaNoticia(noticiaId: number) {
    void this.router.navigate(['informacao-cidadao/credenciamento-contadores/noticia', noticiaId]);
    this.obterArquivos(noticiaId);
  }

  getImagem(noticiaImagem: string): string | undefined {
    return this.imagemTratamento.obterImagemBlob(noticiaImagem);
  }

  processarTexto(texto: string): string {
    if (texto) {
      return texto.replace(/<img\b[^>]*>/, (match) => match.replace('>', ' width="60%">'));
    } else {
      return '';
    }
  }

  openPDF(event: MouseEvent, pdf: pdfAnexo): void {
    event.preventDefault();
    this.base64ToPdfService.base64ToBlob(pdf.arquivo);
  }

  ngOnDestroy(): void {
    this.imagemTratamento.revogarUrlBlob();
  }
}
