import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { SeparadorComponent } from '../../core/layout/separador/separador.component';

@Component({
  selector: 'app-minutas-padronizadas',
  templateUrl: './minutas-padronizadas.component.html',
  styleUrl: './minutas-padronizadas.component.scss',
  standalone: true,

  imports: [SeparadorComponent, MatIcon],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MinutasPadronizadasComponent {
  minutas = [
    {
      titulo: 'CONTRATAÇÃO DIRETA',
      estaAberto: false
    },
    {
      titulo: 'LICITAÇÃO',
      estaAberto: false
    },
    {
      titulo: 'LICITAÇÃO / CONTRATAÇÃO DIRETA',
      estaAberto: false
    },
    {
      titulo: 'DOCUMENTAÇÃO DE INSTRUÇÃO DO PROCESSO',
      estaAberto: false
    },
    {
      titulo: 'MATERIAL DE APOIO',
      estaAberto: false
    }
  ];

  listaContratacaoDireta = [
    {
      descricao: 'Aviso de contratação direta',
      inseridaEm: '09/10/2024',
      baixar: 'doc/minutas/contratacao-direta/aviso-de-contratacao-direta-14-133-ESPv4-24.docx'
    },
    {
      descricao: 'Contrato para aquisição de bens',
      inseridaEm: '09/10/2024',
      baixar: 'doc/minutas/contratacao-direta/contratacao_direta_contrato_compras_14-133_ESPv4-24.docx'
    },
    {
      descricao: 'Contrato para execução de obras e serviços de engenharia',
      inseridaEm: '09/10/2024',
      baixar: 'doc/minutas/contratacao-direta/contratacao_direta_contrato_obras_servicos_engenharia_14-133_ESPv4-24.docx'
    },
    {
      descricao: 'Contrato para prestação de serviços sem dedicação exclusiva',
      inseridaEm: '09/10/2024',
      baixar: 'doc/minutas/contratacao-direta/contratacao_direta_contrato_servicos_sem_mo_14-133_ESPv5-24.docx'
    },
    {
      descricao: 'Contrato para prestação de serviços com dedicação exclusiva',
      inseridaEm: '09/10/2024',
      baixar: 'doc/minutas/contratacao-direta/contratacao_direta_contrato_servicos_com_mo_14-133_ESPv4-24.docx'
    },
    {
      descricao: 'Termo de referência para aquisição de bens',
      inseridaEm: '09/10/2024',
      baixar: 'doc/minutas/contratacao-direta/contratacao_direta_tr_compras_lei_14-133_ESPv5-24.docx'
    },
    {
      descricao: 'Termo de referência para execução de serviços comuns de engenharia',
      inseridaEm: '09/10/2024',
      baixar: 'doc/minutas/contratacao-direta/contratacao_direta_tr_servicos_comuns_engenharia_lei_14-133_ESPv5-24.docx'
    },
    {
      descricao: 'Termo de referência para prestação de serviços sem dedicação exclusiva',
      inseridaEm: '09/10/2024',
      baixar: 'doc/minutas/contratacao-direta/contratacao_direta_tr_servicos_sem_mo_lei-14-133_ESPv5-24.docx'
    },
    {
      descricao: 'Termo de referência para prestação de serviços com dedicação exclusiva',
      inseridaEm: '09/10/2024',
      baixar: 'doc/minutas/contratacao-direta/contratacao_direta_tr_servicos_com_mo_lei_14-133_ESPv5-24.docx'
    }
  ];

  listaLicitacao = [
    {
      descricao: 'Edital de pregão',
      inseridaEm: '09/10/2024',
      baixar: 'doc/minutas/licitacao/licitacao_edital_pregao_lei_14-133_ESPv5-24.docx'
    },
    {
      descricao: 'Edital de concorrência por menor preço ou maior desconto',
      inseridaEm: '09/10/2024',
      baixar: 'doc/minutas/licitacao/licitacao_edital_concorrencia_lei_14-133_ESPv5-24.docx'
    },
    {
      descricao: 'Contrato para aquisição de bens',
      inseridaEm: '09/10/2024',
      baixar: 'doc/minutas/licitacao/licitacao_contrato_compras_lei_14-133_ESPv4-24.docx'
    },
    {
      descricao: 'Contrato para execução de obras e serviços de engenharia',
      inseridaEm: '09/10/2024',
      baixar: 'doc/minutas/licitacao/licitacao_contrato_obras_servicos_engenharia_lei_14-133_ESPv4-24.docx'
    },
    {
      descricao: 'Contrato para prestação de serviços sem dedicação exclusiva',
      inseridaEm: '09/10/2024',
      baixar: 'doc/minutas/licitacao/licitacao_contrato_servicos_sem_mo_lei_14-133_ESPv4-24.docx'
    },
    {
      descricao: 'Contrato para prestação de serviços com dedicação exclusiva',
      inseridaEm: '09/10/2024',
      baixar: 'doc/minutas/licitacao/licitacao_contrato_servicos_com_mo_lei_14-133_ESPv4-24.docx'
    },
    {
      descricao: 'Termo de referência para aquisição de bens',
      inseridaEm: '09/10/2024',
      baixar: 'doc/minutas/licitacao/licitacao_tr_compras_lei_14-133_ESPv4-24.docx'
    },
    {
      descricao: 'Termo de referência para execução de serviços comuns de engenharia',
      inseridaEm: '09/10/2024',
      baixar: 'doc/minutas/licitacao/licitacao_tr_servicos_comuns_engenharia_lei_14-133_ESPv4-24.docx'
    },
    {
      descricao: 'Termo de referência para prestação de serviços sem dedicação exclusiva',
      inseridaEm: '09/10/2024',
      baixar: 'doc/minutas/licitacao/licitacao_tr_servicos_sem_mo_lei-14-133_ESPv4-24.docx'
    },
    {
      descricao: 'Termo de referência para prestação de serviços com dedicação exclusiva',
      inseridaEm: '09/10/2024',
      baixar: 'doc/minutas/licitacao/licitacao_tr_servicos_com_mo_lei_14-133_ESPv4-24.docx'
    },
    {
      descricao: 'Edital de leilão para alienação de imóveis',
      inseridaEm: '09/10/2024',
      baixar: 'doc/minutas/licitacao/licitacao_leilao_alienacao_imoveis_lei_14-133_ESPv2-24.docx'
    }
  ];

  listaLicitacaoContratacaoDireta = [
    {
      descricao: 'Ata de registro de preços',
      inseridaEm: '09/10/2024',
      baixar: 'doc/minutas/licitaca-contratacao-direta/licitacao_contratacao_direta_ata_registro_de_precos_lei_14-133_ESPv4-24.docx'
    }
  ];

  listaDocumentacaoInstrucaoProcesso = [
    {
      descricao: 'Declaração de utilização de minutas padronizadas',
      inseridaEm: '09/10/2024',
      baixar: 'doc/minutas/documentacao-instrucao-processo/declaracao_utilizacao_minutas_padronizadas-14-133-ESPv3-24.docx'
    }
  ];

  listaMaterialApoio = [
    {
      descricao: 'Orientações Consolidadas SubG-Cons PGE/SP',
      inseridaEm: '20/09/2024',
      baixar: 'doc/minutas/material-apoio/8_versao_orientacoes_consolidadas_NLLC_20_09_2024.pdf'
    }
  ];

  alternar(index: number): void {
    this.minutas[index].estaAberto = !this.minutas[index].estaAberto;
    this.minutas.forEach((item, i) => {
      if (i !== index) item.estaAberto = false;
    });
  }
}
