import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SeparadorComponent } from '../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-diario-oficial',
  templateUrl: './diario-oficial.component.html',
  styleUrl: './diario-oficial.component.scss',
  standalone: true,
  imports: [SeparadorComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DiarioOficialComponent {}
