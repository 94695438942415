import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StateAnaliseService {
  public procuradorServidor = '';
  public status = '';
  public perfil = '';
  public tipoAuxilioSaude = '';
  public mesAnoSolicitacao = '';
  public periodoDe = '';
  public periodoAte = '';
  public telaAnalise = false;
  public telaAnaliseInscricao = false;
  public page = 0;
  public size = 10;
  public mesAnoOrdem = false;

  public limpar(): void {
    this.procuradorServidor = '';
    this.status = '';
    this.perfil = '';
    this.tipoAuxilioSaude = '';
    this.mesAnoSolicitacao = '';
    this.periodoDe = '';
    this.periodoAte = '';
    this.telaAnaliseInscricao = false;
    this.page = 0;
    this.size = 10;
    this.mesAnoOrdem = false;
  }
}

@Injectable({
  providedIn: 'root'
})
export class StateAnaliseInscricaoService {
  public procuradorServidor = '';
  public status = '1';
  public dataEnvio = '';
  public perfil = '';
  public dataDeferimento = '';
  public telaAnalise = false;
  public page = 0;
  public size = 10;

  public limpar(): void {
    this.procuradorServidor = '';
    this.status = '1';
    this.perfil = '';
    this.dataEnvio = '';
    this.dataDeferimento = '';
    this.page = 0;
    this.size = 10;
  }
}
