<div class="container">
  <br />
  <app-separador titulo="Legislação - Procuradoria Geral do Estado" />
  <br />
  <div class="row g-3">
    <form [formGroup]="dadosFormulario" (ngSubmit)="buscar()">
      <div class="d-flex g-1">
        <div class="col-4">
          <label for="tipoLegislacao">Tipo:</label>
          <p-dropdown
            placeholder="Selecione um tipo para pesquisa"
            class="mat-form-control"
            id="tipoLegislacao"
            optionLabel="descricao"
            optionValue="id"
            formControlName="tipoLegislacao"
            filterBy="descricao"
            [options]="tipoLegislacao()"
            [filter]="true"
            [virtualScroll]="true"
            [virtualScrollItemSize]="20"
          />
        </div>
        <div class="col">
          <label for="publicacao" class="form-label" id="publicacao">Publicação:</label>
          <input pInputText type="text" id="publicacao" formControlName="publicacao" />
        </div>
      </div>
      <div class="col-12">
        <label for="conteudo" class="form-label" id="conteudo"> Palavras no Ato Normativo:</label>
        <input pInputText type="text" id="conteudo" formControlName="conteudo" />
      </div>
      <br />
      <div class="d-flex row">
        <div class="col-12 conjunto-botoes">
          <button class="botao-limpar" mat-button type="reset">Limpar filtros</button>
          <button mat-flat-button color="primary" type="submit" id="buscar" name="buscar" class="btn-dark" [disabled]="!this.dadosFormulario.valid">Buscar</button>
        </div>
      </div>
    </form>
    @if (mostraTabela()) {
      <div>
        <br />
        <div class="table-responsive">
          <table class="tabelaPadrao">
            <thead>
              <tr>
                <th class="fw-bold text-center">Publicação</th>
                <th class="fw-bold text-center">Conteudo</th>
                <th class="fw-bold text-center">Anexo</th>
              </tr>
            </thead>
            <tbody>
              @for (publicacao of publicacoes(); track publicacao.id) {
                <tr>
                  <td class="alinhamento">
                    {{ publicacao.publicacao }}
                  </td>
                  <td class="alinhamento" [innerHTML]="publicacao.conteudo | truncateHtml: 500" (click)="openPDF($event, publicacao.id)"></td>
                  <td class="imagem-pdf">
                    @for (item of publicacao.arquivos; track item) {
                      <a href="#" (click)="openPDF($event, item.id)">
                        <img width="30" class="icon-pdf" src="images/pdf-file-svgrepo-com.svg" alt="PDF" />
                      </a>
                    }
                  </td>
                </tr>
              }
            </tbody>
          </table>
          <!-- Aviso quando a tabela estiver vazia -->
          @if (!publicacoes() || publicacoes().length === 0) {
            <div>
              <p class="text-center">Nenhum registro encontrado.</p>
            </div>
          }
        </div>
        <mat-paginator
          #paginator
          class="custom-paginator"
          aria-label="Selecionar Página"
          showFirstLastButtons
          [length]="length"
          [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions"
          [pageIndex]="pageNumber"
          (page)="lidarEventoPagina($event)"
        />
        <br />
      </div>
    }
    <p>Para consultar os Atos Normativos anteriores a 02/05/2006 <a href="pdf/resolucoes-13.pdf" target="_blank">clique aqui.</a></p>
  </div>
</div>
