import type { OnInit } from '@angular/core';
import { Component, inject } from '@angular/core';
import type { PageEvent } from '@angular/material/paginator';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { CategoriaNoticia } from 'src/app/area-aberta/shared/enums/categoria-noticia';
import type { Noticias } from 'src/app/shared/models/noticia.interface';
import type { Page } from 'src/app/shared/models/paginacao/page.model';
import { NoticiaService } from 'src/app/shared/services/noticia.service';
import { SeparadorComponent } from '../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-comunicados',
  templateUrl: './comunicados.component.html',
  styleUrl: './comunicados.component.scss',
  standalone: true,

  imports: [SeparadorComponent, MatPaginator]
})
export class ComunicadosComponent implements OnInit {
  private readonly noticiaService = inject(NoticiaService);
  private readonly router = inject(Router);

  objComunicados?: Noticias[] = [];

  eventoPagina: PageEvent;
  comprimento = 0;
  tamanhoDaPagina = 10;
  numeroPagina = 0;
  opcoesDoTamanhoDaPagina = [5, 10, 25];

  ocultarTamanhoDaPagina = false;
  mostrarOpcoesDeTamanhoPagina = true;
  mostrarPrimeiroUltimosBotoes = true;
  desabilitado = false;

  ngOnInit(): void {
    this.carregarComunicado();
  }

  carregarComunicado(): void {
    this.noticiaService.getNoticia(this.numeroPagina, this.tamanhoDaPagina, CategoriaNoticia.COMUNICADOS).subscribe(
      (response: Page<Noticias>) => {
        this.objComunicados = response.content;
        this.comprimento = response.totalElements;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  comunicado(id: number): void {
    void this.router.navigate(['informacao-cidadao/comunicado', id]);
  }

  editarData(data: string): string {
    const ano = data.substring(6, 10);
    const mes = this.transformarMes(data.substring(3, 5));
    const dia = data.substring(0, 2);
    return `<strong>${ano}</strong> ${dia} - ${mes}`;
  }

  transformarMes(mes: string): string {
    const mesEditado = Number(mes);
    const numeroMes = Number(mesEditado);
    const listaMeses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

    return listaMeses[numeroMes - 1];
  }

  lidarEventoPagina(e: PageEvent) {
    this.eventoPagina = e;
    this.comprimento = e.length;
    this.tamanhoDaPagina = e.pageSize;
    this.numeroPagina = e.pageIndex;
    this.carregarComunicado();
  }
}
