import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SeparadorComponent } from '../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-atendimento-ouvidoria',
  templateUrl: './atendimento-ouvidoria.component.html',
  styleUrl: './atendimento-ouvidoria.component.scss',
  standalone: true,

  imports: [SeparadorComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AtendimentoOuvidoriaComponent {}
