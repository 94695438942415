import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import type { SafeHtml } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';
import type { ParamMap } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';
import { PrimeTemplate } from 'primeng/api';
import { CarouselModule } from 'primeng/carousel';
import { GalleriaModule } from 'primeng/galleria';
import { ImagemTratamento } from 'src/app/area-aberta/features/home/shared/services/imagem-tratamento';
import { CategoriaNoticia } from 'src/app/area-aberta/shared/enums/categoria-noticia';
import { ConversorValidadorService } from 'src/app/area-restrita/shared/services/conversor-validador.service';
import type { Noticias, PdfAnexo } from 'src/app/shared/models/noticia.interface';
import type { Page } from 'src/app/shared/models/paginacao/page.model';
import { NoticiaService } from 'src/app/shared/services/noticia.service';
import { SeparadorComponent } from '../../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-uma-noticia',
  templateUrl: './uma-noticia.component.html',
  styleUrl: './uma-noticia.component.scss',
  providers: [ImagemTratamento],
  standalone: true,
  imports: [MatIcon, SeparadorComponent, MatButton, CarouselModule, PrimeTemplate, GalleriaModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UmaNoticiaComponent implements OnInit, OnDestroy {
  private readonly noticiaService = inject(NoticiaService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly imagemTratamento = inject(ImagemTratamento);
  private readonly base64ToPdfService = inject(ConversorValidadorService);
  private readonly sanitizer = inject(DomSanitizer);

  noticia = signal<Noticias | undefined>(undefined);
  objNoticias = signal<Noticias[]>([]);
  noticiaId: number;
  arquivos = signal<PdfAnexo[]>([]);
  listaImagens = signal<PdfAnexo[]>([]);
  urlsImagens = signal<string[]>([]);

  opcoesResponsividade = [
    {
      breakpoint: '1500px',
      numVisible: 4
    },
    {
      breakpoint: '1070px',
      numVisible: 3
    },
    {
      breakpoint: '768px',
      numVisible: 2
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.noticiaId = Number(params.get('id'));

      if (this.noticiaId) {
        this.noticiaService.getOneNoticia(this.noticiaId).subscribe((noticia) => {
          this.noticia.set(noticia);
          this.obterArquivos(this.noticiaId);
          this.obterNoticiasDestaque();
        });
      }
    });
  }
  retornaListaNoticias(): void {
    void this.router.navigate(['/noticias-diversas']);
  }

  obterArquivos(idNoticia: number): void {
    this.listaImagens.set([]);
    this.arquivos.set([]);
    this.urlsImagens.set([]);
    this.noticiaService.obterArquivosNoticias(idNoticia).subscribe({
      next: (response) => {
        response?.forEach((arquivo) => {
          if (arquivo.imagem) {
            this.listaImagens.update((imagens) => {
              return [...imagens, arquivo];
            });
          } else {
            this.arquivos.update((arquivos) => {
              return [...arquivos, arquivo];
            });
          }
        });
        if (this.listaImagens().length > 0) {
          this.processarImagens(this.listaImagens());
        }
      },
      error: console.error
    });
  }

  obterNoticiasDestaque(): void {
    this.noticiaService.getNoticia(0, 4, CategoriaNoticia.NOTICIA_AREA_ABERTA).subscribe((response: Page<Noticias>) => {
      this.objNoticias.set(response.content.filter((noticia) => noticia.ativo && noticia.id !== this.noticiaId).slice(0, 4));
    });
  }

  abrirUmaNoticia(noticiaId: number): void {
    void this.router.navigate(['uma-noticia', noticiaId]);
    this.obterArquivos(noticiaId);
  }

  obterImagem(noticiaImagem?: string): string | undefined {
    return this.imagemTratamento.obterImagemBlob(noticiaImagem);
  }

  processarImagens(lista: PdfAnexo[]): void {
    const imagens = lista.map((imagem) => this.obterImagem(imagem.arquivo)).filter((imagem): imagem is string => imagem !== undefined);
    this.urlsImagens.set(imagens);
  }

  processarTexto(texto: string): SafeHtml {
    if (texto) {
      return this.sanitizer.bypassSecurityTrustHtml(texto.replace(/<img\b[^>]*>/, (match) => match.replace('>', ' width="60%">')));
    } else {
      return '';
    }
  }

  abrirArquivo(evento: MouseEvent, pdf: PdfAnexo): void {
    evento.preventDefault();
    this.base64ToPdfService.base64ToBlob(pdf.arquivo);
  }

  ngOnDestroy(): void {
    this.imagemTratamento.revogarUrlBlob();
  }
}
