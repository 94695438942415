import { inject, Injectable } from '@angular/core';
import type { SafeHtml } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, takeUntil } from 'rxjs';
import { DynamicTableService } from 'src/app/area-restrita/shared/services/dynamic-table.service';
import { ReportService } from 'src/app/area-restrita/shared/services/report.service';
import { RelatorioAuxilioSaudeService } from '../services/relatorio-auxilio-saude.service';
import type { InscricaoAuxilioSaude } from './auxilio-saude';
import type { RelatorioInscricaoResponse } from './relatorio';

@Injectable({
  providedIn: 'root'
})
export class FabricaRelatorioInscricao {
  private readonly relatorioAuxilioSaudeService = inject(RelatorioAuxilioSaudeService);
  private readonly reportService = inject(ReportService);
  private readonly sanitizer = inject(DomSanitizer);
  private readonly dynamicTableService = inject(DynamicTableService);

  private readonly destroy$ = new Subject<void>();
  private titulo: string;
  private rows: string[][];
  private tipo: string;
  private tituloColunas: string[];
  private totalElements: number;
  private pageSize: number;
  private pageNumber: number;

  async criarRelatorio(nomeTitular: string, cpfTitular: string, tipoCargo: string, dataInscicao: string, pageNumber: number, pageSize: number): Promise<void> {
    const dadosRelatorio = await this.obterDadosRelatorio(nomeTitular, cpfTitular, tipoCargo, dataInscicao, pageNumber, pageSize);
    this.titulo = 'Relatório de Inscrição';
    this.totalElements = dadosRelatorio.totalElements;
    this.pageSize = dadosRelatorio.number;
    this.pageNumber = dadosRelatorio.size;
    this.rows = this.carregarLinhas(dadosRelatorio.content);
    this.tipo = 'pdf/csv';
    this.tituloColunas = ['Nome do titular', 'CPF do titular', 'Nome do cargo', 'Data de inscrição'];
  }

  private async obterDadosRelatorio(
    nomeTitular: string,
    cpfTitular: string,
    tipoCargo: string,
    dataInscicao: string,
    pageNumber: number,
    pageSize: number
  ): Promise<RelatorioInscricaoResponse> {
    return new Promise<RelatorioInscricaoResponse>((resolve, reject) => {
      this.relatorioAuxilioSaudeService
        .relatorioPesquisaInscricao(nomeTitular, cpfTitular, tipoCargo, dataInscicao, pageNumber, pageSize)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (dados) => {
            resolve(dados);
          },
          error: (error) => {
            reject(new Error('Erro ao obter dados do RelatorioPesquisaInscricoes', error));
          }
        });
    });
  }

  private carregarLinhas(inscricoes: InscricaoAuxilioSaude[]): string[][] {
    const rows: string[][] = [];
    for (const inscricao of inscricoes) {
      const row: string[] = [];
      row.push(inscricao.usuario.nome);
      row.push(this.reportService.formatCPF(inscricao.usuario.cpf));
      row.push(inscricao.usuario.perfil.nome);
      row.push(inscricao.dataInscricao.toString());
      rows.push(row);
    }

    return rows;
  }
  public getTabela(): SafeHtml {
    const resultH = this.dynamicTableService.createSearchHTML(this.tituloColunas, this.rows);
    return this.sanitizer.bypassSecurityTrustHtml(resultH);
  }

  public getRows(): string[][] {
    return this.rows;
  }

  // public getTipo(): string {
  //   return this.tipo;
  // }

  public getTitulo(): string {
    return this.titulo;
  }

  public getColunas(): string[] {
    return this.tituloColunas;
  }

  public getTotalElementos(): number {
    return this.totalElements;
  }

  public getPageSize(): number {
    return this.pageNumber;
  }

  public getPageNumber(): number {
    return this.pageSize;
  }
}
