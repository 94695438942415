<div class="container-noticia-aberta">
  <div class="mais-noticias">
    <div>
      <div class="container-cards">
        <h2 class="titulo-link">Últimos Links</h2>
        @for (pagina of paginasAcessadas(); track $index; let i = $index) {
          <div class="item">
            <span class="separador"
              >>
              <a [href]="linkPagina(pagina.urlPagina)" [title]="nomePagina(pagina.urlPagina)">
                {{ nomePagina(pagina.urlPagina) }}
              </a>
            </span>
          </div>
        }
      </div>
    </div>
    <br />
  </div>
  <div class="container-noticia">
    <h1 class="titulo">Notícias</h1>
    @for (noticia of objNoticias(); track noticia.id) {
      <div class="div-noticia">
        <p class="titulo-noticia">{{ noticia?.titulo }}</p>
        <p class="data-noticia" [innerHTML]="editarData(noticia.dataPublicacao)"></p>
        @if (noticia?.imagem) {
          <img alt="Imagem da Notícia" class="imagem-noticia" [src]="obterImagem(noticia?.imagem)" />
        }
        <p class="text-noticia" [innerHTML]="processarTexto(noticia.texto)"></p>
        <p class="text-noticia leia-mais" tabindex="0" (click)="visualizarNoticia(noticia.id)" (keypress)="visualizarNoticia(noticia.id)"><a> ... Leia Mais</a></p>
      </div>
    }
    @if (objNoticias().length > 0) {
      <div>
        <mat-paginator
          #paginator
          class="paginacao"
          aria-label="Select page"
          showFirstLastButtons
          [length]="comprimento"
          [pageSize]="tamanhoDaPagina"
          [pageSizeOptions]="opcoesDoTamanhoDaPagina"
          [pageIndex]="indiceDaPagina"
          (page)="lidarEventoDaPagina($event)"
        />
        <br />
      </div>
    }
  </div>
</div>

<br />
<div class="div-bottom">
  {{ descricaoInferior }}
</div>
