import { environment } from 'src/environments/environment';
import type { MenuItem } from './menu.model';

export const MenuList: MenuItem[] = [
  {
    titulo: 'INSTITUCIONAL',
    menuFilho: [
      {
        titulo: 'Sobre a PGE',
        externo: false,
        redirecionar: 'menu/INSTITUCIONAL'
      },

      {
        titulo: 'Áreas de Atuação',
        externo: false,
        redirecionar: 'menu/AREASATUACAO'
      },

      {
        externo: true,
        redirecionar: 'pdf/carta-de-servicos-pge-2024.pdf',
        titulo: 'Carta de Serviços'
      },

      {
        titulo: 'Corregedoria',
        externo: false,
        redirecionar: 'menu/CORREGEDORIA'
      },

      {
        titulo: 'Conselho',
        externo: false,
        redirecionar: 'menu/CONSELHO'
      },

      {
        titulo: 'Ouvidoria',
        externo: false,
        redirecionar: 'menu/OUVIDORIA'
      }
    ]
  },

  {
    titulo: 'INFORMAÇÃO AO CIDADÃO',
    menuFilho: [
      {
        externo: false,
        redirecionar: 'informacao-cidadao/atendimento-protestos-icms-ipva-itcmd-certidao-negativa',
        titulo: 'Atendimento sobre protestos/ ICMS / IPVA / ITCMD / Certidão Negativa'
      },
      {
        externo: false,
        redirecionar: 'informacao-cidadao/cepen',
        titulo: 'Certidão Positiva com Efeito de Negativa (Cepen)'
      },
      {
        externo: false,
        redirecionar: 'informacao-cidadao/credenciamento-contadores',
        titulo: 'Credenciamento de contadores'
      },
      {
        externo: true,
        redirecionar: 'https://www.pge.sp.gov.br/editais/licitacoes/index.aspx',
        titulo: 'Compras e Contratações'
      },
      {
        externo: false,
        redirecionar: 'informacao-cidadao/enderecos-telefones-uteis',
        titulo: 'Endereços e Telefones Úteis'
      },
      {
        externo: true,
        redirecionar: 'pdf/entidades-representadas-pela-pge.pdf',
        titulo: 'Entidades representadas pela PGE'
      },
      {
        externo: false,
        redirecionar: 'informacao-cidadao/localizar-regionais',
        titulo: 'Localize a Regional'
      }
    ]
  },
  {
    titulo: 'CENTRO DE ESTUDOS',
    menuFilho: [
      {
        externo: true,
        redirecionar: 'https://www.pge.sp.gov.br/CentroEstudo/Default.htm',
        titulo: 'Site CE'
      },
      {
        externo: true,
        redirecionar: 'http://biblioteca.pge.sp.gov.br',
        titulo: 'Biblioteca'
      },
      {
        externo: true,
        redirecionar: 'https://revistas.pge.sp.gov.br',
        titulo: 'Publicações'
      },
      {
        externo: true,
        redirecionar: environment.siteEscola,
        titulo: 'Escola Superior da PGE'
      }
    ]
  },
  {
    titulo: 'PRIVACIDADE E PROTEÇÃO DE DADOS – LGPD',
    externo: false,
    menuFilho: [
      { titulo: 'Informações classificadas e desclassificadas', externo: false, redirecionar: 'lgpd' },
      { titulo: 'Controlador de dados pessoais', externo: true, redirecionar: 'https://www.controladoriageral.sp.gov.br/encarregado-de-dados-pessoais/' }
    ]
  },
  {
    externo: false,
    redirecionar: 'login',
    titulo: 'ÁREA RESTRITA'
  }
];
