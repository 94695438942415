<main class="main" role="main">
  <section class="page">
    <app-separador titulo="Consultorias" />
    <div class="row">
      <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
        <h3><strong>CJ DA SEC ADMINISTRAÇÃO PENITENCIÁRIA</strong></h3>
        <p class="procurador"><strong>Procurador(a) Chefe:</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("RAFAEL CAMARGO TRIDA") }}</p>
        </div>
        <p class="endereco"><strong>Endereço</strong>: Avenida General Ataliba Leonel,656, , Santana, São Paulo-SP, 02088-900.</p>
        <p class="tel"><strong>Telefone</strong>: (11) 3206-4716 / 4726 / 4719</p>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("ANA LUIZA DE MAGALHAES PEIXOTO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("ANTONIO AGOSTINHO DA SILVA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("EDSON MARCELO VELOSO DONARDI") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("LIEGE PEIXOTO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MIRIAN GONCALVES DILGUERIAN") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("RODRIGO AUGUSTO DE CARVALHO CAMPOS") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("VERA PIMENTEL FONSECA") }}</p>
        </div>
        <hr />
        <h3><strong>CJ DA SEC AGRICULTURA E ABASTECIMENTO</strong></h3>
        <p class="procurador"><strong>Procurador(a) Chefe</strong>:</p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("RITA KELCH") }}</p>
        </div>
        <p class="endereco"><strong>Endereço</strong>: Praça Ramos de Azevedo, 254, 6º andar, República, São Paulo-SP, 01037-912.</p>
        <p class="tel"><strong>Telefone</strong>: (11) 5067-0088</p>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("EDUARDO DE CARVALHO LAGES") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("JOSE LUIZ BORGES DE QUEIROZ") }}</p>
        </div>
        <hr />
        <h3><strong>CJ DA SEC CULTURA E ECONOMIA CRIATIVA</strong></h3>
        <p class="procurador"><strong>Procurador(a) Chefe</strong>:</p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("FABIO TEIXEIRA REZENDE") }}</p>
        </div>
        <p class="endereco"><strong>Endereço</strong>: Rua Mauá,51, 1º andar sala 144, Luz, São Paulo-SP, 01028-900.</p>
        <p class="tel"><strong>Telefone</strong>: (11) 3339-818</p>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">ANA CLAUDIA VERGAMINI LUNA</p>
          <p class="demais-procuradores">{{ encontrarNome("ROBERTO ZULAR") }}</p>
        </div>
        <hr />
        <h3><strong>CJ DA SEC FAZENDA E PLANEJAMENTO</strong></h3>
        <p class="procurador"><strong>Procurador(a) Chefe</strong>:</p>
        <div class="pge-unidade">
          <p class="demais-procuradores">JUSTINE ESMERALDA RULLI FILIZZOLA</p>
        </div>
        <p class="endereco"><strong>Endereço</strong>: Rua Rangel Pestana,300, 6º andar, Centro, São Paulo-SP, 01091-900.</p>
        <p class="tel"><strong>Telefone</strong>: (11) 3243-3236/ 3414/ 3415 – Fax (11) 3243-3795</p>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("AIRA CRISTINA RACHID BRUNO DE LIMA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("ALESSANDRA FERREIRA DE ARAUJO RIBEIRO") }}</p>
          <p class="demais-procuradores">BEATRIZ MENEGHEL CHAGAS CAMARGO</p>
          <p class="demais-procuradores">{{ encontrarNome("DANAE DAL BIANCO") }}</p>
        </div>
        <hr />
        <h3><strong>CJ DA SEC DESENVOLVIMENTO ECONÔMICO</strong></h3>
        <p class="procurador"><strong>Procurador(a) Chefe</strong>:</p>
        <div class="pge-unidade">
          <p class="demais-procuradores">RAFAEL CARVALHO DE FASSIO</p>
        </div>
        <p class="endereco"><strong>Endereço</strong>: Av. Escola Politécnica, 82, , Jaguaré, São Paulo-SP, 80530-000.</p>
        <p class="tel"><strong>Telefone</strong>: (11) 3718-6500</p>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("ADRIANA RUIZ VICENTIN") }}</p>
        </div>
        <hr />
        <h3><strong>CJ DA SEC DESENVOLVIMENTO REGIONAL</strong></h3>
        <p class="procurador"><strong>Procurador(a) Chefe</strong>:</p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p class="endereco"><strong>Endereço</strong>: Avenida Rangel Pestana, nº 300, 3º andar, Brás, São Paulo-SP, 01017-911.</p>
        <p class="tel"><strong>Telefone</strong>: (11) 3204-4418 // 3204-4419 // 3204-4420</p>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <hr />
        <h3><strong>CJ DA SEC DESENVOLVIMENTO SOCIAL</strong></h3>
        <p class="procurador"><strong>Procurador(a) Chefe(a)</strong>:</p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("LUIZ ROBERTO LUCARELLI") }}</p>
        </div>
        <p class="endereco"><strong>Endereço</strong>: Rua Bela Cintra,1032, 2º andar, Cerqueira Cesar, São Paulo-SP, 01415-000.</p>
        <p class="tel"><strong>Telefone</strong>: (11) 2763-8259 / 2763-8023 – Fax (11) 2763-8242</p>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("CHRISTIANE MINA FALSARELLA") }}</p>
        </div>
        <hr />
        <h3><strong>CJ DA SEC DIREITOS DAS PESSOAS COM DEFICIENCIA</strong></h3>
        <p class="procurador"><strong>Procurador(a) Chefe</strong>:</p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("FABIO ANDRE UEMA OLIVEIRA") }}</p>
        </div>
        <p class="endereco"><strong>Endereço</strong>: Rua Auro Soares de Moura Andrade, 564, 2º andar – Sala 15, , São Paulo-SP, 12345-678.</p>
        <p class="tel"><strong>Telefone</strong>: Tel.: (11) 5212-3792/3776</p>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <hr />
        <h3><strong>CJ DA SEC DO GOVERNO</strong></h3>
        <p><strong>Procurador(a) Chefe</strong>:</p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p class="endereco"><strong>Endereço</strong>: Av. Morumbi, , 4500, Morumbi, São Paulo-SP, CEP: 05650-905.</p>
        <p class="tel"><strong>Telefone</strong>: (11) 2193-8000</p>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <hr />
        <h3><strong>CJ DA SEC EDUCAÇÃO</strong></h3>
        <p class="procurador"><strong>Procurador(a) Chefe</strong>:</p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("CLAUDIA MARA ARANTES DA SILVA") }}</p>
        </div>
        <p class="endereco"><strong>Endereço</strong>: Praça República,53, 1º andar – sala 107, República, São Paulo-SP, 01045-903.</p>
        <p class="tel"><strong>Telefone</strong>: (11) 2075-4708/4710</p>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("DULCE MYRIAM CACAPAVA FRANCA HIBIDE CLAVER") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("JULIANA YUMI YOSHINAGA KAYANO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("LARISSA DE ABREU D ORSI") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MARCIA WILLIAM ESPER VEDRIN") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MARCUS VINICIUS ARMANI ALVES") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MARIANA BEATRIZ TADEU DE OLIVEIRA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MIRNA NATALIA AMARAL DA GUIA MARTINS") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("PAULO VICTOR FERNANDES") }}</p>
        </div>
        <hr />
        <h3><strong>CJ DA SEC ESPORTES</strong></h3>
        <p class="procurador"><strong>Procurador(a) Chefe</strong>:</p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("ROBERTO AUGUSTO CASTELLANOS PFEIFFER") }}</p>
        </div>
        <p class="endereco"><strong>Endereço</strong>: Rua São Bento, 380, 8º andar, Centro, São Paulo-SP, 01010-001.</p>
        <p class="tel"><strong>Telefone</strong>: (11) 3107-6233</p>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <hr />
        <h3><strong>CJ DA SEC HABITAÇÃO</strong></h3>
        <p class="procurador"><strong>Procurador(a) Chefe</strong>:</p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("ROGERIO AUGUSTO DA SILVA") }}</p>
        </div>
        <p class="endereco"><strong>Endereço</strong>: Rua Boa Vista,170, 15º andar Edificio A Bloco 3, Centro, São Paulo-SP, 01014-000.</p>
        <p class="tel"><strong>Telefone</strong>: (11) 3638-5126 / 5129 / 5128</p>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("ADRIANA GUIMARAES GOMES PEREIRA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("REINALDO PASSOS DE ALMEIDA") }}</p>
        </div>
        <hr />
        <h3><strong>CJ DA SEC INFRAESTRUTURA E MEIO AMBIENTE</strong></h3>
        <p class="procurador"><strong>Procurador(a) Chefe</strong>:</p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("CAMILA ROCHA CUNHA VIANA") }}</p>
        </div>
        <p class="endereco"><strong>Endereço</strong>: Avenida Prof. Frederico Hermann Júnior,345, 1º andar, Alto de Pinheiros, São Paulo-SP, 05459-900.</p>
        <p class="tel"><strong>Telefone</strong>: (11) 3133-3350/ 3352 – Fax (11) 3133-3956</p>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("DANIEL SMOLENTZOV") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("GISELE NOVACK DIANA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("LUCAS SOARES DE OLIVEIRA") }}</p>
        </div>
        <hr />
        <h3><strong>CJ DA SEC JUSTIÇA E CIDADANIA</strong></h3>
        <p class="procurador"><strong>Procurador(a) Chefe</strong>:</p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("MARCIA DE OLIVEIRA FERREIRA APARICIO") }}</p>
        </div>
        <p class="endereco"><strong>Endereço</strong>: Páteo do Colégio, 148, 3º andar, Centro, São Paulo-SP, 01016-040.</p>
        <p class="tel"><strong>Telefone</strong>: (11) 3291-2647 / 2650</p>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("CARLOS EDUARDO TEIXEIRA BRAGA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("HELIO JOSE MARSIGLIA JUNIOR") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("PAULA CRISTINA RIGUEIRO BARBOSA") }}</p>
        </div>
        <hr />
        <h3><strong>CJ DA SEC LOGÍSTICA E TRANSPORTES</strong></h3>
        <p class="procurador"><strong>Procurador(a) Chefe</strong>:</p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <p class="endereco"><strong>Endereço</strong>: Rua Iaiá,126, 13º andar, Itaim Bibi, São Paulo-SP, 04542-906.</p>
        <p class="tel"><strong>Telefone</strong>: (11) 3702-8185 / 8186</p>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <hr />
        <h3><strong>CJ DA SEC POLÍCIA MILITAR</strong></h3>
        <p class="procurador"><strong>Procurador(a) Chefe</strong>:</p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("VERA EVANDIA BENINCASA") }}</p>
        </div>
        <p class="endereco"><strong>Endereço</strong>: Praça Coronel Fernando Prestes,115, 3º andar, Luz, São Paulo-SP, 01124-060.</p>
        <p class="tel"><strong>Telefone</strong>: (11) 3327-7047/ 3327</p>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("ANA CRISTINA LIVORATI OLIVA GARBELINI") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("GUILHERME DARIO RUSSO KOHNEN") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("TANIA ORMENI FRANCO") }}</p>
        </div>
        <hr />
        <h3><strong>CJ DA SEC SAÚDE</strong></h3>
        <p class="procurador"><strong>Procurador(a) Chefe</strong>:</p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("MARCELO GRANDI GIROLDO") }}</p>
        </div>
        <p class="endereco"><strong>Endereço</strong>: Av. Dr. Arnaldo, 355, 2º andar, Cerqueira Cesar, São Paulo-SP, 01246-902.</p>
        <p class="tel"><strong>Telefone</strong>: (11) 3066-8646 / 3066-8647 / 8110 – Fax (11) 3081-8455</p>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("CRISTINA DE ARRUDA FACCA LOPES") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("EDNA MARIA FARAH HERVEY COSTA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("HEITOR TEIXEIRA PENTEADO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("JULIANA MARIA DELLA PELLICANI") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MARCIA COLI NOGUEIRA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MARIA INEZ PERES BIAZOTTO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("PATRICIA DE OLIVEIRA GARCIA ALVES") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("RODRIGO PIERONI FERNANDES") }}</p>
        </div>
        <hr />
        <h3><strong>CJ DA SEC SEGURANCA PUBLICA</strong></h3>
        <p class="procurador"><strong>Procurador(a) Chefe</strong>:</p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("LEVI DE MELLO") }}</p>
        </div>
        <p class="endereco"><strong>Endereço</strong>: Rua Líbero Badaró,39, 4º andar, Centro, São Paulo-SP, 01009-000.</p>
        <p class="tel"><strong>Telefone</strong>: (11) 3291-6713 / 6753/ 6755</p>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("BRUNO MACIEL DOS SANTOS") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("CLAUDIA HELENA DESTEFANI DE LACERDA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("CLAUDIA KIYOMI QUIAN TRANI") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("IVANIRA PANCHERI") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("JI NA PARK") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("JOSE FABIANO DE ALMEIDA ALVES FILHO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("LUCAS COSTA DA FONSECA GOMES") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MARIA CECILIA FONTANA SAEZ") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MARINA BENEVIDES SOARES") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MILENA CARLA AZZOLINI PEREIRA DA ROSA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MONICA HERNANDES DE SAO PEDRO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("NORBERTO OYA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("PAOLA DE ALMEIDA PRADO") }}</p>
        </div>
        <hr />
        <h3><strong>CJ DA SEC TRANSPORTES METROPOLITANOS</strong></h3>
        <p class="procurador"><strong>Procurador(a) Chefe</strong>:</p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("MARCELLO GARCIA") }}</p>
        </div>
        <p class="endereco"><strong>Endereço</strong>: Rua Boa Vista,175, Bloco A, 12º andar, Centro, São Paulo-SP, 01014-000.</p>
        <p class="tel"><strong>Telefone</strong>: (11) 3291-2112/ 2111/ 2113</p>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <hr />
        <h3><strong>CJ DA SEC TURISMO</strong></h3>
        <p class="procurador"><strong>Procurador(a) Chefe</strong>:</p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("PATRICIA HELENA MASSA") }}</p>
        </div>
        <p class="endereco"><strong>Endereço</strong>: Praça Ramos de Azevedo, 254 , Centro, São Paulo-SP, 01037-912.</p>
        <p class="tel"><strong>Telefone</strong>: (11) 3204-2877</p>
        <p><strong>Demais Procuradores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores"></p>
        </div>
        <hr />
      </div>
    </div>
  </section>
</main>
