import { inject } from '@angular/core';
import type { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';
import { Router } from '@angular/router';

export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const token = localStorage.getItem('token');
  const isLoginPage = route.routeConfig.path == 'login';
  const router = inject(Router);
  const hasToken = token !== null;
  if (!isLoginPage && !hasToken) void router.navigate(['/login']);
  if (isLoginPage && hasToken) void router.navigate(['/area-restrita/home']);

  return true;
};
