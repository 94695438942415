import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SeparadorComponent } from '../../../../core/layout/separador/separador.component';
import { legislacaoList } from './legislacao-corregedoria-file';

@Component({
  selector: 'app-legislacao-corregedoria',
  templateUrl: './legislacao-corregedoria.component.html',
  standalone: true,

  imports: [SeparadorComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegislacaoCorregedoriaComponent {
  legislacaoContent = legislacaoList;
}
