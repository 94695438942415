import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { MatCard, MatCardContent, MatCardFooter, MatCardHeader } from '@angular/material/card';
import { Router, RouterLink } from '@angular/router';
import type { Servico } from '../servico.model';

@Component({
  selector: 'app-card-servico',
  templateUrl: './card-servico.component.html',
  styleUrl: './card-servico.component.scss',
  standalone: true,
  imports: [MatCard, MatCardHeader, MatCardContent, MatCardFooter, RouterLink],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardServicoComponent implements OnInit {
  private readonly router = inject(Router);

  conteudoCartao = input.required<Servico>();
  caminhoSGV = '';

  ngOnInit(): void {
    this.caminhoSGV = `images/svg/${this.conteudoCartao().icone}.svg`;
  }

  redirect(route: string): void {
    void this.router.navigateByUrl(route);
  }
}
