import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import type { SafeResourceUrl } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-portal-tributario-fiscal',
  standalone: true,
  imports: [],
  templateUrl: './portal-tributario-fiscal.component.html',
  styleUrl: './portal-tributario-fiscal.component.sass',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PortalTributarioFiscalComponent {
  private readonly sanitizer = inject(DomSanitizer);

  url: SafeResourceUrl;

  constructor() {
    const tkuValue = '123';
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(`https://www.pge.sp.gov.br/Portal_PGE/Portal_Tributario/portaltributario.asp?TKU=${tkuValue}`);
  }
}
