import type { ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild, inject, signal } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import type { NgxFileDropEntry } from 'ngx-file-drop';
import { NgxFileDropModule } from 'ngx-file-drop';

import { Subject, takeUntil } from 'rxjs';
import { ToastService } from 'src/app/shared/toast/toast.service';

import { LiveAnnouncer } from '@angular/cdk/a11y';
import type { HttpErrorResponse } from '@angular/common/http';
import { MatButton } from '@angular/material/button';
import { MatChipGrid, MatChipInput, MatChipRemove, MatChipRow } from '@angular/material/chips';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { EditorModule } from 'primeng/editor';
import type { pdfAnexo } from 'src/app/shared/models/noticia.interface';
import { NoticiaService } from 'src/app/shared/services/noticia.service';
@Component({
  selector: 'app-criar-noticia',
  templateUrl: './criar-noticia.component.html',
  styleUrl: './criar-noticia.component.scss',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatSelect,
    MatOption,
    MatRadioGroup,
    MatRadioButton,
    EditorModule,
    NgxFileDropModule,
    MatIcon,
    MatLabel,
    MatChipInput,
    MatChipGrid,
    MatChipRow,
    MatChipRemove,
    MatButton
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CriarNoticiaComponent implements OnInit, OnDestroy {
  private readonly formBuilder = inject(FormBuilder);
  private readonly toastr = inject(ToastService);
  private readonly noticiaService = inject(NoticiaService);
  private readonly router = inject(Router);
  private readonly cdr = inject(ChangeDetectorRef);

  private readonly destroy$ = new Subject<void>();
  maxCaracteres: number = 250;
  campoCategoriaAbertaRestrita = signal(false);
  campoAcesso = signal(false);
  campoDestaque = signal(false);
  pdf = signal<pdfAnexo[]>([]);
  listaImagens = signal<pdfAnexo[]>([]);
  listaTotalArquivos: pdfAnexo[] = [];
  @ViewChild('palavraChaveInput') palavraChaveInput: ElementRef<HTMLInputElement>;

  readonly palavrasChaves = signal([]);
  readonly anunciador = inject(LiveAnnouncer);
  limiteMaximoPlavrasChaves: number = 10;
  limiteCaracteres: number = 30;

  objetoDestaque = [
    { value: 0, nome: 'Nenhuma' },
    { value: 1, nome: '1' },
    { value: 2, nome: '2' },
    { value: 3, nome: '3' },
    { value: 4, nome: '4' },
    { value: 5, nome: '5' }
  ];

  objetoCategoria = [
    { value: 1, nome: 'Notícia Área Aberta' },
    { value: 2, nome: 'Notícia Área Restrita' },
    { value: 3, nome: 'Comunicados' },
    { value: 4, nome: 'Credenciamento de contadores' }
  ];

  ngOnInit() {
    this.alternarAcessoRegistro();
  }

  formulario = this.formBuilder.group({
    titulo: ['', Validators.required],
    dataPublicacao: [this.dataAtual(), Validators.required],
    texto: ['', Validators.required],
    imagem: [''],
    nomeArquivo: [''],
    categoria: [0, Validators.required],
    acesso: [{ id: null }],
    usuario: { id: Number(localStorage.getItem('idUsuario')) },
    ativo: [true],
    destaque: [null],
    palavraChave: ['']
  });

  adicionarPalavra(event: string): void {
    const value = (event || '').trim();
    if (value && value.length >= this.limiteCaracteres) {
      this.toastr.error(`O nome da palavra excede o limite permitido de ${this.limiteCaracteres} caracteres.`);
      return;
    }
    if (this.palavrasChaves().length >= this.limiteMaximoPlavrasChaves) {
      this.toastr.error(`A quantidade de palavras excede o limite permitido de ${this.limiteMaximoPlavrasChaves} caracteres.`);
      return;
    }
    if (!this.palavrasChaves().includes(value)) this.palavrasChaves.update((palavraChave) => [...palavraChave, value]);
    this.palavraChaveInput.nativeElement.value = '';
  }

  removerPalavra(palavraChave: string): void {
    this.palavrasChaves.update((palavrasChaves) => {
      const index = palavrasChaves.indexOf(palavraChave);
      if (index < 0) {
        return palavrasChaves;
      }

      palavrasChaves.splice(index, 1);
      void this.anunciador.announce(`Removed ${palavraChave}`);
      return [...palavrasChaves];
    });
  }

  dataAtual(): string {
    const data = new Date();
    const diferencaFusoHorarioMinutos = data.getTimezoneOffset();
    data.setMinutes(data.getMinutes() - diferencaFusoHorarioMinutos);
    const dataEditada = data.toISOString().slice(0, 16);
    return dataEditada;
  }

  imagemSelecionada(eventFileDrop: NgxFileDropEntry[]) {
    const primeiroArquivo = eventFileDrop[0];

    if (primeiroArquivo?.fileEntry?.isFile) {
      const fileEntry = primeiroArquivo.fileEntry as FileSystemFileEntry;

      fileEntry.file((file: File) => {
        if (file.name.length > this.maxCaracteres) {
          this.toastr.error(`O nome do arquivo excede o limite permitido de ${this.maxCaracteres} caracteres.`);
          return;
        }
        if (!this.tamanhoMaximoArquivo(file.size)) {
          this.toastr.error('O arquivo excede o tamanho máximo permitido de 25MB.');
          return;
        }
        if (!file.type.startsWith('image/')) {
          this.toastr.error('Apenas imagem é aceita.');
          return;
        }
        this.formulario.controls.nomeArquivo.setValue(file.name);
        const leitorArquivo = new FileReader();
        leitorArquivo.onload = (event) => {
          this.formulario.controls.imagem.setValue((event.target.result as string).split(',')[1]);
        };
        this.cdr.detectChanges();
        leitorArquivo.readAsDataURL(file);
      });
    }
  }

  imagemsoltoClicado() {
    const elementoEntrada = document.createElement('input');
    elementoEntrada.type = 'file';
    elementoEntrada.multiple = false;
    elementoEntrada.accept = 'image/*';

    elementoEntrada.onchange = (event: Event) => {
      const alvo = event.target as HTMLInputElement;
      if (alvo.files) {
        const listaArquivo: FileList = alvo.files;
        const entradasDeArquivos: NgxFileDropEntry[] = [];
        Array.from(listaArquivo).forEach((arquivo) => {
          const fileEntry: FileSystemFileEntry = {
            isDirectory: false,
            isFile: true,
            file: (successCallback: (file: File) => void) => {
              successCallback(arquivo);
            },
            name: arquivo.name,
            fullPath: arquivo.name,
            filesystem: undefined,
            getParent: function (): void {
              throw new Error('Function not implemented.');
            }
          };
          entradasDeArquivos.push({ relativePath: arquivo.name, fileEntry });
        });
        this.imagemSelecionada(entradasDeArquivos);
      }
    };
    elementoEntrada.click();
  }

  public arquivosSelecionados(event: NgxFileDropEntry[], imagem: boolean) {
    const maximoArquivosPDF: number = 5;
    const maximoArquivosImagem: number = 10;

    if (
      event &&
      event.length > 0 &&
      ((event.length + this.pdf().length <= maximoArquivosPDF && !imagem) || (event.length + this.listaImagens().length <= maximoArquivosImagem && imagem))
    ) {
      for (const arquivoSelecionado of event) {
        if (arquivoSelecionado.fileEntry?.isFile) {
          const entradaArquivos = arquivoSelecionado.fileEntry as FileSystemFileEntry;
          entradaArquivos.file((arquivo: File) => {
            if (arquivo.name.length > this.maxCaracteres) {
              this.toastr.error(`O nome do arquivo ${arquivo.name} excede o limite permitido de ${this.maxCaracteres} caracteres.`);
              return;
            }
            if (!this.tamanhoMaximoArquivo(arquivo.size)) {
              this.toastr.error(`O arquivo ${arquivo.name} excede o tamanho máximo permitido de 25MB.`);
              return;
            }
            if (!this.verificarExtensao(arquivo.name) && !imagem) {
              this.toastr.error('Apenas arquivo no formatos PDF é aceito.');
              return;
            }
            if (imagem && !arquivo.type.startsWith('image/')) {
              this.toastr.error('Apenas imagem(s) são aceitas.');
              return;
            }
            const leitorArquivo = new FileReader();
            leitorArquivo.onload = (evento) => {
              const base64String = (evento.target.result as string).split(',')[1];
              const anexo: pdfAnexo = {
                id: 0,
                noticia: { id: 0 },
                nome: arquivo.name,
                arquivo: base64String,
                imagem: imagem
              };
              if (imagem) {
                this.listaImagens.update((imagens) => {
                  const existeItem = imagens.find((item) => item.nome === anexo.nome);
                  if (existeItem) {
                    existeItem.arquivo = anexo.arquivo;
                  } else {
                    imagens.push(anexo);
                  }
                  return imagens;
                });
              } else {
                this.pdf.update((pdfs) => {
                  const existeItem = pdfs.find((item) => item.nome === anexo.nome);
                  if (existeItem) {
                    existeItem.arquivo = anexo.arquivo;
                  } else {
                    pdfs.push(anexo);
                  }
                  return pdfs;
                });
              }
              this.cdr.detectChanges();
            };
            leitorArquivo.readAsDataURL(arquivo);
          });
        }
      }
    } else {
      if (imagem) {
        this.toastr.error(`As imagens excede  a quantidade máxima, permitido até  ${maximoArquivosImagem} imagens.`);
      } else {
        this.toastr.error(`O arquivos excede  a quantidade máxima, permitido até  ${maximoArquivosPDF} arquivos.`);
      }
    }
  }

  arquivossoltoClicado(tipoEnvio: boolean) {
    const elementoEntrada = document.createElement('input');
    elementoEntrada.type = 'file';
    elementoEntrada.multiple = true;
    if (tipoEnvio) elementoEntrada.accept = 'image/*';

    elementoEntrada.onchange = (event: Event) => {
      const alvo = event.target as HTMLInputElement;
      if (alvo.files) {
        const listaArquivo: FileList = alvo.files;
        const entradasDeArquivos: NgxFileDropEntry[] = [];
        Array.from(listaArquivo).forEach((arquivo) => {
          const fileEntry: FileSystemFileEntry = {
            isDirectory: false,
            isFile: true,
            file: (successCallback: (file: File) => void) => {
              successCallback(arquivo);
            },
            name: arquivo.name,
            fullPath: arquivo.name,
            filesystem: undefined,
            getParent: function (): void {
              throw new Error('Function not implemented.');
            }
          };
          entradasDeArquivos.push({ relativePath: arquivo.name, fileEntry });
        });
        this.arquivosSelecionados(entradasDeArquivos, tipoEnvio);
      }
    };
    elementoEntrada.click();
  }

  verificarExtensao(nomeArquivo: string) {
    let verificarExtensao = false;
    const extensaoPermitida = '.pdf';
    const regex = /(?:\.([^.]+))?$/;
    const extensao = regex.exec(nomeArquivo);
    if (undefined !== extensao && null !== extensao) {
      if (extensaoPermitida === extensao[0]) {
        verificarExtensao = true;
      }
    }
    return verificarExtensao;
  }

  tamanhoMaximoArquivo(tamanho: number): boolean {
    let tamanhoArquivo = false;
    if (tamanho < 25000000) {
      tamanhoArquivo = true;
    }
    return tamanhoArquivo;
  }

  public removerArquivo(indice: number, imagem: boolean): void {
    if (imagem) {
      this.listaImagens().splice(indice, 1);
      this.listaImagens.set(this.listaImagens());
    } else {
      this.pdf().splice(indice, 1);
      this.pdf.set(this.pdf());
    }
  }

  removerimagem() {
    this.formulario.controls.imagem.setValue('');
    this.formulario.controls.nomeArquivo.setValue('');
  }

  cancelarEnvio() {
    void this.router.navigate(['area-restrita/administracao-noticia', 'cadastrar-noticia']);
  }
  alternarAcessoRegistro(): void {
    const categoriaControl = this.formulario.get('categoria').value.toString();
    this.campoCategoriaAbertaRestrita.set(true);
    if (categoriaControl === '1') {
      this.campoAcesso.set(false);
      this.campoDestaque.set(true);
      this.formulario.get('acesso').setValue(null);
    } else if (categoriaControl === '2') {
      this.campoAcesso.set(true);
      this.campoDestaque.set(false);
      this.formulario.get('destaque').setValue(null);
    } else {
      this.campoCategoriaAbertaRestrita.set(false);
      this.campoDestaque.set(false);
      this.campoAcesso.set(false);
      this.formulario.get('acesso').setValue(null);
      this.formulario.get('destaque').setValue(null);
      this.removerimagem();
    }
  }

  enviarFormulario(): void {
    if (this.formulario.valid && this.verificacao()) {
      const copiaFormulario = this.ajustarFormulario(this.formulario.getRawValue());
      this.noticiaService
        .incluir(copiaFormulario)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (response) => {
            if (this.pdf().length > 0 || this.listaImagens().length > 0) {
              this.enviarFomularioArquivos(response.id);
            }
            this.toastr.success('Enviado com sucesso.');
            setTimeout(() => {
              void this.router.navigate(['area-restrita/administracao-noticia', 'cadastrar-noticia']);
            }, 500);
          },
          (error: HttpErrorResponse) => {
            this.toastr.error('Erro ao enviar o formulário: ' + error.message);
          }
        );
    } else {
      this.toastr.error('Falta preencher campos obrigatórios.');
    }
  }

  enviarFomularioArquivos(idNoticia: number) {
    this.pdf().forEach((anexo) => (anexo.noticia.id = idNoticia));
    this.listaImagens().forEach((anexo) => (anexo.noticia.id = idNoticia));
    this.listaTotalArquivos = [...this.pdf(), ...this.listaImagens()];
    this.noticiaService
      .incluirArquivo(this.listaTotalArquivos)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response) => {
          console.log(response);
        },
        (error: HttpErrorResponse) => {
          this.toastr.error('Erro ao enviar os arquivos.' + error.message);
        }
      );
  }

  ajustarFormulario(formularioValue) {
    const dataPublicacao = new Date(formularioValue.dataPublicacao);
    const dia = ('0' + dataPublicacao.getDate()).slice(-2);
    const mes = ('0' + (dataPublicacao.getMonth() + 1)).slice(-2);
    const ano = dataPublicacao.getFullYear();
    const hora = ('0' + dataPublicacao.getHours()).slice(-2);
    const minutos = ('0' + dataPublicacao.getMinutes()).slice(-2);
    const segundos = ('0' + dataPublicacao.getSeconds()).slice(-2);

    formularioValue.dataPublicacao = `${dia}/${mes}/${ano} ${hora}:${minutos}:${segundos}`;

    formularioValue.categoria = { id: Number(formularioValue.categoria) };
    formularioValue.acesso = formularioValue.acesso !== null ? { id: Number(formularioValue.acesso) } : null;
    formularioValue.destaque = formularioValue.destaque == null || formularioValue.destaque == 0 ? null : Number(formularioValue.destaque);
    formularioValue.palavraChave = this.palavrasChaves()
      .map((palavra) => palavra)
      .join(' ');
    return formularioValue;
  }

  verificacao(): boolean {
    const categoriaControl = Number(this.formulario.get('categoria').value);
    const acessoControl = this.formulario.get('acesso').value;
    const imagemControl = this.formulario.get('imagem').value;
    if ((categoriaControl === 2 && acessoControl !== null) || (categoriaControl === 1 && acessoControl === null && imagemControl !== '')) {
      return true;
    } else if (
      (categoriaControl === 3 && acessoControl === null && imagemControl === '') ||
      (categoriaControl === 4 && acessoControl === null && imagemControl === '')
    ) {
      return true;
    }
    return false;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
