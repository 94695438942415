import { Injectable } from '@angular/core';

class ShaContext {
  public databyte: number[];
  public dataword: number[];
  public digest: number[];
  public countHi: number;
  public countLo: number;
}

@Injectable({
  providedIn: 'root'
})
export class ShaService {
  private readonly k1: number = 0x5a827999;
  private readonly k2: number = 0x6ed9eba1;
  private readonly k3: number = 0x8f1bbcdc;
  private readonly k4: number = 0xca62c1d6;

  public executaHashing(origem: string): string {
    return this.shahash(origem, origem.length);
  }

  private shahash(data: string, size: number): string {
    const shaInfo = new ShaContext();
    const hash: number[] = Array.from({ length: 5 }, () => 0);
    this.shaInit(shaInfo);
    this.shaUpdate(shaInfo, data, size);
    this.shaFinal(shaInfo, hash);
    return hash.map((h) => this.shahex(h)).join('');
  }

  private shaTransform(shaInfo: ShaContext): void {
    for (let i = 0; i < 16; i++)
      shaInfo.dataword[i] =
        (shaInfo.databyte[i * 4] << 24) + (shaInfo.databyte[i * 4 + 1] << 16) + (shaInfo.databyte[i * 4 + 2] << 8) + shaInfo.databyte[i * 4 + 3];
    let A = shaInfo.digest[0];
    let B = shaInfo.digest[1];
    let C = shaInfo.digest[2];
    let D = shaInfo.digest[3];
    let E = shaInfo.digest[4];
    E = this.subRound1A(A, B, C, D, E, this.k1, shaInfo.dataword, 0);
    B = this.rotA(B);
    D = this.subRound1A(E, A, B, C, D, this.k1, shaInfo.dataword, 1);
    A = this.rotA(A);
    C = this.subRound1A(D, E, A, B, C, this.k1, shaInfo.dataword, 2);
    E = this.rotA(E);
    B = this.subRound1A(C, D, E, A, B, this.k1, shaInfo.dataword, 3);
    D = this.rotA(D);
    A = this.subRound1A(B, C, D, E, A, this.k1, shaInfo.dataword, 4);
    C = this.rotA(C);
    E = this.subRound1A(A, B, C, D, E, this.k1, shaInfo.dataword, 5);
    B = this.rotA(B);
    D = this.subRound1A(E, A, B, C, D, this.k1, shaInfo.dataword, 6);
    A = this.rotA(A);
    C = this.subRound1A(D, E, A, B, C, this.k1, shaInfo.dataword, 7);
    E = this.rotA(E);
    B = this.subRound1A(C, D, E, A, B, this.k1, shaInfo.dataword, 8);
    D = this.rotA(D);
    A = this.subRound1A(B, C, D, E, A, this.k1, shaInfo.dataword, 9);
    C = this.rotA(C);
    E = this.subRound1A(A, B, C, D, E, this.k1, shaInfo.dataword, 10);
    B = this.rotA(B);
    D = this.subRound1A(E, A, B, C, D, this.k1, shaInfo.dataword, 11);
    A = this.rotA(A);
    C = this.subRound1A(D, E, A, B, C, this.k1, shaInfo.dataword, 12);
    E = this.rotA(E);
    B = this.subRound1A(C, D, E, A, B, this.k1, shaInfo.dataword, 13);
    D = this.rotA(D);
    A = this.subRound1A(B, C, D, E, A, this.k1, shaInfo.dataword, 14);
    C = this.rotA(C);
    E = this.subRound1A(A, B, C, D, E, this.k1, shaInfo.dataword, 15);
    B = this.rotA(B);
    D = this.subRound1B(E, A, B, C, D, this.k1, shaInfo.dataword, 16);
    A = this.rotA(A);
    C = this.subRound1B(D, E, A, B, C, this.k1, shaInfo.dataword, 17);
    E = this.rotA(E);
    B = this.subRound1B(C, D, E, A, B, this.k1, shaInfo.dataword, 18);
    D = this.rotA(D);
    A = this.subRound1B(B, C, D, E, A, this.k1, shaInfo.dataword, 19);
    C = this.rotA(C);
    E = this.subRound2(A, B, C, D, E, this.k2, shaInfo.dataword, 20);
    B = this.rotA(B);
    D = this.subRound2(E, A, B, C, D, this.k2, shaInfo.dataword, 21);
    A = this.rotA(A);
    C = this.subRound2(D, E, A, B, C, this.k2, shaInfo.dataword, 22);
    E = this.rotA(E);
    B = this.subRound2(C, D, E, A, B, this.k2, shaInfo.dataword, 23);
    D = this.rotA(D);
    A = this.subRound2(B, C, D, E, A, this.k2, shaInfo.dataword, 24);
    C = this.rotA(C);
    E = this.subRound2(A, B, C, D, E, this.k2, shaInfo.dataword, 25);
    B = this.rotA(B);
    D = this.subRound2(E, A, B, C, D, this.k2, shaInfo.dataword, 26);
    A = this.rotA(A);
    C = this.subRound2(D, E, A, B, C, this.k2, shaInfo.dataword, 27);
    E = this.rotA(E);
    B = this.subRound2(C, D, E, A, B, this.k2, shaInfo.dataword, 28);
    D = this.rotA(D);
    A = this.subRound2(B, C, D, E, A, this.k2, shaInfo.dataword, 29);
    C = this.rotA(C);
    E = this.subRound2(A, B, C, D, E, this.k2, shaInfo.dataword, 30);
    B = this.rotA(B);
    D = this.subRound2(E, A, B, C, D, this.k2, shaInfo.dataword, 31);
    A = this.rotA(A);
    C = this.subRound2(D, E, A, B, C, this.k2, shaInfo.dataword, 32);
    E = this.rotA(E);
    B = this.subRound2(C, D, E, A, B, this.k2, shaInfo.dataword, 33);
    D = this.rotA(D);
    A = this.subRound2(B, C, D, E, A, this.k2, shaInfo.dataword, 34);
    C = this.rotA(C);
    E = this.subRound2(A, B, C, D, E, this.k2, shaInfo.dataword, 35);
    B = this.rotA(B);
    D = this.subRound2(E, A, B, C, D, this.k2, shaInfo.dataword, 36);
    A = this.rotA(A);
    C = this.subRound2(D, E, A, B, C, this.k2, shaInfo.dataword, 37);
    E = this.rotA(E);
    B = this.subRound2(C, D, E, A, B, this.k2, shaInfo.dataword, 38);
    D = this.rotA(D);
    A = this.subRound2(B, C, D, E, A, this.k2, shaInfo.dataword, 39);
    C = this.rotA(C);
    E = this.subRound3(A, B, C, D, E, this.k3, shaInfo.dataword, 40);
    B = this.rotA(B);
    D = this.subRound3(E, A, B, C, D, this.k3, shaInfo.dataword, 41);
    A = this.rotA(A);
    C = this.subRound3(D, E, A, B, C, this.k3, shaInfo.dataword, 42);
    E = this.rotA(E);
    B = this.subRound3(C, D, E, A, B, this.k3, shaInfo.dataword, 43);
    D = this.rotA(D);
    A = this.subRound3(B, C, D, E, A, this.k3, shaInfo.dataword, 44);
    C = this.rotA(C);
    E = this.subRound3(A, B, C, D, E, this.k3, shaInfo.dataword, 45);
    B = this.rotA(B);
    D = this.subRound3(E, A, B, C, D, this.k3, shaInfo.dataword, 46);
    A = this.rotA(A);
    C = this.subRound3(D, E, A, B, C, this.k3, shaInfo.dataword, 47);
    E = this.rotA(E);
    B = this.subRound3(C, D, E, A, B, this.k3, shaInfo.dataword, 48);
    D = this.rotA(D);
    A = this.subRound3(B, C, D, E, A, this.k3, shaInfo.dataword, 49);
    C = this.rotA(C);
    E = this.subRound3(A, B, C, D, E, this.k3, shaInfo.dataword, 50);
    B = this.rotA(B);
    D = this.subRound3(E, A, B, C, D, this.k3, shaInfo.dataword, 51);
    A = this.rotA(A);
    C = this.subRound3(D, E, A, B, C, this.k3, shaInfo.dataword, 52);
    E = this.rotA(E);
    B = this.subRound3(C, D, E, A, B, this.k3, shaInfo.dataword, 53);
    D = this.rotA(D);
    A = this.subRound3(B, C, D, E, A, this.k3, shaInfo.dataword, 54);
    C = this.rotA(C);
    E = this.subRound3(A, B, C, D, E, this.k3, shaInfo.dataword, 55);
    B = this.rotA(B);
    D = this.subRound3(E, A, B, C, D, this.k3, shaInfo.dataword, 56);
    A = this.rotA(A);
    C = this.subRound3(D, E, A, B, C, this.k3, shaInfo.dataword, 57);
    E = this.rotA(E);
    B = this.subRound3(C, D, E, A, B, this.k3, shaInfo.dataword, 58);
    D = this.rotA(D);
    A = this.subRound3(B, C, D, E, A, this.k3, shaInfo.dataword, 59);
    C = this.rotA(C);
    E = this.subRound4(A, B, C, D, E, this.k4, shaInfo.dataword, 60);
    B = this.rotA(B);
    D = this.subRound4(E, A, B, C, D, this.k4, shaInfo.dataword, 61);
    A = this.rotA(A);
    C = this.subRound4(D, E, A, B, C, this.k4, shaInfo.dataword, 62);
    E = this.rotA(E);
    B = this.subRound4(C, D, E, A, B, this.k4, shaInfo.dataword, 63);
    D = this.rotA(D);
    A = this.subRound4(B, C, D, E, A, this.k4, shaInfo.dataword, 64);
    C = this.rotA(C);
    E = this.subRound4(A, B, C, D, E, this.k4, shaInfo.dataword, 65);
    B = this.rotA(B);
    D = this.subRound4(E, A, B, C, D, this.k4, shaInfo.dataword, 66);
    A = this.rotA(A);
    C = this.subRound4(D, E, A, B, C, this.k4, shaInfo.dataword, 67);
    E = this.rotA(E);
    B = this.subRound4(C, D, E, A, B, this.k4, shaInfo.dataword, 68);
    D = this.rotA(D);
    A = this.subRound4(B, C, D, E, A, this.k4, shaInfo.dataword, 69);
    C = this.rotA(C);
    E = this.subRound4(A, B, C, D, E, this.k4, shaInfo.dataword, 70);
    B = this.rotA(B);
    D = this.subRound4(E, A, B, C, D, this.k4, shaInfo.dataword, 71);
    A = this.rotA(A);
    C = this.subRound4(D, E, A, B, C, this.k4, shaInfo.dataword, 72);
    E = this.rotA(E);
    B = this.subRound4(C, D, E, A, B, this.k4, shaInfo.dataword, 73);
    D = this.rotA(D);
    A = this.subRound4(B, C, D, E, A, this.k4, shaInfo.dataword, 74);
    C = this.rotA(C);
    E = this.subRound4(A, B, C, D, E, this.k4, shaInfo.dataword, 75);
    B = this.rotA(B);
    D = this.subRound4(E, A, B, C, D, this.k4, shaInfo.dataword, 76);
    A = this.rotA(A);
    C = this.subRound4(D, E, A, B, C, this.k4, shaInfo.dataword, 77);
    E = this.rotA(E);
    B = this.subRound4(C, D, E, A, B, this.k4, shaInfo.dataword, 78);
    D = this.rotA(D);
    A = this.subRound4(B, C, D, E, A, this.k4, shaInfo.dataword, 79);
    C = this.rotA(C);
    shaInfo.digest[0] = (shaInfo.digest[0] + A) & 0xffffffff;
    shaInfo.digest[1] = (shaInfo.digest[1] + B) & 0xffffffff;
    shaInfo.digest[2] = (shaInfo.digest[2] + C) & 0xffffffff;
    shaInfo.digest[3] = (shaInfo.digest[3] + D) & 0xffffffff;
    shaInfo.digest[4] = (shaInfo.digest[4] + E) & 0xffffffff;
  }

  private shaInit(shaInfo: ShaContext): void {
    shaInfo.digest = [0x67452301, 0xefcdab89, 0x98badcfe, 0x10325476, 0xc3d2e1f0];
    shaInfo.databyte = new Array(64).fill(0) as number[];
    shaInfo.dataword = new Array(16).fill(0) as number[];
    shaInfo.countLo = 0;
    shaInfo.countHi = 0;
  }

  private shaUpdate(shaInfo: ShaContext, buffer: string, count: number): void {
    const t = shaInfo.countLo;
    if ((shaInfo.countLo = t + count) < t) shaInfo.countHi++;
    let dataCount = t & 0x3f;
    if (dataCount) {
      const p = dataCount;
      dataCount = 64 - dataCount;
      if (count < dataCount) {
        for (let i = 0; i < count; i++) shaInfo.databyte[i + p] = buffer.charCodeAt(i);
        return;
      }
      for (let i = 0; i < dataCount; i++) shaInfo.databyte[i + p] = buffer.charCodeAt(i);
      this.shaTransform(shaInfo);
      buffer = buffer.substring(dataCount);
      count -= dataCount;
    }
    while (count >= 64) {
      for (let i = 0; i < 64; i++) shaInfo.databyte[i] = buffer.charCodeAt(i);
      this.shaTransform(shaInfo);
      buffer = buffer.substring(64);
      count -= 64;
    }
    for (let i = 0; i < count; i++) shaInfo.databyte[i] = buffer.charCodeAt(i);
  }

  private shaFinal(shaInfo: ShaContext, digest: number[]): void {
    let count = shaInfo.countLo & 0x3f;
    let p = count;
    shaInfo.databyte[p] = 0x80;
    p++;
    count = 63 - count;
    if (count < 8) {
      this.shaTransform(shaInfo);
      for (let i = 0; i < 56; i++) shaInfo.databyte[i] = 0;
    } else {
      for (let i = 0; i < count - 8; i++) shaInfo.databyte[i + p] = 0;
    }
    shaInfo.databyte[56] = (((shaInfo.countHi << 3) | (shaInfo.countLo >>> 29)) >>> 24) & 0x000000ff;
    shaInfo.databyte[57] = (((shaInfo.countHi << 3) | (shaInfo.countLo >>> 29)) >>> 16) & 0x000000ff;
    shaInfo.databyte[58] = (((shaInfo.countHi << 3) | (shaInfo.countLo >>> 29)) >>> 8) & 0x000000ff;
    shaInfo.databyte[59] = ((shaInfo.countHi << 3) | (shaInfo.countLo >>> 29)) & 0x000000ff;
    shaInfo.databyte[60] = ((shaInfo.countLo << 3) >>> 24) & 0x000000ff;
    shaInfo.databyte[61] = ((shaInfo.countLo << 3) >>> 16) & 0x000000ff;
    shaInfo.databyte[62] = ((shaInfo.countLo << 3) >>> 8) & 0x000000ff;
    shaInfo.databyte[63] = (shaInfo.countLo << 3) & 0x000000ff;
    this.shaTransform(shaInfo);
    for (let i = 0; i < 5; i++) digest[i] = shaInfo.digest[i];
    for (let i = 0; i < 16; i++) shaInfo.databyte[i] = 0;
    for (let i = 0; i < 64; i++) shaInfo.dataword[i] = 0;
    for (let i = 0; i < 5; i++) shaInfo.digest[i] = 0;
    shaInfo.countHi = 0;
    shaInfo.countLo = 0;
  }

  private subRound1A(a: number, b: number, c: number, d: number, e: number, k: number, data: number[], i: number): number {
    e += ((a << 5) | (a >>> 27)) + (d ^ (b & (c ^ d))) + k + data[i];
    e = e & 0xffffffff;
    return e;
  }

  private subRound1B(e: number, a: number, b: number, c: number, d: number, k: number, data: number[], i: number): number {
    d +=
      ((e << 5) | (e >>> 27)) +
      (c ^ (a & (b ^ c))) +
      k +
      ((data[i & 15] ^= data[(i - 14) & 15] ^ data[(i - 8) & 15] ^ data[(i - 3) & 15]), (data[i & 15] = (data[i & 15] << 1) | (data[i & 15] >>> 31)));
    d = d & 0xffffffff;
    return d;
  }

  private subRound2(a: number, b: number, c: number, d: number, e: number, k: number, data: number[], i: number): number {
    e +=
      ((a << 5) | (a >>> 27)) +
      (b ^ c ^ d) +
      k +
      ((data[i & 15] ^= data[(i - 14) & 15] ^ data[(i - 8) & 15] ^ data[(i - 3) & 15]), (data[i & 15] = (data[i & 15] << 1) | (data[i & 15] >>> 31)));
    e = e & 0xffffffff;
    return e;
  }

  private subRound3(a: number, b: number, c: number, d: number, e: number, k: number, data: number[], i: number): number {
    e +=
      ((a << 5) | (a >>> 27)) +
      ((b & c) | (d & (b | c))) +
      k +
      ((data[i & 15] ^= data[(i - 14) & 15] ^ data[(i - 8) & 15] ^ data[(i - 3) & 15]), (data[i & 15] = (data[i & 15] << 1) | (data[i & 15] >>> 31)));
    e = e & 0xffffffff;
    return e;
  }

  private subRound4(a: number, b: number, c: number, d: number, e: number, k: number, data: number[], i: number): number {
    e +=
      ((a << 5) | (a >>> 27)) +
      (b ^ c ^ d) +
      k +
      ((data[i & 15] ^= data[(i - 14) & 15] ^ data[(i - 8) & 15] ^ data[(i - 3) & 15]), (data[i & 15] = (data[i & 15] << 1) | (data[i & 15] >>> 31)));
    e = e & 0xffffffff;
    return e;
  }

  private rotA(a: number): number {
    return (a << 30) | (a >>> 2);
  }

  private shahex(i: number): string {
    const sHex = '0123456789ABCDEF';
    let h = '';
    for (let j = 3; j >= 0; j--) {
      h += sHex.charAt((i >>> (j * 8 + 4)) & 0x0f) + sHex.charAt((i >>> (j * 8)) & 0x0f);
    }
    return h;
  }
}
