<app-separador titulo="NOTÍCIAS DIVERSAS" />

<div class="container">
  @for (noticia of objNoticias(); track noticia.id) {
    <div class="news-card" tabindex="0" (click)="abrirUmaNoticia(noticia.id!)" (keypress)="abrirUmaNoticia(noticia.id!)">
      @if (noticia.imagem) {
        <div class="news-card-image">
          <img alt="Imagem da Notícia" tabindex="0" [src]="obterImagem(noticia?.imagem)" (click)="abrirUmaNoticia(noticia.id!)" (keypress)="abrirUmaNoticia(noticia.id!)" />
        </div>
      }
      <div class="news-detail">
        <span class="titulo">{{ noticia.titulo }}</span>
        <span class="date">{{ noticia.dataPublicacao }}</span>
        <span class="description">{{ ajusteTexto(noticia?.texto!) }}</span>
      </div>
    </div>
  }
</div>
@if (objNoticias().length > 0) {
  <div>
    <mat-paginator
      #paginator
      class="custom-paginator"
      aria-label="Select page"
      showFirstLastButtons
      [length]="comprimento()"
      [pageSize]="tamanhoDaPagina()"
      [pageSizeOptions]="opcoesDoTamanhoDaPagina"
      [pageIndex]="numeroPagina()"
      (page)="lidarEventoPagina($event)"
    />
  </div>
}
