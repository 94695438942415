<div class="search-results-container">
  <h1>Resultados para: {{ buscar() }}</h1>
  @if (!carregando()) {
    <mat-list>
      @for (item of resultadoBusca(); track item) {
        <mat-list-item class="list-item">
          <header>
            <h2>
              @if (item.redirecionar) {
                @if (!item.externo) {
                  <a class="link-responsividade" [routerLink]="item.redirecionar">{{ item.titulo }}</a>
                } @else {
                  <a class="link-responsividade" target="_blank" [href]="item.redirecionar">{{ item.titulo }}</a>
                }
              } @else {
                <a class="link-responsividade" [href]="item.url">{{ item.titulo }}</a>
              }
            </h2>
          </header>
          @if ((item.dataPublicacao || "") !== "") {
            <p>{{ item.dataPublicacao }}</p>
          }
          @if (item.texto) {
            <p class="description">
              {{ ajusteHTML(item.texto).trim() }}
              <span matLine class="read-more" [routerLink]="item.url">Leia mais</span>
            </p>
          }
          <mat-divider />
        </mat-list-item>
      }
    </mat-list>
  } @else {
    <div class="spinner-container">
      <mat-spinner />
    </div>
  }
</div>

<ng-template #spinner>
  <div class="spinner-container">
    <mat-spinner />
  </div>
</ng-template>
