import { Injectable, inject } from '@angular/core';
import type { MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastComponent } from './toast.component';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  private readonly _snackBar = inject(MatSnackBar);

  success(message: string, title?: string, config?: MatSnackBarConfig) {
    this.openSnackBar(message, title, 'success', config);
  }
  warning(message: string, title?: string, config?: MatSnackBarConfig) {
    this.openSnackBar(message, title, 'warning', config);
  }

  error(message: string, title?: string, config?: MatSnackBarConfig) {
    this.openSnackBar(message, title, 'error', config);
  }

  info(message: string, title?: string, config?: MatSnackBarConfig) {
    this.openSnackBar(message, title, 'info', config);
  }

  private openSnackBar(message: string, title: string, status: string, config?: MatSnackBarConfig) {
    this._snackBar.openFromComponent(ToastComponent, {
      ...config,
      duration: config?.duration || 2000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: [`snack-bar-${status}`],
      data: {
        message,
        status,
        title
      }
    });
  }
}
