import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SeparadorComponent } from '../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-atendimento-protestos-icms-ipva-itcmd-certidao-negativa',
  templateUrl: './atendimento-protestos-icms-ipva-itcmd-certidao-negativa.component.html',
  styleUrl: './atendimento-protestos-icms-ipva-itcmd-certidao-negativa.component.scss',
  standalone: true,

  imports: [SeparadorComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AtendimentoProtestosIcmsIpvaItcmdCertidaoNegativaComponent {}
