<main class="main" role="main">
  <section class="page">
    <div class="flex-container-separador">
      <app-separador titulo="Subprocuradoria" />
      <div class="flex-spacer-separador"></div>
      <app-separador-email
        titulo="ATENDIMENTO"
        msg1="Horário: de segunda a sexta-feira, das 8h às 20h"
        msg2="E-mail: sgcgeral@sp.gov.br"
        msg3="Telefones: (11) 3372-6447"
        msg4="Endereço: Rua Pamplona,227, 5º andar, Jardim Paulista, São Paulo-SP, 01405-902"
      />
    </div>
    <div class="row">
      <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
        <h2>Subprocuradoria Geral da Consultoria Geral</h2>
        <p><strong>Subprocurador(a) Geral:</strong> Alessandra Obara Soares da Silva</p>
        <div class="pge-unidade">
          <p><strong>Subprocurador(a) Geral Adjunto(a):</strong> Julia Maria Plenamente Silva</p>
        </div>
        <p>A Subprocuradoria Geral da Consultoria Geral é responsável por dois papéis básicos: assessoramento e consultoria jurídica aos gestores públicos.</p>
        <p>
          Com unidades que atendem todas as Secretarias estaduais e as autarquias, o trabalho vai além da análise da conformidade dos atos e decisões administrativas às leis e atos normativos,
          compreendendo o assessoramento do Governo do Estado desde concepção até a implementação de projetos e políticas públicas.
        </p>
        <p><strong>Assistentes/Assessores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">ANDRE RODRIGUES JUNQUEIRA</p>
          <p class="demais-procuradores">CAIO CESAR ALVES FERREIRA RAMOS</p>
          <p class="demais-procuradores">CLAUDIA APARECIDA CIMARDI</p>
          <p class="demais-procuradores">DIANA LOUREIRO PAIVA DE CASTRO</p>
          <p class="demais-procuradores">FABRICIO CONTATO LOPES RESENDE</p>
          <p class="demais-procuradores">GUILHERME MARTINS PELLEGRINI</p>
          <p class="demais-procuradores">IAGO OLIVEIRA FERREIRA</p>
          <p class="demais-procuradores">JULIA MARIA PLENAMENTE SILVA</p>
          <p class="demais-procuradores">SABRINA FERREIRA NOVIS DE MORAES</p>
          <p class="demais-procuradores">ANDRE LUIZ DOS SANTOS NAKAMURA</p>
          <p class="demais-procuradores">MARINA DE LIMA LOPES</p>
          <p class="demais-procuradores">AMANDA BEZERRA DE ALMEIDA</p>
          <p class="demais-procuradores">ANSELMO PRIETO ALVAREZ</p>
          <p class="demais-procuradores">JOYCE SAYURI SAITO</p>
          <p class="demais-procuradores">SIDNEI PASCHOAL BRAGA</p>
          <p class="demais-procuradores">CAROLINA PELLEGRINI MAIA ROVINA LUNKES</p>
          <p class="demais-procuradores">ELISANGELA DA LIBRACAO</p>
          <p class="demais-procuradores">PAULA DE SIQUEIRA NUNES</p>
          <p class="demais-procuradores">THAMY KAWAI MARCOS</p>
        </div>
        <p>&nbsp;</p>
      </div>
    </div>
  </section>
</main>
