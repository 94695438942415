<main class="main" role="main">
  <app-separador titulo="Comunicados" />
  <section class="noticias-lista">
    <div id="is-content" class="row">
      @for (obj of objComunicados(); track obj.id) {
        <div class="is-post col-xl-6 col-lg-6 col-md-6 col-md-6 col-sm-12 col-xs-12">
          <div class="noticia">
            <div class="the_content">
              <span class="data" [innerHTML]="editarData(obj.dataPublicacao)"></span>
              <h5>
                <a class="tipoLink" tabindex="0" (click)="comunicado(obj.id!)" (keypress)="comunicado(obj.id!)">{{ obj.titulo }}</a>
              </h5>
            </div>
          </div>
        </div>
      }
    </div>
  </section>
  <div>
    <mat-paginator
      #paginator
      class="custom-paginator"
      aria-label="Select page"
      showFirstLastButtons
      [length]="comprimento()"
      [pageSize]="tamanhoDaPagina()"
      [pageSizeOptions]="opcoesDoTamanhoDaPagina"
      [pageIndex]="numeroPagina()"
      (page)="lidarEventoPagina($event)"
    />
  </div>
</main>
