import { Component } from '@angular/core';
import { SeparadorComponent } from '../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-certidao-negativa',
  templateUrl: './certidao-negativa.component.html',
  styleUrl: './certidao-negativa.component.sass',
  standalone: true,
  imports: [SeparadorComponent]
})
export class CertidaoNegativaComponent {}
