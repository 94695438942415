import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EstadoFiltroConsultarNoticia {
  public titulo = '';
  public ativo = '';
  public dataPublicacao = '';
  public idAcesso = null;
  public destaque = '';
  public categoria = '';
  public page = 0;
  public size = 10;
}
