import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import type { Observable } from 'rxjs';
import { catchError, map, throwError } from 'rxjs';
import { Globais } from 'src/app/area-restrita/shared/globais/globais';
import type { Page } from 'src/app/shared/models/paginacao/page.model';
import type { AuxilioSaudeMesaAnalise, FormAuxilioSaudeMesaAnalise, Perfil, StatusAuxilio } from '../models/auxilio-saude';

@Injectable({
  providedIn: 'root'
})
export class MesaAuxilioSaudeService {
  private readonly http = inject(HttpClient);

  public obterListaStatusAuxilio(): Observable<StatusAuxilio[]> {
    return this.http.get<StatusAuxilio[]>(`${Globais.urlToken}site-pge/api/status-auxilios`).pipe(
      catchError(() => {
        return throwError(() => 'Falha no envio de dados.');
      })
    );
  }

  public obterListaPerfis(): Observable<Perfil[]> {
    return this.http.get<Perfil[]>(`${Globais.urlToken}site-pge/api/perfis`).pipe(
      catchError(() => {
        return throwError(() => 'Falha no envio de dados.');
      })
    );
  }

  public buscarPedidos(
    dadosFormulario: FormGroup<FormAuxilioSaudeMesaAnalise>,
    pageIndex: string | number,
    pageSize: string | number
  ): Observable<Page<AuxilioSaudeMesaAnalise>> {
    const dataRecebida = dadosFormulario.controls.mesAnoSolicitacao.value ?? '';
    const dataInicio = dadosFormulario.controls.periodoDe.value ? new Date(dadosFormulario.controls.periodoDe.value).toISOString().substring(0, 10) : '';
    const dataFinal = dadosFormulario.controls.periodoAte.value ? new Date(dadosFormulario.controls.periodoAte.value).toISOString().substring(0, 10) : '';

    let parametros = new HttpParams()
      .set('nomeUsuario', dadosFormulario.controls.procuradorServidor.value ?? '')
      .set('idStatus', dadosFormulario.controls.status.value ?? '')
      .set('idPerfil', dadosFormulario.controls.perfil.value ?? '')
      .set('idTipoAuxilioSaude', dadosFormulario.controls.tipoAuxilioSaude.value ?? '')
      .set('dataEnvioInicial', dataInicio)
      .set('dataEnvioFinal', dataFinal)
      .set('idTipoAuxilio', '2') // TODO Modificar para receber variavel conforme o caso
      .set('mesAnoOrdem', dadosFormulario.controls.mesAnoOrdem.value ?? false)
      .set('page', pageIndex)
      .set('size', pageSize);
    if (dataRecebida && dataRecebida.trim() !== '') {
      const partesData = dataRecebida.split('-');
      parametros = parametros.set('anoReembolso', partesData[0] ?? '');
      parametros = parametros.set('mesParcela', partesData[1] ?? '');
    }

    return this.http.get<Page<AuxilioSaudeMesaAnalise>>(`${Globais.urlToken}site-pge/api/auxilios/consulta/simplificada`, { params: parametros }).pipe(
      map((response) => {
        return response;
      })
    );
  }

  public deferirPedidos(idsAuxilios: Set<number>): Observable<string> {
    const idUsuario = Number(localStorage.getItem('idUsuario'));
    const parametros = new HttpParams().set('idUsuario', idUsuario.toString());

    return this.http.put(`${Globais.urlToken}site-pge/api/auxilios/deferir`, Array.from(idsAuxilios), { params: parametros, responseType: 'text' }).pipe(
      catchError(() => {
        return throwError(() => new Error('Ocorreu um erro ao deferir os pedidos. Por favor, tente novamente mais tarde.'));
      })
    );
  }

  public indeferirPedidos(idsAuxilios: Set<number>): Observable<string> {
    const idUsuario = Number(localStorage.getItem('idUsuario'));
    const parametros = new HttpParams().set('idUsuario', idUsuario.toString());

    return this.http
      .put(`${Globais.urlToken}site-pge/api/auxilios/indeferir`, Array.from(idsAuxilios), {
        params: parametros,
        responseType: 'text'
      })
      .pipe(
        catchError(() => {
          return throwError(() => new Error('Ocorreu um erro ao deferir os pedidos. Por favor, tente novamente mais tarde.'));
        })
      );
  }
}
