import type { ProcuradorConselho } from '../../../../../shared/models/procuradores-conselho.interface';

import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { RouterLink } from '@angular/router';
import { ProcuradoresConselho } from 'src/app/shared/models/procuradores-conselho';
import { SeparadorComponent } from '../../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-composicao-conselho',
  templateUrl: './composicao-conselho.component.html',
  styleUrl: './composicao-conselho.component.scss',
  standalone: true,
  imports: [SeparadorComponent, RouterLink, MatGridList, MatGridTile]
})
export class ComposicaoConselhoComponent implements OnInit {
  procuradores: ProcuradorConselho[];
  presidente: ProcuradorConselho;
  listaProcuradoresNatos: ProcuradorConselho[] = [];
  listaProcuradoresEleitos: ProcuradorConselho[] = [];

  private readonly _larguraRedimensionada = 640;

  constructor() {
    this.procuradores = ProcuradoresConselho;
    this.colunasGrade = window.innerWidth <= this._larguraRedimensionada ? 1 : 2;
  }

  colunasGrade = 2;

  ngOnInit(): void {
    this.preencheCampos();
  }

  preencheCampos() {
    for (let index = 0; index < this.procuradores.length; index++) {
      if (this.procuradores[index].titulo == 'Presidente') {
        this.presidente = this.procuradores[index];
        this.presidente.imagem = 'images/procuradores/imagem' + index + '.jpg';
      } else if (this.procuradores[index].titulo == 'Membro Nato') {
        this.procuradores[index].imagem = 'images/procuradores/imagem' + index + '.jpg';
        this.listaProcuradoresNatos.push(this.procuradores[index]);
      } else {
        this.procuradores[index].imagem = 'images/procuradores/imagem' + index + '.jpg';
        this.listaProcuradoresEleitos.push(this.procuradores[index]);
      }
    }
  }

  onResize(event) {
    this.colunasGrade = event.target.innerWidth <= this._larguraRedimensionada ? 1 : 2;
  }
}
