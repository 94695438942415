import { Component } from '@angular/core';
import { SeparadorComponent } from '../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-ex-procuradores-gerais',
  templateUrl: './ex-procuradores-gerais.component.html',
  standalone: true,

  imports: [SeparadorComponent]
})
export class ExProcuradoresGeraisComponent {}
