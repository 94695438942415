import { CommonModule, CurrencyPipe } from '@angular/common';
import type { OnInit } from '@angular/core';
import { Component, LOCALE_ID, inject } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatNativeDateModule, MatOption } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInput, MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import { NgxCurrencyDirective } from 'ngx-currency';
import { finalize } from 'rxjs';
import { WaitLoadingService } from 'src/app/area-restrita/shared/components/wait-loading/wait-loading.service';
import { FormatBRLPipe } from 'src/app/area-restrita/shared/pipes/format-brl.pipe';
import { MesPorExtensoPipe } from 'src/app/area-restrita/shared/pipes/mes-por-extenso.pipe';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { UsuarioService } from '../../../home/shared/services/usuario.service';
import { LimiteMensalService } from '../../shared/services/limite-mensal.service';
import type { Perfil, Usuario } from '../shared/models/auxilio-saude';
import type { LimiteMensal } from '../shared/models/limite-mensal.model';
import { MesaAuxilioSaudeService } from '../shared/services/mesa-auxilio-saude.service';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

registerLocaleData(localePt, 'pt-BR');
@Component({
  selector: 'app-limite-mensal-auxilio-saude',
  templateUrl: './limite-mensal-auxilio-saude.component.html',
  styleUrl: './limite-mensal-auxilio-saude.component.scss',
  standalone: true,

  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatSelect,
    MatOption,
    MatLabel,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatDatepicker,
    MatButton,
    RouterLink,
    MatIcon,
    MatTooltip,
    MatPaginator,
    CurrencyPipe,
    FormatBRLPipe,
    MesPorExtensoPipe,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxCurrencyDirective
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'pt-BR' }]
})
export class LimiteMensalAuxilioSaudeComponent implements OnInit {
  private readonly formBuilder = inject(FormBuilder);
  private readonly waitLoadingService = inject(WaitLoadingService);
  private readonly mesaAuxilioSaudeService = inject(MesaAuxilioSaudeService);
  private readonly limiteMensalService = inject(LimiteMensalService);
  private readonly UsuarioService = inject(UsuarioService);
  private readonly toastService = inject(ToastService);

  dataHoje = new Date();
  proximoMes = new Date(this.dataHoje.getFullYear(), this.dataHoje.getMonth() + 1, 1);
  ultimoDiaMes = new Date(this.proximoMes.getFullYear(), this.proximoMes.getMonth(), 0);

  trocasRecentes: LimiteMensal[] = [];
  perfil: Perfil[] = [];
  limiteAtualUser: LimiteMensal;
  dadosFormulario: FormGroup;
  usuario: Usuario;
  idadeUsuario = 0;
  limiteReembolso = 0;
  selecIdade = false;
  ultimosDias = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  ngOnInit() {
    this.obterPerfis();

    this.dadosFormulario = this.formBuilder.group({
      perfil: [null, Validators.required],
      idade: [{ value: null, disabled: true }, Validators.required],
      valorNovo: ['', Validators.required],
      dataInicio: [this.formatarData(this.proximoMes)],
      valorAtual: [{ value: '', disabled: true }],
      dataExpiracao: [this.formatarData(this.ultimoDiaMes)],
      idUsuario: [{ id: Number(localStorage.getItem('idUsuario')) }]
    });

    this.dadosFormulario.get('valorAtual').disable();
    this.dadosFormulario.get('dataInicio').disable();
    this.dadosFormulario.get('dataExpiracao').disable();
    this.obterDadosUsuario();

    this.dadosFormulario.get('perfil').valueChanges.subscribe((perfilId) => {
      this.dadosFormulario.get('valorAtual').setValue('');
      if (perfilId != null) {
        this.dadosFormulario.get('idade').enable();
        this.atualizarValorAtual();
      } else {
        this.dadosFormulario.get('idade').disable();
        this.dadosFormulario.get('valorAtual').setValue('');
        this.dadosFormulario.get('valorAtual').disable();
      }
    });

    this.dadosFormulario.get('idade').valueChanges.subscribe((idade) => {
      if (idade) {
        this.selecIdade = true;
        this.atualizarValorAtual();
      }
    });
  }

  atualizarValorAtual(): void {
    const perfilId = this.dadosFormulario.get('perfil').value;
    const idade = this.dadosFormulario.get('idade').value;
    if (perfilId && idade) {
      this.limiteMensalService.consultaLimiteMensal(perfilId, this.dataHoje.getFullYear(), this.dataHoje.getMonth(), idade).subscribe({
        next: (dados: LimiteMensal) => {
          this.dadosFormulario.get('valorAtual').setValue(dados.valor);
          this.limiteAtualUser = dados;
        },
        error: (error) => {
          console.error('Erro ao obter dados do serviço', error);
        }
      });
    }
  }

  formatarData(data: Date): string {
    return data.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  }

  dataInicioPorExpiracao(data: string): string {
    if (data) {
      const dataDividida = data.split('/');

      if (dataDividida.length === 3) {
        const dia = '01';
        const mes = dataDividida[1];
        const ano = dataDividida[2];

        return `${dia}/${mes}/${ano}`;
      }
    }
    return '-';
  }

  mudarLimite() {
    this.criarLimiteMensal();
    this.obterLimiteHistorico();
  }

  obterLimite(): void {
    let dataNascimento = localStorage.getItem('nascimento');
    if (typeof dataNascimento === 'string' && dataNascimento.includes('/')) {
      const [dia, mes, ano] = dataNascimento.split('/');
      dataNascimento = `${ano}-${mes}-${dia}`;
    }
    const dataNascimentoDate = new Date(dataNascimento);
    if (isNaN(dataNascimentoDate.getTime())) {
      console.error('Data de Nascimento inválida:', dataNascimento);
      return;
    }
    const anoAtual = new Date().getFullYear();
    this.idadeUsuario = anoAtual - dataNascimentoDate.getFullYear();
    let categoriaIdade = 0;
    if (localStorage.getItem('pcd')) {
      categoriaIdade = 4;
    } else if (this.idadeUsuario <= 49) {
      categoriaIdade = 1;
    } else if (this.idadeUsuario <= 58) {
      categoriaIdade = 2;
    } else if (this.idadeUsuario >= 59) {
      categoriaIdade = 3;
    }

    this.limiteMensalService
      .consultaLimiteMensal(this.usuario.perfil.id, this.ultimoDiaMes.getFullYear(), this.ultimoDiaMes.getMonth(), categoriaIdade)
      .subscribe({
        next: (dados: LimiteMensal) => {
          this.limiteAtualUser = dados;
        },
        error: (error) => {
          console.error('Erro ao obter dados do serviço', error);
        }
      });
  }

  obterLimiteHistorico(): void {
    this.limiteMensalService.consultaHistorico(Number(this.dadosFormulario.get('perfil').value), Number(this.dadosFormulario.get('idade').value)).subscribe({
      next: (dados: LimiteMensal[]) => {
        this.trocasRecentes = dados;
      },
      error: (error) => {
        console.error('Erro ao obter dados do serviço', error);
      }
    });
  }

  consultaDataExpiracao(limite: LimiteMensal) {
    const dividido = limite.dataExpiracao ? limite.dataExpiracao.split('/') : ['0', '0', '0'];
    const divididoHist = this.trocasRecentes[0].dataExpiracao ? this.trocasRecentes[0].dataExpiracao.split('/') : ['0', '0', '0'];
    if (Number(dividido[1]) === this.proximoMes.getMonth() + 1 && this.selecIdade) return '-';

    const limiteExistente = this.trocasRecentes.find((x) => x.id === limite.id);
    if (limite.id === this.limiteAtualUser.id && limite.dataExpiracao !== null) {
      if (Number(divididoHist[1]) !== this.proximoMes.getMonth() + 1) return '-';
      return `${this.ultimosDias[this.dataHoje.getMonth()]}/${this.dataHoje.getMonth() + 1}/${this.dataHoje.getFullYear()}`;
    }
    if (!limiteExistente) return limite.dataExpiracao;
    if (limite.dataExpiracao === null) {
      this.trocasRecentes = this.trocasRecentes.map((item) => (item.id === limiteExistente.id ? limiteExistente : item));
      this.trocasRecentes.sort((a, b) => {
        if (a.dataExpiracao === null && b.dataExpiracao === null) return 0;

        if (a.dataExpiracao === null) return 1;

        if (b.dataExpiracao === null) return -1;

        const dataA = new Date(a.dataExpiracao.split('/').reverse().join('/'));
        const dataB = new Date(b.dataExpiracao.split('/').reverse().join('/'));
        return dataB.getTime() - dataA.getTime();
      });
      return limiteExistente.dataExpiracao;
    }

    return limiteExistente.dataExpiracao;
  }

  criarLimiteMensal(): void {
    this.limiteMensalService
      .criaLimiteMensal(
        Number(this.dadosFormulario.get('perfil').value),
        this.proximoMes.getFullYear(),
        this.proximoMes.getMonth() + 1,
        Number(localStorage.getItem('idUsuario')),
        Number(this.dadosFormulario.get('valorNovo').value),
        Number(this.dadosFormulario.get('idade').value),
        this.trocasRecentes.length === 0
      )
      .subscribe({
        next: (status: string) => {
          if (status.includes('Sucesso:')) {
            const mensagem = status.replace('Sucesso:', '').trim();
            this.toastService.success(mensagem, 'Aviso do Servidor');
            this.obterLimiteHistorico();
            return;
          } else if (status.includes('Erro:')) {
            const mensagem = status.replace('Erro:', '').trim();
            this.toastService.error(mensagem, 'Erro de Servidor');
          } else if (status.includes('Aviso:')) {
            const mensagem = status.replace('Aviso:', '').trim();
            this.toastService.warning(mensagem, 'Aviso do Servidor');
          } else {
            this.toastService.error(status, 'Resposta Inesperada');
          }
          this.toastService.error('Usuário não encontrado', 'Erro de Servidor');
        }
      });
  }

  obterPerfis() {
    this.waitLoadingService.open();
    this.mesaAuxilioSaudeService
      .obterListaPerfis()
      .pipe(
        finalize(() => {
          this.waitLoadingService.close();
        })
      )
      .subscribe({
        next: (dados) => {
          this.perfil = dados.slice(0, 3);
        },
        error: (error) => {
          console.error('Erro ao obter dados do serviço', error);
        }
      });
  }

  obterDadosUsuario() {
    this.UsuarioService.obterDadosUsuario(Number(localStorage.getItem('idUsuario'))).subscribe(
      (response: Usuario) => {
        this.usuario = response;
        let dataNascimento = response.dataNascimento;

        if (typeof dataNascimento === 'string' && dataNascimento.includes('/')) {
          const [dia, mes, ano] = dataNascimento.split('/');
          dataNascimento = `${ano}-${mes}-${dia}`;
        }
        this.obterLimite();
      },
      (error) => {
        console.error(error);
      }
    );
  }
}
