import { DatePipe } from '@angular/common';
import type { HttpErrorResponse } from '@angular/common/http';
import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, signal } from '@angular/core';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { MatSelect } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { finalize, Subject, takeUntil } from 'rxjs';
import verificaCpf from 'src/app/area-aberta/features/home/noticias/shared/custom-validators/cpf.validator';
import dataNascimentoValidator from 'src/app/area-aberta/features/home/noticias/shared/custom-validators/dataNascimento.validor';
import type { Dependente } from 'src/app/area-restrita/features/auxilio/saude/shared/models/dependente';
import type { FichaInscricaoCadastro } from 'src/app/area-restrita/features/auxilio/saude/shared/models/ficha-inscricao-cadastro';
import { WaitLoadingService } from 'src/app/area-restrita/shared/components/wait-loading/wait-loading.service';
import { PaginaVisitadaService } from 'src/app/area-restrita/shared/services/pagina-visitada.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import type { AuxilioSaude } from '../shared/models/auxilio-saude';
import { AuxilioSaudeService } from '../shared/services/auxilio-saude.service';
import { InscricaoAuxilioSaudeService } from '../shared/services/inscricao-auxilio-saude.service';
import FichaAuxilioHelper from './ficha-auxilio-saude.heper';

@Component({
  selector: 'app-ficha-auxilio-saude',
  templateUrl: './ficha-auxilio-saude.component.html',
  styleUrl: './ficha-auxilio-saude.component.scss',
  standalone: true,
  imports: [
    FormsModule,
    MatFormField,
    MatInput,
    NgxMaskDirective,
    MatCheckbox,
    MatButton,
    MatIcon,
    MatSelect,
    MatOption,
    ReactiveFormsModule,
    MatRadioGroup,
    MatRadioButton,
    MatProgressSpinner,
    MatLabel,
    DatePipe,
    NgxMaskPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FichaAuxilioSaudeComponent implements OnInit, OnDestroy {
  private readonly auxilioSaudeService = inject(AuxilioSaudeService);
  private readonly inscricaoAuxilioSaudeService = inject(InscricaoAuxilioSaudeService);
  private readonly toastr = inject(ToastService);
  private readonly router = inject(Router);
  private readonly route = inject(ActivatedRoute);
  private readonly paginaService = inject(PaginaVisitadaService);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly waitLoading = inject(WaitLoadingService);

  private readonly destroy$ = new Subject<void>();
  private readonly fichaAjudante = new FichaAuxilioHelper();
  readonly mensagensErro = signal(this.fichaAjudante.mensagensErro);
  readonly opcoesRelacionamento = signal(this.fichaAjudante.opcoesRelacionamento);
  mostrarFormulario = signal(false);
  possuiInscricao = signal(false);
  mostrarFrase = signal(true);
  dependentes = signal<Dependente[]>([]);
  private dependentesAuxiliar: Dependente[] = [];
  selecionados = signal<Set<Dependente>>(new Set<Dependente>());
  mensagemErro = signal('');
  dataAlteracao = signal<Date>(null);
  private readonly mostrarCheckbox: boolean = false;
  fichaInscricaoCadastro = signal<FichaInscricaoCadastro>(null);
  inscricaoIndeferida = signal(false);
  private pcd: boolean = false;
  idUsuario: number;
  validacaoAvaliacao = signal(false);
  editarFormularioDependente: boolean = false;
  dependenteEditar = signal<Dependente>(null);

  dadosFormulario = signal<AuxilioSaude>({
    id: '',
    rg: '',
    cpf: '',
    lotacao: '',
    endereco: '',
    cep: '',
    cidade: '',
    uf: '',
    telefone: '',
    celular: '',
    email: '',
    tipoPcd: { id: 0 },
    dependentes: [] as { id: number }[],
    usuario: { id: Number(localStorage.getItem('idUsuario')), nome: '' },
    dependentesImpostoRenda: false,
    contratantePlanoSaude: false,
    autorizacaoIAMSPE: false,
    possuoDependentes: false,
    dataAlteracao: new Date().toLocaleString('pt-BR').replace(',', '')
  });

  dadosAvaliacao = signal({
    idUsuario: null,
    idFicha: '',
    justificativa: '',
    idStatus: 0,
    loading: false
  });

  auxilioStatusOpcoes = [
    {
      id: 2,
      name: 'Deferido'
    },
    {
      id: 3,
      name: 'Indeferido'
    }
  ];

  constructor() {
    this.idUsuario = this.route.snapshot.params['id'] || localStorage.getItem('idUsuario');
  }

  ngOnInit(): void {
    this.carregarDadosFichaAuxilio();
    this.paginaService.salvaPagina(this.validacaoAvaliacao() ? 'Avaliação da Inscrição' : 'Ficha de Inscrição').subscribe();
  }

  formularioDependentes: FormGroup = new FormGroup({
    passo: new FormArray([
      new FormGroup({
        checked: new FormControl(false),
        nome: new FormControl('', [Validators.required]),
        cpfDependente: new FormControl('', [Validators.required, verificaCpf.bind(this)]),
        dataNascimento: new FormControl(null, [Validators.required, dataNascimentoValidator.bind(this)]),
        descricaoDependente: new FormControl('', [Validators.required]),
        tipoPcd: new FormControl({ id: null }, [Validators.required]),
        usuario: new FormGroup({
          id: new FormControl(Number(localStorage.getItem('idUsuario')))
        })
      })
    ])
  });

  formularioDependenteEdicao: FormGroup = new FormGroup({
    checked: new FormControl(false),
    nome: new FormControl('', [Validators.required]),
    cpfDependente: new FormControl('', [Validators.required, verificaCpf.bind(this)]),
    dataNascimento: new FormControl(null, [Validators.required, dataNascimentoValidator.bind(this)]),
    descricaoDependente: new FormControl('', [Validators.required]),
    tipoPcd: new FormControl({ id: null }, [Validators.required]),
    usuario: new FormGroup({
      id: new FormControl(Number(localStorage.getItem('idUsuario')))
    })
  });

  carregarDadosFichaAuxilio(): void {
    this.waitLoading.open();
    setTimeout(() => {
      this.validacaoAvaliacao.set(this.router.url.includes('inscricao-auxilio-avaliacao'));
      this.auxilioSaudeService
        .carregarFichaAuxilioSaude(this.idUsuario)
        .pipe(
          finalize(() => {
            this.waitLoading.close();
          })
        )
        .pipe(takeUntil(this.destroy$))
        .subscribe(([fichaInscricaoResponse, inscricaoResponse, dependentesResponse]) => {
          this.dependentesAuxiliar = dependentesResponse;
          if (inscricaoResponse) {
            this.dadosFormulario.set({
              id: inscricaoResponse.id || '',
              rg: inscricaoResponse.rg || '',
              cpf: inscricaoResponse.cpf || '',
              lotacao: inscricaoResponse.lotacao || '',
              endereco: inscricaoResponse.endereco || '',
              cep: inscricaoResponse.cep || '',
              cidade: inscricaoResponse.cidade || '',
              uf: inscricaoResponse.uf || '',
              telefone: inscricaoResponse.telefone || '',
              celular: inscricaoResponse.celular || '',
              email: inscricaoResponse.email || '',
              tipoPcd: inscricaoResponse.tipoPcd,
              dependentes: inscricaoResponse.dependentes || [],
              usuario: inscricaoResponse.usuario || { id: Number(localStorage.getItem('idUsuario')), nome: '' },
              dependentesImpostoRenda: inscricaoResponse.dependentesImpostoRenda || false,
              contratantePlanoSaude: inscricaoResponse.contratantePlanoSaude || false,
              autorizacaoIAMSPE: false,
              possuoDependentes: false,
              dataAlteracao: inscricaoResponse.dataAlteracao || new Date().toLocaleDateString()
            });

            this.fichaInscricaoCadastro.set(fichaInscricaoResponse);
            this.fichaInscricaoCadastro.update((ficha) => ({ ...ficha, nome: inscricaoResponse.usuario.nome }));
            this.dadosFormulario.update((formulario) => ({ ...formulario, dependentesImpostoRenda: true }));
            this.possuiInscricao.set(true);

            this.dadosAvaliacao.update((avaliacao) => ({
              ...avaliacao,
              idUsuario: this.idUsuario.toString(),
              justificativa: inscricaoResponse.justificativa,
              idFicha: inscricaoResponse.id,
              idStatus: Number(inscricaoResponse.status.id)
            }));

            if (inscricaoResponse.dataAlteracao) {
              const [data, hora] = inscricaoResponse.dataAlteracao.split(' ');
              const [dia, mes, ano] = data.split('/').map(Number);
              const [horas, minutos, segundos] = hora.split(':').map(Number);

              this.dataAlteracao.set(new Date(ano, mes - 1, dia, horas, minutos, segundos));
            }
            this.inscricaoIndeferida.set(inscricaoResponse.status.id == 3 || inscricaoResponse.status.id == 5 ? true : false);
          } else if (fichaInscricaoResponse) {
            this.fichaInscricaoCadastro.set(fichaInscricaoResponse);
            this.dadosFormulario.update((formulario) => ({
              ...formulario,
              rg: fichaInscricaoResponse.rg,
              cpf: fichaInscricaoResponse.cpf,
              endereco: fichaInscricaoResponse.endereco,
              lotacao: fichaInscricaoResponse.lotacao,
              cep: fichaInscricaoResponse.cep,
              cidade: fichaInscricaoResponse.cidade,
              uf: fichaInscricaoResponse.uf,
              telefone: fichaInscricaoResponse.dddTelefone + fichaInscricaoResponse.telefone,
              celular: fichaInscricaoResponse.dddCelular + fichaInscricaoResponse.celular,
              email: fichaInscricaoResponse.email,
              dependentes: dependentesResponse.map((dep) => ({ ...dep }))
            }));
          }
          void this.carregarDependentePreenchido();
          this.cdr.markForCheck();
        });
    }, 1000);
  }

  get passo(): FormArray {
    return this.formularioDependentes.get('passo') as FormArray;
  }

  adicionarLinhaGrid() {
    const ultimoGrupo = this.passo.at(this.passo.length - 1) as FormGroup;
    if (this.verificarCamposPrenhenchidosDependentes(ultimoGrupo)) {
      this.passo.push(
        new FormGroup({
          checked: new FormControl(false),
          nome: new FormControl(''),
          cpfDependente: new FormControl(''),
          dataNascimento: new FormControl(),
          descricaoDependente: new FormControl(''),
          tipoPcd: new FormControl({ id: 0 }),
          usuario: new FormGroup({
            id: new FormControl(Number(localStorage.getItem('idUsuario')))
          })
        })
      );
    }
  }

  verificarCamposPrenhenchidosDependentes(formGroup: FormGroup): boolean {
    let retorno = true;

    for (const controle in formGroup.controls) {
      if (formGroup.controls.hasOwnProperty.call(controle)) {
        const valor = formGroup.get(controle).value;
        if (valor === null || valor === undefined || valor === '') {
          retorno = false;
        }
      }
    }

    if (formGroup.status === 'INVALID' && formGroup.touched) {
      retorno = false;
    }

    return retorno;
  }

  async carregarDependentePreenchido(): Promise<void> {
    return new Promise<void>((resolve) => {
      this.selecionados().clear();
      this.dependentesAuxiliar.forEach((item) => {
        if (item.checked) {
          this.selecionados.update((values) => {
            return values.add(item);
          });
          this.dadosFormulario.update((formulario) => ({ ...formulario, autorizacaoIAMSPE: true, possuoDependentes: true }));
        }
      });
      this.dependentes.set(this.dependentesAuxiliar);
      resolve();
    });
  }

  selecaoDependente(dependente: Dependente) {
    if (this.selecionados().has(dependente)) {
      this.selecionados.update((values) => {
        values.delete(dependente);
        return values;
      });
    } else {
      this.selecionados.update((values) => values.add(dependente));
    }
  }

  formatarSaidaCPF(cpf: string): string {
    if (!cpf) return '';
    cpf = cpf.replace(/\D/g, '');
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  excluirDependente(id: number): void {
    if (this.dadosFormulario().dependentes.length > 0) this.dadosFormulario.update((formulario) => ({ ...formulario, possuoDependentes: true }));
    this.auxilioSaudeService.excluirDependente(id).subscribe(
      () => {
        this.toastr.success('Dependente(s) Excluído(s) com Sucesso');
        this.mostrarFormulario.set(false);
        this.mostrarFrase.set(true);
        this.atualizarDadosFichaInscricao();
      },
      (error) => {
        console.error('Erro ao Apagar dado(s)', error);
      }
    );
  }

  enviarDadosDependentes() {
    if (this.verificarCamposPreenchidosDependentesDaLista()) {
      const dadosParaEnviar = (this.passo.controls as FormGroup[])
        .map((grupo) => grupo.getRawValue())
        .map((e) => {
          const dataNascimento = new Date(e.dataNascimento);
          dataNascimento.setDate(dataNascimento.getDate() + 1);
          return {
            ...e,
            dataNascimento: dataNascimento.toLocaleDateString()
          };
        });

      this.auxilioSaudeService.enviarDadosDependentes(dadosParaEnviar as Dependente[]).subscribe(
        () => {
          this.mostrarFormulario.set(false);
          this.mostrarFrase.set(true);
          this.toastr.success('Enviado com sucesso');
          const urlAtual = this.router.url;
          void this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            void this.router.navigateByUrl(urlAtual);
          });
          while (this.passo.length !== 0) {
            this.passo.removeAt(0);
          }
          this.passo.push(
            new FormGroup({
              nome: new FormControl(''),
              cpfDependente: new FormControl(''),
              dataNascimento: new FormControl(),
              descricaoDependente: new FormControl(''),
              tipoPcd: new FormControl({ id: 0 }),
              usuario: new FormGroup({
                id: new FormControl(Number(localStorage.getItem('idUsuario')))
              })
            })
          );
        },
        (error) => {
          this.toastr.error(error);
        }
      );
    } else this.toastr.error('Favor preencher todos os campos');
  }

  enviarDadosEditadosDependentes() {
    if (this.formularioDependenteEdicao.valid) {
      const dadosParaEnviar = { ...this.formularioDependenteEdicao.getRawValue() };
      const dataNascimento = new Date(dadosParaEnviar.dataNascimento);
      dataNascimento.setDate(dataNascimento.getDate() + 1);
      dadosParaEnviar.dataNascimento = dataNascimento.toLocaleDateString();

      this.auxilioSaudeService.enviarDadosEditadoDependentes(this.dependenteEditar().id, dadosParaEnviar as Dependente[]).subscribe(
        () => {
          this.toastr.success('Enviado com sucesso');
          const urlAtual = this.router.url;
          void this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            void this.router.navigateByUrl(urlAtual);
          });
        },
        (error) => {
          this.toastr.error(error);
        }
      );
    } else this.toastr.error('Favor preencher todos os campos');
  }

  verificarObjectCamposPreenchidosDependentes(): boolean {
    for (let i = 0; i < this.passo.length; i++) {
      const formGroup = this.passo.at(i) as FormGroup;
      if (!this.verificarCamposPrenhenchidosDependentes(formGroup)) {
        return false;
      }
    }
    return true;
  }

  verificarCamposPreenchidosDependentesDaLista(): boolean {
    for (let i = 0; i < this.passo.length; i++) {
      const formGroup = this.passo.at(i) as FormGroup;
      if (!this.verificarCamposPrenhenchidosDependentes(formGroup)) {
        return false;
      }
      const tipoPcd = formGroup.get('tipoPcd').value;
      if (tipoPcd && tipoPcd.id === null) {
        return false;
      }
    }
    return true;
  }

  incluirPcd(): void {
    this.auxilioSaudeService.incluirPcd(Number(localStorage.getItem('idUsuario')), this.pcd).subscribe({
      next: (response) => {
        console.log('Sucesso:', response);
      },
      error: (error) => {
        console.error('Erro:', ` ${error.status} - ${error.error}`);
      }
    });
  }

  enviarDadosFormulario() {
    if (this.camposObrigatoriosPreenchidos() && this.verificarObjectCamposPreenchidosDependentes()) {
      this.dadosFormulario.update((formulario) => ({ ...formulario, dependentes: [...this.selecionados()].map((dependente) => ({ id: dependente.id })) }));
      this.auxilioSaudeService.enviarDadosFormulario(this.dadosFormulario()).subscribe(
        () => {
          this.toastr.success('Dados enviados com Sucesso');
          if (this.dadosFormulario().tipoPcd.id < 8) {
            this.pcd = true;
          } else {
            this.pcd = false;
          }
          this.incluirPcd();
          this.limpaChecksBox();
          this.carregarDadosFichaAuxilio();
          this.enviarAtualizacaoStatus();
          this.mensagemErro.set('');
        },
        (error) => {
          const errorMessage = error.erros ? error.erros['Erro de negócio'] : 'Verifique a ficha, erro ao efetuar inscrição.';
          this.toastr.warning(errorMessage);
        }
      );
    } else {
      this.mensagemErro.set('Falta preencher campos obrigatórios.');
    }
  }

  atualizarDadosFichaInscricao() {
    if (this.camposObrigatoriosPreenchidosEditar() && this.verificarObjectCamposPreenchidosDependentes()) {
      if (this.dadosFormulario().tipoPcd.id < 8) {
        this.pcd = true;
      } else {
        this.pcd = false;
      }
      this.dadosFormulario.update((formulario) => ({
        ...formulario,
        dependentes: [...this.selecionados()].map((dependente) => ({ id: dependente.id, checked: dependente.checked }))
      }));
      this.auxilioSaudeService.atualizarDadosFormulario(this.dadosFormulario()).subscribe(
        () => {
          this.toastr.success('Dados atualizados com Sucesso');
          this.incluirPcd();
          this.limpaChecksBox();
          this.carregarDadosFichaAuxilio();
          this.enviarAtualizacaoStatus();
          this.mensagemErro.set('');
        },
        (error: Error) => {
          this.toastr.warning(error.message, 'Erro de negócio');
        }
      );
    } else {
      this.toastr.error(null, 'Falta preencher campos obrigatórios.');
    }
  }

  camposObrigatoriosPreenchidos(): boolean {
    if (this.mostrarCheckbox && [...this.selecionados()].length > 0) {
      return (
        this.dadosFormulario().celular !== '' &&
        this.dadosFormulario().email !== '' &&
        this.dadosFormulario().dependentesImpostoRenda &&
        this.dadosFormulario().contratantePlanoSaude &&
        this.dadosFormulario().autorizacaoIAMSPE &&
        this.dadosFormulario().possuoDependentes &&
        (this.dadosFormulario().tipoPcd.id ?? 0) > 0
      );
    } else {
      return this.dadosFormulario().celular !== '' && this.dadosFormulario().email !== '' && this.dadosFormulario().dependentesImpostoRenda;
    }
  }

  camposObrigatoriosPreenchidosEditar(): boolean {
    if ([...this.selecionados()].length > 0) {
      return (
        this.dadosFormulario().celular !== '' &&
        this.dadosFormulario().email !== '' &&
        this.dadosFormulario().dependentesImpostoRenda &&
        this.dadosFormulario().autorizacaoIAMSPE &&
        this.dadosFormulario().possuoDependentes &&
        (this.dadosFormulario().tipoPcd.id ?? 0) > 0
      );
    } else {
      return this.dadosFormulario().celular !== '' && this.dadosFormulario().email !== '' && this.dadosFormulario().dependentesImpostoRenda;
    }
  }

  enviarAtualizacaoStatus() {
    if (!this.validacaoAvaliacao()) return;
    const obsDeveSerPreenchido = this.dadosAvaliacao().idStatus == 3 && !this.dadosAvaliacao().justificativa;
    if (obsDeveSerPreenchido) {
      this.toastr.warning('Favor preencher a justificativa.');
      return;
    }
    this.auxilioSaudeService.enviarAtualizacaoStatus(Number(this.dadosFormulario().id), this.dadosAvaliacao());
  }

  ngOnDestroy(): void {
    this.waitLoading.close();
    this.destroy$.next();
    this.destroy$.complete();
  }

  limpaChecksBox() {
    this.dadosFormulario.update((formulario) => ({
      ...formulario,
      dependentesImpostoRenda: false,
      contratantePlanoSaude: false,
      autorizacaoIAMSPE: false,
      possuoDependentes: false
    }));
  }

  removerLinhaDependente(i: number): void {
    const dependenteExcluir = this.dependentes()[i];
    dependenteExcluir.checked = true;
    const temDependenteSelecionado = [...this.selecionados()].some((e) => e.cpfDependente === dependenteExcluir.cpfDependente);
    if (temDependenteSelecionado) {
      this.selecaoDependente(dependenteExcluir);
    }
    this.excluirDependente(dependenteExcluir.id);
  }

  editarLinhaDependente(i: number, dependente: Dependente): void {
    this.editarFormularioDependente = true;
    const dependenteAuxiliar = this.dependenteEditar();
    if (dependenteAuxiliar) {
      this.dependentes.update((lista) => [...lista, dependenteAuxiliar]);
    }
    this.dependenteEditar.set(dependente);
    this.formularioDependenteEdicao.patchValue({
      nome: dependente.nome,
      cpfDependente: dependente.cpfDependente,
      dataNascimento: this.transformarDataStringParaISO(dependente.dataNascimento),
      descricaoDependente: dependente.descricaoDependente,
      tipoPcd: dependente.tipoPcd.id
    });
    this.dependentes().splice(i, 1);
  }

  transformarDataStringParaISO(dataString: string): string {
    const partes = dataString.split('/');
    const dia = partes[0];
    const mes = partes[1];
    const ano = partes[2];
    return `${ano}-${mes}-${dia}`;
  }

  get opcoesRelacionamentoComDescricaoAtual() {
    const opcoes = this.opcoesRelacionamento();
    const descricaoAtual = this.formularioDependenteEdicao.get('descricaoDependente')?.value;
    if (descricaoAtual && !opcoes.some((opcao) => opcao === descricaoAtual)) {
      return [...opcoes, descricaoAtual];
    }
    return opcoes;
  }

  avaliarPedido(statusId: number) {
    switch (statusId) {
      case 2: {
        this.deferirIndeferirPedido();
        break;
      }
      case 3: {
        if (!this.dadosAvaliacao().justificativa || this.dadosAvaliacao().justificativa == '') {
          this.toastr.warning('Informe a justificativa para indeferir.');
          return;
        }
        this.deferirIndeferirPedido();
        break;
      }
      default: {
        this.toastr.warning('Opção status de avaliação inválido: ' + statusId);
      }
    }
  }

  desabilitarBotaoAvaliar(idStatus: number, justificativa: string) {
    return ![2, 3].includes(idStatus) || (idStatus === 3 && (!justificativa || justificativa === ''));
  }

  deferirIndeferirPedido() {
    this.dadosAvaliacao.update((avaliacao) => ({ ...avaliacao, loading: true }));

    this.inscricaoAuxilioSaudeService
      .deferirIndeferirPedido(this.dadosAvaliacao())
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response: string) => {
          this.dadosAvaliacao.update((avaliacao) => ({ ...avaliacao, loading: false }));
          this.toastr.success(response);
          setTimeout(() => {
            void this.router.navigate(['area-restrita', 'administracao-auxilio-saude', 'analisar-pedido']);
          }, 2000);
        },
        (error: HttpErrorResponse) => {
          this.dadosAvaliacao.update((avaliacao) => ({ ...avaliacao, loading: false }));
          this.toastr.error('Ocorreu um erro ao deferir os pedidos: ' + error.message);
        },
        () => {
          this.dadosAvaliacao.update((avaliacao) => ({ ...avaliacao, loading: false }));
        }
      );
  }
}
