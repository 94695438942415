<mat-accordion>
  <mat-expansion-panel class="borda-arredondada">
    <mat-expansion-panel-header>
      <mat-panel-title>Filtros aplicados</mat-panel-title>
      <!-- <mat-panel-description>
        <button mat-stroked-button type="button" color="primary" (click)="limpar()">Limpar filtros</button>
      </mat-panel-description> -->
    </mat-expansion-panel-header>

    <div class="container-filtros">
      @for (filtro of filtros(); track $index) {
        <div>
          <small class="texto-filtro-tipo">{{ textoFiltroTipo(filtro.tipo) }}</small>
          <mat-chip-set>
            @for (valor of filtro.valores; track $index) {
              <mat-chip-row (removed)="remover(filtro, valor)">
                {{ valor }}
                <button type="button" matChipRemove>
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip-row>
            }
          </mat-chip-set>
        </div>
      }
    </div>
  </mat-expansion-panel>
</mat-accordion>
