import type { Servico } from './servico.model';

export const ServicoList: Servico[] = [
  {
    titulo: 'Atendimento ao Cidadão',
    icone: 'INFO_CIDADAO',
    url: 'servicos/atendimento-cidadao',
    linkExterno: false
  },
  {
    titulo: 'GARE para liquidação ',
    icone: 'GARE_LIIQUIDACAO',
    url: 'https://www.dividaativa.pge.sp.gov.br/sc/pages/pagamento/gareLiquidacao.jsf',
    linkExterno: true
  },
  {
    titulo: 'GARE para Parcelamento',
    icone: 'GARE_PARCELAMENTO',
    url: 'https://www.dividaativa.pge.sp.gov.br/sc/pages/pagamento/gareParcelamento.jsf',
    linkExterno: true
  },
  {
    titulo: 'Portal da Dívida Ativa',
    icone: 'PORTAL_DIVIDA_ATIVA',
    url: 'servicos/divida-ativa',
    linkExterno: false
  },
  {
    titulo: 'Portal de Arbitragens',
    icone: 'PORTAL_ARBITRAGENS',
    url: 'https://www.pge.sp.gov.br/Portal_PGE/Portal_Arbitragens/paginas/',
    linkExterno: true
  },
  {
    titulo: 'Portal de Precatórios',
    icone: 'PORTAL_PRECATORIOS',
    url: 'servicos/portal-precatorios',
    linkExterno: false
  },
  {
    titulo: 'Reparação de Danos',
    icone: 'REPARACAO_DANOS',
    url: 'servicos/reparacao-danos',
    linkExterno: false
  }
];

export const ServicoColorList: Servico[] = [
  {
    titulo: 'Portal de Precatórios',
    icone: 'PORTAL_PRECATORIOS_COLOR',
    url: 'servicos/portal-precatorios',
    linkExterno: false
  },
  {
    titulo: 'Portal da Dívida Ativa',
    icone: 'PORTAL_DIVIDA_ATIVA_COLOR',
    url: 'servicos/divida-ativa',
    linkExterno: false
  },
  {
    titulo: 'Atendimento ao Cidadão',
    icone: 'INFO_CIDADAO_COLOR',
    url: 'servicos/atendimento-cidadao',
    linkExterno: false
  },
  {
    titulo: 'Portal de Arbitragens',
    icone: 'PORTAL_ARBITRAGENS_COLOR',
    url: 'https://www.pge.sp.gov.br/Portal_PGE/Portal_Arbitragens/paginas/',
    linkExterno: true
  },
  {
    titulo: 'Reparação de Danos',
    icone: 'REPARACAO_DANOS_COLOR',
    url: 'servicos/reparacao-danos',
    linkExterno: false
  },
  {
    titulo: 'GARE para Parcelamento',
    icone: 'GARE_PARCELAMENTO_COLOR',
    url: 'https://www.dividaativa.pge.sp.gov.br/sc/pages/pagamento/gareParcelamento.jsf',
    linkExterno: true
  },
  {
    titulo: 'GARE para liquidação ',
    icone: 'GARE_LIQUIDACAO_COLOR',
    url: 'https://www.dividaativa.pge.sp.gov.br/sc/pages/pagamento/gareLiquidacao.jsf',
    linkExterno: true
  }
];
