import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatLine } from '@angular/material/core';
import { MatNavList, MatListItem } from '@angular/material/list';
import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'app-drawer-content',
  templateUrl: './drawer-content.component.html',
  styleUrl: './drawer-content.component.scss',
  standalone: true,
  imports: [HeaderComponent, MatNavList, MatListItem, MatLine, MatIconButton, MatIcon, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DrawerContentComponent {
  itemExpandida: string | null = null;

  lidarExpansao(item: string): void {
    if (this.itemExpandida === item) {
      this.itemExpandida = null; // Recolhe o item se ele já estiver expandido
    } else {
      this.itemExpandida = item; // Expande o item se estiver recolhido
    }
  }

  estaExpandida(item: string): boolean {
    return this.itemExpandida === item;
  }
}
