import { CurrencyPipe } from '@angular/common';
import type { HttpErrorResponse } from '@angular/common/http';
import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import type { PageEvent } from '@angular/material/paginator';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { NgxCurrencyDirective } from 'ngx-currency';
import { Router } from '@angular/router';
import { Subject, finalize, takeUntil } from 'rxjs';
import type { HistoricoSolicitacoes } from 'src/app/area-restrita/features/auxilio/saude/shared/models/historico-solicitacoes';
import type { ConfirmaDialogData } from 'src/app/area-restrita/shared/components/confirma-dialog/confirma-dialog-data';
import { ConfirmaDialogComponent } from 'src/app/area-restrita/shared/components/confirma-dialog/confirma-dialog.component';
import { WaitLoadingService } from 'src/app/area-restrita/shared/components/wait-loading/wait-loading.service';
import { PaginaVisitadaService } from 'src/app/area-restrita/shared/services/pagina-visitada.service';
import type { Page } from 'src/app/shared/models/paginacao/page.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { AuxilioStatusEnum } from '../shared/enums/auxilio-status.enum';
import type { StatusAuxilio } from '../shared/models/auxilio-saude';
import type { TipoAuxilio } from '../shared/models/tipo-auxilio';
import type { TipoAuxilioSaude } from '../shared/models/tipo-auxilio-saude';
import { AuxilioSaudeService } from '../shared/services/auxilio-saude.service';
import { MesaAuxilioSaudeService } from '../shared/services/mesa-auxilio-saude.service';
import { SolicitacaoAuxilioSaudeService } from '../shared/services/solicitacao-auxilio-saude.service';

@Component({
  selector: 'app-pedido-auxilio-saude',
  templateUrl: './pedido-auxilio-saude.component.html',
  styleUrl: './pedido-auxilio-saude.component.scss',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatSelect,
    MatOption,
    MatButtonModule,
    MatInput,
    NgxCurrencyDirective,
    MatButton,
    MatPaginator,
    CurrencyPipe,
    MatIconModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PedidoAuxilioSaudeComponent implements OnInit, OnDestroy {
  private readonly solicitacaoAuxilioSaudeService = inject(SolicitacaoAuxilioSaudeService);
  private readonly auxilioSaudeService = inject(AuxilioSaudeService);
  private readonly mesaAuxilioSaudeService = inject(MesaAuxilioSaudeService);
  private readonly formBuilder = inject(FormBuilder);
  private readonly toast = inject(ToastService);
  private readonly waitLoadingService = inject(WaitLoadingService);
  private readonly router = inject(Router);
  private readonly paginaService = inject(PaginaVisitadaService);
  private readonly dialog = inject(MatDialog);

  titulo = 'Dados do Pedido';
  private readonly destroy$ = new Subject<void>();
  historicoSolicitacoesResponse = signal<HistoricoSolicitacoes[]>([]);
  saldo: number = 0;
  deferido: number = 0;
  meses: string[] = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

  comprimento = signal(-1);
  vazio = signal(false);
  tamanhoDaPagina = 10;
  numeroPagina = 0;
  opcoesDoTamanhoDaPagina = [5, 10, 25];

  statusAuxilio = signal<StatusAuxilio[]>([]);
  readonly statusAuxilioEnum = AuxilioStatusEnum;
  tiposPrograma = signal<TipoAuxilio[]>([]);
  tiposAuxilioSaude = signal<TipoAuxilioSaude[]>([]);
  limiteReembolsoResponse: number = 0;

  ngOnInit(): void {
    this.paginaService.salvaPagina(this.titulo).subscribe();
    this.obterTipoAuxilio();
    this.obterListaTipoAuxilioSaude();
    this.obterTiposStatus();
    this.buscarPedidos();
  }

  dadosFormulario = this.formBuilder.group({
    status: [''],
    mesAnoSolicitacao: [''],
    programa: [''],
    tipoAuxilioSaude: [''],
    totalSolicitado: 0,
    totalDeferido: 0
  });

  buscarPedidos(): void {
    this.waitLoadingService.open();

    this.solicitacaoAuxilioSaudeService
      .obterLista(this.dadosFormulario, this.numeroPagina, this.tamanhoDaPagina)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.waitLoadingService.close();
        })
      )
      .subscribe({
        next: (dados: Page<HistoricoSolicitacoes>) => {
          this.historicoSolicitacoesResponse.set(dados.content);
          this.calculaSaldo();

          this.historicoSolicitacoesResponse().sort((a, b) => {
            if (a.anoReembolso > b.anoReembolso) return -1;
            if (a.anoReembolso < b.anoReembolso) return 1;
            if (a.mesParcela > b.mesParcela) return -1;
            if (a.mesParcela < b.mesParcela) return 1;
            return 0;
          });

          this.comprimento.set(dados.totalElements);
          this.vazio.set(dados.empty);
        }
      });
  }

  calculaSaldo(): void {
    console.warn('Atenção!!! Calcula saldo de acordo com o mês corrente, lembre-se disso.');
    this.saldo = this.limiteReembolsoResponse;
    this.deferido = 0;
    const dados = this.historicoSolicitacoesResponse();
    for (const dado of dados) {
      this.deferido += Number(dado.valorDeferidoPge);
    }
    this.saldo -= this.deferido;
  }

  pedido(id: string): void {
    void this.router.navigate(['area-restrita', 'pedido-auxilio-saude', id]);
  }

  obterTiposStatus(): void {
    this.mesaAuxilioSaudeService
      .obterListaStatusAuxilio()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dados: StatusAuxilio[]) => {
          this.statusAuxilio.set(dados);
        },
        error: (error) => {
          console.error('Erro ao obter dados do serviço', error);
        }
      });
  }

  obterTipoAuxilio(): void {
    this.solicitacaoAuxilioSaudeService
      .obterTipoAuxilio()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dados) => {
          this.tiposPrograma.set(dados);
        },
        error: (error) => {
          console.error('Erro ao obter dados do serviço', error);
        }
      });
  }

  lidarEventoPagina(e: PageEvent): void {
    this.comprimento.set(e.length);
    this.tamanhoDaPagina = e.pageSize;
    this.numeroPagina = e.pageIndex;
    this.buscarPedidos();
  }

  limparFormulario(): void {
    this.dadosFormulario.reset({
      status: '',
      mesAnoSolicitacao: '',
      programa: '',
      totalSolicitado: 0,
      totalDeferido: 0
    });
    this.comprimento.set(0);
    this.tamanhoDaPagina = 10;
    this.numeroPagina = 0;
    this.historicoSolicitacoesResponse.set([]);
    this.vazio.set(false);
  }

  remover(id: string): void {
    const data: ConfirmaDialogData = {
      title: 'Cancelar Pedido de Auxílio',
      message: 'Deseja remover o pedido?',
      escondeBotaoNao: false
    };
    const dialogRef = this.dialog.open(ConfirmaDialogComponent, {
      width: '50%',
      data: data
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.waitLoadingService.open();
        this.auxilioSaudeService.cancelarPedidoAuxilioSaude(Number(id)).subscribe({
          next: () => {
            this.toast.info('Pedido cancelado com sucesso');
            this.waitLoadingService.close();
            this.buscarPedidos();
          },
          error: (error: HttpErrorResponse) => {
            if (error.status === 409) {
              this.toast.error(error.error);
            } else {
              console.error('erro', error);
            }
            this.waitLoadingService.close();
          }
        });
      }
    });
  }

  obterListaTipoAuxilioSaude(): void {
    this.solicitacaoAuxilioSaudeService.obterListaTipoAuxilioSaude().subscribe((response) => {
      this.tiposAuxilioSaude.set(response);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
