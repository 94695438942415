/* eslint-disable @angular-eslint/prefer-on-push-component-change-detection */
import type { OnInit } from '@angular/core';
import { Component, inject } from '@angular/core';
import { WebServiceInfosService } from 'src/app/shared/services/infos-webservice.service';
import { SeparadorEmailComponent } from '../../../../core/layout/separador-email/separador-email.component';
import { SeparadorComponent } from '../../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-subprocuradoria-consultoria-geral',
  templateUrl: './subprocuradoria-consultoria-geral.component.html',
  standalone: true,

  imports: [SeparadorComponent, SeparadorEmailComponent]
})
export class SubprocuradoriaConsultoriaGeralComponent implements OnInit {
  private readonly service = inject(WebServiceInfosService);

  informacoes: string[] = [];
  codigoUnidades: number[] = [
    40110600000080, 40110600600091, 40110600400091, 40110600300091, 40110600500091, 40110600700091, 40110600600011, 40110600400011, 40110600300011,
    40110600500011, 40110600700011
  ];

  ngOnInit(): void {
    this.carregarConteudo();
  }

  carregarConteudo(): void {
    const vetorElementos: string[] = [];
    this.codigoUnidades.forEach((codigo) => {
      this.service.getInfosPorCodigo(codigo).subscribe({
        next: (response) => {
          const parser = new DOMParser();
          const xml = parser.parseFromString(response, 'text/xml');
          xml.querySelectorAll('NOME_PROCURADOR, NOME_SUBSTITUTO, NOME_SUBSTITUTO2').forEach((element) => {
            if (element.textContent && element.textContent !== '') {
              vetorElementos.push(element.textContent.trim());
            }
          });
        },
        error: console.error
      });
    });

    this.informacoes = vetorElementos;
  }

  encontrarNome(nome: string): string {
    const result = this.informacoes.find((element) => element === nome);
    return result ? result : 'Nome não encontrado';
  }
}
