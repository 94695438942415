import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import type { PageEvent } from '@angular/material/paginator';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { ImagemTratamento } from 'src/app/area-aberta/features/home/shared/services/imagem-tratamento';
import { transformarData } from 'src/app/area-restrita/features/home/shared/utils/util';
import { Globais } from 'src/app/area-restrita/shared/globais/globais';
import type { PaginaVisitada } from 'src/app/area-restrita/shared/models/pagina-visitada';
import { PaginaVisitadaService } from 'src/app/area-restrita/shared/services/pagina-visitada.service';
import type { Noticias } from 'src/app/shared/models/noticia.interface';
import type { Page } from 'src/app/shared/models/paginacao/page.model';
import { NoticiaService } from 'src/app/shared/services/noticia.service';
import type { Usuario } from '../../auxilio/saude/shared/models/auxilio-saude';
import { UsuarioService } from '../shared/services/usuario.service';

@Component({
  selector: 'app-home-info-area-restrita',
  templateUrl: './home-info-area-restrita.component.html',
  styleUrl: './home-info-area-restrita.component.scss',
  providers: [ImagemTratamento],
  standalone: true,
  imports: [MatPaginator],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeInfoAreaRestritaComponent implements OnInit {
  private readonly noticiaService = inject(NoticiaService);
  private readonly UsuarioService = inject(UsuarioService);
  private readonly imagemTratamento = inject(ImagemTratamento);
  private readonly router = inject(Router);
  private readonly paginaService = inject(PaginaVisitadaService);

  descricaoInferior = Globais.versionFront;
  paginasAcessadas = signal<PaginaVisitada[]>([]);
  objNoticias = signal<Noticias[]>([]);
  hisNoticias?: Noticias[] = [];

  comprimento = 0;
  tamanhoDaPagina = 10;
  indiceDaPagina = 0;
  opcoesDoTamanhoDaPagina = [5, 10, 25];

  textoLimitado: boolean = true;

  ngOnInit() {
    this.paginaService.obtemPaginas().subscribe(
      (resp) => {
        this.paginasAcessadas.set(resp);
      },
      (error) => {
        console.log('Error ao obter Historico do usuario', error);
      }
    );
    if (localStorage.getItem('idUsuario')) {
      console.info('Notícias? Ok');
      this.obterDadosUsuario();
    } else {
      console.warn('Notícias? IdPerfil não encontrado!');
    }
  }

  nomePagina(url): string {
    const parts = url.split('@');
    return parts.length > 1 ? parts[0] : 'Pagina sem nome';
  }
  linkPagina(url): string {
    const parts = url.split('@');
    return parts[1] || '';
  }

  obterNoticiasPorIdPerfil(id: number) {
    this.noticiaService.obterNoticiasPorIdPerfil(this.indiceDaPagina, this.tamanhoDaPagina, id).subscribe(
      (response: Page<Noticias>) => {
        this.objNoticias.set(response.content);
        this.comprimento = response.totalElements;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  obterDadosUsuario() {
    this.UsuarioService.obterDadosUsuario(Number(localStorage.getItem('idUsuario'))).subscribe(
      (response: Usuario) => {
        const id = response.perfil.id;
        this.obterNoticiasPorIdPerfil(Number(id));
      },
      (error) => {
        console.log(error);
      }
    );
  }

  editarData(data: string): string {
    return transformarData(data);
  }

  obterImagem(noticiaImagem: string): string | undefined {
    if (!noticiaImagem) console.log(noticiaImagem);
    return this.imagemTratamento.obterImagemBlob(noticiaImagem);
  }

  processarTexto(texto: string): string {
    const linhas = texto.split('<p');

    this.textoLimitado = linhas.length > 10;

    const textoProcessado = this.textoLimitado ? linhas.slice(0, 10).join('<p') : texto;
    const textoSemQuebra = textoProcessado.replace(/<br>/g, '');
    const textoAlterado = textoSemQuebra.replace(/<[^>]*>/g, '');

    return textoAlterado;
  }

  visualizarNoticia(id: number) {
    const historicoNoticia = localStorage.getItem('historicoNoticia');

    if (historicoNoticia) {
      const ids = historicoNoticia.split(',');
      const index = ids.indexOf(id.toString());
      if (index !== -1) ids.splice(index, 1);
      ids.unshift(id.toString());
      if (ids.length > 10) ids.pop();
      localStorage.setItem('historicoNoticia', ids.join(','));
    } else localStorage.setItem('historicoNoticia', id.toString());
    void this.router.navigate(['area-restrita', 'noticia-home', id]);
  }

  lidarEventoDaPagina(e: PageEvent) {
    this.comprimento = e.length;
    this.tamanhoDaPagina = e.pageSize;
    this.indiceDaPagina = e.pageIndex;
    this.obterDadosUsuario();
  }
}
