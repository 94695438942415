<app-mapa-site />

<div class="quadrado">
  <span class="conteudo">
    <a class="footFinal" href="https://www.ouvidoria.sp.gov.br/Portal/Default.aspx" target="_blank">Ouvidoria</a>|
    <a class="footFinal" href="https://www.transparencia.sp.gov.br/" target="_blank">Transparência</a>|
    <a class="footFinal" href="http://www.sic.sp.gov.br/" target="_blank">SIC</a>
  </span>
  <span class="versao">{{ versao }}</span>
</div>
