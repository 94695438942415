import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'mesPorExtenso',
  standalone: true
})
export class MesPorExtensoPipe implements PipeTransform {
  transform(value: string): string {
    const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
    if (parseInt(value) >= 1 && parseInt(value) <= 12) {
      return months[parseInt(value) - 1];
    } else {
      const dateParts = value.split('/');
      const year = parseInt(dateParts[2]);
      const month = parseInt(dateParts[1]);
      return months[month - 1] + '/' + year;
    }
  }
}
