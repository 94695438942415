<div class="flex-container">
  <div class="header-menu-container">
    <div>
      <app-separador titulo="SERVIÇOS" />
      <app-servicos />
    </div>
    <div>
      <app-separador titulo="DESTAQUES" />
    </div>
    <div class="posicionamento">
      <app-noticias-destaque />
    </div>
    <div>
      <app-separador titulo="ACESSO RÁPIDO" />
    </div>
    <div class="full-width-background">
      <app-acesso-rapido />
    </div>
    <div class="full-width-background-redes">
      <app-nas-redes />
    </div>
  </div>
</div>
