export enum PerfilEnum {
  SERVIDOR = '0',
  PROCURADOR = '1',
  APOSENTADO = '2',
  FALECIDO = '3',
  EXONERADO = '4',
  TRANSF_DEF_PUB = '5',
  DEMITIDO = '6',
  AUTARQUIA = '7'
}

export function obterPerfilPorId(id: string): PerfilEnum | undefined {
  return Object.values(PerfilEnum).find((p: string) => p === id);
}
