<div class="mapa-do-site" tabindex="0" [class.expanded]="espandida" (click)="alteranarConteudo()" (keypress)="alteranarConteudo()">
  <div class="header">
    <span class="cor">MAPA DO SITE</span>
    <mat-icon>{{ espandida ? "expand_less" : "expand_more" }}</mat-icon>
    <span class="toggle" tabindex="0" (click)="alteranarConteudo()" (keypress)="alteranarConteudo()"> </span>
  </div>
  @if (espandida) {
    <div class="content">
      <div class="menu-container">
        <ng-container>
          <div class="menu-item">
            <h5>SERVIÇOS</h5>
            <span class="sub-menu">
              @for (servico of servicoList; track servico) {
                <li>
                  @if (!servico.linkExterno) {
                    <a [routerLink]="servico.url">{{ servico.titulo }}</a>
                  }
                  @if (servico.linkExterno) {
                    <a target="_blank" [href]="servico.url">{{ servico.titulo }}</a>
                  }
                </li>
              }
            </span>
          </div>
        </ng-container>
        @for (item of menuList; track item) {
          <div class="menu-item">
            @if (item.menuFilho) {
              <h4>{{ item.titulo }}</h4>
              <div class="sub-menu">
                @for (subItem of item.menuFilho; track subItem) {
                  <li>
                    @if (!subItem.externo) {
                      <a [routerLink]="subItem.redirecionar">{{ subItem.titulo }}</a>
                    }
                    @if (subItem.externo) {
                      <a target="_blank" [href]="subItem.redirecionar">{{ subItem.titulo }}</a>
                    }
                    @if (subItem.menuFilho && subItem.menuFilho.length) {
                      <ul class="sub-sub-menu">
                        @for (subSubItem of subItem.menuFilho; track subSubItem) {
                          <li>
                            @if (!subSubItem.externo) {
                              <a [routerLink]="subSubItem.redirecionar">{{ subSubItem.titulo }}</a>
                            }
                            @if (subSubItem.externo) {
                              <a target="_blank" [href]="subSubItem.redirecionar">{{ subSubItem.titulo }}</a>
                            }
                            @if (subSubItem.menuFilho && subSubItem.menuFilho.length) {
                              <ul class="sub-sub-sub-menu">
                                @for (subSubSubItem of subSubItem.menuFilho; track subSubSubItem) {
                                  <li>
                                    @if (!subSubSubItem.externo) {
                                      <a [routerLink]="subSubSubItem.redirecionar">{{ subSubSubItem.titulo }}</a>
                                    }
                                    @if (subSubSubItem.externo) {
                                      <a target="_blank" [href]="subSubSubItem.redirecionar">{{ subSubSubItem.titulo }}</a>
                                    }
                                  </li>
                                }
                              </ul>
                            }
                          </li>
                        }
                      </ul>
                    }
                  </li>
                }
              </div>
            }
            @if (!item.menuFilho) {
              <a class="nav-link menubtn" target="_blank" [href]="item.redirecionar"
                ><h4>{{ item.titulo }}</h4></a
              >
            }
          </div>
        }
      </div>
    </div>
  }
</div>
