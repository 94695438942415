<main class="main" role="main">
  <section class="page">
    <div class="flex-container-separador">
      <app-separador titulo="Subprocuradoria" />
      <div class="flex-spacer-separador"></div>
      <app-separador-email
        titulo="ATENDIMENTO"
        msg1="Horário: de segunda a sexta-feira, das 8h às 20h"
        msg2="E-mail: contenciosogeralpge@sp.gov.br"
        msg3="Telefones: (11) 3372-6436 | 3372-6490 | 3372-6451"
        msg4="Endereço: Rua Pamplona,227, 7º andar, Jardim Paulista, São Paulo-SP, 01405-902"
      />
    </div>
    <div class="row">
      <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
        <h2>Subprocuradoria Geral do Contencioso Geral</h2>
        <p class="procurador"><strong>Subprocurador(a) Geral:</strong> Bruno Lopes Megna</p>
        <p class="procurador"><strong>Subprocurador(a) Geral Adjunto(a):</strong> Juliana Campolina Rebelo Horta</p>
        <p class="endereco"><strong>Endereço</strong>: Rua Pamplona,227, 7º andar, Jardim Paulista, São Paulo-SP, 01405-902.</p>
        <p class="tel"><strong>Telefone</strong>: (11) 3372-6436/6490/6451</p>
        <p><strong>Assistentes/Assessores</strong></p>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("AMANDA DE MORAES MODOTTI") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("AUGUSTO BELLO ZORZI") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("CLAUDIO HENRIQUE RIBEIRO DIAS") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("FERNANDO HENRIQUE MEDICI") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("JULIANA CAMPOLINA REBELO HORTA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MARCELO GATTO SPINARDI") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("MARINA ELISA COSTA DE ARAUJO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("PRISCILLA SOUZA E SILVA MENARIO SCOFANO") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("RENAN RAULINO SANTIAGO") }}</p>
        </div>
        <br />
        <hr />
        <br />
        <h2><em>Grupo Especial de Atuação do Contencioso</em>&nbsp;Geral – GEAC</h2>
        <div class="pge-unidade">
          <p class="demais-procuradores">{{ encontrarNome("FLORENCE ANGEL GUIMARAES MARTINS DE SOUZA") }}</p>
          <p class="demais-procuradores">FRANCIMAR SOARES DA SILVA JUNIOR</p>
          <p class="demais-procuradores">{{ encontrarNome("GUILHERME MOREIRA LOURES DA COSTA") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("RENATA LANE") }}</p>
          <p class="demais-procuradores">{{ encontrarNome("RENATO MANENTE CORREA") }}</p>
        </div>
        <br />
        <hr />
      </div>
    </div>
  </section>
</main>
