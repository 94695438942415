import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { LocalStorageService } from 'src/app/area-restrita/shared/services/local-storage.service';
import { Globais } from '../../../shared/globais/globais';
import { MenuPgeFim } from './menu-pge-fim';
import { MenuPgeHome } from './menu-pge-home';
import { MenuPgeLegado } from './menu-pge-legado';
import type { MenuNode } from './MenuNode';

@Injectable({
  providedIn: 'root'
})
export class MenuSguService {
  private readonly http = inject(HttpClient);
  private readonly localS = inject(LocalStorageService);

  private readonly menuDataSubject = new BehaviorSubject<MenuNode[]>([]);
  menuData$ = this.menuDataSubject.asObservable();
  menuUser: MenuNode[] = [];
  version: number = 0;
  private readonly menuItemclick = new Subject<MenuNode>();

  getMenu() {
    const idUsuario = this.localS.get('idUsuario');
    void this.getMenus(idUsuario);
    return this.menuUser;
  }

  async getMenus(idUsuario: string) {
    this.version++;
    this.menuUser = [];

    this.menuUser.push(MenuPgeHome);
    this.menuUser.push(MenuPgeLegado);
    const token = localStorage.getItem('token').toString();
    const headers = new HttpHeaders({ Authorization: token });
    const params = new HttpParams().set('idUsuario', idUsuario);
    return this.http.get<MenuNode[]>(`${Globais.urlToken}site-pge/api/menus`, { headers: headers, params: params }).subscribe(
      (resp) => {
        for (const itemMenu of resp) this.menuUser.push(itemMenu);
        this.menuUser.push(MenuPgeFim);
        this.menuDataSubject.next(this.menuUser);
      },
      (error) => {
        console.error('Error na busca de dados em criarMenus()', error);
      }
    );
  }

  emitMenuItemclicado$ = this.menuItemclick.asObservable();

  emitMenuItemclicado(item: MenuNode) {
    this.menuItemclick.next(item);
  }
}
