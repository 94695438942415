import type { HttpErrorResponse } from '@angular/common/http';
import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatFormField } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/area-restrita/shared/services/authentication.service';
import { LocalStorageService } from 'src/app/area-restrita/shared/services/local-storage.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { LegadoUsuarioService } from '../../auxilio/shared/services/legado-usuario.service';
import { FundoAreaRestritaPgeComponent } from '../shared/components/fundo-area-restrita-pge/fundo-area-restrita-pge.component';
import type { TrocaSenha } from '../shared/services/senha.service';
import { SenhaService } from '../shared/services/senha.service';

@Component({
  selector: 'app-alterar-senha-card',
  templateUrl: './alterar-senha-card.component.html',
  styleUrl: './alterar-senha-card.component.scss',
  standalone: true,
  imports: [FundoAreaRestritaPgeComponent, FormsModule, ReactiveFormsModule, MatButton, MatDivider, MatFormField, MatInputModule, MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlterarSenhaCardComponent implements OnInit {
  private readonly fb = inject(FormBuilder);
  private readonly senhaService = inject(SenhaService);
  private readonly toastr = inject(ToastService);
  private readonly localStorage = inject(LocalStorageService);
  private readonly legadoUsuarioService = inject(LegadoUsuarioService);
  private readonly route = inject(ActivatedRoute);
  private readonly authenticationService = inject(AuthenticationService);
  private readonly router = inject(Router);

  areaRestrita = signal(this.route.snapshot.params['isRestrito'] === 'true');
  temError = signal(false);
  mensagemError = '';

  senhaAntigaVisivel = false;
  senhaNovaVisivel = false;
  confirmaSenhaVisivel = false;

  formularioAlterarSenha: FormGroup;
  formularioAlterarSenhaRestricao: FormGroup;

  hideAtual = signal(true);
  hideNova1 = signal(true);
  hideNova2 = signal(true);

  ngOnInit(): void {
    this.formularioAlterarSenha = this.fb.group({
      senhaAntiga: ['', Validators.required],
      senhaNova: ['', [Validators.required]],
      confirmaSenha: ['', Validators.required]
    });

    this.formularioAlterarSenhaRestricao = this.fb.group({
      senhaAntiga: ['', Validators.required],
      senhaNova: ['', [Validators.required]],
      confirmaSenha: ['', Validators.required]
    });

    this.route.queryParams.subscribe((params) => {
      this.areaRestrita.set(params['isRestrito'] === 'true');
    });
  }

  mudarSenha(restrito: boolean): void {
    this.temError.set(false);
    const form = restrito ? this.formularioAlterarSenhaRestricao : this.formularioAlterarSenha;

    if (form.invalid) {
      this.mensagemError = 'Todos os campos devem ser preenchidos';
      this.temError.set(true);
      return;
    }

    const { senhaAntiga, senhaNova, confirmaSenha } = form.value as TrocaSenha;

    if (senhaNova !== confirmaSenha) {
      this.mensagemError = 'Senha digitada não é igual à nova senha!';
      this.temError.set(true);
      return;
    }

    if (senhaAntiga === senhaNova) {
      this.mensagemError = 'A nova senha não pode ser igual à antiga';
      this.temError.set(true);
      return;
    }

    const trocaSenha: TrocaSenha = { senhaAntiga, senhaNova };
    this.senhaService.alterarSenha(trocaSenha).subscribe({
      next: () => {
        this.verificarUsuarioNoLegado(trocaSenha);
      },
      error: (error) => {
        this.exibirErro('Falha ao trocar senha no sistema atual. A senha permanece inalterada.', error);
      }
    });
  }

  verificarUsuarioNoLegado(trocaSenha: TrocaSenha): void {
    const config = new MatSnackBarConfig();
    config.duration = 5000;

    const cpfUsuario = this.localStorage.get('cpf');
    if (!cpfUsuario) {
      this.exibirErro('Erro: CPF do usuário não encontrado.');
      return;
    }
    this.legadoUsuarioService.checkUsuarioLegado(cpfUsuario).subscribe({
      next: () => {
        this.trocaSenhaNoLegado(trocaSenha);
      },
      error: (error: HttpErrorResponse) => {
        console.log(error);
        this.toastr.info('Usuário não encontrado no legado, será levado para o novo sistema somente!', 'Aviso do Sistema', config);
        this.authenticationService.logout('/login');
      }
    });
  }

  trocaSenhaNoLegado(trocaSenha: TrocaSenha): void {
    const config = new MatSnackBarConfig();
    config.duration = 5000;

    const cpfUsuario = this.localStorage.get('cpf');
    if (!cpfUsuario) {
      this.exibirErro('Erro: CPF do usuário não encontrado.');
      return;
    }
    this.legadoUsuarioService.enviarNovaSenhaParaLegado(cpfUsuario, trocaSenha.senhaNova).subscribe({
      next: () => {
        this.toastr.info('Senha alterada com sucesso nos dois sistemas!', 'Aviso do Sistema', config);
        this.authenticationService.logout('/login');
      },
      error: (error: HttpErrorResponse) => {
        this.reverterSenhaNoNovoAmbiente(trocaSenha);
        this.exibirErro('Erro ao trocar senha no sistema legado. A senha permanece inalterada em ambos os sistemas.', error);
      }
    });
  }

  reverterSenhaNoNovoAmbiente(trocaSenha: TrocaSenha): void {
    const senhaOriginal: TrocaSenha = { senhaAntiga: trocaSenha.senhaNova, senhaNova: trocaSenha.senhaAntiga };
    this.senhaService.alterarSenha(senhaOriginal).subscribe({
      next: () => {
        console.log('Reversão da senha no novo ambiente bem-sucedida.');
      },
      error: (error) => {
        console.error('Falha ao reverter a senha no novo ambiente.', error);
      }
    });
  }

  exibirErro(mensagem: string, error?: HttpErrorResponse): void {
    const config = new MatSnackBarConfig();
    config.duration = 5000;
    this.temError.set(true);
    this.mensagemError = mensagem;
    this.toastr.error(mensagem, 'Erro', config);
    if (error) console.error('Erro:', error.message || 'Erro desconhecido');
  }

  voltar(): void {
    void this.router.navigate(['area-restrita/home']);
  }

  toggleSenhaAntigaVisivel(): void {
    this.senhaAntigaVisivel = !this.senhaAntigaVisivel;
  }

  toggleSenhaNovaVisivel(): void {
    this.senhaNovaVisivel = !this.senhaNovaVisivel;
  }

  toggleConfirmaSenhaVisivel(): void {
    this.confirmaSenhaVisivel = !this.confirmaSenhaVisivel;
  }

  clickAtual(event: MouseEvent): void {
    this.hideAtual.set(!this.hideAtual());
    event.stopPropagation();
  }

  clickNova1(event: MouseEvent): void {
    this.hideNova1.set(!this.hideNova1());
    event.stopPropagation();
  }

  clickNova2(event: MouseEvent): void {
    this.hideNova2.set(!this.hideNova2());
    event.stopPropagation();
  }
}
