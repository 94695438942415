import type { HttpErrorResponse } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LegadoUsuarioService {
  private readonly http = inject(HttpClient);

  private readonly env = environment;
  private readonly user: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public enviarNovaSenhaParaLegado(cpf: string, senha: string): Observable<void> {
    const body = {
      cpf: cpf,
      senha: senha
    };

    return this.http.post<undefined>(this.env.apiUrl + 'legado/senha', body).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Erro durante o envio de nova senha ao sistema legado:', error);
        return throwError(() => error);
      })
    );
  }

  public checkUsuarioLegado(id: string): Observable<void> {
    const url = `${this.env.apiUrl}legado/check/${id}`;

    return this.http.post<undefined>(url, null);
  }
}
