import { NgClass } from '@angular/common';
import type { HttpErrorResponse } from '@angular/common/http';
import type { ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, signal, ViewChild } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatNativeDateModule, MatOption } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInput, MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import type { PageEvent } from '@angular/material/paginator';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { finalize, Subject, takeUntil } from 'rxjs';
import type { InscricaoAuxilioSaude, Perfil, StatusAuxilio } from 'src/app/area-restrita/features/auxilio/saude/shared/models/auxilio-saude';
import { WaitLoadingService } from 'src/app/area-restrita/shared/components/wait-loading/wait-loading.service';
import { LocalStorageService } from 'src/app/area-restrita/shared/services/local-storage.service';
import { PaginaVisitadaService } from 'src/app/area-restrita/shared/services/pagina-visitada.service';
import type { Page } from 'src/app/shared/models/paginacao/page.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { StateAnaliseInscricaoService } from '../../shared/services/state-analise.service';
import { InscricaoAuxilioSaudeService } from '../shared/services/inscricao-auxilio-saude.service';
import { MesaAuxilioSaudeService } from '../shared/services/mesa-auxilio-saude.service';

@Component({
  selector: 'app-mesa-analise-inscricao',
  templateUrl: './mesa-analise-inscricao.component.html',
  styleUrl: './mesa-analise-inscricao.component.scss',
  standalone: true,

  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatLabel,
    MatFormField,
    MatInput,
    MatSelect,
    MatOption,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatDatepicker,
    MatButton,
    MatPaginator,
    NgClass,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MesaAnaliseInscricaoComponent implements OnInit, OnDestroy {
  private readonly mesaAuxilioSaudeService = inject(MesaAuxilioSaudeService);
  private readonly inscricaoAuxilioSaudeService = inject(InscricaoAuxilioSaudeService);
  private readonly formBuilder = inject(FormBuilder);
  private readonly router = inject(Router);
  private readonly toastr = inject(ToastService);
  private readonly localStorage = inject(LocalStorageService);
  private readonly waitLoadingService = inject(WaitLoadingService);
  private readonly stateAnaliseInscricaoService = inject(StateAnaliseInscricaoService);
  private readonly paginaService = inject(PaginaVisitadaService);
  private readonly cdr = inject(ChangeDetectorRef);

  private readonly destroy$ = new Subject<void>();

  @ViewChild('dataDeferimentoInput') dataDeferimentoInput: ElementRef<HTMLInputElement>;

  pedidosInscricao = signal<InscricaoAuxilioSaude[]>([]);
  pedidosSelecionados: Set<number> = new Set<number>();
  statusAuxilio = signal<StatusAuxilio[]>([]);
  checkboxCabecalhoSelecionado = signal(false);
  mostraTabela = signal(false);
  ativaBotao = signal(true);
  perfil: Perfil[];

  comprimento = signal(0);
  tamanhoDaPagina = signal(10);
  numeroPagina = signal(0);
  opcoesDoTamanhoDaPagina = [5, 10, 25];

  isAnaliseInscricao = false;

  ngOnInit(): void {
    this.paginaService.salvaPagina('Analisar Inscrição no Auxílio Saúde').subscribe();
    this.isAnaliseInscricao = this.router.url.includes('analisar-inscricao');
    this.numeroPagina.set(this.stateAnaliseInscricaoService.page);
    this.tamanhoDaPagina.set(this.stateAnaliseInscricaoService.size);
    this.stateAnaliseInscricaoService.limpar();
    this.recuperarFiltro();
    this.obterTiposStatus();
    this.obterPerfis();
    const filtroArmazenado = this.localStorage.getJson('filtroInscricao');
    if (filtroArmazenado && this.existeValorNoFiltro(filtroArmazenado)) {
      setTimeout(() => {
        this.buscarPedidos(true);
      }, 1000);
    }
  }

  dadosFormulario = this.formBuilder.group({
    procuradorServidor: [this.stateAnaliseInscricaoService.procuradorServidor],
    status: [this.stateAnaliseInscricaoService.status],
    perfil: [this.stateAnaliseInscricaoService.perfil],
    dataDeferimento: [this.stateAnaliseInscricaoService.dataDeferimento],
    dataEnvio: [this.stateAnaliseInscricaoService.dataEnvio]
  });

  buscarPedidos(resetarTabela: boolean): void {
    if (this.dadosFormulario.valid) {
      if (resetarTabela) this.mostraTabela.set(false);
      this.waitLoadingService.open();
      const valorNome = this.dadosFormulario.controls.procuradorServidor.getRawValue();
      const valorDataEnvio = this.dadosFormulario.controls.dataEnvio.getRawValue();
      const valorDataDeferimento = this.dadosFormulario.controls.dataDeferimento.getRawValue();
      this.dadosFormulario.controls.procuradorServidor.setValue('');
      this.dadosFormulario.controls.dataEnvio.setValue('');
      this.dadosFormulario.controls.dataDeferimento.setValue(this.dataDeferimentoInput.nativeElement.value);

      this.localStorage.setJson('filtroInscricao', this.dadosFormulario.value);

      this.dadosFormulario.controls.procuradorServidor.setValue(valorNome);
      this.dadosFormulario.controls.dataEnvio.setValue(valorDataEnvio);

      this.inscricaoAuxilioSaudeService
        .buscarPedidos(this.dadosFormulario, this.numeroPagina(), this.tamanhoDaPagina())
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => {
            this.waitLoadingService.close();
          })
        )
        .subscribe({
          next: (response: Page<InscricaoAuxilioSaude>) => {
            this.pedidosInscricao.set(response.content);
            this.comprimento.set(response.totalElements);
            this.mostraTabela.set(true);
            this.ativaBotao.set(true);
            if (resetarTabela) this.numeroPagina.set(0);
          },
          error: (error: HttpErrorResponse) => {
            this.toastr.error(error.message);
          }
        });

      this.dadosFormulario.controls.dataDeferimento.setValue(valorDataDeferimento);
    }
  }

  avaliarPedido(pedido: InscricaoAuxilioSaude): void {
    if (this.dadosFormulario.controls.status.value) {
      this.stateAnaliseInscricaoService.status = this.dadosFormulario.controls.status.value;
    }

    if (this.dadosFormulario.controls.dataEnvio.value) {
      this.stateAnaliseInscricaoService.dataEnvio = this.dadosFormulario.controls.dataEnvio.value;
    }

    this.stateAnaliseInscricaoService.telaAnalise = true;
    this.stateAnaliseInscricaoService.page = this.numeroPagina();
    this.stateAnaliseInscricaoService.size = this.tamanhoDaPagina();
    if (!this.isAnaliseInscricao) {
      void this.router.navigate(['area-restrita', 'analise-pedido-auxilio-saude', pedido.id]);
      return;
    }
    void this.router.navigate(['area-restrita', 'inscricao-auxilio-avaliacao', pedido.usuario.id]);
  }

  deferirPedidos(): void {
    this.inscricaoAuxilioSaudeService
      .deferirPedidos(this.pedidosSelecionados)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: string) => {
          this.toastr.success(response);
          this.buscarPedidos(true);
          this.pedidosSelecionados = new Set<number>();
          this.checkboxCabecalhoSelecionado.set(false);
          this.ativaBotao.set(true);
        },
        error: (error: HttpErrorResponse) => {
          this.toastr.error('Ocorreu um erro ao deferir os pedidos: ' + error.message);
        }
      });
  }

  indeferirPedidos(): void {
    this.inscricaoAuxilioSaudeService
      .indeferirPedidos(this.pedidosSelecionados)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: string) => {
          this.toastr.success(response);
          this.buscarPedidos(true);
          this.pedidosSelecionados = new Set<number>();
          this.checkboxCabecalhoSelecionado.set(false);
          this.ativaBotao.set(true);
        },
        error: (error: HttpErrorResponse) => {
          this.toastr.error('Ocorreu um erro ao indeferir os pedidos: ' + error.message);
        }
      });
  }

  checarPedidoSelecionado(pedidoAuxilio: InscricaoAuxilioSaude): void {
    if (pedidoAuxilio.podeDeferir) {
      const pedidoId = Number.parseInt(pedidoAuxilio.id);

      if (this.pedidosSelecionados.has(pedidoId)) {
        this.pedidosSelecionados.delete(pedidoId);
      } else {
        this.pedidosSelecionados.add(pedidoId);
      }

      this.ativaBotao.set(this.pedidosSelecionados.size == 0);
    }
    const todosSelecionados = this.pedidosInscricao()
      .filter((pedido) => pedido.podeDeferir)
      .every((pedido) => this.pedidosSelecionados.has(Number.parseInt(pedido.id)));
    this.checkboxCabecalhoSelecionado.set(todosSelecionados);
  }

  obterTiposStatus(): void {
    this.inscricaoAuxilioSaudeService
      .obterListaStatusInscricaoAuxilio()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dados) => {
          this.statusAuxilio.set(dados);
        },
        error: (error) => {
          console.error('Erro ao obter dados do serviço', error);
        }
      });
  }

  obterPerfis(): void {
    this.waitLoadingService.open();
    this.mesaAuxilioSaudeService
      .obterListaPerfis()
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.waitLoadingService.close();
        })
      )
      .subscribe({
        next: (dados) => {
          this.perfil = dados;
        },
        error: (error) => {
          console.error('Erro ao obter dados do serviço', error);
        }
      });
  }

  limparFormulario(): void {
    this.dadosFormulario.reset({
      procuradorServidor: '',
      status: '',
      perfil: '',
      dataDeferimento: '',
      dataEnvio: ''
    });
    this.localStorage.remove('filtroInscricao');
    this.comprimento.set(0);
    this.tamanhoDaPagina.set(10);
    this.numeroPagina.set(0);
    this.pedidosSelecionados.clear();
    this.pedidosInscricao.set([]);
    this.mostraTabela.set(false);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  selecionarTodos(evento: Event): void {
    const estaVerificado = evento.target as HTMLInputElement;
    this.checkboxCabecalhoSelecionado.set(estaVerificado.checked);

    this.pedidosInscricao().forEach((pedido) => {
      if (pedido.podeDeferir) {
        if (estaVerificado.checked) {
          this.pedidosSelecionados.add(Number.parseInt(pedido.id));
        } else {
          this.pedidosSelecionados.delete(Number.parseInt(pedido.id));
        }
      }
    });
    this.ativaBotao.set(!estaVerificado.checked || this.pedidosSelecionados.size == 0);
  }

  lidarEventoPagina(e: PageEvent): void {
    this.comprimento.set(e.length);
    this.tamanhoDaPagina.set(e.pageSize);
    this.numeroPagina.set(e.pageIndex);
    this.buscarPedidos(false);
  }

  recuperarFiltro(): void {
    const filtro = this.localStorage.getJson('filtroInscricao');

    if (filtro) {
      this.dadosFormulario.patchValue(filtro);
    }
  }

  private existeValorNoFiltro(filtro): boolean {
    return Object.values(filtro).some((value) => !!value);
  }
}
