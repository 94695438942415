import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StateAnaliseService {
  procuradorServidor = '';
  status = '';
  perfil = '';
  tipoAuxilioSaude = '';
  mesAnoSolicitacao = '';
  periodoDe = '';
  periodoAte = '';
  telaAnalise = false;
  telaAnaliseInscricao = false;
  page = 0;
  size = 10;
  mesAnoOrdem = false;
  limpar() {
    this.procuradorServidor = '';
    this.status = '';
    this.perfil = '';
    this.tipoAuxilioSaude = '';
    this.mesAnoSolicitacao = '';
    this.periodoDe = '';
    this.periodoAte = '';
    this.telaAnaliseInscricao = false;
    this.page = 0;
    this.size = 10;
    this.mesAnoOrdem = false;
  }
}

@Injectable({
  providedIn: 'root'
})
export class StateAnaliseInscricaoService {
  procuradorServidor = '';
  status = '1';
  dataEnvio = '';
  telaAnalise = false;
  page = 0;
  size = 10;
  limpar() {
    this.procuradorServidor = '';
    this.status = '1';
    this.page = 0;
    this.size = 10;
  }
}
