import type { ProcuradorConselho } from './procuradores-conselho.interface';

export const ProcuradoresConselho: ProcuradorConselho[] = [
  {
    titulo: 'Presidente',
    nome: 'INÊS MARIA DOS SANTOS COIMBRA',
    representacao: 'Procuradora Geral do Estado',
    imagem: '',
    email: '',
    bienio: '2023-2024'
  },
  {
    titulo: 'Membro Nato',
    nome: 'FABIO TRABOLD GASTALDO',
    representacao: 'Corregedor Geral',
    imagem: '',
    email: '',
    bienio: '2023-2024'
  },
  {
    titulo: 'Membro Nato',
    nome: 'CINTIA BYCZKOWSKI',
    representacao: 'Procuradora Chefe do Centro de Estudos',
    imagem: '',
    email: '',
    bienio: '2023-2024'
  },
  {
    titulo: 'Membro Nato',
    nome: 'ALESSANDRA OBARA SOARES DA SILVA',
    representacao: 'Subprocuradora Geral - Consultoria Geral',
    imagem: '',
    email: '',
    bienio: '2023-2024'
  },
  {
    titulo: 'Membro Nato',
    nome: 'DANILO BARTH PIRES',
    representacao: 'Subprocurador Geral - Contencioso Tributário - Fiscal',
    imagem: '',
    email: '',
    bienio: '2023-2024'
  },
  {
    titulo: 'Membro Nato',
    nome: 'BRUNO LOPES MEGNA',
    representacao: 'Subprocurador Geral - Contencioso Geral',
    imagem: '',
    email: '',
    bienio: '2023-2024'
  },
  {
    titulo: 'Membro Eleito',
    nome: 'GUSTAVO CAMPOS ABREU',
    representacao: 'Nível I',
    imagem: '',
    email: 'gcabreu@sp.gov.br',
    bienio: '2023-2024'
  },
  {
    titulo: 'Membro Eleito',
    nome: 'ANA PAULA VENDRAMINI',
    representacao: 'Nível II',
    imagem: '',
    email: 'avendramini@sp.gov.br',
    bienio: '2023-2024'
  },
  {
    titulo: 'Membro Eleito',
    nome: 'MILENA CARLA AZZOLINI PEREIRA DA ROSA',
    representacao: 'Nível III',
    imagem: '',
    email: 'mcazzolini@sp.gov.br',
    bienio: '2023-2024'
  },
  {
    titulo: 'Membro Eleito',
    nome: 'PAULO HENRIQUE SILVA GODOY',
    representacao: 'Nível V',
    imagem: '',
    email: 'phgodoy@sp.gov.br',
    bienio: '2023-2024'
  },
  {
    titulo: 'Membro Eleito',
    nome: 'EDUARDO LUIS DE OLIVEIRA FILHO',
    representacao: 'Consultoria',
    imagem: '',
    email: 'elfilho@sp.gov.br',
    bienio: '2023-2024'
  },
  {
    titulo: 'Membro Eleito',
    nome: 'RAFAEL POLITI ESPOSITO GOMES',
    representacao: 'Contencioso',
    imagem: '',
    email: 'rpegomes@sp.gov.br',
    bienio: '2023-2024'
  },
  {
    titulo: 'Membro Eleito',
    nome: 'JOÃO GUILHERME SIMÕES HERRERA',
    representacao: 'Contencioso Tributário - Fiscal',
    imagem: '',
    email: 'jherrera@sp.gov.br',
    bienio: '2023-2024'
  }
];
