import type { OnInit } from '@angular/core';
import { Component, inject } from '@angular/core';
import { WebServiceInfosService } from 'src/app/shared/services/infos-webservice.service';
import { SeparadorEmailComponent } from '../../../../core/layout/separador-email/separador-email.component';
import { SeparadorComponent } from '../../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-subprocuradoria-contencioso-geral',
  templateUrl: './subprocuradoria-contencioso-geral.component.html',
  standalone: true,

  imports: [SeparadorComponent, SeparadorEmailComponent]
})
export class SubprocuradoriaContenciosoGeralComponent implements OnInit {
  private readonly service = inject(WebServiceInfosService);

  informacoes: string[];
  codigoUnidades: number[] = [40110500000080, 40110500400091, 40110500600091, 40110500500091, 40110700700091, 40110500800091, 40110500800011];

  ngOnInit(): void {
    this.carregarConteudo();
  }

  carregarConteudo() {
    const vetorElementos: string[] = [];
    this.codigoUnidades.forEach((codigo) => {
      this.service.getInfosPorCodigo(codigo).subscribe(
        (response) => {
          const parser = new DOMParser();
          const xml = parser.parseFromString(response, 'text/xml');
          xml.querySelectorAll('NOME_PROCURADOR, NOME_SUBSTITUTO, NOME_SUBSTITUTO2').forEach((element) => {
            if (element.textContent !== '') {
              vetorElementos.push(element.textContent.trim());
            }
          });
        },
        (error) => {
          console.log(error);
        }
      );
    });

    this.informacoes = vetorElementos;
  }

  encontrarNome(nome: string): string {
    const result = this.informacoes.find((element) => element === nome);
    return result ? result : 'Nome não encontrado';
  }
}
