<main class="main" role="main">
  <section class="page">
    <div class="container">
      <app-separador titulo="Apresentação e Composição" />
      <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
          <h2>Corregedoria</h2>
          <br />
          <p>
            A Corregedoria da Procuradoria Geral do Estado tem, entre suas atribuições, orientar e fiscalizar as atividades dos órgãos da Procuradoria Geral do Estado e dos Procuradores do Estado, no
            exercício de suas funções, realizando correições periódicas.
          </p>
          <p>Cabe à Corregedoria, ainda, apreciar as representações que lhe forem encaminhadas relativamente à atuação da Procuradoria Geral do Estado e dos integrantes da carreira.</p>

          <h3>Equipe da Corregedoria</h3>
          <br />
          <p><strong>Corregedor Geral:</strong> Fabio Trabold Gastaldo</p>
          <p><strong>Corregedor Geral Adjunto:</strong> Haroldo Pereira</p>
          <p><strong>Corregedora Auxiliar:</strong> Carolina Ferraz Passos</p>
          <br />
          <hr />
          <p>Telefone: (11) 3372-6483</p>
          <p>Rua Pamplona 227, 8º andar, São Paulo, SP</p>
          <hr />
          <pre></pre>
        </div>
      </div>
    </div>
  </section>
</main>
