<div class="grid-container">
  @for (noticia of noticiasDestaque().slice(0, 5); track noticia.id) {
    <div class="grid-item" tabindex="0" (click)="abrirUmaNoticia(noticia?.id!)" (keypress)="abrirUmaNoticia(noticia?.id!)">
      <img class="item-imagem" alt="Imagem da Notícia" [src]="getImagem(noticia?.imagem)" />
      <div class="description">{{ noticia?.titulo }}</div>
    </div>
  }
  <div class="align-button">
    <button mat-flat-button class="botao-mais-noticias" type="submit" (click)="noticiasDiversas()">Mais Notícias</button>
  </div>
</div>
