import { ChangeDetectionStrategy, Component, inject, output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-wait-loading',
  templateUrl: './wait-loading.component.html',
  styleUrl: './wait-loading.component.scss',
  standalone: true,
  imports: [MatProgressSpinner],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WaitLoadingComponent {
  readonly fechar = output<string>();
  readonly label = inject<string>(MAT_DIALOG_DATA);

  onClose(): void {
    this.fechar.emit('');
  }
}
