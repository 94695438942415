import { NgClass } from '@angular/common';
import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, HostListener, inject, signal } from '@angular/core';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatNavList } from '@angular/material/list';
import { MatDrawer, MatDrawerContainer, MatDrawerContent } from '@angular/material/sidenav';
import { Router, RouterOutlet } from '@angular/router';
import type { Subscription } from 'rxjs';
import type { MenuNode } from 'src/app/area-restrita/core/layout/menu/MenuNode';
import { MenuSguService } from 'src/app/area-restrita/core/layout/menu/MenuSgu.service';
import { AuthenticationService } from 'src/app/area-restrita/shared/services/authentication.service';
import { UsuarioService } from '../../../features/home/shared/services/usuario.service';
import { MenuItemComponent } from '../menu/menu-item/menu-item.component';

@Component({
  selector: 'app-home-area-restrita',
  templateUrl: './home-area-restrita.component.html',
  styleUrl: './home-area-restrita.component.scss',
  standalone: true,
  imports: [MatIconButton, MatIcon, MatDrawerContainer, MatDrawer, NgClass, MatNavList, MenuItemComponent, MatDrawerContent, RouterOutlet],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeAreaRestritaComponent implements OnInit, OnDestroy {
  private readonly router = inject(Router);
  private readonly authenticationService = inject(AuthenticationService);
  private readonly menuSguService = inject(MenuSguService);
  private readonly UsuarioService = inject(UsuarioService);

  nomes = [];
  dataAtual = signal('');

  menuAtivo = signal(false);
  private timeoutHandle: NodeJS.Timeout;
  menuAberto = signal(true);
  estaMostrando = signal(false);
  botaoFechar = signal(false);
  idUsuario = localStorage.getItem('idUsuario');

  menuDataSubscription: Subscription;
  dataSource = signal<MenuNode[]>([]);

  alternarMenuUsuario() {
    this.menuAtivo.set(!this.menuAtivo());
    if (this.menuAtivo()) {
      this.resetMenu();
    }
  }
  manterMenu() {
    if (this.timeoutHandle) {
      clearTimeout(this.timeoutHandle);
    }
  }
  fecharMenu() {
    this.timeoutHandle = setTimeout(() => {
      this.menuAtivo.set(false);
    }, 1000);
  }
  private resetMenu() {
    if (this.timeoutHandle) {
      clearTimeout(this.timeoutHandle);
    }
  }
  sairUsuario() {
    void this.router.navigate(['area-restrita/logout-site-pge']);
  }

  ngOnInit() {
    if (localStorage.getItem('logout') == 'true') {
      this.authenticationService.logout('', false);
    }

    this.menuSguService.getMenu();
    this.menuDataSubscription = this.menuSguService.menuData$.subscribe((menu) => {
      this.dataSource.set(menu);
    });
    this.dataAtual.set(this.obterDataAtual());
    this.obterNomeUsuario();
    this.opcaoSelecionada();
    this.menuSguService.emitMenuItemclicado$.subscribe(() => {
      this.alternarMenu(true);
    });

    window.addEventListener('storage', (event) => {
      if (event.key === 'logout') {
        if (localStorage.getItem('logout') == 'true') {
          this.authenticationService.logout('', false);
        }
      }
    });
  }

  obterNomeUsuario() {
    this.UsuarioService.obterDadosUsuario(Number(localStorage.getItem('idUsuario'))).subscribe(
      (response) => {
        localStorage.setItem('nascimento', response.dataNascimento);
        localStorage.setItem('pcd', String(response.pcd));
        const responseNome = response.nome.trim();
        if (responseNome.includes(',')) this.nomes = response.nome.split(',');
        else if (responseNome.includes(' ')) this.nomes = response.nome.split(' ');
      },
      (error) => {
        console.log(error);
      }
    );
  }

  obterDataAtual(): string {
    const data = new Date();
    const dia = data.getDate().toString().padStart(2, '0');
    const mes = (data.getMonth() + 1).toString().padStart(2, '0'); // Lembre-se que os meses começam em 0
    const ano = data.getFullYear();
    const hora = data.getHours().toString().padStart(2, '0');
    const minutos = data.getMinutes().toString().padStart(2, '0');

    return `${dia}/${mes}/${ano} ${hora}:${minutos}`;
  }

  ngOnDestroy() {
    if (this.menuDataSubscription) {
      this.menuDataSubscription.unsubscribe();
    }
  }

  eServicoMobile(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
      return true;
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return true;
    }

    return false;
  }

  alternarMenu(botaoFechar: boolean, click: boolean = false) {
    if (click) {
      this.menuAberto.set(!this.menuAberto());
      this.estaMostrando.set(this.menuAberto());
    } else {
      if (this.menuAberto() && !botaoFechar) {
        this.estaMostrando.set(true);
      } else {
        this.estaMostrando.set(false);
      }

      if (this.eServicoMobile()) {
        this.menuAberto.set(!this.menuAberto);
      }
    }
  }

  opcaoSelecionada() {
    if (this.eServicoMobile()) {
      this.menuAberto.set(false);
      this.estaMostrando.set(false);
      this.botaoFechar.set(true);
    } else {
      this.botaoFechar.set(false);
    }
  }

  alterarSenha() {
    const isRestrito = 'true';
    void this.router.navigate(['area-restrita', 'alterar-senha-area-restrita'], {
      queryParams: { isRestrito: isRestrito }
    });
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.opcaoSelecionada();
  }

  obterPrimeiroESegundoNome(): string {
    if (this.nomes.length > 1) {
      return `${this.nomes[0]} ${this.nomes[1]}`;
    }
    return this.nomes[0];
  }
}
