import type { PipeTransform } from '@angular/core';
import { inject, Pipe } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import type { SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'truncateHtml',
  standalone: true
})
export class TruncateHtmlPipe implements PipeTransform {
  private readonly sanitizer = inject(DomSanitizer);
  transform(value: string, limit: number): SafeHtml {
    if (!value) return '';
    if (value.length <= limit) return value;
    return this.sanitizer.bypassSecurityTrustHtml(value.substring(0, limit) + '... Ver completo no(s) arquivo(s) anexo.<br>');
  }
}
