import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import type { ParamMap } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';
import { ImagemTratamento } from 'src/app/area-aberta/features/home/shared/services/imagem-tratamento';
import { CategoriaNoticia } from 'src/app/area-aberta/shared/enums/categoria-noticia';
import { ConversorValidadorService } from 'src/app/area-restrita/shared/services/conversor-validador.service';
import type { Noticias, PdfAnexo } from 'src/app/shared/models/noticia.interface';
import type { Page } from 'src/app/shared/models/paginacao/page.model';
import { NoticiaService } from 'src/app/shared/services/noticia.service';
import { SeparadorComponent } from '../../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-noticia-contadores',
  templateUrl: './noticia-contadores.component.html',
  styleUrl: './noticia-contadores.component.scss',
  providers: [ImagemTratamento],
  standalone: true,

  imports: [MatIcon, SeparadorComponent, MatButton],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoticiaContadoresComponent implements OnInit, OnDestroy {
  private readonly noticiaService = inject(NoticiaService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly imagemTratamento = inject(ImagemTratamento);
  private readonly base64ToPdfService = inject(ConversorValidadorService);

  noticia = signal<Noticias | undefined>(undefined);
  objNoticias = signal<Noticias[]>([]);
  noticiaId: number;
  arquivos = signal<PdfAnexo[]>([]);

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.noticiaId = Number(params.get('id'));

      if (this.noticiaId) {
        this.noticiaService.getOneNoticia(this.noticiaId).subscribe((noticia) => {
          this.noticia.set(noticia);
          this.obterArquivos(this.noticiaId);
          this.obterNoticiasDestaque();
        });
        //TODO: Redirect para o login caso não o ID seja invalido
      }
    });
  }
  retornaListaNoticias(): void {
    void this.router.navigate(['/informacao-cidadao/credenciamento-contadores']);
  }

  obterArquivos(idNoticia: number): void {
    this.noticiaService.obterArquivosNoticias(idNoticia).subscribe({
      next: (response) => {
        if (response) this.arquivos.set(response);
      },
      error: console.error
    });
  }

  obterNoticiasDestaque(): void {
    this.noticiaService.getNoticia(0, 5, CategoriaNoticia.CREDENCIAMENTO_DE_CONTADORES).subscribe((response: Page<Noticias>) => {
      this.objNoticias.set(response.content.filter((noticia) => noticia.ativo && noticia.id !== this.noticiaId).slice(0, 4));
    });
  }

  abrirUmaNoticia(noticiaId: number): void {
    void this.router.navigate(['informacao-cidadao/credenciamento-contadores/noticia', noticiaId]);
    this.obterArquivos(noticiaId);
  }

  getImagem(noticiaImagem?: string): string | undefined {
    return this.imagemTratamento.obterImagemBlob(noticiaImagem);
  }

  processarTexto(texto: string): string {
    if (texto) {
      return texto.replace(/<img\b[^>]*>/, (match) => match.replace('>', ' width="60%">'));
    } else {
      return '';
    }
  }

  openPDF(event: MouseEvent, pdf: PdfAnexo): void {
    event.preventDefault();
    this.base64ToPdfService.base64ToBlob(pdf.arquivo);
  }

  ngOnDestroy(): void {
    this.imagemTratamento.revogarUrlBlob();
  }
}
