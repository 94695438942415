import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import type { Observable } from 'rxjs';
import { map } from 'rxjs';
import { Globais } from 'src/app/area-restrita/shared/globais/globais';
import type { Page } from 'src/app/shared/models/paginacao/page.model';
import type { AuxilioFinanceiroVoltaEtapa } from '../models/auxilio-financeiro-voltaetapa';
import type { AuxilioSaudeMesaAnalise } from '../models/auxilio-saude';
import type { AuxilioFinanceiro, FormFinanceiro } from './../models/auxilio-financeiro';

interface Dados {
  procuradorServidor: string | null;
  totalDeferido: string | null;
  mesAnoSolicitacao: string | null;
  periodoDe: string | null;
  periodoAte: string | null;
}

@Injectable({
  providedIn: 'root'
})
export class FinanceiroAuxilioSaudeService {
  private readonly formBuilder = inject(FormBuilder);
  private readonly http = inject(HttpClient);

  public dadosCarregados = this.formBuilder.group({
    procuradorServidor: [''],
    totalDeferido: [''],
    mesAnoSolicitacao: [''],
    periodoDe: [''],
    periodoAte: ['']
  });

  public salvarDados(dados: Dados): void {
    this.dadosCarregados.setValue(dados);
  }

  public esvaziarDados(): void {
    this.dadosCarregados.reset({
      procuradorServidor: '',
      totalDeferido: '',
      mesAnoSolicitacao: '',
      periodoDe: '',
      periodoAte: ''
    });
  }

  public buscarDeferidos(
    dadosFormulario: FormGroup<FormFinanceiro>,
    pageIndex: string | number,
    pageSize: string | number
  ): Observable<Page<AuxilioSaudeMesaAnalise>> {
    const dataRecebida = dadosFormulario.controls.mesAnoSolicitacao.value;

    let parametros = new HttpParams()
      .set('nomeUsuario', dadosFormulario.controls.procuradorServidor.value)
      .set('dataEnvioInicial', dadosFormulario.controls.periodoDe.value)
      .set('dataEnvioFinal', dadosFormulario.controls.periodoAte.value)
      .set('idStatus', 2)
      .set('mesAnoOrdem', true)
      .set('page', pageIndex)
      .set('size', pageSize);

    if (dataRecebida && dataRecebida.trim() !== '') {
      const partesData = dataRecebida.split('-');
      parametros = parametros.set('anoReembolso', partesData[0]);
      parametros = parametros.set('mesParcela', partesData[1]);
    }
    return this.http.get<Page<AuxilioSaudeMesaAnalise>>(`${Globais.urlToken}site-pge/api/auxilios`, { params: parametros });
  }

  public definePagamento(dados: AuxilioFinanceiro): Observable<string> {
    const idUsuario = Number(localStorage.getItem('idUsuario'));
    const parametros = new HttpParams().set('idUsuario', idUsuario);

    return this.http.put<string>(`${Globais.urlToken}site-pge/api/auxilios/pagar`, dados, { params: parametros, responseType: 'text' as 'json' }).pipe(
      map((response) => {
        return response;
      })
    );
  }

  public voltaEtapa(dados: AuxilioFinanceiroVoltaEtapa): Observable<string> {
    const parametros = new HttpParams();
    return this.http
      .put<string>(`${Globais.urlToken}site-pge/api/auxilios/saude/financeiro/voltar-etapa`, dados.idsAuxilios, {
        params: parametros,
        responseType: 'text' as 'json'
      })
      .pipe(
        map((response) => {
          return response;
        })
      );
  }
}
