import { animate, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { ServicoList } from './servicos';
import { CardServicoComponent } from './card-servico/card-servico.component';
import { PrimeTemplate } from 'primeng/api';
import { CarouselModule } from 'primeng/carousel';

@Component({
  selector: 'app-servicos',
  templateUrl: './servicos.component.html',
  styleUrl: './servicos.component.scss',
  animations: [trigger('fadeOut', [transition(':leave', [animate('0.1s', style({ opacity: 0 }))])])],
  standalone: true,
  imports: [CarouselModule, PrimeTemplate, CardServicoComponent]
})
export class ServicosComponent {
  conteudoCartao = ServicoList;

  opcoesResponsividade = [
    {
      breakpoint: '1280px',
      numVisible: 4,
      numScroll: 4
    },
    {
      breakpoint: '1024px',
      numVisible: 3,
      numScroll: 3
    },
    {
      breakpoint: '750px',
      numVisible: 2,
      numScroll: 2
    },
    {
      breakpoint: '480px',
      numVisible: 1,
      numScroll: 1
    }
  ];

  slideConfig = {
    slidesToShow: 5,
    dots: true,
    autoplay: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
}
