import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, ViewChild, inject, signal } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatFormField } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import type { PageEvent } from '@angular/material/paginator';
import { MatPaginator } from '@angular/material/paginator';
import { Router, RouterLink } from '@angular/router';
import { NgxCurrencyDirective } from 'ngx-currency';
import { Subject, finalize, takeUntil } from 'rxjs';
import { WaitLoadingService } from 'src/app/area-restrita/shared/components/wait-loading/wait-loading.service';
import { FormatBRLPipe } from 'src/app/area-restrita/shared/pipes/format-brl.pipe';
import { MesPorExtensoPipe } from 'src/app/area-restrita/shared/pipes/mes-por-extenso.pipe';
import { PaginaVisitadaService } from 'src/app/area-restrita/shared/services/pagina-visitada.service';
import type { Page } from 'src/app/shared/models/paginacao/page.model';
import { ToastService } from 'src/app/shared/toast/toast.service';
import type { DadosFormFinanceiro, FormFinanceiro } from '../shared/models/auxilio-financeiro';
import type { AuxilioSaudeMesaAnalise } from '../shared/models/auxilio-saude';
import { FinanceiroAuxilioSaudeService } from '../shared/services/financeiro-auxilio-saude.service';

@Component({
  selector: 'app-auxilio-financeiro',
  templateUrl: './auxilio-financeiro.component.html',
  styleUrl: './auxilio-financeiro.component.scss',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    NgxCurrencyDirective,
    MatButton,
    MatPaginator,
    RouterLink,
    FormatBRLPipe,
    MesPorExtensoPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuxilioFinanceiroComponent implements OnDestroy, OnInit {
  private readonly financeiroAuxilioS = inject(FinanceiroAuxilioSaudeService);
  private readonly toastrS = inject(ToastService);
  private readonly formBuilder = inject(FormBuilder);
  private readonly waitLoadingService = inject(WaitLoadingService);
  private readonly paginaService = inject(PaginaVisitadaService);
  private readonly router = inject(Router);

  private readonly destroy$ = new Subject<void>();
  pedidosAuxilioFinanceiro = signal<AuxilioSaudeMesaAnalise[]>([]);
  pedidosSelecionados = signal<Set<number>>(new Set<number>());
  verificado = true;
  checkboxCabecalhoSelecionado: boolean = false;
  ativaBotao: boolean = true;
  dataAprovacao: string = '';

  paginador = false;

  eventoPagina: PageEvent;
  comprimento = 0;
  tamanhoDaPagina = 10;
  indicePagina = 0;
  opcoesDoTamanhoDaPagina = [5, 10, 25];

  ocultarTamanhoDaPagina = false;
  mostrarOpcoesDeTamanhoPagina = true;
  mostrarPrimeiroUltimosBotoes = true;
  desabilitado = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dadosFormulario = this.formBuilder.group({
    procuradorServidor: [''],
    totalDeferido: [''],
    mesAnoSolicitacao: [''],
    periodoDe: [''],
    periodoAte: ['']
  });

  ngOnInit(): void {
    const dadosCarregados = this.financeiroAuxilioS.dadosCarregados.value;

    if (this.temDadosValidos(dadosCarregados)) {
      this.dadosFormulario.patchValue(dadosCarregados);
      this.financeiroAuxilioS.esvaziarDados();
    }
    this.paginaService.salvaPagina('Financeiro do Auxílio Saúde').subscribe();
  }

  private temDadosValidos(dados: DadosFormFinanceiro): boolean {
    return Object.keys(dados).some((chave) => dados[chave] && String(dados[chave]).trim() !== '');
  }

  lidarEventoPagina(e: PageEvent): void {
    this.eventoPagina = e;
    this.comprimento = e.length;
    this.tamanhoDaPagina = e.pageSize;
    this.indicePagina = e.pageIndex;
    this.buscarDeferidos();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  buscarDeferidos(): void {
    this.waitLoadingService.open();
    this.financeiroAuxilioS
      .buscarDeferidos(this.dadosFormulario as FormGroup<FormFinanceiro>, this.indicePagina, this.tamanhoDaPagina)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.waitLoadingService.close();
        })
      )
      .subscribe({
        next: (response: Page<AuxilioSaudeMesaAnalise>) => {
          this.pedidosAuxilioFinanceiro.set(response.content);
          this.comprimento = response.totalElements;
          this.paginador = !response.empty;

          this.pedidosSelecionados.set(new Set<number>());
          if (this.pedidosAuxilioFinanceiro().length == 0) this.toastrS.warning('Nenhum auxílio deferido foi encontrado.');
        },
        error: () => {
          this.toastrS.error(
            'Não foi possível carregar a lista de financeiros no momento. Por favor, tente novamente mais tarde. Se o problema persistir, entre em contato com o suporte.'
          );
        }
      });
  }

  siafem(): void {
    this.financeiroAuxilioS.salvarDados(this.dadosFormulario.getRawValue());
    void this.router.navigate(['area-restrita/siafem-importe']);
  }
  voltarEtapa(): void {
    const auxilioFinanceiroVoltaEtapa = {
      idsAuxilios: [...this.pedidosSelecionados()]
    };
    this.financeiroAuxilioS
      .voltaEtapa(auxilioFinanceiroVoltaEtapa)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: string) => {
          this.pedidosSelecionados.set(new Set<number>());
          this.buscarDeferidos();
          this.toastrS.success(response);
        },
        error: (error: Error) => {
          this.toastrS.error(error.message);
        }
      });
  }

  verificarPedidoSelecionado(pedidoAuxilio: AuxilioSaudeMesaAnalise): void {
    const pedidoId = Number.parseInt(pedidoAuxilio.id);
    if (this.pedidosSelecionados().has(pedidoId)) {
      this.pedidosSelecionados.update((pedidos) => {
        pedidos.delete(pedidoId);
        return pedidos;
      });
    } else {
      this.pedidosSelecionados.update((pedidos) => {
        pedidos.add(pedidoId);
        return pedidos;
      });
    }
    this.checkboxCabecalhoSelecionado = this.pedidosAuxilioFinanceiro().every((pedido) => this.pedidosSelecionados().has(Number.parseInt(pedido.id)));
  }

  selecionarTodos(evento: Event): void {
    const caixaSelecao = evento.target as HTMLInputElement;
    this.checkboxCabecalhoSelecionado = caixaSelecao.checked;
    const arrayIdPedido = this.pedidosSelecionados();
    this.pedidosAuxilioFinanceiro().forEach((pedido) => {
      const pedidoId = Number.parseInt(pedido.id);
      if (this.checkboxCabecalhoSelecionado) {
        arrayIdPedido.add(pedidoId);
      } else {
        arrayIdPedido.delete(pedidoId);
      }
    });

    this.pedidosSelecionados.set(arrayIdPedido);
  }

  limparFormulario(): void {
    this.dadosFormulario.reset({
      procuradorServidor: '',
      totalDeferido: '',
      mesAnoSolicitacao: '',
      periodoDe: '',
      periodoAte: ''
    });
    this.pedidosSelecionados().clear();
    this.pedidosAuxilioFinanceiro.set([]);
    this.verificado = true;
  }

  realizarPagamento(): void {
    const auxilioFin = {
      idsAuxilios: [...this.pedidosSelecionados()],
      dataPagamento: this.obterDataConversao(this.dataAprovacao)
    };
    this.financeiroAuxilioS
      .definePagamento(auxilioFin)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: string) => {
          this.waitLoadingService.close();
          this.toastrS.success(response);
          this.buscarDeferidos();
          this.pedidosSelecionados.set(new Set<number>());
        },
        error: (error: Error) => {
          this.toastrS.error(error.message);
        }
      });
  }

  obterDataConversao(date: string): string {
    const dataAtual = new Date(date);
    const ano = dataAtual.getFullYear();
    const mes = ('0' + (dataAtual.getMonth() + 1)).slice(-2);
    const dia = ('0' + dataAtual.getDate()).slice(-2);

    return `${dia}/${mes}/${ano}`;
  }

  validoData(data: string): boolean {
    if (data == '') return false;
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.exec(data)) {
      return false;
    }

    const dataInstanciada = new Date(data);
    const carimboDataHora = dataInstanciada.getTime();

    if (typeof carimboDataHora !== 'number' || Number.isNaN(carimboDataHora)) {
      return false;
    }

    return dataInstanciada.toISOString().startsWith(data);
  }
  formatCpf(cpf: string): string {
    const espacoRemovido = cpf.replace(/\D/g, '');
    const padrao = /(\d{3})(\d{3})(\d{3})(\d{2})/;
    const formatado = espacoRemovido.replace(padrao, '$1.$2.$3-$4');
    return formatado;
  }
}
