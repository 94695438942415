<div id="topoDaPagina" class="container">
  <br />
  <h1 class="card-title titulo">Análise Pedidos - Auxílio Saúde</h1>
  <br />

  @if (pedidoAuxilio() && !pedidoAuxilio()?.fichaAprovada) {
    <div class="alert-bar">
      <mat-icon class="alerta" fontIcon="warning" matTooltip="Inscrição Aprovada" />
      Inscrição no auxilio Saúde encontra-se em Análise. Não é possível realizar avaliação!
    </div>
  }
  <br />

  <div class="row g-3">
    @if (pedidoAuxilio()) {
      <form [formGroup]="formAnalisePedidoAuxilioSaude" (ngSubmit)="enviarFormulario()">
        <div class="row">
          <div class="d-flex row col-md-12">
            <div class="col-md-6">
              <div class="row">
                <div class="d-flex row">
                  <div class="col-md-12">
                    <label for="rg" class="form-label">Procurador/Servidor:</label>
                    <mat-form-field class="mat-form-control">
                      <input matInput type="text" id="rg" disabled [value]="pedidoAuxilio().usuario.nome" />
                    </mat-form-field>
                  </div>
                </div>
                <div class="d-flex row">
                  <div class="col-md-6">
                    <label for="anoRequerimento" class="form-label">Ano do Requerimento:</label>
                    <mat-form-field class="mat-form-control">
                      <input matInput type="text" id="anoRequerimento" disabled [value]="pedidoAuxilio().dataEmissaoNota | extrairAnoData" />
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <label for="mesSolicitadoReembolso" class="form-label">Mês Solicitado para Reembolso:</label>
                    <mat-form-field class="mat-form-control">
                      <input matInput type="text" id="mesSolicitadoReembolso" disabled [value]="pedidoAuxilio().dataEmissaoNota | obterMesData" />
                    </mat-form-field>
                  </div>
                </div>
                <div class="d-flex row">
                  <div class="col-md-6">
                    <label for="dataVencimentoParcela" class="form-label">Data Vencimento Parcela:</label>
                    <mat-form-field class="mat-form-control">
                      <input matInput type="text" id="dataVencimentoParcela" disabled [value]="pedidoAuxilio().dataVencimentoParcela" />
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <label for="dataInscricao" class="form-label">Data de Inscrição:</label>
                    <mat-form-field class="mat-form-control">
                      <input matInput type="text" id="dataInscricao" disabled [value]="dataInscricao()" />
                    </mat-form-field>
                  </div>
                </div>
                <div class="d-flex row">
                  <div id="avaliacao" class="col-md-12">Avaliação:</div>
                </div>
                <div class="d-flex row">
                  <div class="col-md-12">
                    <div class="form-check">
                      @if (pedidoAuxilio().podeDeferir && pedidoAuxilio().fichaAprovada) {
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          value="100%"
                          [checked]="cemPorcento()"
                          [disabled]="pedidoAuxilio().status.id === statusAuxilioEnum.CANCELADO"
                          (change)="selecionarValor($event)"
                        />
                      }
                      <label class="form-check-label" for="flexRadioDefault1">
                        @if (!pedidoAuxilio().podeDeferir && pedidoAuxilio().flagAvaliacao === "A") {
                          <img src="images/check-circle-fill.svg" alt="Check selecionado" />
                        }
                        @if (!pedidoAuxilio().podeDeferir && pedidoAuxilio().flagAvaliacao !== "A") {
                          <img src="/images/circle.svg" alt="Check não selecionado" />
                        }
                        Aprovar Pedido (100%)
                      </label>
                    </div>
                    <div class="form-check">
                      @if (pedidoAuxilio().podeDeferir && pedidoAuxilio().fichaAprovada) {
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                          value="0%"
                          [checked]="zeroPorcento()"
                          [disabled]="pedidoAuxilio().status.id === statusAuxilioEnum.CANCELADO"
                          (change)="selecionarValor($event)"
                        />
                      }
                      <label class="form-check-label" for="flexRadioDefault2">
                        @if (!pedidoAuxilio().podeDeferir && pedidoAuxilio().flagAvaliacao === "S") {
                          <img src="images/check-circle-fill.svg" alt="Check selecionado" />
                        }
                        @if (!pedidoAuxilio().podeDeferir && pedidoAuxilio().flagAvaliacao !== "S") {
                          <img src="images/circle.svg" alt="Check não selecionado" />
                        }
                        Sem reembolso (0%)
                      </label>
                    </div>
                    <div class="form-check">
                      @if (pedidoAuxilio().podeDeferir && pedidoAuxilio().fichaAprovada) {
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault3"
                          value="inserirValorEspecifico"
                          [checked]="valorEspecifico()"
                          [disabled]="pedidoAuxilio().status.id === statusAuxilioEnum.CANCELADO"
                          (change)="selecionarValor($event)"
                        />
                      }
                      <label class="form-check-label" for="flexRadioDefault3">
                        @if (!pedidoAuxilio().podeDeferir && pedidoAuxilio().flagAvaliacao === "I") {
                          <img src="images/check-circle-fill.svg" alt="Check selecionado" />
                        }
                        @if (!pedidoAuxilio().podeDeferir && pedidoAuxilio().flagAvaliacao !== "I") {
                          <img src="images/circle.svg" alt="Check não selecionado" />
                        }
                        Inserir valor específico:
                      </label>
                    </div>
                  </div>
                </div>
                <div class="d-flex row">
                  <div class="col-md-6">
                    <label for="valorDeferidoPGE" class="form-label">
                      Valor Deferido pela PGE:
                      <span class="text-danger requerido">*</span>
                    </label>
                    <mat-form-field class="mat-form-control">
                      @if (pedidoAuxilio().podeDeferir) {
                        <input
                          matInput
                          type="text"
                          id="valorDeferidoPGE"
                          formControlName="valorDeferidoPGE"
                          mask="separator.2"
                          prefix="R$ "
                          placeholder="R$ 0,00"
                          [min]="0.0"
                          [thousandSeparator]="'.'"
                          [decimalMarker]="','"
                        />
                      }
                      @if (!pedidoAuxilio().podeDeferir) {
                        <input matInput type="text" class="form-control" id="rg" disabled [value]="pedidoAuxilio().valorDeferidoPge | currency" />
                      }
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <div>
                      <label for="dataAprovacao" class="form-label" [ngClass]="{ 'highlight-data-aprovacao': destacarData() }">
                        Data da Aprovação:
                        <span class="text-danger requerido">*</span>
                      </label>
                    </div>
                    <input type="date" class="form-control input-group" id="dataAprovacao" formControlName="dataAprovacaoPGE" [ngClass]="{ 'highlight-data-aprovacao': destacarData() }" />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="d-flex row">
                  <div class="col-md-6">
                    <label for="valorSolicitadoTitular" class="form-label">Tipo de Auxílio Saúde:</label>
                    <mat-form-field class="mat-form-control">
                      <input matInput type="text" id="valorSolicitadoTitular" disabled [value]="pedidoAuxilio().tipoAuxilioSaude.tipo" />
                    </mat-form-field>
                  </div>
                  <div class="col-md-6">
                    <label for="dataEnvioPedido" class="form-label">Data do Envio do Pedido:</label>
                    <mat-form-field class="mat-form-control">
                      <input matInput type="text" id="dataEnvioPedido" disabled [value]="pedidoAuxilio().dataEnvioPedido" />
                    </mat-form-field>
                  </div>
                </div>
                <div class="d-flex row">
                  @if (pedidoAuxilio().valorReembolsoTitular > 0) {
                    <div class="col-md-6">
                      <label for="valorSolicitadoTitular" class="form-label">Valor Solicitado pelo Titular:</label>
                      <mat-form-field class="mat-form-control">
                        <input matInput type="text" id="valorSolicitadoTitular" disabled [value]="pedidoAuxilio().valorReembolsoTitular | currency" />
                      </mat-form-field>
                    </div>
                  }
                  @if (pedidoAuxilio().valorReembolsoDependente > 0) {
                    <div class="col-md-6">
                      <label for="valorSolicitadoDependente" class="form-label">Valor Solicitado para Dependentes:</label>
                      <mat-form-field class="mat-form-control">
                        <input matInput type="text" id="valorSolicitadoDependente" disabled [value]="pedidoAuxilio().valorReembolsoDependente | currency" />
                      </mat-form-field>
                    </div>
                  } @else {
                    <div class="col-md-6">
                      <label for="valorTotalSolicitado" class="form-label">Valor Total Solicitado:</label>
                      <mat-form-field class="mat-form-control">
                        <input matInput type="text" id="valorTotalSolicitado" disabled [value]="pedidoAuxilio().valorReembolsoTotal | currency" />
                      </mat-form-field>
                    </div>
                  }
                </div>
                @if (pedidoAuxilio().valorReembolsoDependente > 0) {
                  <div class="d-flex row">
                    <div class="col-md-6">
                      <label for="valorTotalSolicitado" class="form-label">Valor Total Solicitado:</label>
                      <mat-form-field class="mat-form-control">
                        <input matInput type="text" id="valorTotalSolicitado" disabled [value]="pedidoAuxilio().valorReembolsoTotal | currency" />
                      </mat-form-field>
                    </div>
                  </div>
                }

                <div class="row">
                  <div class="col-md-12">
                    <div>
                      <label for="observacao">Observação:</label>
                    </div>
                    <textarea matInput type="text" class="form-control campo-observacao" id="observacao" formControlName="observacao" rows="5" cols="66">{{ pedidoAuxilio().observacao }}</textarea>
                  </div>
                </div>
                <div>
                  <br />
                  @if (documentosTitular().length > 0) {
                    <div class="d-flex row">
                      <div class="col-md-12 form-label">Documentos enviados Titular:</div>
                    </div>
                    <div class="row">
                      <!-- Coluna 2 -->
                      <div class="col-md-6">
                        <div class="d-flex flex-column">
                          @for (documentoTitular of documentosTitular().slice(documentosTitular().length / 2); track documentoTitular; let i = $index) {
                            <div class="row">
                              <div class="col">
                                <a href="#" (click)="abrirArquivo($event, documentoTitular)">
                                  <img class="icon-pdf" src="images/pdf-file-svgrepo-com.svg" alt="PDF" /> {{ documentoTitular.nomeArquivo }}
                                </a>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                      <!-- Coluna 1 -->
                      <div class="col-md-6">
                        <div class="d-flex flex-column">
                          @for (documentoTitular of documentosTitular().slice(0, documentosTitular().length / 2); track documentoTitular; let i = $index) {
                            <div class="row">
                              <div class="col">
                                <a href="#" (click)="abrirArquivo($event, documentoTitular)">
                                  <img class="icon-pdf" src="images/pdf-file-svgrepo-com.svg" alt="PDF" /> {{ documentoTitular.nomeArquivo }}
                                </a>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                    <br />
                  }
                  @if (documentosDependente().length > 0) {
                    <div class="d-flex row">
                      <div class="col-md-12 form-label">Documentos enviados Dependentes:</div>
                    </div>
                    <div class="row">
                      <!-- Coluna 2 -->
                      <div class="col-md-6">
                        <div class="d-flex flex-column">
                          @for (documentoDependente of documentosDependente().slice(documentosDependente().length / 2); track documentoDependente; let i = $index) {
                            <div class="row">
                              <div class="col">
                                <a href="#" (click)="abrirArquivo($event, documentoDependente)">
                                  <img class="icon-pdf" src="images/pdf-file-svgrepo-com.svg" alt="PDF" /> {{ documentoDependente.nomeArquivo }}
                                </a>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                      <!-- Coluna 1 -->
                      <div class="col-md-6">
                        <div class="d-flex flex-column">
                          @for (documentoDependente of documentosDependente().slice(0, documentosDependente().length / 2); track documentoDependente; let i = $index) {
                            <div class="row">
                              <div class="col">
                                <a href="#" (click)="abrirArquivo($event, documentoDependente)">
                                  <img class="icon-pdf" src="images/pdf-file-svgrepo-com.svg" alt="PDF" /> {{ documentoDependente.nomeArquivo }}
                                </a>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        @if (pedidoAuxilio().dependentesAuxilioSaude.length > 0) {
          <div class="fw-bold">Dependentes</div>
        }
        @if (pedidoAuxilio().dependentesAuxilioSaude.length > 0) {
          <div class="table-responsive">
            <table class="tabelaPadrao">
              <thead>
                <tr class="mb-2">
                  <th class="fw-bold text-center">Nome</th>
                  <th class="fw-bold text-center">CPF</th>
                  <th class="fw-bold text-center">Data Nascimento</th>
                  <th class="fw-bold text-center">Descrição de Parentesco</th>
                </tr>
              </thead>
              <tbody>
                @for (dependente of pedidoAuxilio().dependentesAuxilioSaude; track dependente; let i = $index) {
                  <tr class="mb-2">
                    <td class="text-center">{{ dependente.nome.toLocaleUpperCase() }}</td>
                    <td class="text-center">{{ dependente.cpfDependente }}</td>
                    <td class="text-center">{{ dependente.dataNascimento }}</td>
                    <td class="text-center">{{ dependente.descricaoDependente }}</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        }
        <div class="col-12 botao-enviar-formulario">
          <button mat-button type="button" class="btn btn-dark botaoRetificacao" (click)="cancelarEnvio()">Voltar</button>&nbsp;
          @if (pedidoAuxilio().podeDeferir && pedidoAuxilio().fichaAprovada && pedidoAuxilio().status.id !== statusAuxilioEnum.CANCELADO) {
            <button mat-button type="button" class="btn btn-dark botaoRetificacao" (click)="EnviarRetificacaoAvaliarAuxilioSaude()">Enviar para Retificação</button>
          }
          @if (pedidoAuxilio().podeDeferir && pedidoAuxilio().fichaAprovada && pedidoAuxilio().status.id !== statusAuxilioEnum.CANCELADO) {
            <button mat-button type="submit" class="btn btn-dark">Realizar Avaliação</button>
          }
        </div>
      </form>
    }
  </div>
</div>
