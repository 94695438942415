<br />
<h1 class="card-title titulo">Analisar Pedido de Auxílio Saúde</h1>
<br />
<div class="row g-3">
  <form [formGroup]="dadosFormulario" (ngSubmit)="buscarPedidos()">
    <div class="d-flex row">
      <div class="col-md-6">
        <label for="procuradorServidor" class="form-label">Procurador/Servidor:</label>

        <mat-form-field class="mat-form-control">
          <input matInput placeholder="" formControlName="procuradorServidor" />
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <label for="status" class="form-label" id="status">Status:</label>

        <mat-form-field>
          <mat-select class="mat-form-control" formControlName="status" (selectionChange)="buscarPedidos()" (keyup.enter)="buscarPedidos()">
            @for (s of statusAuxilio(); track s.id) {
              <mat-option [value]="s.id">
                {{ s.nome }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col-md-3">
        <label for="perfil" class="form-label" id="perfil">Tipo de Perfil:</label>
        <mat-form-field>
          <mat-select class="mat-form-control" formControlName="perfil" (selectionChange)="buscarPedidos()" (keyup.enter)="buscarPedidos()">
            @for (s of perfil; track s) {
              <mat-option [value]="s.id">
                {{ s.nome }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="d-flex row tag-geral-entradas">
      <div class="col-md-3">
        <label for="tipoAuxilioSaude" class="form-label" id="tipoAuxilioSaude">Tipo de Auxílio Saúde:</label>
        <mat-form-field>
          <mat-select class="mat-form-control" formControlName="tipoAuxilioSaude" (selectionChange)="buscarPedidos()" (keyup.enter)="buscarPedidos()">
            @for (s of tiposAuxilioSaude(); track s) {
              <mat-option [value]="s.id">
                {{ s.tipo }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <label for="mesAnoSolicitacao" class="form-label" id="mesAnoSolicitacao">Mês/Ano de solicitação:</label>
        <input id="mesAnoSolicitacao" name="mesAnoSolicitacao" class="mat-full-width form-control" type="month" formControlName="mesAnoSolicitacao" />
      </div>
      <div class="col-md-3 tag-data">
        <mat-label for="periodoDe" class="form-label" id="periodoDe">Período de:</mat-label>
        <mat-form-field class="mat-full-width form" (keyup.enter)="buscarPedidos()">
          <input matInput formControlName="periodoDe" [matDatepicker]="pickerDe" />
          <mat-datepicker-toggle matIconSuffix [for]="pickerDe" />
          <mat-datepicker #pickerDe />
        </mat-form-field>
      </div>
      <div class="col-md-3 tag-data">
        <mat-label for="periodoDe" class="form-label" id="periodoDe">Até:</mat-label>
        <mat-form-field class="mat-full-width form" id="periodoAte" name="periodoAte" (keyup.enter)="buscarPedidos()">
          <input matInput formControlName="periodoAte" [matDatepicker]="pickerAte" />
          <mat-datepicker-toggle matIconSuffix [for]="pickerAte" />
          <mat-datepicker #pickerAte />
        </mat-form-field>
      </div>
    </div>
    <br />
    <div class="d-flex row">
      <div class="col-12 conjunto-botoes">
        <button mat-flat-button type="button" id="apesp" name="apesp" class="btn-dark botao-iamspe" routerLink="/area-restrita/apesp-importe">IAMSPE/APESP</button>
        <div>
          <button mat-flat-button color="primary" type="submit" id="buscar" name="buscar" class="btn-dark" [disabled]="!this.dadosFormulario.valid">Buscar</button>
          <button mat-button type="button" id="limpaForm" name="limpaForm" class="clear-filter text-secondary" (click)="limparFormulario()">Limpar filtros</button>
        </div>
      </div>
    </div>
    <br />
  </form>
  @if (mostraTabela()) {
    <div class="tabela-paginacao">
      <div class="table-responsive">
        <table class="tabelaPadrao">
          <thead>
            <tr>
              <th class="fw-bold text-center">
                <input type="checkbox" (change)="selecionarTodos($event)" />
              </th>
              <th class="fw-bold text-center">Nome</th>
              <th class="fw-bold text-center">Status</th>
              <th class="fw-bold text-center">Tipo de Perfil</th>
              <th class="fw-bold text-center">Tipo de Auxilio Saúde</th>
              <th class="fw-bold text-center tabela-titulo-mes" (click)="lidarOrnadacao()">Mês de Solicitação</th>
              <th class="fw-bold text-center">Total Solicitado</th>
              <th class="fw-bold text-center">Total Deferido</th>
              <th class="fw-bold text-center">Obs</th>
              <th class="coluna-remover">Cancelar</th>
            </tr>
          </thead>
          <tbody>
            @for (pedido of pedidosAuxilio(); track pedido.id; let i = $index) {
              <tr>
                <td class="text-center">
                  <input
                    type="checkbox"
                    [checked]="pedido.podeDeferir && checkboxCabecalhoSelecionado()"
                    [disabled]="!(pedido.podeDeferir && pedido.fichaAprovada && pedido.inscricaoAuxilioSaude.status.id !== 1)"
                    (change)="verificarPedidoSelecionado(pedido)"
                  />
                </td>
                <td>
                  <a class="tipoLink" tabindex="0" (click)="avaliarPedido(pedido)" (keypress)="avaliarPedido(pedido)">{{ pedido.usuario.nome }} </a>
                </td>
                <td class="text-center">
                  <a class="tipoLink" tabindex="0" (click)="avaliarPedido(pedido)" (keypress)="avaliarPedido(pedido)"> {{ pedido.status.nome }} </a>
                </td>
                <td class="text-center">
                  <a class="tipoLink" tabindex="0" (click)="avaliarPedido(pedido)" (keypress)="avaliarPedido(pedido)"> {{ pedido.usuario.perfil.nome }}</a>
                </td>
                <td class="text-center">
                  <a class="tipoLink" tabindex="0" (click)="avaliarPedido(pedido)" (keypress)="avaliarPedido(pedido)"> {{ pedido.tipoAuxilioSaude.tipo }} </a>
                </td>
                <td class="text-center">{{ pedido.mesParcela | mesPorExtenso }}/{{ pedido.anoReembolso }}</td>
                <td class="text-center">
                  {{ pedido.valorReembolsoTotal | formatarBRL }}
                </td>
                <td class="text-center">
                  {{ pedido.valorDeferidoPge | currency }}
                </td>
                <td class="text-center">
                  @if (pedido.inscricaoAuxilioSaude.status.id === 1) {
                    <mat-icon class="emAnalise" fontIcon="timelapse" matTooltip="Inscrição Em Análise. Não é possível avaliar" matTooltipPosition="above" />
                  }
                  @if (pedido.inscricaoAuxilioSaude.status.id === 3 || pedido.inscricaoAuxilioSaude.status.id === 5) {
                    <mat-icon class="cancelada" fontIcon="cancel" matTooltip="Inscrição Indeferida. Contactar a PGE" matTooltipPosition="above" />
                  }
                </td>
                <td>
                  @if (pedido.status.id === statusAuxilioEnum.AGUARDANDO_AVALIACAO) {
                    <button mat-icon-button type="submit" (click)="remover(pedido.id)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  }
                </td>
              </tr>
            }
          </tbody>
        </table>
        <!-- Aviso quando a tabela estiver vazia -->
        @if (!pedidosAuxilio() || pedidosAuxilio().length === 0) {
          <div>
            <p class="text-center">Nenhum registro encontrado.</p>
          </div>
        }
      </div>
      <mat-paginator
        #paginator
        class="custom-paginator"
        aria-label="Select page"
        showFirstLastButtons
        [length]="comprimento()"
        [pageSize]="tamanhoDaPagina()"
        [pageSizeOptions]="opcoesDoTamanhoDaPagina"
        [pageIndex]="numeroPagina()"
        (page)="lidarEventoPagina($event)"
      />
      <br />
      <div class="botao-enviar-formulario">
        <button mat-button type="submit" class="btn btn-dark" [disabled]="ativaBotao()" (click)="deferirPedidos()">Deferir Solicitações</button>
        <button mat-button type="submit" class="btn btn-dark" [disabled]="ativaBotao()" (click)="indeferirPedidos()">Indeferir Solicitações</button>
      </div>
    </div>
  }
</div>
