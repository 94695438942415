import { environment } from 'src/environments/environment';
import type { MenuNode } from './MenuNode';

export const MenuPgeLegado: MenuNode = {
  id: 1,
  order: 1,
  description: 'Área Restrita antiga',
  name: 'Área Restrita antiga',
  router: environment.apiAreaRestritaLegado,
  url: 'S',
  children: [],
  materialIcon: 'home'
};
