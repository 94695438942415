import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EstadoFiltroConsultarNoticia {
  titulo = '';
  ativo = '';
  dataPublicacao = '';
  idAcesso = null;
  destaque = '';
  categoria = '';
  page = 0;
  size = 10;
  limpar() {
    this.titulo = '';
    this.ativo = '';
    this.dataPublicacao = '';
    this.idAcesso = null;
    this.destaque = '';
    this.categoria = '';
    this.page = 0;
    this.size = 10;
  }
}
