<br />
<h1 class="card-title titulo">Financeiro do Auxílio Saúde</h1>
<br />
<div class="row g-3">
  <form [formGroup]="dadosFormulario">
    <div class="d-flex row">
      <div class="col-md-6">
        <label for="procuradorServidor" class="form-label">Procurador/Servidor:</label>
        <mat-form-field>
          <input matInput type="text" id="procuradorServidor" name="procuradorServidor" formControlName="procuradorServidor" (keyup.enter)="buscarDeferidos()" />
        </mat-form-field>
      </div>
      <div class="col-md-3">
        <label for="totalDeferido" class="form-label">Total Deferido:</label>
        <mat-form-field>
          <input
            matInput
            currencyMask
            type="text"
            id="totalDeferido"
            name="totalDeferido"
            formControlName="totalDeferido"
            placeholder="R$ 0,00"
            [min]="0"
            [options]="{
              align: 'left',
              allowNegative: false,
              allowZero: false,
              decimal: ',',
              precision: 2,
              prefix: 'R$ ',
              suffix: '',
              thousands: '.',
              nullable: true,
              min: 0
            }"
            (keyup.enter)="buscarDeferidos()"
          />
        </mat-form-field>
      </div>
    </div>
    <br />
    <div class="d-flex row">
      <div class="col-md-3">
        <label for="mesAnoSolicitacao" class="form-label" id="mesAnoSolicitacao">Mês/Ano de solicitação:</label>
        <input class="calendar-month mat-full-width" id="mesAnoSolicitacao" name="mesAnoSolicitacao" type="month" formControlName="mesAnoSolicitacao" (keyup.enter)="buscarDeferidos()" />
      </div>
      <div class="col-md-3">
        <label for="periodoDe" class="form-label custom-label">Período de:</label>
        <input class="mat-full-width" id="periodoDe" name="periodoDe" type="date" formControlName="periodoDe" />
      </div>
      <div class="col-md-3">
        <label for="periodoAte" class="form-label">Até:</label>

        <input class="mat-full-width" id="pickerAte" name="pickerAte" type="date" formControlName="periodoAte" (keyup.enter)="buscarDeferidos()" />
      </div>
    </div>
    <br />
    <div class="d-flex row">
      <div class="d-flex justify-content-end">
        <button mat-button color="primary" type="button" id="buscar" name="buscar" class="btn btn-dark" (click)="buscarDeferidos()">Buscar</button>
        <button mat-button type="button" id="limpaForm" name="limpaForm" class="btn btn-link text-secondary" (click)="limparFormulario()">Limpar filtros</button>
      </div>
    </div>
  </form>
  <br />
  <div>
    <div class="table-responsive">
      @if (pedidosAuxilioFinanceiro().length > 0) {
        <table class="tabelaPadrao">
          <thead>
            <tr>
              <th class="fw-bold text-center">
                <input type="checkbox" (change)="selecionarTodos($event)" />
              </th>
              <th class="fw-bold text-center">Nome</th>
              <th class="fw-bold text-center">CPF</th>
              <th class="fw-bold text-center">Status</th>
              <th class="fw-bold text-center">Reembolso</th>
              <th class="fw-bold text-center">Total Deferido</th>
            </tr>
          </thead>
          <tbody>
            @for (pedido of pedidosAuxilioFinanceiro(); track pedido.id) {
              <tr>
                <td class="text-center">
                  <input
                    type="checkbox"
                    [checked]="pedido.status.nome === 'Deferido' && checkboxCabecalhoSelecionado"
                    [disabled]="pedido.status.nome !== 'Deferido'"
                    (change)="verificarPedidoSelecionado(pedido)"
                  />
                </td>
                <td class="text-left">
                  {{ pedido.usuario.nome }}
                </td>
                <td class="text-left">
                  {{ formatCpf(pedido.usuario.cpf) }}
                </td>
                <td class="text-center">
                  {{ pedido.status.nome }}
                </td>
                <td class="text-center">{{ pedido.mesParcela | mesPorExtenso }}{{ "/" + pedido.anoReembolso }}</td>
                <td class="text-center">
                  {{ pedido.valorDeferidoPge | formatarBRL }}
                </td>
              </tr>
            }
          </tbody>
        </table>
      }
    </div>
    <br />
    <div class="paginacao-campos">
      @if (pedidosAuxilioFinanceiro().length > 0) {
        <mat-paginator
          #paginator
          class="custom-paginator"
          aria-label="Select page"
          [length]="comprimento"
          [pageSize]="tamanhoDaPagina"
          [disabled]="desabilitado"
          [showFirstLastButtons]="mostrarPrimeiroUltimosBotoes"
          [pageSizeOptions]="mostrarOpcoesDeTamanhoPagina ? opcoesDoTamanhoDaPagina : []"
          [hidePageSize]="ocultarTamanhoDaPagina"
          [pageIndex]="indicePagina"
          (page)="lidarEventoPagina($event)"
        />
      }
      <br />
      <div class="d-flex row justify-content-between">
        <div class="col-md-2"><span class="text-danger requerido">*</span>Data do Pagamento:</div>
      </div>
      <div class="d-flex row justify-content-between tag-todos-botoes">
        <div class="col-md-2">
          <input id="dataPagamento" class="mat-full-width" name="dataPagamento" type="date" [(ngModel)]="dataAprovacao" />
        </div>
        <div class="col-md-2">
          <button
            mat-flat-button
            color="primary"
            type="button"
            class="btn btn-dark me-2 botao-pagamento"
            id="iamspe"
            name="iamspe"
            [disabled]="pedidosSelecionados().size === 0 || !validoData(dataAprovacao)"
            (click)="realizarPagamento()"
          >
            Realizar Pagamento
          </button>
        </div>
        <div class="col-md-5"></div>
        <div class="col-md-auto">
          <div class="d-flex justify-content-end">
            <button mat-flat-button color="primary" type="button" class="btn btn-dark me-2" id="siafem" name="siafem" (click)="siafem()">SIAFEM</button>
            <button mat-flat-button color="warn" type="button" class="btn btn-dark me-2" id="voltarEtapa" name="voltarEtapa" [disabled]="pedidosSelecionados().size === 0" (click)="voltarEtapa()">
              Voltar Etapa
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
