import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
  selector: 'app-separador',
  templateUrl: './separador.component.html',
  styleUrl: './separador.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SeparadorComponent {
  titulo = input.required<string>();
}
