import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, effect, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIcon } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';
import { catchError, of, switchMap } from 'rxjs';
import { ImagemTratamento } from 'src/app/area-aberta/features/home/shared/services/imagem-tratamento';
import { transformarData } from 'src/app/area-restrita/features/home/shared/utils/util';
import { ConversorValidadorService } from 'src/app/area-restrita/shared/services/conversor-validador.service';
import { PaginaVisitadaService } from 'src/app/area-restrita/shared/services/pagina-visitada.service';
import type { pdfAnexo } from 'src/app/shared/models/noticia.interface';
import { NoticiaService } from 'src/app/shared/services/noticia.service';
import { ToastService } from 'src/app/shared/toast/toast.service';

@Component({
  selector: 'app-home-noticia',
  templateUrl: './home-noticia.component.html',
  styleUrl: './home-noticia.component.scss',
  providers: [ImagemTratamento],
  standalone: true,
  imports: [MatIcon],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeNoticiaComponent implements OnInit, OnDestroy {
  private readonly noticiaService = inject(NoticiaService);
  private readonly route = inject(ActivatedRoute);
  private readonly imagemTratamento = inject(ImagemTratamento);
  private readonly base64ToPdfService = inject(ConversorValidadorService);
  private readonly toastr = inject(ToastService);
  private readonly paginaService = inject(PaginaVisitadaService);

  noticia = toSignal(
    this.route.paramMap
      .pipe(
        switchMap((params) => {
          const id = params.get('id');
          return id ? this.noticiaService.getOneNoticia(parseInt(id)) : of(null);
        })
      )
      .pipe(
        catchError((erro) => {
          console.log(erro);
          this.toastr.error('Erro ao obter notícia.');
          return of(null);
        })
      )
  );

  arquivos = signal<pdfAnexo[]>([]);

  constructor() {
    effect(() => {
      if (this.noticia()) {
        this.buscarArquivosNoticia(this.noticia().id);
      }
    });
  }

  ngOnInit(): void {
    this.paginaService.salvaPagina('Lista de noticias').subscribe();
  }

  buscarArquivosNoticia(id: number) {
    this.noticiaService.obterArquivosNoticias(id).subscribe({
      next: (arquivos) => {
        this.arquivos.set(arquivos);
      },
      error: (error) => {
        console.log(error);
        this.toastr.error('Erro ao obter arquivos da notícia.');
        return of([]);
      }
    });
  }

  obterImagem(noticiaImagem: string): string | undefined {
    return this.imagemTratamento.obterImagemBlob(noticiaImagem);
  }

  editarData(data: string): string {
    return transformarData(data);
  }

  processarTexto(texto: string): string {
    if (texto) {
      return texto.replace(/<img\b[^>]*>/, (match) => match.replace('>', ' width="40%">'));
    } else {
      return '';
    }
  }

  AbrirArquivo(event: MouseEvent, pdf: pdfAnexo): void {
    event.preventDefault();
    this.base64ToPdfService.base64ToBlob(pdf.arquivo);
  }

  ngOnDestroy(): void {
    this.imagemTratamento.revogarUrlBlob();
  }
}
