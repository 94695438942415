import type { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { catchError } from 'rxjs';
import type { CustomError } from 'src/app/shared/models/custom-error.interface';
import { AuthenticationService } from '../../shared/services/authentication.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  token: string = '';
  private readonly authenticationService = inject(AuthenticationService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const requestWithToken = request.clone({
      headers: request.headers.set('Authorization', this.authenticationService.getTokenValue() || '')
    });
    return next.handle(requestWithToken).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status == 401 || err.status == 403) {
          this.authenticationService.logout();
          throw new Error('Error in TokenInterceptor. ' + err.message.toString());
        }
        if (err.error) {
          const customError = err.error as CustomError;
          throw new Error(customError.erros['Erro de negócio']);
        }
        throw new Error(err.message);
      })
    );
  }
}
