import { Component } from '@angular/core';
import { SeparadorComponent } from '../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-ex-ouvidoria',
  templateUrl: './ex-ouvidoria.component.html',
  styleUrl: './ex-ouvidoria.component.scss',
  standalone: true,

  imports: [SeparadorComponent]
})
export class ExOuvidoriaComponent {
  exouvidores = [
    {
      nome: 'Nancy Regina Costa Flosi',
      nomeacao: '1999',
      termino: '2000'
    },
    {
      nome: 'Yara Chucralla Moherdaui Blasi',
      nomeacao: '2000',
      termino: '2002'
    },
    {
      nome: 'Ana Sofia Schmidt de Oliveira',
      nomeacao: '2002',
      termino: '2004'
    },
    {
      nome: 'Raquel Freitas de Souza',
      nomeacao: '2004',
      termino: '2004'
    },
    {
      nome: 'Magally Motta de Oliveira',
      nomeacao: '2004',
      termino: '2005'
    },
    {
      nome: 'Cintia Byczkowski',
      nomeacao: '10/2005',
      termino: '06/2006'
    },
    {
      nome: 'Ana Sofia Schmidt de Oliveira',
      nomeacao: '07/2006',
      termino: '12/2006'
    },
    {
      nome: 'Claudia Cardoso Chahoud',
      nomeacao: '01/2007',
      termino: '04/2008'
    },
    {
      nome: 'Flavia Cherto Carvalhaes',
      nomeacao: '05/2008',
      termino: '01/2011'
    },
    {
      nome: 'Maria Rita Vaz de Arruda Corsini',
      nomeacao: '01/2011',
      termino: '12/2015'
    },
    {
      nome: 'Lucia de Faria Freitas',
      nomeacao: '12/2015',
      termino: '07/2019'
    },
    {
      nome: 'Regina Maria Sartori',
      nomeacao: '07/2019',
      termino: '07/2023'
    }
  ];

  get obtemListaOrdenada() {
    const analisarDados = (data: string): Date => {
      if (!data) return new Date(0);
      const partes = data.split('/');
      if (partes.length === 2) {
        return new Date(`${partes[1]}-${partes[0]}-1`);
      } else {
        return new Date(`${data}-01-01`);
      }
    };
    return (lista) => {
      return lista.sort((a, b) => analisarDados(b.termino).getTime() - analisarDados(a.termino).getTime());
    };
  }
}
