import { Injectable, inject } from '@angular/core';
import type { MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { InformaDialogComponent } from './informa-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class InformaDialogService {
  private readonly dialog = inject(MatDialog);

  private dialogRef?: MatDialogRef<InformaDialogComponent>;

  public paramWidth: string = '40$';
  public paramHeight: string = '40%';
  public paramTitle: string = 'Planilha Excel';
  public paramLinhas: number = 0;
  public paramRegistros: number = 0;
  public paramFalhas: number = 0;
  public paramParaProcessar: number = 0;

  public show(): void {
    this.dialogRef = this.dialog.open(InformaDialogComponent, {
      width: this.paramWidth,
      height: this.paramHeight,
      data: {
        title: this.paramTitle,
        linhas: this.paramLinhas,
        registros: this.paramRegistros,
        falhas: this.paramFalhas,
        paraProcessar: this.paramParaProcessar
      },
      panelClass: 'transparent',
      disableClose: true
    });
  }

  public end(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
