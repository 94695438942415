import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import type { PageEvent } from '@angular/material/paginator';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { finalize, Subject, takeUntil } from 'rxjs';
import type { Noticias } from 'src/app/shared/models/noticia.interface';
import { NoticiaService } from 'src/app/shared/services/noticia.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import type { ConfirmaDialogData } from './../../../shared/components/confirma-dialog/confirma-dialog-data';

import { NgClass } from '@angular/common';
import { MatButton } from '@angular/material/button';
import { MatNativeDateModule, MatOption } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInput, MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import { ConfirmaDialogComponent } from 'src/app/area-restrita/shared/components/confirma-dialog/confirma-dialog.component';
import { WaitLoadingService } from 'src/app/area-restrita/shared/components/wait-loading/wait-loading.service';
import { LocalStorageService } from 'src/app/area-restrita/shared/services/local-storage.service';
import type { Page } from 'src/app/shared/models/paginacao/page.model';
import { EstadoFiltroConsultarNoticia } from '../shared/services/estado-filtro.service';

@Component({
  selector: 'app-consultar-noticias',
  templateUrl: './consultar-noticias.component.html',
  styleUrl: './consultar-noticias.component.scss',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatSelect,
    MatOption,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatDatepicker,
    MatButton,
    NgClass,
    MatIcon,
    MatTooltip,
    MatPaginator,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsultarNoticiasComponent implements OnInit, OnDestroy {
  private readonly formBuilder = inject(FormBuilder);
  private readonly router = inject(Router);
  private readonly toastr = inject(ToastService);
  private readonly noticiaService = inject(NoticiaService);
  private readonly dialog = inject(MatDialog);
  private readonly estadoFiltroConsultarNoticia = inject(EstadoFiltroConsultarNoticia);
  private readonly localStorage = inject(LocalStorageService);
  private readonly loadingService = inject(WaitLoadingService);

  private readonly destroy$ = new Subject<void>();

  noticias = signal<Noticias[]>(undefined);
  paginador = false;

  comprimento = 0;
  tamanhoDaPagina = 10;
  indiceDaPagina = 0;
  opcoesDoTamanhoDaPagina = [5, 10, 25, 50];

  objetoCategoria = [
    { value: 0, nome: 'Selecione' },
    { value: 1, nome: 'Notícia Área Aberta' },
    { value: 2, nome: 'Notícia Área Restrita' },
    { value: 3, nome: 'Comunicados' },
    { value: 4, nome: 'Credenciamento de contadores' }
  ];

  dadosFormulario = this.formBuilder.group({
    titulo: [this.estadoFiltroConsultarNoticia.titulo],
    ativo: [this.estadoFiltroConsultarNoticia.ativo],
    dataPublicacao: [this.estadoFiltroConsultarNoticia.dataPublicacao],
    idAcesso: [this.estadoFiltroConsultarNoticia.idAcesso],
    destaque: [this.estadoFiltroConsultarNoticia.destaque],
    categoria: [this.estadoFiltroConsultarNoticia.categoria]
  });

  ngOnInit(): void {
    this.indiceDaPagina = this.estadoFiltroConsultarNoticia.page;
    this.tamanhoDaPagina = this.estadoFiltroConsultarNoticia.size;
    this.recuperarFiltro();
    this.consultar();
  }

  consultar() {
    if (this.dadosFormulario.valid) {
      this.loadingService.open('Buscando notícias...');
      this.localStorage.setJson('filtroConsulta', this.dadosFormulario.value);
      this.noticiaService
        .consultar(this.dadosFormulario, this.indiceDaPagina, this.tamanhoDaPagina)
        .pipe(
          finalize(() => {
            this.loadingService.close();
          }),
          takeUntil(this.destroy$)
        )
        .subscribe({
          next: (response: Page<Noticias>) => {
            this.comprimento = response.totalElements;
            this.paginador = !response.empty;
            this.noticias.set(response.content);
          },
          error: () => console.error
        });
    }
  }

  recuperarFiltro(): void {
    const filtro = this.localStorage.getJson('filtroConsulta');

    if (filtro) {
      this.dadosFormulario.patchValue(filtro);
    }
  }

  removerNoticia(idNoticia: number) {
    this.noticiaService
      .remover(idNoticia)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.consultar();
        this.toastr.success('Notícia excluída com sucesso.');
      });
  }

  editarNoticia(id: number) {
    void this.router.navigate(['area-restrita', 'editar-noticia', id]);
  }

  criarNoticia() {
    void this.router.navigate(['area-restrita', 'criar-noticia']);
  }

  visualizarNoticia(id: number) {
    void this.router.navigate(['area-restrita', 'visualizar-noticia', id]);
  }

  alternarAtivo(id: number, ativo: boolean) {
    this.noticiaService
      .atualizarAtivo(id, ativo)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.consultar();
          if (ativo) {
            this.toastr.success('Noticia ativada sucesso.');
          } else {
            this.toastr.success('Noticia desativada sucesso.');
          }
        },
        (error) => {
          this.toastr.error(error.message);
        }
      );
  }

  limparFormulario() {
    this.dadosFormulario.reset({
      titulo: '',
      ativo: '',
      dataPublicacao: '',
      idAcesso: null,
      destaque: '',
      categoria: ''
    });
    this.localStorage.remove('filtroConsulta');
    this.comprimento = 0;
    this.tamanhoDaPagina = 10;
    this.indiceDaPagina = 0;
    this.noticias.set(undefined);
    this.paginador = false;
  }

  lidarEventoDaPagina(e: PageEvent) {
    this.comprimento = e.length;
    this.tamanhoDaPagina = e.pageSize;
    this.indiceDaPagina = e.pageIndex;
    this.consultar();
  }

  abrirModal(id: number): void {
    const data: ConfirmaDialogData = {
      title: '',
      message: 'Deseja remover a Notícia?',
      escondeBotaoNao: false
    };
    const caixaDialogo = this.dialog.open(ConfirmaDialogComponent, {
      width: '20%',
      data: data
    });
    caixaDialogo.afterClosed().subscribe((result) => {
      if (result) {
        this.removerNoticia(id);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
