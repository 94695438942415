import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-nas-redes',
  templateUrl: './nas-redes.component.html',
  styleUrl: './nas-redes.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NasRedesComponent {}
