import { Component } from '@angular/core';
import { SeparadorComponent } from '../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-enderecos-telefones-uteis',
  templateUrl: './enderecos-telefones-uteis.component.html',
  styleUrl: './enderecos-telefones-uteis.component.scss',
  standalone: true,
  imports: [SeparadorComponent]
})
export class EnderecosTelefonesUteisComponent {}
