<main class="main" role="main">
  <section class="page">
    <app-separador titulo="Endereços e Telefones Úteis" />
    <table>
      <tbody>
        <tr class="tituloTable">
          <th>Unidades</th>
          <th>Endereços</th>
        </tr>
        <tr>
          <td rowspan="2">Corregedoria da PGE</td>
          <td>(11) 3372-6483</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:corregedoriapge@sp.gov.br">corregedoriapge&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Gabinete da PGE</td>
          <td>(11) 3372-6402/ 6404</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pgegpg@sp.gov.br">pgegpg&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Precatórios Judiciais</td>
          <td>(11) 3372-6518 (Atendimento por Whatsapp)</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:precatoriospgeatendimento@sp.gov.br">precatoriospgeatendimento&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Procuradoria de Procedimentos Disciplinares</td>
          <td>(11) 3291-7100</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:procuradoriappd@sp.gov.br">procuradoriappd&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Procuradoria de São Paulo em Brasília</td>
          <td>(61) 3328-0244</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pge.pespb@sp.gov.br">pge.pespb&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Procuradoria do Contencioso Ambiental e Imobiliário</td>
          <td>(11) 3107-8453 | 3241-3733 ramal 119/120</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:atendimentopcai@sp.gov.br">atendimentopcai&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr class="barra">
          <td colspan="2"></td>
        </tr>
        <tr>
          <td rowspan="2">Procuradoria Fiscal</td>
          <td>(11) 3243-3541</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pfatendimento@sp.gov.br">pfatendimento&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr class="barra">
          <td colspan="2"></td>
        </tr>
        <tr>
          <td rowspan="2">Procuradoria Judicial</td>
          <td>(11) 3130-9162/ 9167 / 9168</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:atendimentopj@sp.gov.br">atendimentopj&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Procuradoria Regional de Araçatuba</td>
          <td>(18) 3623–6920 /1031 | (18) 3625-2961</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pr9atendimento@sp.gov.br">pr9atendimento&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Procuradoria Regional de Bauru</td>
          <td>(14) 3878-4023 | (14) 3878-4024</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pr7atendimento@sp.gov.br">pr7atendimento&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Procuradoria Regional de Campinas</td>
          <td>(19) 3775-8040 | 8042 | 8086</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:protocolo-pr5@sp.gov.br">protocolo-pr5&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Procuradoria Regional da Grande São Paulo</td>
          <td>(11) 3107-1211 1277 /1232 /6853</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pfatendimento@sp.gov.br">pfatendimento&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr class="barra">
          <td colspan="2"></td>
        </tr>
        <tr>
          <td rowspan="2">Procuradoria Regional de Marília</td>
          <td>(14) 3433-9699</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pr11atendimento@sp.gov.br">pr11atendimento&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Procuradoria Regional de Presidente Prudente</td>
          <td>(18) 3222–7233 | (18) 3223–6770</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pr10atendimento@sp.gov.br">pr10atendimento&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Procuradoria Regional de Ribeirão Preto</td>
          <td>(16) 3610-1889</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pr6atendimento@sp.gov.br">pr6atendimento&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Procuradoria Regional de Santos</td>
          <td>(13) 3219-6991</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pr2atendimento@sp.gov.br">pr2atendimento&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Procuradoria Regional de São Carlos</td>
          <td>(16) 3371-9268 / 2879</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pr12atendimento@sp.gov.br">pr12atendimento&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Procuradoria Regional de São José do Rio Preto</td>
          <td>(17) 2138 -8221</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pr8atendimento@sp.gov.br">pr8atendimento&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Procuradoria Regional de Sorocaba</td>
          <td>(15) 3231-3000</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pr4atendimento@sp.gov.br">pr4atendimento&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Procuradoria Regional de Taubaté</td>
          <td>(12) 3621-4861</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pr3atendimento@sp.gov.br">pr3atendimento&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Protocolo Geral</td>
          <td>(11) 3130-9000</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:protocolopge@sp.gov.br">protocolopge&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Seccional de Mogi das Cruzes</td>
          <td>(11) 4726-2629/ 5417</td>
        </tr>
        <tr>
          <td>
            <a href="mailto:pfatendimento@sp.gov.br">pfatendimento&#64;sp.gov.br</a>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
        <tr>
          <td rowspan="2">Seccional de Piracicaba</td>
        </tr>
        <tr>
          <td>(19) 3422-3740</td>
        </tr>
        <tr>
          <td colspan="2" class="barra"></td>
        </tr>
      </tbody>
    </table>
    <hr />
    <br />
  </section>
</main>
