import { NgClass } from '@angular/common';
import type { OnInit } from '@angular/core';
import { Component, HostListener, inject } from '@angular/core';
import type { MatDialogConfig } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatDrawer, MatDrawerContainer } from '@angular/material/sidenav';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { DrawerContentComponent } from './area-aberta/core/layout/drawer-content/drawer-content.component';
import { FooterComponent } from './area-aberta/core/layout/footer/footer.component';
import { HeaderComponent } from './area-aberta/core/layout/header/header.component';
import { MenuComponent } from './area-aberta/core/layout/menu/menu.component';
import { SearchResultsComponent } from './area-aberta/core/layout/menu/search-results/search-results.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  standalone: true,
  imports: [
    HeaderComponent,
    MenuComponent,
    SearchResultsComponent,
    FooterComponent,
    DrawerContentComponent,
    MatDrawer,
    MatDrawerContainer,
    MatIcon,
    RouterOutlet,
    NgClass
  ]
})
export class AppComponent implements OnInit {
  private readonly route = inject(Router);
  private readonly activeRoute = inject(ActivatedRoute);
  private readonly dialog = inject(MatDialog);

  title = 'Site-PGE-FrontEnd';
  rotasSemCabecalho = ['/painel-area-externa', '/login', '/esqueci-senha', '/alterar-senha', '/area-restrita'];
  buscar = '';
  visibilidade: boolean = false;

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((params) => {
      this.buscar = params['buscar'];
    });
  }

  naoFomularioLogin(): boolean {
    if (this.rotasSemCabecalho.includes(this.route.url) || this.route.url.includes('/area-restrita')) {
      return false;
    }
    return true;
  }

  abrirMenu(): void {
    const config: MatDialogConfig = {
      height: '100%',
      width: 'fit-content',
      position: { left: '0' },
      data: { buscar: this.buscar }
    };
    this.dialog.open(MenuComponent, config);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (window.innerWidth > 480) {
      this.dialog.closeAll();
    }
  }
}
