import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogRef } from '@angular/material/dialog';
import type { ConfirmaDialogData } from './confirma-dialog-data';

@Component({
  selector: 'app-confirma-dialog',
  templateUrl: './confirma-dialog.component.html',
  styleUrl: './confirma-dialog.component.scss',
  standalone: true,
  imports: [MatDialogActions],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmaDialogComponent {
  dialogRef = inject<MatDialogRef<ConfirmaDialogComponent>>(MatDialogRef);
  data = inject<ConfirmaDialogData>(MAT_DIALOG_DATA);

  escondeBotaoNao: boolean | undefined = false;
  constructor() {
    this.escondeBotaoNao = this.data.escondeBotaoNao;
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
