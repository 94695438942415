import { CommonModule, CurrencyPipe, Location } from '@angular/common';
import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxMaskDirective } from 'ngx-mask';
import { finalize, Subject, takeUntil } from 'rxjs';
import type { NotaFiscalAnexadaAuxilio, PedidoAuxilioSaude } from 'src/app/area-restrita/features/auxilio/saude/shared/models/auxilio-saude';
import type { AvaliacaoAuxilioDto } from 'src/app/area-restrita/features/auxilio/saude/shared/models/auxilio-saude-dto';
import { AuxilioSaudeService } from 'src/app/area-restrita/features/auxilio/saude/shared/services/auxilio-saude.service';
import { WaitLoadingService } from 'src/app/area-restrita/shared/components/wait-loading/wait-loading.service';
import { ConversorValidadorService } from 'src/app/area-restrita/shared/services/conversor-validador.service';
import { PaginaVisitadaService } from 'src/app/area-restrita/shared/services/pagina-visitada.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { ExtrairAnoDataPipe } from '../../../../shared/pipes/extrair-ano-data.pipe';
import { ObterMesDataPipe } from '../../../../shared/pipes/obter-mes-data.pipe';
import { AuxilioStatusEnum } from '../shared/enums/auxilio-status.enum';
import { StateAnaliseService } from './../../shared/services/state-analise.service';

@Component({
  selector: 'app-analise-pedido-auxilio-saude',
  templateUrl: './analise-pedido-auxilio-saude.component.html',
  styleUrl: './analise-pedido-auxilio-saude.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    MatIcon,
    MatTooltip,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    NgxMaskDirective,
    MatButton,
    CurrencyPipe,
    ObterMesDataPipe,
    ExtrairAnoDataPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnalisePedidoAuxilioSaudeComponent implements OnInit, OnDestroy {
  private readonly auxilioSaudeService = inject(AuxilioSaudeService);
  private readonly formBuilder = inject(FormBuilder);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly location = inject(Location);
  private readonly base64ToPdfService = inject(ConversorValidadorService);
  private readonly stateAnaliseService = inject(StateAnaliseService);
  private readonly toastr = inject(ToastService);
  private readonly paginaService = inject(PaginaVisitadaService);
  private readonly waitLoadingService = inject(WaitLoadingService);

  private readonly destroy$ = new Subject<void>();

  pedidoAuxilio = signal<PedidoAuxilioSaude>(undefined);
  documentosTitular = signal<NotaFiscalAnexadaAuxilio[]>([]);
  documentosDependente = signal<NotaFiscalAnexadaAuxilio[]>([]);
  valorPerso: boolean = false;
  avaliacaoAuxilio: AvaliacaoAuxilioDto = {
    flagAvaliacao: '',
    idAprovador: '',
    observacao: '',
    valorDeferidoPge: 0.0,
    dataAprovacao: null
  };
  dataInscricao = signal<string>('');
  valorSelecionado: string = '';
  obervacaoRetificacao: string = '';
  valorEspecifico = signal<boolean>(false);
  zeroPorcento = signal<boolean>(false);
  cemPorcento = signal<boolean>(false);
  readonly statusAuxilioEnum = AuxilioStatusEnum;

  destacarData = signal(false);

  ngOnInit(): void {
    this.paginaService.salvaPagina('Analise do Pedido').subscribe();
    const idPedido = this.route.snapshot.params['id'];
    this.carregarAuxilioSaude(idPedido);
  }

  obterInscricao() {
    this.waitLoadingService.open();
    this.auxilioSaudeService
      .obterInscricao(this.pedidoAuxilio().usuario.id)
      .pipe(
        finalize(() => {
          this.waitLoadingService.close();
        })
      )
      .subscribe({
        next: (dados) => {
          if (dados != null) {
            this.dataInscricao.set(dados.dataInscricao);
          }
        },
        error: (error) => {
          this.toastr.error(error.message);
        }
      });
  }

  formAnalisePedidoAuxilioSaude = this.formBuilder.group({
    valorDeferidoPGE: { value: this.formatarMoeda(0.0), disabled: true },
    dataAprovacaoPGE: [{ value: '', disabled: true }, Validators.required],
    observacao: [''],
    aprovador: ['']
  });

  carregarAuxilioSaude(idPedido: string) {
    this.auxilioSaudeService
      .obterPedidoAuxilioSaude(idPedido)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dados) => {
          this.pedidoAuxilio.set(dados);

          this.formAnalisePedidoAuxilioSaude.controls.observacao.setValue(dados.observacao);

          // Desativar campos de forma centralizada
          const formControls = this.formAnalisePedidoAuxilioSaude.controls;
          if (!this.pedidoAuxilio().podeDeferir) {
            formControls.dataAprovacaoPGE.disable();
            formControls.observacao.disable();
          } else {
            formControls.observacao.enable();
          }

          // Resetar valores
          const avaliacaoFlags = {
            A: { cemPorcento: true, zeroPorcento: false, valorEspecifico: false },
            S: { cemPorcento: false, zeroPorcento: true, valorEspecifico: false },
            I: { cemPorcento: false, zeroPorcento: false, valorEspecifico: true }
          };

          // Verificar o valor de flagAvaliacao, tratando o caso de null
          const avaliacao =
            this.pedidoAuxilio().flagAvaliacao !== null ?
              avaliacaoFlags[this.pedidoAuxilio().flagAvaliacao]
            : { cemPorcento: false, zeroPorcento: false, valorEspecifico: false };

          // Aplicar valores com base no flagAvaliacao
          this.cemPorcento.set(avaliacao.cemPorcento);
          this.zeroPorcento.set(avaliacao.zeroPorcento);
          this.valorEspecifico.set(avaliacao.valorEspecifico);

          this.valorSelecionado =
            avaliacao.cemPorceto ? '100%'
            : avaliacao.zeroPorcento ? '0%'
            : 'inserirValorEspecifico';

          this.formAnalisePedidoAuxilioSaude.controls.dataAprovacaoPGE.setValue(
            this.pedidoAuxilio().dataAprovacao ? this.converteData(this.pedidoAuxilio().dataAprovacao) : this.obterDataAtual()
          );
          this.formAnalisePedidoAuxilioSaude.controls.valorDeferidoPGE.setValue(this.pedidoAuxilio().valorDeferidoPge?.toString() ?? '0');

          this.obterInscricao();
          this.obterListaDocumentos(this.pedidoAuxilio());
        },
        error: (error) => {
          console.error('Erro ao obter dados do serviço', error);
        }
      });
  }

  obterListaDocumentos(pedido: PedidoAuxilioSaude) {
    this.auxilioSaudeService
      .obterListaDocumentos(pedido.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dados) => {
          dados.forEach((element: NotaFiscalAnexadaAuxilio) => {
            if (element.titular) {
              this.documentosTitular.update((values) => {
                return [...values, element];
              });
            } else {
              this.documentosDependente.update((values) => {
                return [...values, element];
              });
            }
          });
          this.desativaDataAprovacao();
        },
        error: (error) => {
          console.error('Erro ao obter dados do serviço', error);
        }
      });
  }

  cancelarEnvio() {
    this.stateAnaliseService.telaAnalise = false;
    this.location.back();
  }

  private normalizarData(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }

  enviarFormulario() {
    if (this.camposObrigatoriosPreenchidos()) {
      this.avaliacaoAuxilio.observacao = this.formAnalisePedidoAuxilioSaude.controls.observacao.value || '';
      this.avaliacaoAuxilio.valorDeferidoPge = this.converterMoedaParaNumero(this.formAnalisePedidoAuxilioSaude.controls.valorDeferidoPGE.value) || 0;

      const dataOriginal = new Date(this.formAnalisePedidoAuxilioSaude.get('dataAprovacaoPGE').value);
      dataOriginal.setDate(dataOriginal.getDate() + 1);

      if (this.normalizarData(dataOriginal).getTime() < this.normalizarData(new Date()).getTime()) {
        this.toastr.warning('Data de aprovação não deve ser menor que a data atual. Verifique e tente novamente');
        const control = this.formAnalisePedidoAuxilioSaude.get('dataAprovacaoPGE');
        this.destacarData.set(true);
        setTimeout(() => {
          this.destacarData.set(false);
        }, 1000);
        control.markAllAsTouched();
        return;
      }

      const dataFormatada = dataOriginal.toLocaleDateString();

      this.avaliacaoAuxilio.dataAprovacao = dataFormatada;

      this.auxilioSaudeService
        .enviarAnalisePedidoAuxilioSaude(this.avaliacaoAuxilio, this.pedidoAuxilio())
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this.toastr.success('Pedido avaliado com sucesso.');
            setTimeout(() => {
              void this.router.navigate(['area-restrita', 'administracao-auxilio-saude', 'analisar-pedido']);
            }, 2000);
          },
          (error) => {
            this.toastr.error(error.message);
          }
        );
    } else {
      this.toastr.error('Falta preencher campos obrigatórios.');
    }
  }

  EnviarRetificacaoAvaliarAuxilioSaude() {
    this.obervacaoRetificacao = this.formAnalisePedidoAuxilioSaude.controls.observacao.value || '';
    if (this.obervacaoRetificacao.length > 0) {
      this.auxilioSaudeService
        .enviarRetificacaoAvaliarAuxilioSaude(this.obervacaoRetificacao, this.pedidoAuxilio())
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          () => {
            this.toastr.success('Retificação de Avaliação enviada com sucesso.');
            setTimeout(() => {
              void this.router.navigate(['area-restrita', 'administracao-auxilio-saude', 'analisar-pedido']);
            }, 2000);
          },
          (error) => {
            this.toastr.error(error.message);
          }
        );
    } else {
      this.toastr.error('Falta preencher campos obrigatórios.');
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  abrirArquivo(evento: MouseEvent, pedido: NotaFiscalAnexadaAuxilio): void {
    evento.preventDefault();
    this.base64ToPdfService.base64ToBlob(pedido.arquivo);
  }

  atualizarValorDeferidoPGE(): void {
    this.pedidoAuxilio().valorTotalDeferido = this.pedidoAuxilio().valorReembolsoTotal;
  }

  selecionarValor(evento: Event): void {
    const valorSelecionado = (evento.target as HTMLInputElement).value;
    this.valorSelecionado = valorSelecionado;

    if (valorSelecionado === '100%') {
      this.formAnalisePedidoAuxilioSaude.controls.valorDeferidoPGE.setValue(this.formatarMoeda(this.pedidoAuxilio().valorReembolsoTotal));
      this.formAnalisePedidoAuxilioSaude.controls.valorDeferidoPGE.disable();
    } else if (valorSelecionado === '0%') {
      this.formAnalisePedidoAuxilioSaude.controls.valorDeferidoPGE.setValue(this.formatarMoeda(0.0));
      this.formAnalisePedidoAuxilioSaude.controls.valorDeferidoPGE.disable();
    } else {
      this.formAnalisePedidoAuxilioSaude.controls.valorDeferidoPGE.setValue(null);
      this.formAnalisePedidoAuxilioSaude.controls.valorDeferidoPGE.enable();
    }
  }

  camposObrigatoriosPreenchidos(): boolean {
    return !!this.valorSelecionado && !!this.formAnalisePedidoAuxilioSaude.get('dataAprovacaoPGE').value;
  }
  validaModo() {
    return !this.pedidoAuxilio().podeDeferir ? true : false;
  }

  obterDataAtual(): string {
    const dataAtual = new Date();
    const ano = dataAtual.getFullYear();
    const mes = ('0' + (dataAtual.getMonth() + 1)).slice(-2);
    const dia = ('0' + dataAtual.getDate()).slice(-2);
    return `${ano}-${mes}-${dia}`;
  }

  desativaDataAprovacao() {
    if (this.pedidoAuxilio().valorDeferidoPge != null) {
      this.formAnalisePedidoAuxilioSaude.controls.dataAprovacaoPGE.disable();
    } else {
      this.formAnalisePedidoAuxilioSaude.controls.dataAprovacaoPGE.enable();
    }
  }

  private converterMoedaParaNumero(valorMoeda: string): number {
    // Remove qualquer caractere que não seja número, vírgula ou ponto.
    const numeroSemFormatacao = valorMoeda.toString().replace(/[^\d.,]/g, '');

    // Verifica se o número tem vírgula e ponto. Se tiver, assume que vírgula é para decimais.
    if (numeroSemFormatacao.includes(',') && numeroSemFormatacao.includes('.')) {
      // Remove os pontos que são separadores de milhar
      const numeroLimpo = numeroSemFormatacao.replace(/\./g, '');

      // Substitui a vírgula pelo ponto para conversão decimal
      const numeroFormatado = numeroLimpo.replace(',', '.');

      // Converte para número de ponto flutuante
      return parseFloat(numeroFormatado);
    } else {
      // Caso tenha apenas vírgula ou ponto, assume o último como separador decimal
      const numeroFormatado = numeroSemFormatacao.replace(',', '.');
      return parseFloat(numeroFormatado);
    }
  }

  formatarMoeda(value: number): string {
    return value.toFixed(2);
  }

  converteData(date: string): string {
    const parts = date.split('/');

    const day = parts[0];
    const month = parts[1];
    const year = parts[2];

    return `${year}-${month}-${day}`;
  }
}
