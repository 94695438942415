import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
  name: 'extrairAnoData',
  standalone: true
})
export class ExtrairAnoDataPipe implements PipeTransform {
  transform(value?: string): number | undefined {
    if (!value) return undefined;

    const parts = value.split('/');
    const year = parseInt(parts[2], 10);

    return year;
  }
}
