<main class="main" role="main">
  <section class="page">
    <div class="container-noticia-aberta">
      <div class="container-noticia">
        <h1 class="title-noticia">{{ noticia?.titulo }}</h1>
        <p class="data">
          {{ noticia?.dataPublicacao }}
        </p>
        @if (noticia?.imagem) {
          <img alt="Imagem da Notícia" class="imagem-noticia" [src]="obterImagem(noticia?.imagem)" />
        }
        <div class="corpo-noticia" [innerHTML]="processarTexto(noticia?.texto)"></div>
        <div class="arquivo">
          <table>
            <tbody>
              @for (item of arquivos; track item; let i = $index) {
                <tr>
                  <td>
                    <a href="#" (click)="abrirArquivo($event, item)">
                      <img class="icon-pdf" src="images/pdf-file-svgrepo-com.svg" alt="PDF" />
                      {{ item.nome }}</a
                    >
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
        <br />
        <div class="card">
          <p-galleria
            class="p-galleria"
            [value]="urlsImagens"
            [numVisible]="5"
            [autoPlay]="true"
            [circular]="true"
            [containerStyle]="{ 'max-width': '640px' }"
            [responsiveOptions]="opcoesResponsividade"
          >
            <ng-template let-imagem pTemplate="item">
              <img class="imagem-galeria" [src]="imagem" [alt]="" />
            </ng-template>
            <ng-template let-imagem pTemplate="thumbnail">
              <div>
                <img class="imagens-galeria" [src]="imagem" [alt]="" />
              </div>
            </ng-template>
          </p-galleria>
        </div>
        <div class="align-button">
          <a href="/">
            <mat-icon class="home-icon">home</mat-icon>
          </a>
        </div>
        <br />
      </div>
      <div class="mais-noticias">
        <app-separador titulo="Outras Notícias" />
        <div class="container-cards">
          @for (item of objNoticias; track item; let i = $index) {
            <div class="card-noticia" tabindex="0" (click)="abrirUmaNoticia(item?.id)" (keypress)="abrirUmaNoticia(item?.id)">
              <img [src]="obterImagem(item?.imagem)" [alt]="'Imagem ' + (i + 1)" />
              <div class="titulo">
                {{ item?.titulo }}
              </div>
            </div>
          }
        </div>
        <br />
        <div class="align-button-r">
          <button mat-flat-button class="botao-voltar" type="submit" (click)="retornaListaNoticias()"><mat-icon class="add-icon">add</mat-icon> NOTÍCIAS</button>
        </div>
      </div>
    </div>
  </section>
</main>
