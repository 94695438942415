import type { HttpErrorResponse } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { BehaviorSubject, catchError, throwError } from 'rxjs';
import { LocalStorageService } from 'src/app/area-restrita/shared/services/local-storage.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LegadoUsuarioService {
  private readonly http = inject(HttpClient);
  private readonly localStorage = inject(LocalStorageService);
  private readonly toastService = inject(ToastService);

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: this.localStorage.get('token')
  });

  env = environment;
  private readonly user: BehaviorSubject<string> = new BehaviorSubject<string>('');
  user$: Observable<string> = this.user.asObservable();

  enviarNovaSenhaParaLegado(cpf: string, senha: string): Observable<void> {
    const body = {
      cpf: cpf,
      senha: senha
    };

    return this.http.post<undefined>(this.env.apiUrl + 'legado/senha', body, { headers: this.headers }).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Erro durante o envio de nova senha ao sistema legado:', error);
        return throwError(() => error);
      })
    );
  }

  checkUsuarioLegado(id: string): Observable<void> {
    const url = `${this.env.apiUrl}legado/check/${id}`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post<undefined>(url, null, { headers: headers });
  }
}
