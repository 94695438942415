import { Injectable, inject } from '@angular/core';
import type { MatDialogRef } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { WaitLoadingComponent } from './wait-loading.component';

@Injectable({
  providedIn: 'root'
})
export class WaitLoadingService {
  private readonly dialog = inject(MatDialog);
  private dialogRef: MatDialogRef<WaitLoadingComponent> | null = null;

  public open(label: string = ''): void {
    this.dialogRef = this.dialog.open(WaitLoadingComponent, {
      panelClass: 'transparent',
      disableClose: true,
      data: label
    });
  }

  public close(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
