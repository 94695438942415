import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SeparadorComponent } from '../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-inform-ouvidoria',
  templateUrl: './inform-ouvidoria.component.html',
  styleUrl: './inform-ouvidoria.component.scss',
  standalone: true,

  imports: [SeparadorComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InformOuvidriaComponent {}
