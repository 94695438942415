import type { HttpErrorResponse } from '@angular/common/http';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { catchError, combineLatest, firstValueFrom, map, of, throwError } from 'rxjs';
import type { Dependente } from 'src/app/area-restrita/features/auxilio/saude/shared/models/dependente';
import type { RelatorioDependenteResponse } from 'src/app/area-restrita/features/auxilio/saude/shared/models/relatorio';
import { Globais } from 'src/app/area-restrita/shared/globais/globais';
import type { AuxilioApesp, AuxilioSaude, FormAvaliacao, InscricaoAuxilioSaude, NotaFiscalAnexadaAuxilio, PedidoAuxilioSaude } from '../models/auxilio-saude';
import type { AvaliacaoAuxilioDto } from '../models/auxilio-saude-dto';
import type { FichaInscricaoCadastro } from '../models/ficha-inscricao-cadastro';

@Injectable({
  providedIn: 'root'
})
export class AuxilioSaudeService {
  private readonly http = inject(HttpClient);

  public enviarDadosDependentes(dados: Dependente[]): Observable<Dependente[]> {
    return this.http.post<Dependente[]>(`${Globais.urlToken}site-pge/api/dependentes-auxilio-saude/batch`, dados).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log('error', error);

        return throwError(() => error);
      })
    );
  }

  public enviarDadosEditadoDependentes(id: number, dados: Dependente): Observable<Dependente> {
    return this.http.put<Dependente>(`${Globais.urlToken}site-pge/api/dependentes-auxilio-saude/${id}`, dados).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log('error', error);

        return throwError(() => error);
      })
    );
  }

  public enviarDadosFormulario(dados: AuxilioSaude): Observable<InscricaoAuxilioSaude> {
    return this.http.post<InscricaoAuxilioSaude>(`${Globais.urlToken}site-pge/api/inscricoes-auxilio-saude`, dados).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }

  public incluirPcd(idUsuario: number, pcd: boolean): Observable<string> {
    return this.http.put<string>(`${Globais.urlToken}site-pge/api/usuarios/${idUsuario}/pcd`, pcd, { responseType: 'text' as 'json' });
  }

  public enviarAtualizacaoStatus(id: number, dadosAvaliacao: FormAvaliacao): Observable<void> {
    return this.http.put<undefined>(`${Globais.urlToken}site-pge/api/auxilios/${id}/avaliar-com-observacao`, { ...dadosAvaliacao });
  }

  public cancelarPedidoAuxilioSaude(id: number): Observable<string> {
    return this.http.put<string>(`${Globais.urlToken}site-pge/api/auxilios/${id}/cancelar`, {}, { responseType: 'text' as 'json' });
  }

  public atualizarDadosFormulario(dados: AuxilioSaude): Observable<InscricaoAuxilioSaude> {
    return this.http.put<InscricaoAuxilioSaude>(`${Globais.urlToken}site-pge/api/inscricoes-auxilio-saude/${dados.id}`, dados);
  }

  public obterInscricao(idUser: number | null = null): Observable<InscricaoAuxilioSaude | null> {
    const idUsuario = idUser ?? localStorage.getItem('idUsuario');
    return this.http.get<InscricaoAuxilioSaude>(`${Globais.urlToken}site-pge/api/inscricoes-auxilio-saude?idUsuario=${idUsuario}`).pipe(
      map((req) => req),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          console.error('Não foi encontrado inscrição deste Usuário: ', error);
          return of(null);
        } else {
          console.error('Erro durante a obtenção de dados: ', error);
          return throwError(() => 'Falha na obtenção de dados');
        }
      })
    );
  }

  public obterPedidoAuxilioSaude(idPedido: string): Observable<PedidoAuxilioSaude> {
    return this.http.get<PedidoAuxilioSaude>(`${Globais.urlToken}site-pge/api/auxilios/${idPedido}`).pipe(
      map((response) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }

  public obterListaDocumentos(id: string): Observable<NotaFiscalAnexadaAuxilio[]> {
    return this.http.get<NotaFiscalAnexadaAuxilio[]>(`${Globais.urlToken}site-pge/api/notas-fiscais-anexadas/porIdAuxilio/${id}`).pipe(
      map((response) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }

  public enviarAnalisePedidoAuxilioSaude(dados: AvaliacaoAuxilioDto, pedido: PedidoAuxilioSaude): Observable<string> {
    dados.idAprovador = String(localStorage.getItem('idUsuario'));
    if (dados.valorDeferidoPge === pedido.valorReembolsoTotal) {
      dados.flagAvaliacao = 'A';
    } else if (dados.valorDeferidoPge == 0) {
      dados.flagAvaliacao = 'S';
    } else {
      dados.flagAvaliacao = 'I';
    }

    return this.http.put(`${Globais.urlToken}site-pge/api/auxilios/saude/${pedido.id}/avaliacao`, dados, { responseType: 'text' }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }

  public enviarRetificacaoAvaliarAuxilioSaude(dados: string, pedido: PedidoAuxilioSaude): Observable<string> {
    return this.http.put(`${Globais.urlToken}site-pge/api/auxilios/saude/${pedido.id}/retificar-avaliacao`, dados, { responseType: 'text' }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }

  /*    *** RelatorioPesquisaDependentes
   *** Uso no processo de pesquisa dos dependentes para busca e relatório. Não será esta rota, api, etc.       */
  public relatorioPesquisaDependentes(
    nomeTitular: string,
    cpfTitular: string,
    nomeDependente: string,
    cpfDependente: string,
    parentesco: string,
    pageNumber: number,
    pageSize: number
  ): Observable<RelatorioDependenteResponse> {
    const params = new HttpParams()
      .set('nomeTitular', nomeTitular)
      .set('cpfTitular', cpfTitular)
      .set('nomeDependente', nomeDependente)
      .set('cpfDependente', cpfDependente)
      .set('parentesco', parentesco)
      .set('page', pageNumber)
      .set('size', pageSize == 0 ? 10 : pageSize);

    return this.http.get<RelatorioDependenteResponse>(`${Globais.urlToken}site-pge/api/auxilios/saude/consultar/relatorio/dependente`, { params: params }).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Erro durante a obtenção de dependentes:', error);
        return throwError(() => 'Falha na obtenção de dependentes.');
      })
    );
  }

  public relatorioPesquisaDependentesTipo(
    nomeTitular: string,
    cpfTitular: string,
    nomeDependente: string,
    cpfDependente: string,
    parentesco: string,
    tipoRelatorio: string
  ): Observable<Blob> {
    const params = new HttpParams()
      .set('nomeTitular', nomeTitular)
      .set('cpfTitular', cpfTitular)
      .set('nomeDependente', nomeDependente)
      .set('cpfDependente', cpfDependente)
      .set('parentesco', parentesco);

    return this.http
      .get(`${Globais.urlToken}site-pge/api/auxilios/saude/exportar/relatorio/dependente/${tipoRelatorio}`, {
        params: params,
        responseType: 'blob'
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Erro durante a obtenção de dependentes:', error);
          return throwError(() => 'Falha na obtenção de dependentes.');
        })
      );
  }

  private dadosFichaInscricao(idUsuario: number): Observable<FichaInscricaoCadastro | null> {
    return this.http.get<FichaInscricaoCadastro>(`${Globais.urlToken}site-pge/api/inscricoes-auxilio-saude/consultar-usuario/${idUsuario}`).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          console.error('Não foi encontrado dados deste usuário: ', error);
          return of(null);
        } else {
          console.error('Erro durante a obtenção de dados: ', error);
          return throwError(() => 'Falha na obtenção de dados');
        }
      })
    );
  }

  public excluirDependente(id: number): Observable<void> {
    return this.http.delete<undefined>(`${Globais.urlToken}site-pge/api/dependentes-auxilio-saude/${id}`).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Erro durante a obtenção de dependentes:', error);
        return throwError(() => 'Falha na obtenção de dependentes.');
      })
    );
  }

  public async inserirAuxilioSiafem(auxilioApesp: AuxilioApesp): Promise<string> {
    try {
      const response = await firstValueFrom(this.http.post<string>(`${Globais.urlToken}site-pge/api/auxilios/saude/incluir/siafem`, auxilioApesp));

      return response;
    } catch (error) {
      return error;
    }
  }

  public async inserirAuxilioApesp(auxilioApesp: AuxilioApesp): Promise<boolean> {
    try {
      const response = await firstValueFrom(this.http.post<boolean>(`${Globais.urlToken}site-pge/api/auxilios/saude/incluir/apesp`, auxilioApesp));

      return response;
    } catch (error) {
      return error;
    }
  }

  private obterListaDependentes(idUser: number | null = null): Observable<Dependente[] | null> {
    const idUsuario = idUser ?? Number(localStorage.getItem('idUsuario'));
    const params = new HttpParams().set('idUsuario', idUsuario);
    return this.http.get<Dependente[]>(`${Globais.urlToken}site-pge/api/inscricoes-auxilio-saude/dependentes`, { params: params }).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          console.error('Não foi encontrado dependentes deste usuário: ', error);
          return of(null);
        } else {
          console.error('Erro durante a obtenção de dados: ', error);
          return throwError(() => 'Falha na obtenção de dados');
        }
      })
    );
  }

  public carregarFichaAuxilioSaude(idUsuario: number): Observable<[FichaInscricaoCadastro | null, InscricaoAuxilioSaude | null, Dependente[] | null]> {
    return combineLatest([this.dadosFichaInscricao(idUsuario), this.obterInscricao(idUsuario), this.obterListaDependentes(idUsuario)]);
  }
}
