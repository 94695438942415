<ng-container>
  @if (item().url === "S" && !item().router.includes("http")) {
    @if (item().router.includes(".pdf")) {
      <a mat-list-item tabindex="0" (keyup)="KeyUp()" (click)="tratarUrl(item()); sobreItemClicado()">
        <mat-icon matListItemIcon>{{ item().materialIcon }}</mat-icon>
        @if (menuAberto() || estaMostrando()) {
          <span matListItemTitle>{{ item().name }}</span>
        }
      </a>
    }

    @if (item().router.includes(".doc") || item().router.includes(".docx")) {
      <a mat-list-item tabindex="0" (keyup)="KeyUp()" (click)="baixarArquivo(item().router); sobreItemClicado()">
        <mat-icon matListItemIcon>{{ item().materialIcon }}</mat-icon>
        @if (menuAberto() || estaMostrando()) {
          <span matListItemTitle>{{ item().name }}</span>
        }
      </a>
    }

    @if (item().router && !item().router.includes(".pdf") && !item().router.includes(".doc") && !item().router.includes(".docx")) {
      <a mat-list-item [routerLink]="mudarRota(item().router)" (click)="sair(item().router); sobreItemClicado()">
        <mat-icon matListItemIcon>{{ item().materialIcon }}</mat-icon>
        @if (menuAberto() || estaMostrando()) {
          <span matListItemTitle>{{ item().name }}</span>
        }
      </a>
    }
  }

  @if (item().url === "S" && item().router.includes("http")) {
    <a mat-list-item [href]="" (click)="abrirNovaAba(mudarRota(item().router)[0]); sair(item().router); sobreItemClicado()">
      <mat-icon matListItemIcon>{{ item().materialIcon }}</mat-icon>
      @if (menuAberto() || estaMostrando()) {
        <span matListItemTitle>{{ item().name }}</span>
      }
    </a>
  }

  @if (item().url === "N") {
    <mat-expansion-panel class="mat-elevation-z0" hideToggle [expanded]="eVisivel(item())">
      <mat-expansion-panel-header (click)="alternarVisibilidade(item())">
        <a mat-list-item>
          <mat-icon matListItemIcon>{{ eVisivel(item()) ? "keyboard_arrow_down" : "keyboard_arrow_right" }}</mat-icon>
          @if (menuAberto() || estaMostrando()) {
            <span matListItemTitle>{{ item().name }}</span>
          }
        </a>
      </mat-expansion-panel-header>
      <mat-nav-list class="sub-menu-item">
        @for (subItem of item().children; track subItem) {
          <app-menu-item [item]="subItem" [menuAberto]="menuAberto()" [estaMostrando]="estaMostrando()" />
        }
      </mat-nav-list>
    </mat-expansion-panel>
  }
</ng-container>
