import type { HttpErrorResponse } from '@angular/common/http';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import type { Observable } from 'rxjs';
import { catchError, throwError } from 'rxjs';
import { Globais } from 'src/app/area-restrita/shared/globais/globais';
import { dateFormat } from 'src/app/date-adapter.custom';
import type { RelatorioAnalisePedidoAuxilioSaude, RelatorioInscricaoResponse } from '../models/relatorio';

@Injectable({
  providedIn: 'root'
})
export class RelatorioAuxilioSaudeService {
  private readonly http = inject(HttpClient);

  public relatorioPesquisaAnalisePedidosAuxilio(
    dadosFormulario: FormGroup,
    pageNumber: number,
    pageSize: number
  ): Observable<RelatorioAnalisePedidoAuxilioSaude> {
    let params = new HttpParams()

      .set('nomeTitular', dadosFormulario.controls['nomeTitular'].value ?? '')
      .set('cpfTitular', dadosFormulario.controls['cpfTitular'].value ?? '')
      .set('dataInscricao', dadosFormulario.controls['dataInscricao'].value ? dateFormat(dadosFormulario.controls['dataInscricao'].value) : '')
      .set('idTipoAuxilioSaude', dadosFormulario.controls['tipoAuxilioSaude'].value ?? '')
      .set('tipoBeneficiario', dadosFormulario.controls['tipoBeneficiario'].value ?? '')
      .set('dataAprovacao', dadosFormulario.controls['dataAprovacao'].value ? dateFormat(dadosFormulario.controls['dataAprovacao'].value) : '')
      .set('page', pageNumber.toString())
      .set('size', pageSize == 0 ? '10' : pageSize.toString());

    if (dadosFormulario.controls['status'].value > 0) {
      params = params.set('status', dadosFormulario.controls['status'].value);
    }
    if (dadosFormulario.controls['valorReembolso'].value > 0) {
      params = params.set('valorReembolsoTotal', dadosFormulario.controls['valorReembolso'].value);
    }
    return this.http
      .get<RelatorioAnalisePedidoAuxilioSaude>(`${Globais.urlToken}site-pge/api/auxilios/saude/consultar/relatorio/analise-pedidos`, {
        params: params
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Erro durante a obtenção de Análise de pedidos:', error);
          return throwError(() => 'Falha na obtenção das Análise de pedidos.');
        })
      );
  }

  public relatorioPesquisaInscricao(
    nomeTitular: string,
    cpfTitular: string,
    tipoCargo: string,
    dataInscricao: string,
    pageNumber: number,
    pageSize: number
  ): Observable<RelatorioInscricaoResponse> {
    const params = new HttpParams()
      .set('nomeTitular', nomeTitular)
      .set('cpfTitular', cpfTitular)
      .set('tipoCargo', tipoCargo)
      .set('dataInscricao', dataInscricao ? dateFormat(dataInscricao) : '')
      .set('page', pageNumber)
      .set('size', pageSize == 0 ? 10 : pageSize);

    return this.http.get<RelatorioInscricaoResponse>(`${Globais.urlToken}site-pge/api/auxilios/saude/consultar/relatorio/inscricao`, { params: params }).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('Erro durante a obtenção de inscrições:', error);
        return throwError(() => 'Falha na obtenção de inscrições.');
      })
    );
  }

  public relatorioPesquisaAnalisePedidosAuxilioTipo(
    nomeTitular: string,
    cpfTitular: string,
    dataInscricao: string,
    idTipoAuxilioSaude: number | string,
    tipoBeneficiario: string,
    valorReembolsoTotal: number | string,
    dataAprovacao: string,
    idStatusAuxilio: string,
    tipoRelatorio: string
  ): Observable<Blob> {
    let params = new HttpParams()
      .set('nomeTitular', nomeTitular)
      .set('cpfTitular', cpfTitular)
      .set('dataInscricao', dataInscricao ? dateFormat(dataInscricao) : '')
      .set('idTipoAuxilioSaude', idTipoAuxilioSaude)
      .set('tipoBeneficiario', tipoBeneficiario)
      .set('dataAprovacao', dataAprovacao ? dateFormat(dataAprovacao) : '')
      .set('status', idStatusAuxilio);

    if (typeof valorReembolsoTotal == 'number' && valorReembolsoTotal > 0) {
      params = params.set('valorReembolsoTotal', valorReembolsoTotal);
    }
    return this.http
      .get(`${Globais.urlToken}site-pge/api/auxilios/saude/exportar/relatorio/analise-pedidos/${tipoRelatorio}`, {
        params: params,
        responseType: 'blob'
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Erro durante a obtenção do relatório:', error.error);
          return throwError(() => 'Falha na obtenção de Análise de pedidos.');
        })
      );
  }

  public relatorioPesquisaInscricaoTipo(
    nomeTitular: string,
    cpfTitular: string,
    tipoCargo: string,
    dataInscricao: string,
    tipoRelatorio: string
  ): Observable<Blob> {
    const params = new HttpParams()
      .set('nomeTitular', nomeTitular)
      .set('cpfTitular', cpfTitular)
      .set('tipoCargo', tipoCargo)
      .set('dataInscricao', dataInscricao ? dateFormat(dataInscricao) : '');

    return this.http
      .get(`${Globais.urlToken}site-pge/api/auxilios/saude/exportar/relatorio/inscricao/${tipoRelatorio}`, {
        params: params,
        responseType: 'blob'
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error('Erro durante a obtenção do relatório:', error.error);
          return throwError(() => 'Falha na obtenção de inscrições.');
        })
      );
  }
}
