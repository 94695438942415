import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { MatAnchor } from '@angular/material/button';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatIcon } from '@angular/material/icon';
import type { ParamMap } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoriaNoticia } from 'src/app/area-aberta/shared/enums/categoria-noticia';
import { ConversorValidadorService } from 'src/app/area-restrita/shared/services/conversor-validador.service';
import type { Noticias, PdfAnexo } from 'src/app/shared/models/noticia.interface';
import type { Page } from 'src/app/shared/models/paginacao/page.model';
import { NoticiaService } from 'src/app/shared/services/noticia.service';
import { SeparadorComponent } from '../../../core/layout/separador/separador.component';
import { ImagemTratamento } from '../../home/shared/services/imagem-tratamento';

@Component({
  selector: 'app-visualizar-comunicado',
  templateUrl: './visualizar-comunicado.component.html',
  styleUrl: './visualizar-comunicado.component.scss',
  providers: [ImagemTratamento],
  standalone: true,

  imports: [MatAnchor, MatIcon, SeparadorComponent, MatCard, MatCardContent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VisualizarComunicadoComponent implements OnInit {
  private readonly noticiaService = inject(NoticiaService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly imagemTratamento = inject(ImagemTratamento);
  private readonly base64ToPdfService = inject(ConversorValidadorService);

  noticia = signal<Noticias | undefined>(undefined);
  objNoticias = signal<Noticias[]>([]);
  arquivos = signal<PdfAnexo[]>([]);
  noticiaId: number;

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.noticiaId = Number(params.get('id'));

      if (this.noticiaId) {
        this.noticiaService.getOneNoticia(this.noticiaId).subscribe((noticia) => {
          this.noticia.set(noticia);
          this.obterNoticiasDestaque();
        });
      }
    });
    this.obterArquivos(this.noticiaId);
  }

  obterArquivos(idNoticia: number): void {
    this.noticiaService.obterArquivosNoticias(idNoticia).subscribe({
      next: (response) => {
        if (response) this.arquivos.set(response);
      },
      error: console.error
    });
  }

  retornaListaComunicados(): void {
    void this.router.navigate(['informacao-cidadao/comunicados']);
  }

  obterNoticiasDestaque(): void {
    this.noticiaService.getNoticia(0, 4, CategoriaNoticia.NOTICIA_AREA_ABERTA).subscribe((response: Page<Noticias>) => {
      this.objNoticias.set(response.content.filter((noticia) => noticia.ativo).slice(0, 4));
    });
  }

  abrirUmaNoticia(noticiaId: number): void {
    void this.router.navigate(['/uma-noticia', noticiaId]);
    this.obterArquivos(noticiaId);
  }

  obterImagem(noticiaImagem?: string): string | undefined {
    return this.imagemTratamento.obterImagemBlob(noticiaImagem);
  }

  editarData(data?: string): string {
    if (!data || data.length < 10) {
      console.error('Data inválida:', data);
      return 'Data inválida';
    }

    const ano = data.substring(6, 10);
    const mes = this.transformarMes(data.substring(3, 5));
    const dia = data.substring(0, 2);

    return `${dia} de ${mes} de <strong>${ano}</strong>`;
  }

  transformarMes(mes: string): string {
    const mesEditado = Number(mes);
    const numeroMes = Number(mesEditado);
    const listaMes = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

    return listaMes[numeroMes - 1];
  }

  processarTexto(texto?: string): string {
    if (texto) {
      return texto.replace(/<img\b[^>]*>/, (match) => match.replace('>', ' width="60%">'));
    } else {
      return '';
    }
  }

  abrirArquivo(evento: MouseEvent, pdf: PdfAnexo): void {
    evento.preventDefault();
    this.base64ToPdfService.base64ToBlob(pdf.arquivo);
  }
}
