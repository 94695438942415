<app-separador titulo="NOTÍCIAS DIVERSAS" />
<div class="container">
  @for (noticia of objNoticias; track noticia) {
    <div class="news-card" tabindex="0" (click)="abrirUmaNoticia(noticia?.id)" (keypress)="abrirUmaNoticia(noticia?.id)">
      @if (noticia?.imagem) {
        <div class="news-card-image">
          <img alt="Imagem da Notícia" tabindex="0" [src]="obterImagem(noticia?.imagem)" (click)="abrirUmaNoticia(noticia?.id)" (keypress)="abrirUmaNoticia(noticia?.id)" />
        </div>
      }
      <div class="news-detail">
        <div class="titulo">{{ noticia?.titulo }}</div>
        <div class="date">{{ noticia?.dataPublicacao }}</div>
        <div class="description">{{ ajusteTexto(noticia?.texto) }}</div>
      </div>
    </div>
  }
</div>
<div>
  <mat-paginator
    #paginator
    class="custom-paginator"
    aria-label="Select page"
    [length]="comprimento"
    [pageSize]="tamanhoDaPagina"
    [disabled]="desabilitado"
    [showFirstLastButtons]="mostrarPrimeiroUltimosBotoes"
    [pageSizeOptions]="mostrarOpcoesDeTamanhoPagina ? opcoesDoTamanhoDaPagina : []"
    [hidePageSize]="ocultarTamanhoDaPagina"
    [pageIndex]="numeroPagina"
    (page)="lidarEventoPagina($event)"
  />
</div>
