{
  "name": "site-pge-front-end",
  "version": "0.5.91",
  "buildDate": "2024-12-10_20-46",
  "environmentRun": "PROD",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build --configuration=dev",
    "predev": "node version.build.js",
    "dev": "ng build --configuration=dev",
    "prehml": "node version.build.js",
    "hml": "ng build --configuration=hml",
    "preprd": "node version.build.js",
    "prd": "ng build --configuration=prd",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "lint": "ng lint"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.10",
    "@angular/cdk": "^18.2.10",
    "@angular/common": "^18.2.10",
    "@angular/compiler": "^18.2.10",
    "@angular/core": "^18.2.10",
    "@angular/forms": "^18.2.10",
    "@angular/material": "^18.2.10",
    "@angular/platform-browser": "^18.2.10",
    "@angular/platform-browser-dynamic": "^18.2.10",
    "@angular/router": "^18.2.10",
    "ngx-currency": "^18.0.0",
    "ngx-file-drop": "^16.0.0",
    "ngx-mask": "^18.0.2",
    "primeng": "^17.18.11",
    "quill": "^2.0.2",
    "rxjs": "~7.8.1",
    "xlsx": "^0.18.5",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.10",
    "@angular/cli": "^18.2.10",
    "@angular/compiler-cli": "^18.2.10",
    "@eslint/js": "9.14.0",
    "angular-eslint": "18.4.0",
    "eslint": "9.14.0",
    "globals": "^15.12.0",
    "prettier": "3.3.2",
    "typescript": "~5.4.5",
    "typescript-eslint": "8.13.0"
  }
}