<main class="main" role="main">
  <section class="page">
    <app-separador titulo="ASSESSORIAS" />
    <div class="row">
      <div>
        <h4>Assessores do GPG</h4>
        <p>
          FLÁVIA DELLA COLETTA DEPINÉ<br />
          RODRIGO LEMOS CURADO
        </p>
        <br />
        <h4>Assessoria Jurídica do GPG</h4>
        <br />
        <h5>AJG-PGE CHEFIA</h5>
        <strong>Procurador(a) Chefe</strong>: {{ encontrarNome("CRISTIANA CORREA CONDE FALDINI") }}
        <br />
        <br />
        <h5>AJG-PGE DEMAIS PROCURADORES</h5>
        <div>
          {{ encontrarNome("ANA PAULA MANENTI DOS SANTOS") }}<br />
          {{ encontrarNome("ANNA CAROLINA SENI PEITO MACEDO CASAGRANDE") }}<br />
          {{ encontrarNome("DIEGO BRITO CARDOSO") }}<br />
          {{ encontrarNome("LUCIANA AUGUSTA SANCHEZ") }}<br />
          {{ encontrarNome("MARIA DE LOURDES D ARCE PINHEIRO") }}<br />
          {{ encontrarNome("MELISSA DI LASCIO SAMPAIO") }}<br />
          {{ encontrarNome("SORAYA LIMA DO NASCIMENTO") }}<br />
          {{ encontrarNome("SUZANE RAMOS ROSA ESTEVES") }}<br />
          {{ encontrarNome("VINICIUS TELES SANCHES") }}<br />
        </div>
        <br />
        <h4>Assessoria Técnico-Legislativa do GPG</h4>
        <br />
        <h5>ATL-PGE CHEFIA</h5>
        <strong>Procurador(a) Chefe</strong>: {{ encontrarNome("TELMA DE FREITAS FONTES") }}
        <br />
        <br />
        <h5>ATL-PGE DEMAIS PROCURADORES</h5>
        <div>
          {{ encontrarNome("CARLOS EDUARDO QUEIROZ MARQUES") }} <br />
          {{ encontrarNome("CARLOS JOSE TEIXEIRA DE TOLEDO") }} <br />
          {{ encontrarNome("CLAYTON EDUARDO PRADO") }} <br />
          {{ encontrarNome("JOSE RENATO FERREIRA PIRES") }} <br />
          {{ encontrarNome("MARCIA AMINO") }} <br />
          {{ encontrarNome("ROSANA MARTINS KIRSCHKE") }} <br />
        </div>
        <br />
        <h4>Assessoria de Precatórios Judiciais</h4>
        <br />
        <strong>Procurador(a) Chefe</strong>: WLADIMIR RIBEIRO JUNIOR
        <br />
        <strong>Endereço</strong>: Rua Pamplona, 227, 15 andar, Jardim Paulista, São Paulo-SP, 01405-902.<br />
        <strong>Telefone</strong>: 3372- 6518<br />
        <strong>E-mail</strong>: precatoriospgeatendimento&#64;sp.gov.br<br />
        <br />
        {{ encontrarNome("ANA LUCIA DE BARROS CANHA ROGGERO") }}<br />
        {{ encontrarNome("FERNANDA RIBEIRO DE MATTOS LUCCAS") }}<br />
        {{ encontrarNome("PEDRO DE ALCANTARA RIBEIRO VILANOVA JUNIOR") }}<br />
        {{ encontrarNome("TATIANA GAIOTTO MADUREIRA") }}<br />
        {{ encontrarNome("WLADIMIR RIBEIRO JUNIOR") }}<br />
        <br />
        <h4>Assessoria de Empresas e Fundações</h4>
        <br />
        <strong>Procurador(a) Coordenador(a)</strong>: CLAUDIA POLTO DA CUNHA<br />
        <strong>Endereço</strong>: Rua Pamplona, 227, 17º andar, Bela Vista, São Paulo-SP, 01405-902.<br />
        <strong>Telefone</strong>: (11) 3372- 6656/6657<br />
        <br />
        {{ encontrarNome("BRUNA TAPIE GABRIELLI") }}<br />
        {{ encontrarNome("CLAUDIA POLTO DA CUNHA") }}<br />
        {{ encontrarNome("LAURA BARACAT BEDICKS PANUCCI") }}<br />
        <p>
          <a href="https://www.pge.sp.gov.br/Portal_PGE/Portal_Empresas_e_Fundacoes/comuns/senha2.asp">Portal AEF</a>
        </p>
        <br />
        <h4>Assessoria de Comunicação e Imprensa</h4>
        <!-- Os dados dessa assessoria estão mocados -->
        <br />
        <strong>Coordenador(a): </strong><br />
        <strong>Endereço</strong>: Rua Pamplona,227, 18º andar, Bela Vista, São Paulo – SP, 01405-902.<br />
        <strong>Telefone</strong>: (11) 3372-6428/9438/6495<br />
        <strong>E-mail</strong>:<a href="mailto:aipge@sp.gov.br">aipge&#64;sp.gov.br</a><br />
        <br />
        JOSÉ BRUSSI JUNIOR<br />
        SYLVIO MONTENEGRO RODRIGUES JUNIOR<br />
        {{ encontrarNome("THAIS FERREIRA ESTEVAO") }}<br />
        {{ encontrarNome("ELIANE NEVES MOITINHO") }}<br />
        <br />
        <h4>Assessoria de Tecnologia da Informação e Comunicação</h4>
        <!-- Os dados dessa assessoria estão mocados -->
        <br />
        <strong>Procurador(a) Coordenador(a)</strong>: VIRGILIO BERNARDES CARBONIERI<br />
        <strong>Endereço</strong>: Rua Pamplona,nº 227, 6º andar, Bela Vista, São Paulo-SP, 01405-902.<br />
        <strong>Telefone</strong>: (11) 3372- 9437/6414/6497<br />
        <br />
        EDUARDO JOSÉ FAGUNDES<br />
        EDSON TOSHIAKI ENDO<br />
        JOSÉ ROBERTO B. GIMENEZ<br />
        LUIS RICARDO SILVA<br />
        <br />
      </div>
    </div>
  </section>
</main>
