import { Component } from '@angular/core';
import { SeparadorComponent } from '../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-sic-ouvidoria',
  templateUrl: './sic-ouvidoria.component.html',
  styleUrl: './sic-ouvidoria.component.scss',
  standalone: true,

  imports: [SeparadorComponent]
})
export class SicOuvidoriaComponent {}
