import type { HttpErrorResponse } from '@angular/common/http';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { FormGroup } from '@angular/forms';
import type { Observable } from 'rxjs';
import { of, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { Globais } from 'src/app/area-restrita/shared/globais/globais';
import { dateFormat } from 'src/app/date-adapter.custom';
import type { Noticias, pdfAnexo } from '../models/noticia.interface';
import type { Page } from '../models/paginacao/page.model';

@Injectable({
  providedIn: 'root'
})
export class NoticiaService {
  private readonly http = inject(HttpClient);

  getNoticia(pageIndex: string | number, pageSize: string | number, idCategoria: number): Observable<Page<Noticias>> {
    const parametros = new HttpParams().set('idCategoria', idCategoria).set('ativo', true).set('page', pageIndex).set('size', pageSize);

    return this.http.get<Page<Noticias>>(`${Globais.urlToken}site-pge/api/noticias`, { params: parametros });
  }

  obterNoticiasPorIdPerfil(pageIndex: string | number, pageSize: string | number, dados: number): Observable<Page<Noticias>> {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });
    const parametros = new HttpParams().set('page', pageIndex).set('size', pageSize);
    return this.http.get<Page<Noticias>>(`${Globais.urlToken}site-pge/api/noticias/porIdPerfil/${dados}`, { headers: headers, params: parametros }).pipe(
      map((response: Page<Noticias>) => {
        return response;
      })
    );
  }

  getNoticiaByName(tituloOuPalavraChave: string): Observable<Noticias[]> {
    const parametros = new HttpParams().set('titulo', tituloOuPalavraChave).set('palavraChave', tituloOuPalavraChave).set('ativo', true);

    return this.http.get<Page<Noticias>>(`${Globais.urlToken}site-pge/api/noticias`, { params: parametros }).pipe(
      map((response: Page<Noticias>) => {
        return response.content;
      })
    );
  }

  consultar(dadosFormulario: FormGroup, pageIndex: string | number, pageSize: string | number): Observable<Page<Noticias>> {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });

    let parametros = new HttpParams()
      .set('titulo', dadosFormulario.controls['titulo'].value)
      .set('ativo', dadosFormulario.controls['ativo'].value !== 0 ? dadosFormulario.controls['ativo'].value : '')
      .set('dataPublicacao', dadosFormulario.controls['dataPublicacao'].value ? dateFormat(dadosFormulario.controls['dataPublicacao'].value) : '')
      .set('destaque', dadosFormulario.controls['destaque'].value !== 0 ? dadosFormulario.controls['destaque'].value : '')
      .set('idCategoria', dadosFormulario.controls['categoria'].value !== 0 ? dadosFormulario.controls['categoria'].value : '')
      .set('page', pageIndex)
      .set('size', pageSize);

    if (dadosFormulario.controls['idAcesso'].value != null) {
      parametros = parametros.set(
        'idAcesso',
        parseInt(dadosFormulario.controls['idAcesso'].value, 10) !== 0 ? parseInt(dadosFormulario.controls['idAcesso'].value, 10) : ''
      );
    }
    return this.http.get<Page<Noticias>>(`${Globais.urlToken}site-pge/api/noticias`, { headers: headers, params: parametros }).pipe(
      map((response: Page<Noticias>) => {
        return response;
      })
    );
  }

  remover(id: number): Observable<void> {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });
    const option = {
      headers: headers
    };
    return this.http.delete<undefined>(`${Globais.urlToken}site-pge/api/noticias/${id}`, option).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          console.error('Não foi encontrado a notícia para ser removida: ', error);
          return of(null);
        } else {
          console.error('Erro durante a obtenção de dados: ', error);
          return throwError(() => 'Falha na obtenção de dados');
        }
      })
    );
  }

  incluir(dadosFormulario: FormGroup): Observable<Noticias> {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });
    return this.http.post<Noticias>(`${Globais.urlToken}site-pge/api/noticias`, dadosFormulario, { headers: headers }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }

  incluirArquivo(dadosPdf: pdfAnexo[]): Observable<void> {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });
    return this.http.post<undefined>(`${Globais.urlToken}site-pge/api/arquivo-noticias/batch`, dadosPdf, { headers: headers }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
      finalize(() => {
        console.log('Requisição concluída.');
      })
    );
  }

  getOneNoticia(noticiaId: number): Observable<Noticias | undefined> {
    return this.http.get<Noticias>(`${Globais.urlToken}site-pge/api/noticias/${noticiaId}`);
  }

  getOneNoticiaAtivoTrueOrFalse(noticiaId: number): Observable<Noticias | undefined> {
    return this.http.get<Noticias>(`${Globais.urlToken}site-pge/api/noticias/consultarPorIdAtivoVerdadeiroOuFalso/${noticiaId}`);
  }

  obterArquivosNoticias(noticiaId: number): Observable<pdfAnexo[]> {
    return this.http.get<pdfAnexo[]>(`${Globais.urlToken}site-pge/api/arquivo-noticias/${noticiaId}`).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }

  atualizar(dadosFormulario: HttpParams, id: number) {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });
    return this.http.put(`${Globais.urlToken}site-pge/api/noticias/${id}`, dadosFormulario, { headers: headers }).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          console.error('Não foi encontrado a noticia para atualizar: ', error);
          return of(null);
        } else {
          console.error('Erro durante a obtenção de dados: ', error);
          return throwError(() => 'Falha na obtenção de dados');
        }
      })
    );
  }

  atualizarArquivo(dadosPdf: pdfAnexo[]): Observable<void> {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });
    return this.http.put<undefined>(`${Globais.urlToken}site-pge/api/arquivo-noticias/batch`, dadosPdf, { headers: headers }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      }),
      finalize(() => {
        console.log('Requisição concluída.');
      })
    );
  }

  atualizarAtivo(id: number, campoAtivo: boolean) {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });
    return this.http.put(`${Globais.urlToken}site-pge/api/noticias/${id}/ativo`, campoAtivo, { headers: headers }).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          console.error('Não foi encontrado a notícia para atualizar em ativar ou desativar: ', error);
          return of(null);
        } else {
          console.error('Erro durante a obtenção de dados: ', error);
          return throwError(() => 'Falha na obtenção de dados');
        }
      })
    );
  }
}
