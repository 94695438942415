import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import type { PageEvent } from '@angular/material/paginator';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { CategoriaNoticia } from 'src/app/area-aberta/shared/enums/categoria-noticia';
import type { Noticias } from 'src/app/shared/models/noticia.interface';
import type { Page } from 'src/app/shared/models/paginacao/page.model';
import { NoticiaService } from 'src/app/shared/services/noticia.service';
import { SeparadorComponent } from '../../../core/layout/separador/separador.component';
import { ImagemTratamento } from '../../home/shared/services/imagem-tratamento';

@Component({
  selector: 'app-credenciamento-contadores',
  templateUrl: './credenciamento-contadores.component.html',
  styleUrl: './credenciamento-contadores.component.scss',
  providers: [ImagemTratamento],
  standalone: true,
  imports: [SeparadorComponent, MatPaginator],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CredenciamentoContadoresComponent implements OnInit {
  private readonly noticiaService = inject(NoticiaService);
  private readonly imagemTratamento = inject(ImagemTratamento);
  private readonly router = inject(Router);

  objCredenciamentoContadores = signal<Noticias[]>([]);

  comprimento = signal(0);
  tamanhoDaPagina = signal(10);
  numeroPagina = signal(0);
  readonly opcoesDoTamanhoDaPagina = [5, 10, 25];

  ngOnInit(): void {
    this.buscarTodosCredenciamentosContadores();
  }

  buscarTodosCredenciamentosContadores(): void {
    this.noticiaService.getNoticia(this.numeroPagina(), this.tamanhoDaPagina(), CategoriaNoticia.CREDENCIAMENTO_DE_CONTADORES).subscribe({
      next: (response: Page<Noticias>) => {
        this.objCredenciamentoContadores.set(response.content);
        this.comprimento.set(response.totalElements);
      },
      error: console.error
    });
  }

  credenciamento(id: number): void {
    void this.router.navigate(['informacao-cidadao/credenciamento-contadores/noticia', id]);
  }

  editarData(data: string): string {
    const ano = data.substring(6, 10);
    const mes = this.transformarMes(data.substring(3, 5));
    const dia = data.substring(0, 2);
    return `<strong>${ano}</strong> ${dia} - ${mes}`;
  }

  transformarMes(mes: string): string {
    const mesEditado = mes.replace('0', '');
    const numeroMes = Number(mesEditado);
    const listaMeses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

    return listaMeses[numeroMes - 1];
  }

  obterImagem(noticiaImagem?: string): string | undefined {
    return this.imagemTratamento.obterImagemBlob(noticiaImagem);
  }

  lidarEventoPagina(e: PageEvent): void {
    this.comprimento.set(e.length);
    this.tamanhoDaPagina.set(e.pageSize);
    this.numeroPagina.set(e.pageIndex);
    this.buscarTodosCredenciamentosContadores();
  }

  ajustetexto(fonte: string): string {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = fonte;
    return tmp.textContent ?? tmp.innerText;
  }
}
