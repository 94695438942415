export const dados = [
  {
    id: 1,
    Municipio: "adamantina",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 2,
    Municipio: "adolfo",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 3,
    Municipio: "aguaí",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 4,
    Municipio: "águas da prata",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 5,
    Municipio: "águas de lindóia",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 6,
    Municipio: "águas de sta bárbara",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 7,
    Municipio: "águas de SÃO pedro",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 8,
    Municipio: "agudos",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 9,
    Municipio: "alambari",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 10,
    Municipio: "alfredo marcondes",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 11,
    Municipio: "altair",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 12,
    Municipio: "altinópolis",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 13,
    Municipio: "alto alegre",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 14,
    Municipio: "alumínio",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 15,
    Municipio: "álvares florence",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 16,
    Municipio: "alvares machado",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 17,
    Municipio: "alvaro de carvalho",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 18,
    Municipio: "alvinlândia",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 19,
    Municipio: "americana",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 20,
    Municipio: "américo brasiliense",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 21,
    Municipio: "américo de campos",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 22,
    Municipio: "amparo",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 23,
    Municipio: "analândia",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 24,
    Municipio: "andradina",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 25,
    Municipio: "angatuba",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 26,
    Municipio: "anhenbi",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 27,
    Municipio: "anhumas",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 28,
    Municipio: "aparecida",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 29,
    Municipio: "aparecida d´oeste",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 30,
    Municipio: "apiaí",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 31,
    Municipio: "araçariguama",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 32,
    Municipio: "ARAÇATUBA",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 33,
    Municipio: "araçoiaba da serra",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 34,
    Municipio: "aramina",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 35,
    Municipio: "arandu",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 36,
    Municipio: "arapei",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 37,
    Municipio: "araraquara",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 38,
    Municipio: "araras",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 39,
    Municipio: "arco iris",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 40,
    Municipio: "arealva",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 41,
    Municipio: "areias",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 42,
    Municipio: "areiópolis",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 43,
    Municipio: "ariranha",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 44,
    Municipio: "artur nogueira",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 45,
    Municipio: "arujá",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 46,
    Municipio: "aspásia",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 47,
    Municipio: "assis",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 48,
    Municipio: "atibaia",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 49,
    Municipio: "auriflama",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 50,
    Municipio: "avaí",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 51,
    Municipio: "avanhandava",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 52,
    Municipio: "avaré",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 53,
    Municipio: "bady bassit",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 54,
    Municipio: "balbinos",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 55,
    Municipio: "bálsamo",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 56,
    Municipio: "bananal",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 57,
    Municipio: "barão de antonina",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 59,
    Municipio: "bariri",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 60,
    Municipio: "barra bonita",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 61,
    Municipio: "barra do chapéu",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 62,
    Municipio: "barra do turvo",
    Codigo: "PR02",
    Regional: "SANTOS",
    Chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 63,
    Municipio: "barretos",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 64,
    Municipio: "barrinha",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 65,
    Municipio: "barueri",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 66,
    Municipio: "bastos",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 67,
    Municipio: "batatais",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 68,
    Municipio: "bauru",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 69,
    Municipio: "bebedouro",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 70,
    Municipio: "bento de abreu",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 71,
    Municipio: "bernardino de campos",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 72,
    Municipio: "bertioga",
    Codigo: "PR02",
    Regional: "SANTOS",
    Chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 73,
    Municipio: "bilac",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 74,
    Municipio: "birigui",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 75,
    Municipio: "birtiba-mirim",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 76,
    Municipio: "boa esperança do sul",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 77,
    Municipio: "bocaína",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 78,
    Municipio: "bofete",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 79,
    Municipio: "boituva",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 80,
    Municipio: "bom jesus dos perdões",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 81,
    Municipio: "bom sucesso de itararé",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 82,
    Municipio: "borá",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 83,
    Municipio: "boracéia",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 84,
    Municipio: "borborema",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 85,
    Municipio: "borebi",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 86,
    Municipio: "botucatu",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 87,
    Municipio: "bragança paulista",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 88,
    Municipio: "bras cubas",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 89,
    Municipio: "braúna",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 90,
    Municipio: "brejo alegre",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 91,
    Municipio: "brodósqui",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 92,
    Municipio: "brotas",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 93,
    Municipio: "buri",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 94,
    Municipio: "buritama",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 95,
    Municipio: "buritizal",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 96,
    Municipio: "cabrália paulista",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 97,
    Municipio: "cabreuva",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 98,
    Municipio: "caçapava",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 99,
    Municipio: "cachoeira das emas",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 100,
    Municipio: "cachoeira paulista",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 101,
    Municipio: "caconde",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 102,
    Municipio: "cafelândia",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 103,
    Municipio: "caiabu",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 104,
    Municipio: "caieiras",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 105,
    Municipio: "caíua",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 106,
    Municipio: "cajamar",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 107,
    Municipio: "cajati",
    Codigo: "PR02",
    Regional: "SANTOS",
    Chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 108,
    Municipio: "cajobi",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 109,
    Municipio: "cajuru",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 110,
    Municipio: "campina do monte alegre",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 111,
    Municipio: "campinas",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 112,
    Municipio: "campo limpo paulista",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 113,
    Municipio: "campos do jordão",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 114,
    Municipio: "campos novos paulista",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 115,
    Municipio: "cananéia",
    Codigo: "PR02",
    Regional: "SANTOS",
    Chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 116,
    Municipio: "canas",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 117,
    Municipio: "cândido mota",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 118,
    Municipio: "candido rodrigues",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 119,
    Municipio: "canitar",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 120,
    Municipio: "capão bonito",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 121,
    Municipio: "capela do alto",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 122,
    Municipio: "capivari",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 123,
    Municipio: "caraguatatuba",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 124,
    Municipio: "carapicuiba",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 125,
    Municipio: "cardoso",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 126,
    Municipio: "casa branca",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 127,
    Municipio: "cassia dos coqueiros",
    Codigo: "PR06",
    Regional: "RIBIERÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 128,
    Municipio: "castilho",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 129,
    Municipio: "catanduva",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 130,
    Municipio: "catiguá",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 131,
    Municipio: "cedral",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 132,
    Municipio: "cerqueira cesar",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 133,
    Municipio: "cerquilho",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 134,
    Municipio: "cesário lange",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 135,
    Municipio: "charqueada",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 136,
    Municipio: "chavantes",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 137,
    Municipio: "clementina",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 138,
    Municipio: "colina",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 139,
    Municipio: "colômbia",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 140,
    Municipio: "conchal",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 141,
    Municipio: "conchas",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 142,
    Municipio: "cordeirópolis",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 143,
    Municipio: "coroados",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 144,
    Municipio: "coronel macedo",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 145,
    Municipio: "corumbataí",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 146,
    Municipio: "cosmópolis",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 147,
    Municipio: "cosmorama",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 148,
    Municipio: "cotia",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 149,
    Municipio: "cravinhos",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 150,
    Municipio: "cristais paulista",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 151,
    Municipio: "cruzália",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 153,
    Municipio: "cubatão",
    Codigo: "PR02",
    Regional: "SANTOS",
    Chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 154,
    Municipio: "cunha",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 155,
    Municipio: "descalvado",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 156,
    Municipio: "diadema",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 157,
    Municipio: "dirce reis",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 158,
    Municipio: "divinolândia",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 159,
    Municipio: "dobrada",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 160,
    Municipio: "dois córregos",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 161,
    Municipio: "dolcinópolis",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 162,
    Municipio: "dourado",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 163,
    Municipio: "dracena",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 164,
    Municipio: "duartina",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 165,
    Municipio: "dumont",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 166,
    Municipio: "echaporã",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 167,
    Municipio: "eldorado",
    Codigo: "PR02",
    Regional: "SANTOS",
    Chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 168,
    Municipio: "elias fausto",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 169,
    Municipio: "elisiário",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 170,
    Municipio: "embaúba",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 171,
    Municipio: "embu",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 172,
    Municipio: "embu-guaçu",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 173,
    Municipio: "emilianóplis",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 174,
    Municipio: "engenheiro coelho",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 175,
    Municipio: "espirito santo do pinhal",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 176,
    Municipio: "espirito santo do turvo",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 177,
    Municipio: "estiva gerbi",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 178,
    Municipio: "estrela do norte",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 179,
    Municipio: "estrela d´oeste",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 180,
    Municipio: "euclides da cunha paulista",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 181,
    Municipio: "fartura",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 182,
    Municipio: "fernando prestes",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 183,
    Municipio: "fernandópolis",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 184,
    Municipio: "fernão",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 185,
    Municipio: "ferraz de vasconcelos",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 186,
    Municipio: "flora rica",
    Codigo: "PR10",
    Regional: "PRESIDENTE  PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 187,
    Municipio: "floreal",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 188,
    Municipio: "florida paulista",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 189,
    Municipio: "florinéa",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 190,
    Municipio: "franca",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 191,
    Municipio: "francisco morato",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 192,
    Municipio: "franco da rocha",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 193,
    Municipio: "gabriel monteiro",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 194,
    Municipio: "galia",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 195,
    Municipio: "garça",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 196,
    Municipio: "gastão vidigal",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 197,
    Municipio: "gavião peixoto",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 198,
    Municipio: "general salgado",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 199,
    Municipio: "getulina",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 200,
    Municipio: "glicério",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 201,
    Municipio: "guaiçará",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 202,
    Municipio: "guaimbé",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 203,
    Municipio: "guaíra",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 204,
    Municipio: "guapiaçu",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 205,
    Municipio: "guapiara",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 206,
    Municipio: "guara",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 207,
    Municipio: "guaraçaí",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 208,
    Municipio: "guaraci",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 209,
    Municipio: "guarani d´oeste",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 210,
    Municipio: "guarantã",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 211,
    Municipio: "guararapes",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 212,
    Municipio: "guararema",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 213,
    Municipio: "guaratinguetá",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 214,
    Municipio: "guarei",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 215,
    Municipio: "guariba",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 216,
    Municipio: "guarujá",
    Codigo: "PR02",
    Regional: "SANTOS",
    Chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 217,
    Municipio: "guarulhos",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 218,
    Municipio: "guatapará",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 219,
    Municipio: "guzolândia",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 220,
    Municipio: "herculândia",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 221,
    Municipio: "holambra",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 222,
    Municipio: "hortolândia",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 223,
    Municipio: "iacanga",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 224,
    Municipio: "iacri",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 225,
    Municipio: "iaras",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 226,
    Municipio: "ibaté",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 227,
    Municipio: "ibirá",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 228,
    Municipio: "ibirarema",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 229,
    Municipio: "ibitinga",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 230,
    Municipio: "ibitiúva",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 231,
    Municipio: "ibiúna",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 232,
    Municipio: "icem",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 233,
    Municipio: "iepê",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 234,
    Municipio: "igaraçu do tietê",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 235,
    Municipio: "igarapava",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 236,
    Municipio: "igarata",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 237,
    Municipio: "iguape",
    Codigo: "PR02",
    Regional: "SANTOS",
    Chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 238,
    Municipio: "ilha comprida",
    Codigo: "PR02",
    Regional: "SANTOS",
    Chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 239,
    Municipio: "ilha solteira",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 240,
    Municipio: "ilhabela",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 241,
    Municipio: "indaiatuba",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 242,
    Municipio: "indiana",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 243,
    Municipio: "indiaporã",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 244,
    Municipio: "inubia paulista",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 245,
    Municipio: "ipauçu",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 246,
    Municipio: "iperó",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 247,
    Municipio: "ipeúna",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 248,
    Municipio: "ipiguá",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 249,
    Municipio: "iporanga",
    Codigo: "PR02",
    Regional: "SANTOS",
    Chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 250,
    Municipio: "ipuã",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 251,
    Municipio: "iracemápolis",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 252,
    Municipio: "irapuã",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 253,
    Municipio: "irapuru",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 254,
    Municipio: "itabera",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 255,
    Municipio: "itaí",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 256,
    Municipio: "itajobi",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 257,
    Municipio: "itaju",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 258,
    Municipio: "itanhaém",
    Codigo: "PR02",
    Regional: "SANTOS",
    Chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 259,
    Municipio: "itaoca",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 260,
    Municipio: "itapecerica da serra",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 261,
    Municipio: "itapetininga",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 262,
    Municipio: "itapeva",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 263,
    Municipio: "itapevi",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 264,
    Municipio: "itapira",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 265,
    Municipio: "itapirapuã paulista",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 266,
    Municipio: "itapolis",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 267,
    Municipio: "itaporanga",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 268,
    Municipio: "itapuí",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 269,
    Municipio: "itapura",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 270,
    Municipio: "itaquaquecetuba",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 271,
    Municipio: "itararé",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 272,
    Municipio: "itariri",
    Codigo: "PR02",
    Regional: "SANTOS",
    Chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 273,
    Municipio: "itatiba",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 274,
    Municipio: "itatinga",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 275,
    Municipio: "itirapina",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 276,
    Municipio: "itirapuã",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 277,
    Municipio: "itobi",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 278,
    Municipio: "itu",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 279,
    Municipio: "itupeva",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 280,
    Municipio: "ituverava",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 281,
    Municipio: "jaborandi",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 282,
    Municipio: "jaboticabal",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 283,
    Municipio: "jacarei",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 284,
    Municipio: "jaci",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 285,
    Municipio: "jacupiranga",
    Codigo: "PR02",
    Regional: "SANTOS",
    Chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 286,
    Municipio: "jaguariúna",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 287,
    Municipio: "jales",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 288,
    Municipio: "jambeiro",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 289,
    Municipio: "jandira",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 290,
    Municipio: "jardinópolis",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 291,
    Municipio: "jarinu",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 292,
    Municipio: "jaú",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 293,
    Municipio: "jeriquara",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 294,
    Municipio: "joanópolis",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 295,
    Municipio: "joão ramalho",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 296,
    Municipio: "JOSÉ bonifácio",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 297,
    Municipio: "júlio mesquita",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 298,
    Municipio: "jumirim",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 299,
    Municipio: "jundiaí",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 300,
    Municipio: "junqueirópolis",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 301,
    Municipio: "juquiá",
    Codigo: "PR02",
    Regional: "SANTOS",
    Chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 302,
    Municipio: "juquitiba",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 303,
    Municipio: "lagoinha",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 304,
    Municipio: "laranjal paulista",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 305,
    Municipio: "lavínia",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 306,
    Municipio: "lavrinhas",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 307,
    Municipio: "leme",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 308,
    Municipio: "lençóis paulista",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 309,
    Municipio: "limeira",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 310,
    Municipio: "lindóia",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 311,
    Municipio: "lins",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 312,
    Municipio: "lorena",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 313,
    Municipio: "lourdes",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 314,
    Municipio: "louveira",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 315,
    Municipio: "lucélia",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 316,
    Municipio: "lucianópolis",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 317,
    Municipio: "luiz antônio",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 318,
    Municipio: "luiziânia",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 319,
    Municipio: "lupércio",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 320,
    Municipio: "lutécia",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 321,
    Municipio: "macatuba",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 322,
    Municipio: "macaubal",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 323,
    Municipio: "macedônia",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 324,
    Municipio: "magda",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 325,
    Municipio: "mairinque",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 326,
    Municipio: "mairiporã",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 327,
    Municipio: "manduri",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 328,
    Municipio: "marabá paulista",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 329,
    Municipio: "maracaí",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 330,
    Municipio: "marapoama",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 331,
    Municipio: "mariápolis",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 332,
    Municipio: "MARÍLIA",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 333,
    Municipio: "mainópolis",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 334,
    Municipio: "martinópolis",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 335,
    Municipio: "matão",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 336,
    Municipio: "maua",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 337,
    Municipio: "mendonça",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 338,
    Municipio: "meridiano",
    Codigo: "PR08",
    Regional: "S. SJOÁ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 339,
    Municipio: "mesópolis",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 340,
    Municipio: "miguelópolis",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 341,
    Municipio: "mineiros do tietê",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 342,
    Municipio: "mira estrela",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 343,
    Municipio: "miracatu",
    Codigo: "PR02",
    Regional: "SANTOS",
    Chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 344,
    Municipio: "mirandópolis",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 345,
    Municipio: "mirante do paranapanema",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 346,
    Municipio: "mirassol",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 347,
    Municipio: "mirassolândia",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO  PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 348,
    Municipio: "mococa",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 349,
    Municipio: "mogi das cruzes",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 350,
    Municipio: "mogi-guaçú",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 351,
    Municipio: "mogi-mirim",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 352,
    Municipio: "mombuca",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 353,
    Municipio: "monções",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 354,
    Municipio: "mongaguá",
    Codigo: "PR02",
    Regional: "SANTOS",
    Chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 355,
    Municipio: "monte alegre do sul",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 356,
    Municipio: "monte alto",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 357,
    Municipio: "monte aprazível",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 358,
    Municipio: "monte azul paulista",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 359,
    Municipio: "monte castelo",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 360,
    Municipio: "monte mor",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 361,
    Municipio: "monteiro lobato",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 362,
    Municipio: "morro agudo",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 363,
    Municipio: "morungaba",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 364,
    Municipio: "motuca",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 365,
    Municipio: "murutinga do sul",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 366,
    Municipio: "nantes",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 367,
    Municipio: "narandiba",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 368,
    Municipio: "natividade da serra",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 369,
    Municipio: "nazaré paulista",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 370,
    Municipio: "neves paulista",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 371,
    Municipio: "nhandeara",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 372,
    Municipio: "nipoã",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 373,
    Municipio: "nova aliança",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 374,
    Municipio: "nova campina",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 375,
    Municipio: "nova canaã paulista",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 376,
    Municipio: "nova castilho",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 377,
    Municipio: "nova europa",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 378,
    Municipio: "nova granada",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 379,
    Municipio: "nova guataporanga",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 380,
    Municipio: "nova independência",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 381,
    Municipio: "nova lusitânia",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 382,
    Municipio: "nova odessa",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 383,
    Municipio: "novais",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 384,
    Municipio: "novo horizonte",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 385,
    Municipio: "nuporanga",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 386,
    Municipio: "ocauçú",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 387,
    Municipio: "óleo",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 388,
    Municipio: "olimpia",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 389,
    Municipio: "onda verde",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 390,
    Municipio: "oriente",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 391,
    Municipio: "orindiuva",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 392,
    Municipio: "orlândia",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 393,
    Municipio: "osasco",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 394,
    Municipio: "oscar bressane",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 395,
    Municipio: "osvaldo cruz",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 396,
    Municipio: "ourinhos",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 397,
    Municipio: "ouro verde",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 398,
    Municipio: "ouroeste",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 399,
    Municipio: "pacaembu",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 400,
    Municipio: "palestina",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 401,
    Municipio: "palmares paulista",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 402,
    Municipio: "palmeira d´oeste",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 403,
    Municipio: "palmital",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 404,
    Municipio: "panorama",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 405,
    Municipio: "paraguaçu paulista",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 406,
    Municipio: "paraibuna",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 407,
    Municipio: "paraíso",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 408,
    Municipio: "paranapanema",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 409,
    Municipio: "paranapuã",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 410,
    Municipio: "parapuã",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 411,
    Municipio: "pardinho",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 412,
    Municipio: "pariquera-açu",
    Codigo: "PR02",
    Regional: "SANTOS",
    Chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 413,
    Municipio: "parisi",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 414,
    Municipio: "patrocinio paulista",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 415,
    Municipio: "paulicéia",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 416,
    Municipio: "paulínia",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 418,
    Municipio: "pederneiras",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 419,
    Municipio: "pedra bela",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 420,
    Municipio: "pedranópolis",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 421,
    Municipio: "pedregulho",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 422,
    Municipio: "pedreira",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 423,
    Municipio: "pedrinhas paulista",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 424,
    Municipio: "pedro de toledo",
    Codigo: "PR02",
    Regional: "SANTOS",
    Chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 425,
    Municipio: "penápolis",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 426,
    Municipio: "pereira barreto",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 427,
    Municipio: "pereiras",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 428,
    Municipio: "peruíbe",
    Codigo: "PR02",
    Regional: "SANTOS",
    Chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 429,
    Municipio: "piacatu",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 430,
    Municipio: "piedade",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 431,
    Municipio: "pilar do sul",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 432,
    Municipio: "pindamonhangaba",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 433,
    Municipio: "pindorama",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 434,
    Municipio: "pinhalzinho",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 435,
    Municipio: "piquerobi",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 436,
    Municipio: "piquete",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 437,
    Municipio: "piracaia",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 438,
    Municipio: "piracicaba",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 439,
    Municipio: "pirassununga",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 440,
    Municipio: "piraju",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 441,
    Municipio: "pirajuí",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 442,
    Municipio: "pirangi",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 443,
    Municipio: "pirapora do bom jesus",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 444,
    Municipio: "pirapozinho",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 445,
    Municipio: "piratininga",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 446,
    Municipio: "pitangueiras",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 447,
    Municipio: "planalto",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 448,
    Municipio: "platina",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 449,
    Municipio: "poa",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 450,
    Municipio: "poloni",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 451,
    Municipio: "pompéia",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 452,
    Municipio: "pongaí",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 453,
    Municipio: "pontal",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 454,
    Municipio: "ponta linda",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 455,
    Municipio: "pontes gestal",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 456,
    Municipio: "populina",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 457,
    Municipio: "porangaba",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 458,
    Municipio: "porto feliz",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 459,
    Municipio: "porto ferreira",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 460,
    Municipio: "potim",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 461,
    Municipio: "potirendaba",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 462,
    Municipio: "pracinha",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 463,
    Municipio: "pradópolis",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 464,
    Municipio: "praia grande",
    Codigo: "PR02",
    Regional: "SANTOS",
    Chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 465,
    Municipio: "pratania",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 466,
    Municipio: "presidente alves",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 467,
    Municipio: "presidente bernardes",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 468,
    Municipio: "presidente epitácio",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 469,
    Municipio: "presidente prudente",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 470,
    Municipio: "presidente venceslau",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 471,
    Municipio: "promisSÃO",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 472,
    Municipio: "quadra",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 473,
    Municipio: "quatá",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 474,
    Municipio: "queiroz",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 475,
    Municipio: "queluz",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 476,
    Municipio: "quintana",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 477,
    Municipio: "rafard",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 478,
    Municipio: "rancharia",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 479,
    Municipio: "redenção da serra",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 480,
    Municipio: "regente feijó",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 481,
    Municipio: "reginópolis",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 482,
    Municipio: "registro",
    Codigo: "PR02",
    Regional: "SANTOS",
    Chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 483,
    Municipio: "restinga",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 484,
    Municipio: "ribeira",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 485,
    Municipio: "ribeirão bonito",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 486,
    Municipio: "ribeirão branco",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 487,
    Municipio: "ribeirão corrente",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 488,
    Municipio: "ribeirão do sul",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 489,
    Municipio: "ribeirão dos índios",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 490,
    Municipio: "ribeirão grande",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 491,
    Municipio: "ribeirão pires",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 492,
    Municipio: "RIBEIRÃO PRETO",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 493,
    Municipio: "rifaina",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 494,
    Municipio: "rincão",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 495,
    Municipio: "rinópolis",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 496,
    Municipio: "rio claro",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 497,
    Municipio: "rio das pedras",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 498,
    Municipio: "rio grande da serra",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 499,
    Municipio: "riolândia",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 500,
    Municipio: "riversul",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 501,
    Municipio: "rosana",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 502,
    Municipio: "roseira",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 503,
    Municipio: "rubiácea",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 504,
    Municipio: "rubinéia",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 505,
    Municipio: "sabino",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 506,
    Municipio: "sagres",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 507,
    Municipio: "sales",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 508,
    Municipio: "sales de oliveira",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 509,
    Municipio: "salesópolis",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 510,
    Municipio: "salmourão",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 511,
    Municipio: "saltinho",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 512,
    Municipio: "salto",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 513,
    Municipio: "salto de pirapora",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 514,
    Municipio: "salto grande",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 515,
    Municipio: "sandovalina",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 516,
    Municipio: "santa adélia",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 517,
    Municipio: "santa albertina",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 518,
    Municipio: "santa barbara d´oeste",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 519,
    Municipio: "santa branca",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 520,
    Municipio: "santa clara d´oeste",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 521,
    Municipio: "santa cruz da conceição",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 522,
    Municipio: "santa cruz das palmeiras",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 523,
    Municipio: "santa cruz do rio pardo",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 524,
    Municipio: "santa ernestina",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 525,
    Municipio: "santa fé do sul",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 526,
    Municipio: "santa gertrudes",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 527,
    Municipio: "santa isabel",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 528,
    Municipio: "santa lúcia",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 529,
    Municipio: "santa maria da serra",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 530,
    Municipio: "santa mercedes",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 531,
    Municipio: "santa rita do passa quatro",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 532,
    Municipio: "santa rita d´oeste",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 533,
    Municipio: "santa rosa do viterbo",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 534,
    Municipio: "santa salete",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 535,
    Municipio: "santana da ponte pensa",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 536,
    Municipio: "santana do parnaíba",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 537,
    Municipio: "santo anastacio",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 538,
    Municipio: "santo andré",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 539,
    Municipio: "santo antonio da alegria",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 540,
    Municipio: "santo antonio da posse",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 541,
    Municipio: "santo antonio do aracangua",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 542,
    Municipio: "santo antonio do jardim",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 543,
    Municipio: "santo antonio do pinhal",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 544,
    Municipio: "santo expedito",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 545,
    Municipio: "santópolis do aguapeí",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 546,
    Municipio: "santos",
    Codigo: "PR02",
    Regional: "SANTOS",
    Chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 547,
    Municipio: "SÃO bento do sapucai",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 549,
    Municipio: "SÃO caetano do sul",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 550,
    Municipio: "SÃO carlos",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 551,
    Municipio: "SÃO francisco",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 552,
    Municipio: "SÃO francisco da praia",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 553,
    Municipio: "SÃO joão da boa vista",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 554,
    Municipio: "SÃO joão das duas pontes",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 555,
    Municipio: "SÃO joão de iracema",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 556,
    Municipio: "SÃO joão do pau d´alho",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 557,
    Municipio: "SÃO joaquim da barra",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 558,
    Municipio: "SÃO JOSÉ da bela vista",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 559,
    Municipio: "SÃO JOSÉ do barreiro",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 560,
    Municipio: "SÃO JOSÉ do rio pardo",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 561,
    Municipio: "SÃO JOSÉ do rio preto",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 562,
    Municipio: "SÃO JOSÉ dos campos",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 563,
    Municipio: "SÃO lourenço da serra",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 564,
    Municipio: "SÃO luiz do paraitinga",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 565,
    Municipio: "SÃO manuel",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 566,
    Municipio: "SÃO miguel arcanjo",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 567,
    Municipio: "SÃO paulo",
    Codigo: "PJ",
    Regional: "PROCURADORIA JUDICIAL",
    Chefe: "MARIA CAROLINA CARVALHO"
  },
  {
    id: 568,
    Municipio: "SÃO pedro",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 569,
    Municipio: "SÃO pedro do turvo",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 570,
    Municipio: "SÃO roque",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 571,
    Municipio: "SÃO sebastião",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 572,
    Municipio: "SÃO sebatião da grama",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 573,
    Municipio: "SÃO sebastião do patrimônio",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 574,
    Municipio: "SÃO simão",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 575,
    Municipio: "SÃO vicente",
    Codigo: "PR02",
    Regional: "SANTOS",
    Chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 576,
    Municipio: "sarapui",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 577,
    Municipio: "sarutaiá",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 578,
    Municipio: "sebastianópolis do sul",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 579,
    Municipio: "serra azul",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 580,
    Municipio: "serra negra",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 581,
    Municipio: "serrana",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 582,
    Municipio: "sertãozinho",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 583,
    Municipio: "sete barras",
    Codigo: "PR02",
    Regional: "SANTOS",
    Chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 584,
    Municipio: "severínia",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 585,
    Municipio: "silveiras",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 586,
    Municipio: "socorro",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 587,
    Municipio: "sorocaba",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 588,
    Municipio: "sud menucci",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 589,
    Municipio: "sumaré",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 590,
    Municipio: "suzano",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 591,
    Municipio: "suzanópolis",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 592,
    Municipio: "tabapuá",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 593,
    Municipio: "tabatinga",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 594,
    Municipio: "taboão da serra",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 595,
    Municipio: "taciba",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 596,
    Municipio: "taguaí",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 597,
    Municipio: "taiaçu",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 598,
    Municipio: "taiuva",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 599,
    Municipio: "tambau",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 600,
    Municipio: "tanabi",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 601,
    Municipio: "tapiraí",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 602,
    Municipio: "tapiratiba",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 603,
    Municipio: "taquaral",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 604,
    Municipio: "taquaritinga",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 605,
    Municipio: "taquarituba",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 606,
    Municipio: "taquarivaí",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 607,
    Municipio: "tarabaí",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 608,
    Municipio: "taruma",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 609,
    Municipio: "tatuí",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 610,
    Municipio: "TAUBATÉ",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 611,
    Municipio: "tejupá",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 612,
    Municipio: "teodoro sampaio",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 613,
    Municipio: "terra roxa",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 614,
    Municipio: "tietê",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 615,
    Municipio: "timburi",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 616,
    Municipio: "torre de pedra",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 617,
    Municipio: "torrinha",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 618,
    Municipio: "trabijú",
    Codigo: "PR12",
    Regional: "SÃO CARLOS",
    Chefe: "REGINA MARTA CEREDA LIMA LOUZADA"
  },
  {
    id: 619,
    Municipio: "tremembé",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 620,
    Municipio: "três fronteiras",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 621,
    Municipio: "tuiuti",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 622,
    Municipio: "tupã",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 623,
    Municipio: "tupi paulista",
    Codigo: "PR10",
    Regional: "PRESIDENTE PRUDENTE",
    Chefe: "JOSE MARIA ZANUTO"
  },
  {
    id: 624,
    Municipio: "turiuba",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 625,
    Municipio: "turmalina",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 626,
    Municipio: "ubarama",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 627,
    Municipio: "ubatuba",
    Codigo: "PR03",
    Regional: "TAUBATÉ",
    Chefe: "ROSELI SEBASTIANA RODRIGUES"
  },
  {
    id: 628,
    Municipio: "ubirajara",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 629,
    Municipio: "uchoa",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 630,
    Municipio: "união paulista",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 631,
    Municipio: "urânia",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 632,
    Municipio: "uru",
    Codigo: "PR07",
    Regional: "BAURU",
    Chefe: "SILVIO CARLOS TELLI"
  },
  {
    id: 633,
    Municipio: "urupês",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 634,
    Municipio: "valentim gentil",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 635,
    Municipio: "valinhos",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 636,
    Municipio: "valparaiso",
    Codigo: "PR09",
    Regional: "ARAÇATUBA",
    Chefe: "CLAUDIA ALVES MUNHOZ RIBEIRO DA SILVA"
  },
  {
    id: 637,
    Municipio: "vargem",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 638,
    Municipio: "vargem grande do sul",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 639,
    Municipio: "vargem grande paulista",
    Codigo: "PR01",
    Regional: "GRANDE SÃO PAULO",
    Chefe: "REBECCA CORREA PORTO DE FREITAS"
  },
  {
    id: 640,
    Municipio: "varzea paulista",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 641,
    Municipio: "vera cruz",
    Codigo: "PR11",
    Regional: "MARÍLIA",
    Chefe: "RICARDO PINHA ALONSO"
  },
  {
    id: 642,
    Municipio: "vicente de carvalho",
    Codigo: "PR02",
    Regional: "SANTOS",
    Chefe: "ADRIANA BRIENCE DA SILVA CORREA"
  },
  {
    id: 643,
    Municipio: "vinhedo",
    Codigo: "PR05",
    Regional: "CAMPINAS",
    Chefe: "GUILHERME MALAGUTI SPINA"
  },
  {
    id: 644,
    Municipio: "viradouro",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 645,
    Municipio: "vista alegre do alto",
    Codigo: "PR06",
    Regional: "RIBEIRÃO PRETO",
    Chefe: "LUCIANO ALVES ROSSATO"
  },
  {
    id: 646,
    Municipio: "vitoria brasil",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 647,
    Municipio: "votorantim",
    Codigo: "PR04",
    Regional: "SOROCABA",
    Chefe: "MARCELO BULIANI BOLZAN"
  },
  {
    id: 648,
    Municipio: "votuporanga",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  },
  {
    id: 649,
    Municipio: "zacarias",
    Codigo: "PR08",
    Regional: "SÃO JOSÉ DO RIO PRETO",
    Chefe: "OSVALDIR FRANCISCO CAETANO CASTRO"
  }
];
