@if (opcao() === "INSTITUCIONAL") {
  <div>
    <app-separador titulo="INSTITUCIONAL" />
    <!-- Item 1 - Sobre a PGE -->
    <div class="accordion-menu">
      <p class="accordion-title" tabindex="0" (click)="alternar(0)" (keypress)="alternar(0)">
        <mat-icon class="red-icon">{{ institucional[0].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ institucional[0].titulo }}
      </p>
      @if (institucional[0].aberto) {
        <div class="accordion-content">
          <app-quem-somos-pge />
        </div>
      }
    </div>
    <!-- Item 2 - Gabinete -->
    <div class="accordion-menu">
      <p class="accordion-title" tabindex="0" (click)="alternar(1)" (keypress)="alternar(1)">
        <mat-icon class="red-icon">{{ institucional[1].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ institucional[1].titulo }}
      </p>
      @if (institucional[1].aberto) {
        <div class="accordion-content">
          <div class="accordion-menu">
            <p class="accordion-title" tabindex="0" (click)="alternar(1, 0)" (keypress)="alternar(1, 0)">
              <mat-icon class="red-icon">{{ institucional[1].children?.[0]?.aberto ? "expand_less" : "expand_more" }}</mat-icon>
              {{ institucional[1].children?.[0]?.titulo }}
            </p>
            @if (institucional[1].children?.[0]?.aberto) {
              <div class="accordion-content">
                <app-composicao-gab />
              </div>
            }
          </div>
          <div class="accordion-menu">
            <p class="accordion-title" tabindex="0" (click)="alternar(1, 1)" (keypress)="alternar(1, 1)">
              <mat-icon class="red-icon">{{ institucional[1].children?.[1]?.aberto ? "expand_less" : "expand_more" }}</mat-icon>
              {{ institucional[1].children?.[1]?.titulo }}
            </p>
            @if (institucional[1].children?.[1]?.aberto) {
              <div class="accordion-content">
                <app-assessorias-gab />
              </div>
            }
          </div>
        </div>
      }
    </div>
    <!-- Item 3 - Histórico -->
    <div class="accordion-menu">
      <p class="accordion-title" tabindex="0" (click)="alternar(2)" (keypress)="alternar(2)">
        <mat-icon class="red-icon">{{ institucional[2].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ institucional[2].titulo }}
      </p>
      @if (institucional[2].aberto) {
        <div class="accordion-content">
          <app-historico />
        </div>
      }
    </div>
    <!-- Item 4 - Ex-Procuradores Gerais -->
    <div class="accordion-menu">
      <p class="accordion-title" tabindex="0" (click)="alternar(3)" (keypress)="alternar(3)">
        <mat-icon class="red-icon">{{ institucional[3].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ institucional[3].titulo }}
      </p>
      @if (institucional[3].aberto) {
        <div class="accordion-content">
          <app-ex-procuradores-gerais />
        </div>
      }
    </div>
    <!-- Item 5 - Organograma -->
    <div class="accordion-menu">
      <p class="accordion-title" tabindex="0" (click)="alternar(4)" (keypress)="alternar(4)">
        <mat-icon class="red-icon">{{ institucional[4].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ institucional[4].titulo }}
      </p>
      @if (institucional[4].aberto) {
        <div class="accordion-content">
          <app-separador titulo="ORGANOGRAMA" />
          <br />
          <a href="pdf/organograma_pge_2024.pdf" target="_blank">Organograma</a>
        </div>
      }
    </div>
    <!-- Item 6 - Lei Orgânica -->
    <div class="accordion-menu">
      <p class="accordion-title" tabindex="0" (click)="alternar(5)" (keypress)="alternar(5)">
        <mat-icon class="red-icon">{{ institucional[5].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ institucional[5].titulo }}
      </p>
      @if (institucional[5].aberto) {
        <div class="accordion-content">
          <app-separador titulo="LEI ORGÂNICA" />
          <br />
          <a href="pdf/lei_organica_pge_revista_e_atualizao_lc_1082_08_1.pdf" target="_blank">Lei Orgânica</a>
        </div>
      }
    </div>
    <!-- Item 7 - Legislação -->
    <div class="accordion-menu">
      <p class="accordion-title" tabindex="0" (click)="alternar(6)" (keypress)="alternar(6)">
        <mat-icon class="red-icon">{{ institucional[6].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ institucional[6].titulo }}
      </p>
      @if (institucional[6].aberto) {
        <div class="accordion-content">
          <app-separador titulo="LEGISLAÇÃO" />
          <br />
          <a href="https://www.pge.sp.gov.br/editais/atosnormativos/index.aspx" target="_blank">Legislação</a>
          @if (ambiente === "loc" || ambiente === "dev" || ambiente === "hml") {
            <div>
              <br />
              <a [routerLink]="['/institucional/legislacao-pesquisa']">Legislação Novo</a>
            </div>
          }
        </div>
      }
    </div>
  </div>
}
@if (opcao() === "AREASATUACAO") {
  <div>
    <app-separador titulo="ÁREAS DE ATUAÇÃO" />
    <!-- Item 1 - Consultoria Geral -->
    <div class="accordion-menu">
      <p class="accordion-title" tabindex="0" (click)="alternar(0)" (keypress)="alternar(0)">
        <mat-icon class="red-icon">{{ areaAtuacao[0].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ areaAtuacao[0].titulo }}
      </p>
      @if (areaAtuacao[0].aberto) {
        <div class="accordion-content">
          <!-- SubItem 1 - Subprocuradoria -->
          <div class="accordion-menu">
            <p class="accordion-title" tabindex="0" (click)="alternar(0, 0)" (keypress)="alternar(0, 0)">
              <mat-icon class="red-icon">{{ areaAtuacao[0].children[0].aberto ? "expand_less" : "expand_more" }}</mat-icon>
              {{ areaAtuacao[0].children[0].titulo }}
            </p>
            @if (areaAtuacao[0].children[0].aberto) {
              <div class="accordion-content">
                <app-subprocuradoria-consultoria-geral />
              </div>
            }
          </div>
          <!-- SubItem 2 - Especializadas -->
          <div class="accordion-menu">
            <p class="accordion-title" tabindex="0" (click)="alternar(0, 1)" (keypress)="alternar(0, 1)">
              <mat-icon class="red-icon">{{ areaAtuacao[0].children[1].aberto ? "expand_less" : "expand_more" }}</mat-icon>
              {{ areaAtuacao[0].children[1].titulo }}
            </p>
            @if (areaAtuacao[0].children[1].aberto) {
              <div class="accordion-content">
                <app-especializadas-consultoria-geral />
              </div>
            }
          </div>
          <!-- SubItem 3 - Scretarias -->
          <div class="accordion-menu">
            <p class="accordion-title" tabindex="0" (click)="alternar(0, 2)" (keypress)="alternar(0, 2)">
              <mat-icon class="red-icon">{{ areaAtuacao[0].children[2].aberto ? "expand_less" : "expand_more" }}</mat-icon>
              {{ areaAtuacao[0].children[2].titulo }}
            </p>
            @if (areaAtuacao[0].children[2].aberto) {
              <div class="accordion-content">
                <app-consultorias />
              </div>
            }
          </div>
          <!-- SubItem 4 - Autarquias -->
          <div class="accordion-menu">
            <p class="accordion-title" tabindex="0" (click)="alternar(0, 3)" (keypress)="alternar(0, 3)">
              <mat-icon class="red-icon">{{ areaAtuacao[0].children[3].aberto ? "expand_less" : "expand_more" }}</mat-icon>
              {{ areaAtuacao[0].children[3].titulo }}
            </p>
            @if (areaAtuacao[0].children[3].aberto) {
              <div class="accordion-content">
                <app-autarquias />
              </div>
            }
          </div>
        </div>
      }
    </div>
    <!-- Item 2 - Contencioso Geral -->
    <div class="accordion-menu">
      <p class="accordion-title" tabindex="0" (click)="alternar(1)" (keypress)="alternar(1)">
        <mat-icon class="red-icon">{{ areaAtuacao[1].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ areaAtuacao[1].titulo }}
      </p>
      @if (areaAtuacao[1].aberto) {
        <div class="accordion-content">
          <!-- SubItem 1 - Subprocuradoria -->
          <div class="accordion-menu">
            <p class="accordion-title" tabindex="0" (click)="alternar(1, 0)" (keypress)="alternar(1, 0)">
              <mat-icon class="red-icon">{{ areaAtuacao[1].children[0].aberto ? "expand_less" : "expand_more" }}</mat-icon>
              {{ areaAtuacao[1].children[0].titulo }}
            </p>
            @if (areaAtuacao[1].children[0].aberto) {
              <div class="accordion-content">
                <app-subprocuradoria-contencioso-geral />
              </div>
            }
          </div>
          <!-- SubItem 2 - Unidades Especializadas -->
          <div class="accordion-menu">
            <p class="accordion-title" tabindex="0" (click)="alternar(1, 1)" (keypress)="alternar(1, 1)">
              <mat-icon class="red-icon">{{ areaAtuacao[1].children[1].aberto ? "expand_less" : "expand_more" }}</mat-icon>
              {{ areaAtuacao[1].children[1].titulo }}
            </p>
            @if (areaAtuacao[1].children[1].aberto) {
              <div class="accordion-content">
                <app-especializadas-contencioso-geral />
              </div>
            }
          </div>
          <!-- SubItem 3 - Regionais -->
          <div class="accordion-menu">
            <p class="accordion-title" tabindex="0" (click)="alternar(1, 2)" (keypress)="alternar(1, 2)">
              <mat-icon class="red-icon">{{ areaAtuacao[1].children[2].aberto ? "expand_less" : "expand_more" }}</mat-icon>
              {{ areaAtuacao[1].children[2].titulo }}
            </p>
            @if (areaAtuacao[1].children[2].aberto) {
              <div class="accordion-content">
                <app-regionais-contencioso-geral />
              </div>
            }
          </div>
        </div>
      }
    </div>
    <!-- Item 3 - Tributário Fiscal -->
    <div class="accordion-menu">
      <p class="accordion-title" tabindex="0" (click)="alternar(2)" (keypress)="alternar(2)">
        <mat-icon class="red-icon">{{ areaAtuacao[2].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ areaAtuacao[2].titulo }}
      </p>
      @if (areaAtuacao[2].aberto) {
        <div class="accordion-content">
          <!-- SubItem 1 - Subprocuradoria -->
          <div class="accordion-menu">
            <p class="accordion-title" tabindex="0" (click)="alternar(2, 0)" (keypress)="alternar(2, 0)">
              <mat-icon class="red-icon">{{ areaAtuacao[2].children[0].aberto ? "expand_less" : "expand_more" }}</mat-icon>
              {{ areaAtuacao[2].children[0].titulo }}
            </p>
            @if (areaAtuacao[2].children[0].aberto) {
              <div class="accordion-content">
                <app-subprocuradoria-tributario-fiscal />
              </div>
            }
          </div>
          <!-- SubItem 2 - Especializadas -->
          <div class="accordion-menu">
            <p class="accordion-title" tabindex="0" (click)="alternar(2, 1)" (keypress)="alternar(2, 1)">
              <mat-icon class="red-icon">{{ areaAtuacao[2].children[1].aberto ? "expand_less" : "expand_more" }}</mat-icon>
              {{ areaAtuacao[2].children[1].titulo }}
            </p>
            @if (areaAtuacao[2].children[1].aberto) {
              <div class="accordion-content">
                <app-especializadas-tributario-fiscal />
              </div>
            }
          </div>
          <!-- SubItem 3 - Regionais -->
          <div class="accordion-menu">
            <p class="accordion-title" tabindex="0" (click)="alternar(2, 2)" (keypress)="alternar(2, 2)">
              <mat-icon class="red-icon">{{ areaAtuacao[2].children[2].aberto ? "expand_less" : "expand_more" }}</mat-icon>
              {{ areaAtuacao[2].children[2].titulo }}
            </p>
            @if (areaAtuacao[2].children[2].aberto) {
              <div class="accordion-content">
                <app-regionais-tributario-fiscal />
              </div>
            }
          </div>
        </div>
      }
    </div>
  </div>
}

@if (opcao() === "CORREGEDORIA") {
  <div>
    <app-separador titulo="CORREGEDORIA" />
    <!-- Item 1 - Apresentação de Composição-->
    <div class="accordion-menu">
      <p class="accordion-title" tabindex="0" (click)="alternar(0)" (keypress)="alternar(0)">
        <mat-icon class="red-icon">{{ corregedoria[0].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ corregedoria[0].titulo }}
      </p>
      @if (corregedoria[0].aberto) {
        <div class="accordion-content">
          <app-apresentacao-composicao />
        </div>
      }
    </div>
    <!-- Item 2 - Ex-corregedores Gerais -->
    <div class="accordion-menu">
      <p class="accordion-title" tabindex="0" (click)="alternar(1)" (keypress)="alternar(1)">
        <mat-icon class="red-icon">{{ corregedoria[1].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ corregedoria[1].titulo }}
      </p>
      @if (corregedoria[1].aberto) {
        <div class="accordion-content">
          <app-ex-corregedores-gerais-pge />
        </div>
      }
    </div>
    <!-- Item 3 - Legislação -->
    <div class="accordion-menu">
      <p class="accordion-title" tabindex="0" (click)="alternar(2)" (keypress)="alternar(2)">
        <mat-icon class="red-icon">{{ corregedoria[2].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ corregedoria[2].titulo }}
      </p>
      @if (corregedoria[2].aberto) {
        <div class="accordion-content">
          <app-legislacao-corregedoria />
        </div>
      }
    </div>
  </div>
}

@if (opcao() === "CONSELHO") {
  <div>
    <app-separador titulo="CONSELHO" />
    <!-- Item 1 - Apresentação -->
    <div class="accordion-menu">
      <p class="accordion-title" tabindex="0" (click)="alternar(0)" (keypress)="alternar(0)">
        <mat-icon class="red-icon">{{ conselho[0].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ conselho[0].titulo }}
      </p>
      @if (conselho[0].aberto) {
        <div class="accordion-content">
          <app-apresentacao />
        </div>
      }
    </div>
    <!-- Item 2 - Composição -->
    <div class="accordion-menu">
      <p class="accordion-title" tabindex="0" (click)="alternar(1)" (keypress)="alternar(1)">
        <mat-icon class="red-icon">{{ conselho[1].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ conselho[1].titulo }}
      </p>
      @if (conselho[1].aberto) {
        <div class="accordion-content">
          <app-composicao-conselho />
        </div>
      }
    </div>
    <!-- Item 3 - Atribuições -->
    <div class="accordion-menu">
      <p class="accordion-title" tabindex="0" (click)="alternar(2)" (keypress)="alternar(2)">
        <mat-icon class="red-icon">{{ conselho[2].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ conselho[2].titulo }}
      </p>
      @if (conselho[2].aberto) {
        <div class="accordion-content">
          <app-atribuicoes />
        </div>
      }
    </div>
    <!-- Item 4 - Legislação -->
    <div class="accordion-menu">
      <p class="accordion-title" tabindex="0" (click)="alternar(3)" (keypress)="alternar(3)">
        <mat-icon class="red-icon">{{ conselho[3].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ conselho[3].titulo }}
      </p>
      @if (conselho[3].aberto) {
        <div class="accordion-content">
          <app-legislacao-conselho />
        </div>
      }
    </div>
  </div>
}

@if (opcao() === "OUVIDORIA") {
  <div>
    <app-separador titulo="OUVIDORIA" />
    <!-- Item 1 - Sobre a Ouvidoria -->
    <div class="accordion-menu">
      <p class="accordion-title" tabindex="0" (click)="alternar(0)" (keypress)="alternar(0)">
        <mat-icon class="red-icon">{{ ouvidoria[0].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ ouvidoria[0].titulo }}
      </p>
      @if (ouvidoria[0].aberto) {
        <div class="accordion-content">
          <app-sobre-ouvidoria />
        </div>
      }
    </div>
    <!-- Item 2 - Atendimento -->
    <div class="accordion-menu">
      <p class="accordion-title" tabindex="0" (click)="alternar(1)" (keypress)="alternar(1)">
        <mat-icon class="red-icon">{{ ouvidoria[1].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ ouvidoria[1].titulo }}
      </p>
      @if (ouvidoria[1].aberto) {
        <div class="accordion-content">
          <app-atendimento-ouvidoria />
        </div>
      }
    </div>

    <!-- Item 3 - Ex-Ouvidorias -->
    <div class="accordion-menu">
      <p class="accordion-title" tabindex="0" (click)="alternar(2)" (keypress)="alternar(2)">
        <mat-icon class="red-icon">{{ ouvidoria[2].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ ouvidoria[2].titulo }}
      </p>
      @if (ouvidoria[2].aberto) {
        <div class="accordion-content">
          <app-ex-ouvidoria />
        </div>
      }
    </div>

    <!-- Item 4 - Relatórios -->
    <div class="accordion-menu">
      <p class="accordion-title" tabindex="0" (click)="alternar(3)" (keypress)="alternar(3)">
        <mat-icon class="red-icon">{{ ouvidoria[3].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ ouvidoria[3].titulo }}
      </p>
      @if (ouvidoria[3].aberto) {
        <div class="accordion-content">
          <app-relatorio-ouvidoria />
        </div>
      }
    </div>

    <!-- Item 5 - Informações classificadas e desclassificadas -->
    <div class="accordion-menu">
      <p class="accordion-title" tabindex="0" (click)="alternar(4)" (keypress)="alternar(4)">
        <mat-icon class="red-icon">{{ ouvidoria[4].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ ouvidoria[4].titulo }}
      </p>
      @if (ouvidoria[4].aberto) {
        <div class="accordion-content">
          <app-inform-ouvidoria />
        </div>
      }
    </div>

    <!-- Item 6 - Programa de Integridade/PGE -->
    <div class="accordion-menu">
      <p class="accordion-title" tabindex="0" (click)="alternar(5)" (keypress)="alternar(5)">
        <mat-icon class="red-icon">{{ ouvidoria[5].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ ouvidoria[5].titulo }}
      </p>
      @if (ouvidoria[5].aberto) {
        <div class="accordion-content">
          <app-programa-integridade-ouvidoria />
        </div>
      }
    </div>

    <!-- Item 7 - SIC -->
    <div class="accordion-menu">
      <p class="accordion-title" tabindex="0" (click)="alternar(6)" (keypress)="alternar(6)">
        <mat-icon class="red-icon">{{ ouvidoria[6].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ ouvidoria[6].titulo }}
      </p>
      @if (ouvidoria[6].aberto) {
        <div class="accordion-content">
          <app-sic-ouvidoria />
        </div>
      }
    </div>
    <!-- Item 8 - Consulta de Sanções -->
    <div class="accordion-menu">
      <p class="accordion-title" tabindex="0" (click)="alternar(7)" (keypress)="alternar(7)">
        <mat-icon class="red-icon">{{ ouvidoria[7].aberto ? "expand_less" : "expand_more" }}</mat-icon>
        {{ ouvidoria[7].titulo }}
      </p>
      @if (ouvidoria[7].aberto) {
        <div class="accordion-content">
          <app-separador titulo="Consulta de Sanções" />
          <br />
          <a target="_blank" routerLink="/ouvidoria/sancoes">Consulta de Sanções</a>
        </div>
      }
    </div>
  </div>
}
