import type { ItemAcessoRapido } from './link-acesso-rapido.model';

export const AcessoRapido: ItemAcessoRapido[] = [
  {
    redirecionar: 'programa-estagio',
    imagem: 'images/acesso-rapido/programa-de-estagio-pge-sp.jpg',
    alt: 'Estágio e Residência Jurídica'
  },
  {
    redirecionar: 'planejamento-estrategico',
    imagem: 'images/acesso-rapido/planejamento-estrategico-pge.jpg',
    alt: 'Planejamento Estratégico PGE'
  },
  {
    redirecionar: 'https://www.dividaativa.pge.sp.gov.br/transacao/pages/home/home.jsf',
    imagem: 'images/acesso-rapido/programa-acordo-paulista.jpg',
    alt: 'Programa Acordo Paulista'
  },
  {
    redirecionar: 'procurando-saber',
    imagem: 'images/acesso-rapido/pge-nas-escolas.jpg',
    alt: 'Procurando Saber'
  },
  {
    redirecionar: 'http://www.ouvidoriageral.sp.gov.br/foccosp/index.html',
    imagem: 'images/acesso-rapido/denuncia-patrimonial.png',
    alt: 'Denúncia Patrimonial'
  },
  {
    redirecionar: 'minutas-padronizadas',
    imagem: 'images/acesso-rapido/minutas-padronizadas.jpg',
    alt: 'Canal da PGE'
  },
  {
    redirecionar: 'menu/OUVIDORIA',
    imagem: 'images/acesso-rapido/ouvidoria.jpg',
    alt: 'Ouvidoria, Integridade e Transparência'
  },
  {
    redirecionar: 'informacao-cidadao/enderecos-telefones-uteis',
    imagem: 'images/acesso-rapido/enderecos-e-telefones.jpg',
    alt: 'Enderecos e Telefones'
  }
];
