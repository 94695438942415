import type { HttpErrorResponse } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import type { Observable } from 'rxjs';
import { catchError, throwError } from 'rxjs';
import { Globais } from 'src/app/area-restrita/shared/globais/globais';
import type { Usuario } from '../../../auxilio/saude/shared/models/auxilio-saude';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  private readonly http = inject(HttpClient);

  idUsuario = 0;
  constructor() {
    this.idUsuario = Number(localStorage.getItem('idUsuario'));
  }

  obterDadosUsuario(idIusuario: number): Observable<Usuario> {
    const token = new String(localStorage.getItem('token')).toString();
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: token
    });
    return this.http.get<Usuario>(`${Globais.urlToken}site-pge/api/usuarios/${idIusuario}`, { headers: headers }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(() => error);
      })
    );
  }
}
