@if (conteudoCartao().linkExterno) {
  <a target="_blank" class="nav-link" [href]="conteudoCartao().url">
    <mat-card class="carousel-card">
      <mat-card-header class="carousel-card-header">
        <img [src]="caminhoSGV" [alt]="conteudoCartao().titulo" />
      </mat-card-header>
      <mat-card-content class="carousel-card-body">
        <span>{{ conteudoCartao().titulo }}</span>
      </mat-card-content>
      <mat-card-footer />
    </mat-card>
  </a>
}

@if (!conteudoCartao().linkExterno) {
  <a class="nav-link" target="_blank" [routerLink]="conteudoCartao().url">
    <mat-card class="carousel-card">
      <mat-card-header class="carousel-card-header">
        <img [src]="caminhoSGV" [alt]="conteudoCartao().titulo" />
      </mat-card-header>
      <mat-card-content class="carousel-card-body">
        <span>{{ conteudoCartao().titulo }}</span>
      </mat-card-content>
      <mat-card-footer />
    </mat-card>
  </a>
}
