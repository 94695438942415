<div class="container-planejamento-estrategico">
  <app-separador titulo="Planejamento estratégico" />
  <div class="divisor">
    <p>
      O Planejamento Estratégico da Procuradoria Geral do Estado de São Paulo (PGE-SP), ciclo 2023-2026, é o produto de um processo colaborativo, estabelecendo os direcionadores e objetivos
      estratégicos pautados nos compromissos presentes e futuros. O desenvolvimento de todo o processo contou com a participação de diversos atores internos e externos, além da parceria com a
      Comunitas e assessoramento técnico e metodológico do Instituto Publix.
    </p>
  </div>
  <div class="divisor">
    <p>
      Dado o conjunto de atribuições essenciais desempenhadas pela Instituição, o PE busca traduzir a identidade e o posicionamento da Procuradoria, estabelecendo o propósito, missão, visão e valores,
      assim como os objetivos e indicadores estratégicos que guiarão sua atuação, considerando a necessidade de realizar a defesa do estado, em favor da sociedade. 
    </p>
  </div>
  <div class="divisor">
    <p>
      Na busca pela maior entrega de valor à sociedade, o Plano foi elaborado sob o paradigma da gestão estratégica ágil para resultados. A partir dessa abordagem incremental e interativa, este
      documento se materializa como um importante e reconhecido pilar norteador de ações estratégicas. 
    </p>
  </div>
  <div class="divisor">
    <p>
      O Plano parte do pressuposto fundamental que aponta para a necessidade de instituir uma agenda estratégica baseada no comprometimento, qualidade, sustentabilidade e efetividade. Esses aspectos
      balizaram todo o processo de construção, fundamentado num amplo diagnóstico situacional, que permitiu ouvir procuradores, servidores e colaboradores da Procuradoria, além de representantes de
      outros órgãos governamentais. 
    </p>
  </div>
  <div class="divisor">
    <p>
      A coleta desse insumo foi essencial para delimitar os princípios importantes do planejamento, que tem entre seus atributos a clareza, a simplicidade, a robustez metodológica e a potência
      comunicativa. 
    </p>
  </div>
  <div class="divisor">
    <p>
      Tais características estão refletidas em todo o Plano e norteiam, principalmente, o conjunto de 6 objetivos estratégicos estabelecidos para orientar a atuação e reforçar o compromisso firmado
      pela Procuradoria de “exercer a advocacia do Estado de São Paulo com excelência, garantindo a concretização de políticas públicas com segurança jurídica, em benefício da sociedade”. É com essa
      postura que a Procuradoria consolida e disponibiliza o seu Planejamento Estratégico para a sociedade.
    </p>
    <img class="img-mapa-estrategico" src="images/mapa-estrategico.png" alt="Imagem do mapa estratégico" />
    <p><a href="https://pgesp.institutopublix.com.br/wp-content/uploads/2024/01/PGE-SP_Caderno_da_Estrategia-2_vFinal.pdf">Clique aqui para visualizar o conteúdo na íntegra</a></p>
  </div>
</div>
