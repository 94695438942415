import type { MenuNode } from './MenuNode';

export const menuPgeHome: MenuNode = {
  id: 0,
  order: 0,
  description: 'Home - Início da página',
  name: 'Home',
  router: 'home-site-pge',
  url: 'S',
  children: [],
  materialIcon: 'home'
};
