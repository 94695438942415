import { Component } from '@angular/core';
import { SeparadorComponent } from '../../core/layout/separador/separador.component';

@Component({
  selector: 'app-planejamento-estrategico',
  templateUrl: './planejamento-estrategico.component.html',
  styleUrl: './planejamento-estrategico.component.scss',
  standalone: true,

  imports: [SeparadorComponent]
})
export class PlanejamentoEstrategicoComponent {}
