<div class="container-noticias">
  <br />
  <h1 class="titulo">Cadastrar Notícia</h1>
  <br />
  <div class="container-form-grid">
    <form [formGroup]="dadosFormulario" (ngSubmit)="consultar()">
      <div class="d-flex row">
        <div class="col-md-6">
          <label for="titulo" class="form-label">Título:</label>
          <mat-form-field class="mat-form-control">
            <input id="titulo" matInput type="text" formControlName="titulo" />
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <label for="status" class="form-label">Status:</label>
          <mat-form-field>
            <mat-select id="status" formControlName="ativo" class="mat-form-control">
              <mat-option [value]="0">Selecione</mat-option>
              <mat-option [value]="true">Publicada</mat-option>
              <mat-option [value]="false">Desativada</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <label for="dataPublicacao" class="form-label">Data de Publicação:</label>
          <mat-form-field class="mat-form-control">
            <input id="dataPublicacao" matInput formControlName="dataPublicacao" [matDatepicker]="picker" />
            <mat-datepicker-toggle matIconSuffix [for]="picker" />
            <mat-datepicker #picker />
          </mat-form-field>
        </div>
      </div>
      <div class="d-flex row">
        <div class="col-md-3">
          <label for="idAcesso" class="form-label">Tipo:</label>
          <mat-form-field>
            <mat-select id="idAcesso" formControlName="idAcesso" class="mat-form-control">
              <mat-option [value]="0">Selecione</mat-option>
              <mat-option [value]="1">Geral</mat-option>
              <mat-option [value]="2">Procurador</mat-option>
              <mat-option [value]="3">Servidor</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <label for="destaque" class="form-label">Destaque:</label>
          <mat-form-field>
            <mat-select id="destaque" formControlName="destaque" class="mat-form-control">
              <mat-option [value]="0">Selecione</mat-option>
              <mat-option [value]="1">1</mat-option>
              <mat-option [value]="2">2</mat-option>
              <mat-option [value]="3">3</mat-option>
              <mat-option [value]="4">4</mat-option>
              <mat-option [value]="5">5</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-3">
          <label for="categoria" class="form-label">Categoria:</label>
          <mat-form-field class="mat-form-control">
            <mat-select id="categoria" formControlName="categoria">
              @for (item of objetoCategoria; track item) {
                <mat-option [value]="item.value">
                  {{ item.nome }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <br />
      <div class="campos-direita">
        <button mat-flat-button class="botao" type="button" (click)="criarNoticia()">Criar</button>
        <button mat-flat-button type="submit" class="botao">Buscar</button>
        <button type="button" class="botao-limpar" (click)="limparFormulario()">Limpar filtros</button>
      </div>
    </form>
    <br />
    @if (noticias()) {
      <div>
        <div class="tabela-responsiva">
          <table class="tabela-padrao">
            <thead>
              <tr>
                <th class="titulo">Titulo</th>
                <th class="titulo">Status</th>
                <th class="titulo">Categoria</th>
                <th class="titulo">Data de Publicação</th>
                <th class="titulo"></th>
              </tr>
            </thead>
            <tbody>
              @for (noticia of noticias(); track noticia.id) {
                <tr>
                  <td>{{ noticia.titulo }}</td>
                  <td [ngClass]="{ ativo: noticia.ativo, inativo: !noticia.ativo }">
                    {{ noticia.ativo ? "Publicada" : "Desativada" }}
                  </td>
                  <td>{{ noticia.categoria.nome }}</td>
                  <td>{{ noticia.dataPublicacao }}</td>
                  <td>
                    @if (noticia.ativo) {
                      <mat-icon fontIcon="visibility_off" matTooltip="Desativar" matTooltipPosition="above" (click)="alternarAtivo(noticia.id, false)" />
                    }
                    @if (!noticia.ativo) {
                      <mat-icon fontIcon="visibility" matTooltip="Ativar" matTooltipPosition="above" (click)="alternarAtivo(noticia.id, true)" />
                    }
                    <mat-icon fontIcon="preview" matTooltip="Visualizar" matTooltipPosition="above" (click)="visualizarNoticia(noticia.id)" />
                    <mat-icon fontIcon="edit" matTooltip="Editar" matTooltipPosition="above" (click)="editarNoticia(noticia.id)" />
                    <mat-icon fontIcon="delete" matTooltip="Apagar" matTooltipPosition="above" (click)="abrirModal(noticia.id)" />
                  </td>
                </tr>
              }
            </tbody>
          </table>
          @if (!noticias() || noticias().length === 0) {
            <div>
              <p class="text-center">Nenhum registro encontrado.</p>
            </div>
          }
        </div>
        <mat-paginator
          #paginator
          class="paginacao"
          aria-label="Select page"
          showFirstLastButtons
          [length]="comprimento"
          [pageSize]="tamanhoDaPagina"
          [pageSizeOptions]="opcoesDoTamanhoDaPagina"
          [pageIndex]="indiceDaPagina"
          (page)="lidarEventoDaPagina($event)"
        />
        <br />
      </div>
    }
  </div>
</div>
