import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FichaAuxilioSaudeComponent } from '../ficha-auxilio-saude/ficha-auxilio-saude.component';

@Component({
  selector: 'app-ficha-analise-inscricao',
  templateUrl: './ficha-analise-inscricao.component.html',
  styleUrl: './ficha-analise-inscricao.component.scss',
  standalone: true,
  imports: [FichaAuxilioSaudeComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FichaAnaliseInscricaoComponent {}
