import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatCard } from '@angular/material/card';
import { acessoRapido } from './acesso-rapido';
import type { ItemAcessoRapido } from './link-acesso-rapido.model';

@Component({
  selector: 'app-acesso-rapido',
  templateUrl: './acesso-rapido.component.html',
  styleUrl: './acesso-rapido.component.scss',
  standalone: true,
  imports: [MatCard],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AcessoRapidoComponent {
  readonly items: ItemAcessoRapido[] = acessoRapido;
}
