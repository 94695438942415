import type { FormControl } from '@angular/forms';

export default function dataNascimentoValidator(control?: FormControl): Record<string, boolean> | null {
  if (control?.value != null) {
    const anoNascimento = String(control.value).substring(0, 4) as unknown;
    const anoAtual = new Date().getFullYear();

    if (String(control.value).length < 10 || Number(anoNascimento) < 1900 || Number(anoNascimento) > anoAtual) {
      return { dataNascimentoInvalida: true };
    }
  }
  return null;
}
