import type { FormControl } from '@angular/forms';
import { ConversorValidadorService } from 'src/app/area-restrita/shared/services/conversor-validador.service';

export default function verificaCpf(control: FormControl): Record<string, boolean> | null {
  const validar = new ConversorValidadorService();
  const cpf = String(control.value).replace(/[^\d]+/g, '');
  if (validar.validarCPF(cpf)) {
    return { cpfInvalido: true };
  }
  return null;
}
