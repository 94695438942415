import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Subject } from 'rxjs';
import { LocalStorageService } from 'src/app/area-restrita/shared/services/local-storage.service';
import { Globais } from '../../../shared/globais/globais';
import { menuPgeFim } from './menu-pge-fim';
import { menuPgeHome } from './menu-pge-home';
import { menuPgeLegado } from './menu-pge-legado';
import type { MenuNode } from './MenuNode';

@Injectable({
  providedIn: 'root'
})
export class MenuSguService {
  private readonly http = inject(HttpClient);
  private readonly localS = inject(LocalStorageService);

  private readonly menuDataSubject = new BehaviorSubject<MenuNode[]>([]);
  public menuData$ = this.menuDataSubject.asObservable();
  private menuUser: MenuNode[] = [];
  private version: number = 0;
  private readonly menuItemclick = new Subject<MenuNode>();

  public getMenu(): MenuNode[] {
    const idUsuario = this.localS.get('idUsuario');
    void this.getMenus(idUsuario);
    return this.menuUser;
  }

  private async getMenus(idUsuario: string): Promise<void> {
    this.version++;
    this.menuUser = [];

    this.menuUser.push(menuPgeHome);
    this.menuUser.push(menuPgeLegado);

    const params = new HttpParams().set('idUsuario', idUsuario);
    const response = await firstValueFrom(this.http.get<MenuNode[]>(`${Globais.urlToken}site-pge/api/menus`, { params: params }));

    for (const itemMenu of response) this.menuUser.push(itemMenu);
    this.menuUser.push(menuPgeFim);
    this.menuDataSubject.next(this.menuUser);
  }

  public emitMenuItemclicado$ = this.menuItemclick.asObservable();

  public emitMenuItemclicado(item: MenuNode): void {
    this.menuItemclick.next(item);
  }
}
