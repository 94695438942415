import { environment } from 'src/environments/environment';
import packageJson from '../../../../../package.json';

export class Globais {
  public static urlTokenMaster: string = 'http://10.199.113.137/';
  public static urlTokenLocal: string = 'http://localhost:8081/';
  public static versionFront: string = packageJson.buildDate;
  public static environmentRun: string = packageJson.environmentRun.toLowerCase();
  public static urlToken: string = environment.apiUrl.replace('/site-pge/api/', '/').toLocaleLowerCase(); //chamada com Arthur e Fabiano para ajustar a urlToken.
  public static idUsuario: number = 0;
  public static tokenBearer: string = '';
  public static isLogin: boolean = false;
}
