import type { OnInit } from '@angular/core';
import { Component, inject } from '@angular/core';
import { WebServiceInfosService } from 'src/app/shared/services/infos-webservice.service';
import { SeparadorComponent } from '../../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-especializadas-tributario-fiscal',
  templateUrl: './especializadas-tributario-fiscal.component.html',
  standalone: true,

  imports: [SeparadorComponent]
})
export class EspecializadasTributarioFiscalComponent implements OnInit {
  private readonly service = inject(WebServiceInfosService);

  informacoes: string[] = [];
  codigoUnidades: number[] = [
    40210500000091, 40210100000091, 40210100000081, 40210101000091, 40210101010091, 40210102010091, 40210103010091, 40210104010091, 40210105010091,
    40210106010091, 40210102000091, 40210102010091, 40210202010091, 40210103000091, 40210103010091, 40210103020091, 40210104000091, 40210105000091,
    40210105010091, 40210105020091, 40210105030091, 40210105040091, 40210105050091, 40210105060091, 40210105070091, 40210105080091, 40210105090091,
    40210106000091, 40210106010091, 40210106020091, 40210107000091, 40210107010091, 40210107020091, 40210107030091, 40210107040091, 40210107050091,
    40210107060091, 40210107070091, 40210107080091, 40210107090091, 40210107090011, 40210107130091, 40210100000011, 40210101010011, 40210102010011,
    40210103010011, 40210104010011, 40210105010011, 40210106010011, 40210103000011, 40210105000011, 40210107130011, 40210104010011, 40210101010011,
    40210102010011, 40210103010011, 40210105010011, 40210107010011, 40210104020011, 40210101020011, 40210102020011, 40210103020011, 40210105020011,
    40210107020011, 40210106020011, 40210101030011, 40210105030011, 40210107030011, 40210101040011, 40210105040011, 40210107040011, 40210101050011,
    40210105050011, 40210107050011, 40210107060011, 40210101060011, 40210105060011, 40210107070011, 40210105070011, 40210107080011, 40210105080011,
    40210107090011, 40210105090011
  ];

  ngOnInit(): void {
    this.carregarConteudo();
  }

  carregarConteudo() {
    const elementoVetor: string[] = [];
    this.codigoUnidades.forEach((codigo) => {
      this.service.getInfosPorCodigo(codigo).subscribe(
        (response) => {
          const parser = new DOMParser();
          const xml = parser.parseFromString(response, 'text/xml');
          xml.querySelectorAll('NOME_PROCURADOR, NOME_SUBSTITUTO, NOME_SUBSTITUTO2').forEach((element) => {
            if (element.textContent !== '') {
              elementoVetor.push(element.textContent.trim());
            }
          });
        },
        (error) => {
          console.log(error);
        }
      );
    });

    this.informacoes = elementoVetor;
  }

  encontrarNome(nome: string): string {
    const result = this.informacoes.find((element) => element === nome);
    return result ? result : 'Nome não encontrado';
  }
}
