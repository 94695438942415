import type { Routes } from '@angular/router';
import { ComunicarErroComponent } from './area-aberta/features/comunicar-erro/comunicar-erro.component';
import { HomeAreaAbertaComponent } from './area-aberta/features/home/home-area-aberta.component';
import { NoticiasDiversasComponent } from './area-aberta/features/home/noticias/noticias-diversas/noticias-diversas.component';
import { UmaNoticiaComponent } from './area-aberta/features/home/noticias/uma-noticia/uma-noticia.component';
import { AtendimentoProtestosIcmsIpvaItcmdCertidaoNegativaComponent } from './area-aberta/features/informacao-cidadao/atendimento-protestos-icms-ipva-itcmd-certidao-negativa/atendimento-protestos-icms-ipva-itcmd-certidao-negativa.component';
import { CertidaoNegativaComponent } from './area-aberta/features/informacao-cidadao/certidao-negativa/certidao-negativa.component';
import { ComunicadosComponent } from './area-aberta/features/informacao-cidadao/comunicados/comunicados.component';
import { CredenciamentoContadoresComponent } from './area-aberta/features/informacao-cidadao/credenciamento-contadores/credenciamento-contadores.component';
import { NoticiaContadoresComponent } from './area-aberta/features/informacao-cidadao/credenciamento-contadores/noticia-contadores/noticia-contadores.component';
import { DiarioOficialComponent } from './area-aberta/features/informacao-cidadao/diario-oficial/diario-oficial.component';
import { EnderecosTelefonesUteisComponent } from './area-aberta/features/informacao-cidadao/enderecos-telefones-uteis/enderecos-telefones-uteis.component';
import { LocalizarRegionaisComponent } from './area-aberta/features/informacao-cidadao/localizar-regionais/localizar-regionais.component';
import { VisualizarComunicadoComponent } from './area-aberta/features/informacao-cidadao/visualizar-comunicado/visualizar-comunicado.component';
import { ApresentacaoComponent } from './area-aberta/features/institucional/conselho/apresentacao/apresentacao.component';
import { AtribuicoesComponent } from './area-aberta/features/institucional/conselho/atribuicoes/atribuicoes.component';
import { ComposicaoConselhoComponent } from './area-aberta/features/institucional/conselho/composicao-conselho/composicao-conselho.component';
import { LegislacaoConselhoComponent } from './area-aberta/features/institucional/conselho/legislacao-conselho/legislacao-conselho.component';
import { MomentoVirtualProcuradorComponent } from './area-aberta/features/institucional/conselho/momento-virtual-procurador/momento-virtual-procurador.component';
import { AutarquiasComponent } from './area-aberta/features/institucional/consultoria-geral/autarquias/autarquias.component';
import { ConsultoriasComponent } from './area-aberta/features/institucional/consultoria-geral/consultorias/consultorias.component';
import { EspecializadasConsultoriaGeralComponent } from './area-aberta/features/institucional/consultoria-geral/especializadas-consultoria-geral/especializadas-consultoria-geral.component';
import { SubprocuradoriaConsultoriaGeralComponent } from './area-aberta/features/institucional/consultoria-geral/subprocuradoria-consultoria-geral/subprocuradoria-consultoria-geral.component';
import { EspecializadasContenciosoGeralComponent } from './area-aberta/features/institucional/contencioso-geral/especializadas-contencioso-geral/especializadas-contencioso-geral.component';
import { RegionaisContenciosoGeralComponent } from './area-aberta/features/institucional/contencioso-geral/regionais-contencioso-geral/regionais-contencioso-geral.component';
import { SubprocuradoriaContenciosoGeralComponent } from './area-aberta/features/institucional/contencioso-geral/subprocuradoria-contencioso-geral/subprocuradoria-contencioso-geral.component';
import { ApresentacaoComposicaoComponent } from './area-aberta/features/institucional/corregedoria/apresentacao-composicao/apresentacao-composicao.component';
import { ExCorregedoresGeraisPgeComponent } from './area-aberta/features/institucional/corregedoria/ex-corregedores-gerais-pge/ex-corregedores-gerais-pge.component';
import { LegislacaoCorregedoriaComponent } from './area-aberta/features/institucional/corregedoria/legislacao-corregedoria/legislacao-corregedoria.component';
import { ExProcuradoresGeraisComponent } from './area-aberta/features/institucional/ex-procuradores-gerais/ex-procuradores-gerais.component';
import { AssessoriasComponent } from './area-aberta/features/institucional/gabinete/assessorias/assessorias.component';
import { ComposicaoComponent } from './area-aberta/features/institucional/gabinete/composicao/composicao.component';
import { HistoricoComponent } from './area-aberta/features/institucional/historico/historico.component';
import { LegislacaoComponent } from './area-aberta/features/institucional/legislacao/legislacao.component';
import { ProcuradoriasRegionaisComponent } from './area-aberta/features/institucional/procuradorias-regionais/procuradorias-regionais.component';
import { QuemSomosComponent } from './area-aberta/features/institucional/quem-somos/quem-somos.component';
import { PR01Component } from './area-aberta/features/institucional/regionais/pr01/pr01.component';
import { PR02Component } from './area-aberta/features/institucional/regionais/pr02/pr02.component';
import { PR03Component } from './area-aberta/features/institucional/regionais/pr03/pr03.component';
import { PR04Component } from './area-aberta/features/institucional/regionais/pr04/pr04.component';
import { PR05Component } from './area-aberta/features/institucional/regionais/pr05/pr05.component';
import { PR06Component } from './area-aberta/features/institucional/regionais/pr06/pr06.component';
import { PR07Component } from './area-aberta/features/institucional/regionais/pr07/pr07.component';
import { PR08Component } from './area-aberta/features/institucional/regionais/pr08/pr08.component';
import { PR09Component } from './area-aberta/features/institucional/regionais/pr09/pr09.component';
import { PR10Component } from './area-aberta/features/institucional/regionais/pr10/pr10.component';
import { PR11Component } from './area-aberta/features/institucional/regionais/pr11/pr11.component';
import { PR12Component } from './area-aberta/features/institucional/regionais/pr12/pr12.component';
import { EspecializadasTributarioFiscalComponent } from './area-aberta/features/institucional/tributario-fiscal/especializadas-tributario-fiscal/especializadas-tributario-fiscal.component';
import { RegionaisTributarioFiscalComponent } from './area-aberta/features/institucional/tributario-fiscal/regionais-tributario-fiscal/regionais-tributario-fiscal.component';
import { SubprocuradoriaTributarioFiscalComponent } from './area-aberta/features/institucional/tributario-fiscal/subprocuradoria-tributario-fiscal/subprocuradoria-tributario-fiscal.component';
import { MinutasPadronizadasComponent } from './area-aberta/features/minutas-padronizadas/minutas-padronizadas.component';
import { AtendimentoComponent } from './area-aberta/features/ouvidoria/atendimento/atendimento.component';
import { QuemSomosOuvidoriaComponent } from './area-aberta/features/ouvidoria/quem-somos-ouvidoria/quem-somos-ouvidoria.component';
import { RelatoriosComponent } from './area-aberta/features/ouvidoria/relatorios/relatorios.component';
import { PlanejamentoEstrategicoComponent } from './area-aberta/features/planejamento-estrategico/planejamento-estrategico.component';
import { ProcurandoSaberComponent } from './area-aberta/features/procurando-saber/procurando-saber.component';
import { ProgramaEstagioComponent } from './area-aberta/features/programa-estagio/programa-estagio.component';
import { DividaAtivaComponent } from './area-aberta/features/servicos/divida-ativa/divida-ativa.component';
import { PerguntasFrequentesComponent } from './area-aberta/features/servicos/portal-precatorios/perguntas-frequentes/perguntas-frequentes.component';
import { PortalPrecatoriosComponent } from './area-aberta/features/servicos/portal-precatorios/portal-precatorios.component';
import { ReparacaoDanosComponent } from './area-aberta/features/servicos/reparacao-danos/reparacao-danos.component';
import { SubmenuComponent } from './area-aberta/features/submenu/submenu.component';
import { authGuard } from './area-restrita/core/guards/auth.guard';
import { HomeAreaRestritaComponent } from './area-restrita/core/layout/home-area-restrita/home-area-restrita.component';
import { AnalisePedidoAuxilioSaudeComponent } from './area-restrita/features/auxilio/saude/analise-pedido-auxilio-saude/analise-pedido-auxilio-saude.component';
import { ApespImporteComponent } from './area-restrita/features/auxilio/saude/apesp-importe/apesp-importe.component';
import { AuxilioFinanceiroComponent } from './area-restrita/features/auxilio/saude/auxilio-financeiro/auxilio-financeiro.component';
import { FichaAnaliseInscricaoComponent } from './area-restrita/features/auxilio/saude/ficha-analise-inscricao/ficha-analise-inscricao.component';
import { FichaAuxilioSaudeComponent } from './area-restrita/features/auxilio/saude/ficha-auxilio-saude/ficha-auxilio-saude.component';
import { MesaAnaliseInscricaoComponent } from './area-restrita/features/auxilio/saude/mesa-analise-inscricao/mesa-analise-inscricao.component';
import { MesaAnalisePedidoAuxilioSaudeComponent } from './area-restrita/features/auxilio/saude/mesa-analise-pedido-auxilio-saude/mesa-analise-pedido-auxilio-saude.component';
import { PedidoAuxilioSaudeComponent } from './area-restrita/features/auxilio/saude/pedido-auxilio-saude/pedido-auxilio-saude.component';
import { RelatorioAuxilioSaudeComponent } from './area-restrita/features/auxilio/saude/relatorio-auxilio-saude/relatorio-auxilio-saude.component';
import { SiafemImporteComponent } from './area-restrita/features/auxilio/saude/siafem-importe/siafem-importe.component';
import { SolicitacaoAuxilioSaudeComponent } from './area-restrita/features/auxilio/saude/solicitacao-auxilio-saude/solicitacao-auxilio-saude.component';
import { HomeInfoAreaRestritaComponent } from './area-restrita/features/home/home-info-area-restrita/home-info-area-restrita.component';
import { HomeNoticiaComponent } from './area-restrita/features/home/home-noticia/home-noticia.component';
import { AlterarSenhaCardComponent } from './area-restrita/features/login/alterar-senha-card/alterar-senha-card.component';
import { EsqueciSenhaCardComponent } from './area-restrita/features/login/esqueci-senha-card/esqueci-senha-card.component';
import { LoginCardComponent } from './area-restrita/features/login/login-card/login-card.component';
import { ConsultarNoticiasComponent } from './area-restrita/features/noticia/consultar-noticias/consultar-noticias.component';
import { CriarNoticiaComponent } from './area-restrita/features/noticia/criar-noticia/criar-noticia.component';
import { EditarNoticiaComponent } from './area-restrita/features/noticia/editar-noticia/editar-noticia.component';
import { VisualizarNoticiaComponent } from './area-restrita/features/noticia/visualizar-noticia/visualizar-noticia.component';
import { LimiteMensalAuxilioSaudeComponent } from './area-restrita/features/auxilio/saude/limite-mensal-auxilio-saude/limite-mensal-auxilio-saude.component';
import { LogoutComponent } from './area-restrita/features/logout/logout.component';
import { PortalTributarioFiscalComponent } from './area-restrita/features/legado/portal-tributario-fiscal/portal-tributario-fiscal.component';
import { PrivacidadeProtecaoDadosComponent } from './area-aberta/features/privacidade-protecao-dados/privacidade-protecao-dados.component';

export const routes: Routes = [
  { path: '', component: HomeAreaAbertaComponent },
  { path: 'procurando-saber', component: ProcurandoSaberComponent },
  { path: 'institucional/quem-somos', component: QuemSomosComponent },
  { path: 'institucional/gabinete/assessorias', component: AssessoriasComponent },
  { path: 'institucional/gabinete/composicao', component: ComposicaoComponent },
  { path: 'institucional/corregedoria/ex-corregedores-gerais', component: ExCorregedoresGeraisPgeComponent },
  { path: 'institucional/corregedoria/apresentacao-composicao', component: ApresentacaoComposicaoComponent },
  { path: 'institucional/corregedoria/legislacao', component: LegislacaoCorregedoriaComponent },
  { path: 'institucional/conselho/apresentacao', component: ApresentacaoComponent },
  { path: 'institucional/conselho/composicao', component: ComposicaoConselhoComponent },
  { path: 'institucional/conselho/atribuicoes', component: AtribuicoesComponent },
  { path: 'institucional/conselho/legislacao', component: LegislacaoConselhoComponent },
  { path: 'institucional/conselho/momento-virtual-procurador', component: MomentoVirtualProcuradorComponent },
  { path: 'institucional/consultoria-geral/autarquias', component: AutarquiasComponent },
  { path: 'institucional/consultoria-geral/especializadas', component: EspecializadasConsultoriaGeralComponent },
  { path: 'institucional/consultoria-geral/consultorias', component: ConsultoriasComponent },
  { path: 'institucional/consultoria-geral/subprocuradoria', component: SubprocuradoriaConsultoriaGeralComponent },
  { path: 'institucional/contencioso-geral/subprocuradoria', component: SubprocuradoriaContenciosoGeralComponent },
  { path: 'institucional/contencioso-geral/especializadas', component: EspecializadasContenciosoGeralComponent },
  { path: 'institucional/contencioso-geral/regionais', component: RegionaisContenciosoGeralComponent },
  { path: 'institucional/regionais/PR01', component: PR01Component },
  { path: 'institucional/regionais/PR02', component: PR02Component },
  { path: 'institucional/regionais/PR03', component: PR03Component },
  { path: 'institucional/regionais/PR04', component: PR04Component },
  { path: 'institucional/regionais/PR05', component: PR05Component },
  { path: 'institucional/regionais/PR06', component: PR06Component },
  { path: 'institucional/regionais/PR07', component: PR07Component },
  { path: 'institucional/regionais/PR08', component: PR08Component },
  { path: 'institucional/regionais/PR09', component: PR09Component },
  { path: 'institucional/regionais/PR10', component: PR10Component },
  { path: 'institucional/regionais/PR11', component: PR11Component },
  { path: 'institucional/regionais/PR12', component: PR12Component },
  { path: 'institucional/tributario-fiscal/subprocuradoria', component: SubprocuradoriaTributarioFiscalComponent },
  { path: 'institucional/tributario-fiscal/especializadas', component: EspecializadasTributarioFiscalComponent },
  { path: 'institucional/tributario-fiscal/regionais', component: RegionaisTributarioFiscalComponent },
  { path: 'institucional/procuradorias-regionais', component: ProcuradoriasRegionaisComponent },
  { path: 'institucional/historico', component: HistoricoComponent },
  { path: 'institucional/ex-procuradores-gerais', component: ExProcuradoresGeraisComponent },
  { path: 'institucional/legislacao-pesquisa', component: LegislacaoComponent },
  { path: 'servicos/portal-precatorios', component: PortalPrecatoriosComponent },
  { path: 'servicos/portal-precatorios/perguntas-frequentes', component: PerguntasFrequentesComponent },
  { path: 'servicos/reparacao-danos', component: ReparacaoDanosComponent },
  { path: 'servicos/atendimento-cidadao', component: EnderecosTelefonesUteisComponent },
  { path: 'ouvidoria/quem-somos', component: QuemSomosOuvidoriaComponent },
  { path: 'ouvidoria/atendimento', component: AtendimentoComponent },
  { path: 'ouvidoria/relatorios', component: RelatoriosComponent },
  { path: 'informacao-cidadao/atendimento-protestos-icms-ipva-itcmd-certidao-negativa', component: AtendimentoProtestosIcmsIpvaItcmdCertidaoNegativaComponent },
  { path: 'informacao-cidadao/diario-oficial', component: DiarioOficialComponent },
  { path: 'informacao-cidadao/cepen', component: CertidaoNegativaComponent },
  { path: 'informacao-cidadao/comunicados', component: ComunicadosComponent },
  { path: 'informacao-cidadao/comunicado/:id', component: VisualizarComunicadoComponent },
  { path: 'informacao-cidadao/enderecos-telefones-uteis', component: EnderecosTelefonesUteisComponent },
  { path: 'informacao-cidadao/localizar-regionais', component: LocalizarRegionaisComponent },
  { path: 'informacao-cidadao/credenciamento-contadores', component: CredenciamentoContadoresComponent },
  { path: 'informacao-cidadao/credenciamento-contadores/noticia/:id', component: NoticiaContadoresComponent },
  { path: 'programa-estagio', component: ProgramaEstagioComponent },
  { path: 'planejamento-estrategico', component: PlanejamentoEstrategicoComponent },
  { path: 'minutas-padronizadas', component: MinutasPadronizadasComponent },
  { path: 'servicos/divida-ativa', component: DividaAtivaComponent },
  { path: 'noticias-diversas', component: NoticiasDiversasComponent, pathMatch: 'full' },
  { path: 'uma-noticia/:id', component: UmaNoticiaComponent },
  { path: 'login', component: LoginCardComponent, canActivate: [authGuard] },
  { path: 'esqueci-senha', component: EsqueciSenhaCardComponent },
  { path: 'alterar-senha', component: AlterarSenhaCardComponent },
  { path: 'menu/:opcao', component: SubmenuComponent },
  { path: 'comunicar-erro', component: ComunicarErroComponent },
  { path: 'lgpd', component: PrivacidadeProtecaoDadosComponent },
  { path: 'area-restrita/contencioso-tributario-fiscal/portal-tributario-fiscal', component: PortalTributarioFiscalComponent, canActivate: [authGuard] },
  {
    path: 'area-restrita',
    component: HomeAreaRestritaComponent,
    canActivate: [authGuard],
    pathMatch: 'prefix',
    children: [
      { path: 'home', component: HomeInfoAreaRestritaComponent },
      { path: 'logout-site-pge', component: LogoutComponent },
      { path: 'noticia-home/:id', component: HomeNoticiaComponent },
      {
        path: 'administracao-auxilio-saude',
        children: [
          { path: 'analisar-pedido', component: MesaAnalisePedidoAuxilioSaudeComponent },
          { path: 'analisar-inscricao', component: MesaAnaliseInscricaoComponent },
          { path: 'financeiro', component: AuxilioFinanceiroComponent },
          { path: 'relatorio', component: RelatorioAuxilioSaudeComponent },
          { path: 'limite-mensal', component: LimiteMensalAuxilioSaudeComponent }
        ]
      },
      {
        path: 'administracao-noticia',
        children: [{ path: 'cadastrar-noticia', component: ConsultarNoticiasComponent }]
      },
      {
        path: 'auxilio-saude',
        children: [
          { path: 'solicitacao-reembolso', component: SolicitacaoAuxilioSaudeComponent },
          { path: 'ficha-inscricao', component: FichaAuxilioSaudeComponent },
          { path: 'dados-pedido', component: PedidoAuxilioSaudeComponent }
        ]
      },

      { path: 'analise-pedido-auxilio-saude/:id', component: AnalisePedidoAuxilioSaudeComponent },
      { path: 'inscricao-auxilio-avaliacao/:id', component: FichaAuxilioSaudeComponent },
      { path: 'auxilio-financeiro', component: AuxilioFinanceiroComponent },
      { path: 'apesp-importe', component: ApespImporteComponent },
      { path: 'siafem-importe', component: SiafemImporteComponent },
      { path: 'criar-noticia', component: CriarNoticiaComponent },
      { path: 'editar-noticia/:id', component: EditarNoticiaComponent },
      { path: 'visualizar-noticia/:id', component: VisualizarNoticiaComponent },
      { path: 'analisar-inscricao/:id', component: FichaAnaliseInscricaoComponent },
      { path: 'pedido-auxilio-saude/:id', component: SolicitacaoAuxilioSaudeComponent },
      { path: 'alterar-senha-area-restrita', component: AlterarSenhaCardComponent },
      { path: '**', redirectTo: 'home' }
    ]
  }
];
