import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ImagemTratamento {
  private readonly urlBlobCache: Map<string, string> = new Map<string, string>();

  public obterImagemBlob(noticiaImagem?: string): string | undefined {
    if (!noticiaImagem) {
      return undefined;
    }

    if (this.urlBlobCache.has(noticiaImagem)) {
      return this.urlBlobCache.get(noticiaImagem);
    } else {
      const blobImagem = this.base64ParaBlob(noticiaImagem);

      const urlCreator = typeof window.URL !== 'undefined' ? window.URL : window.webkitURL;
      const blobUrl = urlCreator.createObjectURL(blobImagem);
      this.urlBlobCache.set(noticiaImagem, blobUrl);
      return blobUrl;
    }
  }

  private base64ParaBlob(base64: string): Blob {
    const byteCaracteres = atob(base64);
    const byteNumeros = new ArrayBuffer(byteCaracteres.length);
    const uintVetor = new Uint8Array(byteNumeros);

    for (let i = 0; i < byteCaracteres.length; i++) {
      uintVetor[i] = byteCaracteres.charCodeAt(i);
    }

    const tipoImagem = this.verificarTipoImagem(uintVetor);
    const blob = new Blob([byteNumeros], { type: tipoImagem });
    return blob;
  }

  private verificarTipoImagem(byteVetor: Uint8Array): string {
    const assinaturas = new Map<number[], string>([
      [[0xff, 0xd8, 0xff], 'image/jpeg'],
      [[0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a], 'image/png'],
      [[0x47, 0x49, 0x46, 0x38], 'image/gif'],
      [[0x49, 0x49, 0x2a, 0x00], 'image/tiff'],
      [[0x42, 0x4d], 'image/bmp'],
      [[0x52, 0x49, 0x46, 0x46, 0x57, 0x45, 0x42, 0x50], 'image/webp'],
      [[0x3c, 0x3f, 0x78, 0x6d, 0x6c, 0x20, 0x76, 0x65, 0x72, 0x73, 0x69, 0x6f, 0x6e, 0x3d], 'image/svg'],
      [[0x00, 0x00, 0x01, 0x00], 'image/ico']
    ]);

    for (const [assinatura, tipo] of assinaturas.entries()) {
      if (byteVetor.length >= assinatura.length) {
        let match = true;
        for (let i = 0; i < assinatura.length; i++) {
          if (byteVetor[i] !== assinatura[i]) {
            match = false;
            break;
          }
        }
        if (match) {
          return tipo;
        }
      }
    }
    console.error(`Tipo de imagem não identificado`);
    return 'application/octet-stream';
  }

  public revogarUrlBlob(): void {
    for (const urlBlob of this.urlBlobCache.values()) {
      URL.revokeObjectURL(urlBlob);
    }
    this.urlBlobCache.clear();
  }
}
