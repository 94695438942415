import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDivider } from '@angular/material/divider';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/area-restrita/shared/services/authentication.service';
import type { UsuarioLogin } from 'src/app/shared/models/usuario-login.interface';
import { ToastService } from 'src/app/shared/toast/toast.service';
import { FundoAreaRestritaPgeComponent } from '../shared/components/fundo-area-restrita-pge/fundo-area-restrita-pge.component';
import { SenhaService } from '../shared/services/senha.service';

@Component({
  selector: 'app-esqueci-senha-card',
  templateUrl: './esqueci-senha-card.component.html',
  styleUrl: './esqueci-senha-card.component.scss',
  standalone: true,
  imports: [FundoAreaRestritaPgeComponent, FormsModule, MatProgressSpinner, MatButton, MatDivider],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EsqueciSenhaCardComponent {
  private readonly authService = inject(AuthenticationService);
  private readonly senhaService = inject(SenhaService);
  private readonly toastr = inject(ToastService);

  private readonly router = inject(Router);
  email = signal<string | null>('');
  enviado = signal(false);
  constructor() {
    this.senhaService.user$.subscribe({
      next: (user: UsuarioLogin | null) => {
        if (user) {
          this.definirEmail(user.email);
          this.email.set(user.email);
          localStorage.setItem('idUsuario', user.idUsuario.toString());
        } else {
          this.definirEmail(localStorage.getItem('email'));
          this.email.set(localStorage.getItem('email'));
        }
      }
    });
  }

  confirmaEsqueciSenha(): void {
    this.enviado.set(true);
    this.senhaService.confirmarEsqueciSenha().subscribe({
      next: () => {
        this.toastr.success('Sucesso ao enviar email para troca de senha!');
        this.enviado.set(false);
        this.authService.logout();
      },
      error: (error) => {
        console.log(error);
        this.toastr.error('Falha no envio de email, tente novamente mais tarde!');
        this.voltarParaLogin();
        this.enviado.set(false);
      }
    });
  }

  definirEmail(email: string | null): void {
    if (!email) {
      this.voltarParaLogin();
      return;
    }
    this.email.set(email);
  }

  voltarParaLogin(): void {
    void this.router.navigate(['/login']);
  }
}
