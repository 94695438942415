import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatNativeDateModule, MatOption } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInput, MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatFormField, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import type { PageEvent } from '@angular/material/paginator';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import type { SafeHtml } from '@angular/platform-browser';
import { NgxMaskDirective } from 'ngx-mask';
import { Subject, finalize, takeUntil } from 'rxjs';
import type { DependenteParetesco } from 'src/app/area-restrita/features/auxilio/saude/shared/models/dependente-parentesco';
import { AuxilioSaudeService } from 'src/app/area-restrita/features/auxilio/saude/shared/services/auxilio-saude.service';
import { WaitLoadingService } from 'src/app/area-restrita/shared/components/wait-loading/wait-loading.service';
import { PaginaVisitadaService } from 'src/app/area-restrita/shared/services/pagina-visitada.service';
import { ToastService } from 'src/app/shared/toast/toast.service';
import type { StatusAuxilio } from '../shared/models/auxilio-saude';
import { FabricaRelatorioPedidoAuxilio } from '../shared/models/fabrica-relatorio-pedido-auxilio';
import type { TipoAuxilioSaude } from '../shared/models/tipo-auxilio-saude';
import { MesaAuxilioSaudeService } from '../shared/services/mesa-auxilio-saude.service';
import { RelatorioAuxilioSaudeService } from '../shared/services/relatorio-auxilio-saude.service';
import { ReportAuxilioSaudeService } from '../shared/services/report-auxilio-saude.service';
import { SolicitacaoAuxilioSaudeService } from '../shared/services/solicitacao-auxilio-saude.service';
import { FabricaRelatorioDependente } from './../shared/models/fabrica-relatorio-dependente';

@Component({
  selector: 'app-relatorio-auxilio-saude',
  templateUrl: './relatorio-auxilio-saude.component.html',
  styleUrl: './relatorio-auxilio-saude.component.scss',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatSelect,
    MatOption,
    MatInput,
    NgxMaskDirective,
    MatDatepickerInput,
    MatDatepickerToggle,
    MatSuffix,
    MatDatepicker,
    MatButton,
    MatPaginator,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RelatorioAuxilioSaudeComponent implements OnInit {
  private readonly solicitacaoAuxilioSaudeService = inject(SolicitacaoAuxilioSaudeService);
  private readonly reportAuxilioSaude = inject(ReportAuxilioSaudeService);
  private readonly reportAuxilioSaudeService = inject(AuxilioSaudeService);
  private readonly relatorioPedidoAuxilio = inject(FabricaRelatorioPedidoAuxilio);
  private readonly relatorioPedidoAuxilioService = inject(RelatorioAuxilioSaudeService);
  private readonly mesaAuxilioSaudeService = inject(MesaAuxilioSaudeService);
  private readonly relatorioDependente = inject(FabricaRelatorioDependente);
  private readonly waitLoadingService = inject(WaitLoadingService);
  private readonly toastr = inject(ToastService);
  private readonly paginaService = inject(PaginaVisitadaService);

  resultadoPesquisa = signal<SafeHtml>(null);
  parentesco: DependenteParetesco[] = [];
  formulario: FormGroup;
  gerando = signal(false);

  comprimento = signal(0);
  tamanhoDaPagina = signal(10);
  numeroPagina = signal(0);
  opcoesDoTamanhoDaPagina = [5, 10, 25];

  statusAuxilio = signal<StatusAuxilio[]>([]);
  tiposAuxilioSaude = signal<TipoAuxilioSaude[]>([]);

  relatorioOpcoes = [
    { id: 1, nome: 'Pedidos' },
    { id: 2, nome: 'Dependentes' }
  ];

  private readonly destroy$ = new Subject<void>();

  ngOnInit(): void {
    this.paginaService.salvaPagina('Relatório de Auxílio Saúde').subscribe();
    this.parentesco = this.reportAuxilioSaude.getParentesco();
    this.obterTiposStatus();
    this.obterListaTipoAuxilioSaude();
    this.formulario = new FormGroup({
      status: new FormControl(''),
      tipoRelatorio: new FormControl(this.relatorioOpcoes[0].id),
      nomeTitular: new FormControl(''),
      cpfTitular: new FormControl(''),
      nomeDependente: new FormControl(''),
      cpfDependente: new FormControl(''),
      parentesco: new FormControl(99),
      exportaPdfCsv: new FormControl(''),
      mesAnoSolicitacao: new FormControl(''),
      vencimentoParcela: new FormControl(''),
      valorReembolso: new FormControl(''),
      dataAvaliacao: new FormControl(''),
      tipoAuxilioSaude: new FormControl('')
    });
  }

  obterTiposStatus() {
    this.mesaAuxilioSaudeService
      .obterListaStatusAuxilio()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (dados) => {
          dados.sort((a, b) => a.id - b.id);
          this.statusAuxilio.set(dados);
        },
        error: (error) => {
          console.error('Erro ao obter dados do serviço', error);
        }
      });
  }

  limpar(): void {
    this.formulario = new FormGroup({
      status: new FormControl(this.statusAuxilio()[0].id),
      tipoRelatorio: new FormControl(this.relatorioOpcoes[0].id),
      nomeTitular: new FormControl(''),
      cpfTitular: new FormControl(''),
      nomeDependente: new FormControl(''),
      cpfDependente: new FormControl(''),
      parentesco: new FormControl(99),
      exportaPdfCsv: new FormControl(''),
      mesAnoSolicitacao: new FormControl(''),
      vencimentoParcela: new FormControl(''),
      valorReembolso: new FormControl(''),
      dataAvaliacao: new FormControl(''),
      tipoAuxilioSaude: new FormControl('')
    });
    this.comprimento.set(0);
    this.tamanhoDaPagina.set(10);
    this.numeroPagina.set(0);
    this.gerando.set(false);
  }

  executarPesquisa() {
    this.numeroPagina.set(0);
    this.comprimento.set(0);
    void this.obterPesquisa();
  }

  async obterPesquisa(): Promise<void> {
    this.gerando.set(false);

    const indice: number = this.formulario.get('parentesco').value;
    const parentesco = indice == 99 ? '' : this.parentesco[indice].nome;

    if (this.formulario.controls['tipoRelatorio'].value == 2) {
      await this.relatorioDependente.criarRelatorio(
        this.formulario.get('nomeTitular').value,
        this.formulario.get('cpfTitular').value,
        this.formulario.get('nomeDependente').value,
        this.formulario.get('cpfDependente').value,
        parentesco,
        this.numeroPagina(),
        this.tamanhoDaPagina()
      );

      this.resultadoPesquisa.set(this.relatorioDependente.getTabela());
      this.comprimento.set(this.relatorioDependente.getTotalElementos());
      this.numeroPagina.set(this.relatorioDependente.getPageNumber());
      this.tamanhoDaPagina.set(this.relatorioDependente.getPageSize());
      this.gerando.set(true);
    } else {
      await this.relatorioPedidoAuxilio.criarRelatorio(this.formulario, this.numeroPagina(), this.tamanhoDaPagina());

      this.resultadoPesquisa.set(this.relatorioPedidoAuxilio.getTabela());
      this.comprimento.set(this.relatorioPedidoAuxilio.getTotalElementos());
      this.numeroPagina.set(this.relatorioPedidoAuxilio.getPageNumber());
      this.tamanhoDaPagina.set(this.relatorioPedidoAuxilio.getPageSize());
      this.gerando.set(true);
    }
  }

  obterPdfCsv(tipo: string) {
    this.gerando.set(false);
    const indice: number = this.formulario.get('parentesco').value;
    const parentesco = indice == 99 ? '' : this.parentesco[indice].nome;

    if (this.formulario.get('tipoRelatorio').value == 2) {
      this.reportAuxilioSaudeService
        .relatorioPesquisaDependentesTipo(
          this.formulario.get('nomeTitular').value,
          this.formulario.get('cpfTitular').value,
          this.formulario.get('nomeDependente').value,
          this.formulario.get('cpfDependente').value,
          parentesco,
          tipo.toUpperCase()
        )
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => {
            this.waitLoadingService.close();
          })
        )
        .subscribe({
          next: (response: Blob) => {
            const url = window.URL.createObjectURL(response);
            window.open(url);
          },
          error: (error) => {
            this.toastr.error(error.message);
          }
        });
    } else if (this.formulario.get('tipoRelatorio').value == 1) {
      this.relatorioPedidoAuxilioService
        .relatorioPesquisaPedidosAuxilioTipo(
          this.formulario.get('status').value,
          this.formulario.get('nomeTitular').value,
          this.formulario.get('cpfTitular').value,
          this.formulario.get('nomeDependente').value,
          this.formulario.get('cpfDependente').value,
          parentesco,
          tipo.toUpperCase()
        )
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => {
            this.waitLoadingService.close();
          })
        )
        .subscribe({
          next: (response: Blob) => {
            const url = window.URL.createObjectURL(response);
            window.open(url);
          },
          error: (error) => {
            this.toastr.error(error.error);
          }
        });
    }
  }

  lidarEventoPagina(e: PageEvent) {
    console.log('pageEvent', e);

    this.comprimento.set(e.length);
    this.tamanhoDaPagina.set(e.pageSize);
    this.numeroPagina.set(e.pageIndex);
    void this.obterPesquisa();
  }

  obterListaTipoAuxilioSaude(): void {
    this.solicitacaoAuxilioSaudeService.obterListaTipoAuxilioSaude().subscribe((response) => {
      this.tiposAuxilioSaude.set(response);
    });
  }
}
