import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SeparadorComponent } from '../../../../core/layout/separador/separador.component';

@Component({
  selector: 'app-atribuicoes',
  templateUrl: './atribuicoes.component.html',
  standalone: true,

  imports: [SeparadorComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AtribuicoesComponent {}
