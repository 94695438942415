<main class="main" role="main">
  <section class="page">
    <div class="container">
      <app-separador titulo="Procuradoria Regional de Santos (PR02)" />
      <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 the_content">
          <br />
          <h3>PR02 CHEFIA GABINETE</h3>
          <div class="pge-unidade">
            <p class="procurador"><strong>Procurador(a)</strong>: {{ encontrarNome("ADRIANA BRIENCE DA SILVA CORREA") }}</p>
            <p class="endereco"><strong>Endereço</strong>: Rua Marcílio Dias, 170, 4º andar, Gonzaga, Santos-SP, 11060-210.</p>
            <p class="tel"><strong>Telefone</strong>: (13) 3285-9825 | (13) 3285-9847</p>
            <p class="email"><strong>E-mail</strong>: pr2atendimento&#64;sp.gov.br</p>
            <div class="descricao">
              <strong>Municípios abrangidos:</strong> Barra do Turvo, Bertioga, Cajati, Cananéia, Cubatão, Eldorado, Guarujá, Iporanga, Iguape, Ilha Comprida, Itanhaém, Itariri, Jacupiranga, Juquiá,
              Miracatu, Mongaguá, Pariquera-Açu, Pedro de Toledo, Peruíbe, Praia Grande, Registro, Santos, São Vicente e Sete Barras.
            </div>
          </div>
          <br />
          <hr />
          <br />
          <h3>SECCIONAL VALE DA RIBEIRA</h3>
          <div class="pge-unidade">
            <p class="procurador"><strong>Procurador(a)</strong>: {{ encontrarNome("FELIPE SORDI MACEDO") }}</p>
            <p class="endereco"><strong>Endereço</strong>: Rua Wild José de Souza, 456, Vila Tupy, Registro-SP, 11900-000.</p>
            <p class="tel"><strong>Telefone</strong>: (13) 2130-4106</p>
          </div>
          <br />
          <hr />
        </div>
      </div>
      <br />
      <app-voltar-component />
      <br />
    </div>
  </section>
</main>
