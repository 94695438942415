<nav class="navbar navbar-expand-lg barra-brasao">
  <div class="container-brasao" tabindex="0" (click)="paginaInicial()" (keypress)="paginaInicial()">
    @if (tema() === "claro") {
      <img class="brasao" alt="Brasão PGE" src="images/brasao.svg" tabindex="0" (click)="paginaInicial()" (keypress)="paginaInicial()" />
    }
    @if (tema() === "escuro") {
      <img class="brasao" alt="Brasão PGE" src="images/brasao_Vnegativo.svg" tabindex="0" (click)="paginaInicial()" (keypress)="paginaInicial()" />
    }
    <div class="iconesNav justify-content-end">
      <mat-icon matTooltip="Aumentar a fonte" class="icones" onKeyDown="incrementarFonte()" (click)="incrementarFonte()">text_increase</mat-icon>
      <mat-icon matTooltip="Diminuir a fonte" class="icones" onKeyDown="decrementarFonte()" (click)="decrementarFonte()">text_decrease</mat-icon>
      <mat-icon matTooltip="Alterar o tema" class="icones" onKeyDown="alterarTema()" (click)="alterarTema()">contrast</mat-icon>
      <a href="comunicar-erro" matTooltip="Comunicar Erros" aria-label="Comunicar Erros" class="icones">
        <mat-icon class="warning_amber">warning_amber</mat-icon>
      </a>
    </div>
  </div>
</nav>
